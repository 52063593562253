<h4 class="bold w-87">{{ 'app.ai.ai_content_prediction' | translate }}</h4>
<main class="shadowBorder textDetectorContainer">
    <div class="header">
        <h2>{{ 'app.ai.ai_content_prediction' | translate }}</h2>
        <p>{{ 'app.ai.use_our_ai_prediction_to_check_text' | translate }}.</p>
    </div>

    <div class="content">
        <div class="textContainer">
            <select class="selectModels"  [(ngModel)]="modelSelected" (change)="checkModel($event.target.value)">
                <option value="default">{{ 'app.default' | translate }}</option>
                <option value="model_1">{{ 'general.model' | translate }} 1</option>
                <option value="model_2">{{ 'general.model' | translate }} 2</option>
                <option value="model_3">{{ 'general.model' | translate }} 3</option>
                <option value="model_4">{{ 'general.model' | translate }} 4</option>
                <option value="model_5">{{ 'general.model' | translate }} 5</option>
                <option value="model_6">{{ 'general.model' | translate }} 6</option>
                <option value="model_7">{{ 'general.model' | translate }} 7</option>
                <option value="model_8">{{ 'general.model' | translate }} 8</option>
                <option value="model_9">{{ 'general.model' | translate }} 9</option>
                <option value="model_10">{{ 'general.model' | translate }} 10</option>
                <option value="model_11">{{ 'general.model' | translate }} 11</option>
            </select>
            <h4>{{ 'app.text_box' | translate }}</h4>
            <textarea [placeholder]="'placeholders.paste_or_write_text' | translate" [(ngModel)]="text" (ngModelChange)="checkDefault()"  name="" id="" cols="30" rows="10"></textarea>
        </div>
    <div class="dataConatiner">
        <h4>{{ 'app.results' | translate }}</h4>
        <div class="border">
        <div class="resultsConatiner">
            <h4 class="contentIndex">{{ 'app.ai.ai_content_index' | translate }}<i
             class="fas fa-info-circle"
             matTooltipPosition="after"
             matTooltipClass="tooltipCustomCss"
             matTooltip="AI Content Index is in the beta phase, and results may vary and may not always be accurate. Results are provided for informational purposes only and are not intended to be relied upon for any specific purpose."
          ></i></h4>
            <div class="chartContainer">
                <div [ngStyle]="{'left.%': fakeProbability}" class="pointer">
                    <span>{{fakeProbability}}%</span>
                    <img  src="../../../assets/images/Asset_2.png" alt="">
                </div>

                <img src="../../../assets/images/Results-Graph.png" alt="">
            </div>
            <!-- <div id="chart">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [plotOptions]="chartOptions.plotOptions"
                  [labels]="chartOptions.labels"
                  [fill]="chartOptions.fill"
                ></apx-chart>
              </div> -->
              <div    [ngClass]="{
                'redBorder':
                fakeProbability > 200/3 && hasText,
                'orangeBorder':
                fakeProbability > 100/3 && fakeProbability <= 200/3 && hasText,
                'greenBorder':
                fakeProbability <= 100/3 && hasText
             }"  class="description">
                {{description}}</div>
        </div>
        <div class="rates">
            <span>{{ 'app.ai.are_you_satisfied_with_the_results' | translate }}?</span> <i [ngClass]="{ likedClicked: likedClicked }" (click)="rate('like')" class="far fa-thumbs-up"></i><i [ngClass]="{ dislikeClicked: dislikeClicked }"  (click)="rate('dislike')" class="far fa-thumbs-down"></i>
        </div>
        <h4 class="disclaimer">{{ 'other.disclaimer' | translate }}</h4>
        <div >
            <ul>
                <li>{{ 'app.ai.ai_content_prediction_is_based_on_roberta_2' | translate }} <a href="https://ai.facebook.com/blog/roberta-an-optimized-method-for-pretraining-self-supervised-nlp-systems/">{{ 'other.roberta' | translate }}</a> using the <a href="https://openai.com/blog/gpt-2-1-5b-release/">{{ 'app.ai.open_ai_dataset' | translate }}</a></li>
                <li>{{ 'app.ai.ai_content_prediction_is_in_testing_phase' | translate }}.</li>
                <li>AI Content Prediction generates results by analyzing up to 1,000 words at a time. </li>
                <li>{{ 'app.ai.ai_content_prediction_works_for_english_only' | translate }}.</li>
            </ul>

        </div>
        </div>
    </div>
</div>
<div class="processing" *ngIf="processing">
    Hang tight while we analyze your text.<br/> This shouldn't take too long.
    <mat-spinner></mat-spinner>
</div>
</main>

