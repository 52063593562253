import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReportService } from '../../services/report.service';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReportComponent } from '../../report.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from 'src/app/services/language.service';

export interface Manipulation {
  includedCharacters: { character: string; count: number }[];
  excludedReplacedCharacters: any[];
  characters: any[];
  isExcluded: boolean;
}

export interface IsManipulated {
  isManipulated: boolean;
  isCharReplacement: boolean;
  isHiddenText: boolean;
}

export interface Translated {
  lang: string;
  similarity: number;
  percentage: string;
  source: any;
  isExcluded: boolean;
  comment:string
  sentence:string
  secondarySources:any
}

export interface Data {
  submissionId: string,
  text: string;
  comment: string;
  sentence: string;
  source: any;
  id: number;
  isOriginal: boolean;
  isTranslated: boolean;
  isManipulated: IsManipulated;
  isExcluded: boolean;
  isAi: boolean;
  similarity: number;
  page: number;
  manipulations: Manipulation[];
  translated: Translated[];
  secondarySources:any
}

@Component({
  selector: 'app-sentence-information',
  templateUrl: './sentence-information.component.html',
  styleUrls: ['./sentence-information.component.scss']
})
export class SentenceInformationComponent implements OnInit {
  toggledLang: string | null = null
  data: Data | null = null;
  isOriginal = false;
  isTranslated = false;
  isAi = false;
  isManipulation = false;

  sentence: string = '';
  source: string = '';
  analysisType: string;
  sentenceManipulation: string;

  result: any;
  excluded_result: any;
  selectionState!: boolean[];
  excludedSelectionState!: boolean[];
  modalType = '';

  showInsideModal = false;
  includeClickCount = 0;
  manipulationAction = '';
  excludeClickCount: number = 0;
  commentValue: string = '';
  isEditable: boolean = false;
  showExclude: boolean = false;

  isOriginalExcluded: boolean = false;
  isTranslationExcluded: boolean = false;
  isOriginalLanguage: boolean;
  showCommentButtons: boolean;
  isChangeSource = false;
  selectedSentenceSources: any;
  selectedSource: string;
  changeSourceClickCount = 0;

  formattedText!: string;
  formattedSentence!: string;

  manipulationType: string;
  @Input() context_similarity: any;
  @Input() exactMatchThreshold: number
  @Input() contextSimilarityThreshold:number
  @Input() submission: any

  selectedTransltaionCode;
  translatedObj: any;

  // Tabs for manipulation
  isOnCharReplacement = true;
  isOnHiddenText = false;

  excludedCharReplacement: boolean;
  excludedHiddenText: boolean;

  @Input() canEdit;
  constructor(
    private reportService: ReportService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private reportComponent: ReportComponent,
    private spinnerService:NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private languageService:LanguageService // dont delete this its used on HTML
  ) { }

  ngOnInit(): void {
    this.reportService.selectedTransltaionCodeObject.subscribe((data) => {
      if (data !== null) {
        this.selectedTransltaionCode = data
      }
    })
    this.reportService.selectAnalysisTypeObject.subscribe((data: string) => {
      this.analysisType = data;
    });
    if (this.analysisType !== 'none'){
       if (this.analysisType == 'manipulation') {
        this.isOriginal = false
        this.isTranslated = false
        this.isManipulation = true
        this.analysisType = 'manipulation'
      }else{
         this.reportService.selectLanguageObject.subscribe((data) => {
           if (data.type === 'Original') {
             this.isOriginal = true
             this.isTranslated = false
             this.analysisType = 'Original'
           } else if (data.type === 'Translated') {
             this.isOriginal = false
             this.isTranslated = true
             this.analysisType = 'Translated'

           }
         })
      }
    }


    this.reportService.sentenceInformationObject.subscribe((data: any) => {
      if (data) {
        console.log(data, 'sentence information');

        this.data = data;
        if (!this.data.isManipulated.isCharReplacement) {
          if (this.data?.manipulations[0]?.excludedReplacedCharacters.length > 0) {
            this.excludedCharReplacement = true;
          } else {
            this.excludedCharReplacement = false;
          }
        } else {
          this.excludedCharReplacement = false;
        }


        if (!this.data.isManipulated.isHiddenText) {
          if ((this.data?.manipulations[1]?.characters.length > 0 && this.data?.manipulations[1]?.isExcluded)) {
            this.excludedHiddenText = true;
          } else {
            this.excludedHiddenText = false;
          }
        } else {
          this.excludedHiddenText = false;
        }


        if (this.data.translated && this.data.translated.length > 0) {
          this.translatedObj = data.translated.find(lang => lang.lang === this.selectedTransltaionCode) || data.translated[0];
        }

        if (this.data?.translated.length > 0) {
          this.toggledLang = this.translatedObj?.lang;
          this.reportService.selectedTransltaionCodeSubject.next(this.toggledLang)
        }

        this.isOriginalExcluded = this.data.isExcluded
        if (this.data.translated.length > 0) {
          this.isTranslationExcluded = this.translatedObj?.isExcluded
        }
        if (this.data?.manipulations[0]?.includedCharacters) {
          this.result = this.data?.manipulations[0]?.includedCharacters;
        }
        // console.log(this.data?.manipulations[0]?.excludedReplacedCharacters, 'exclude result');
        if (this.data?.manipulations[0]?.excludedReplacedCharacters) {
          this.excluded_result = this.data?.manipulations[0]?.excludedReplacedCharacters;
        }

        if (this.result && this.result.length > 0) {
          this.selectionState = new Array(this.result.length).fill(false);
        }
        if (this.excluded_result && this.excluded_result.length > 0) {
          this.excludedSelectionState = new Array(this.excluded_result.length).fill(false);
        }

        if (this.data.isManipulated.isCharReplacement === true || this.excludedCharReplacement) {
          this.isOnCharReplacement = true;
          this.isOnHiddenText = false; // Ensure the other one is not opened
        } else if (this.data.isManipulated.isHiddenText === true || this.excludedHiddenText) {
          this.isOnCharReplacement = false; // Ensure the other one is not opened
          this.isOnHiddenText = true;
        } else {
          this.isOnCharReplacement = false;
          this.isOnHiddenText = false;
        }


        if(this.data.isManipulated.isCharReplacement === false && this.data.isManipulated.isHiddenText){
          this.reportService.selectManipulationTypeSubject.next('hiddenText')
        }else if (this.data.isManipulated.isCharReplacement  && this.data.isManipulated.isHiddenText === false){
          this.reportService.selectManipulationTypeSubject.next('charReplacement')
        }

        if (this.data?.isManipulated?.isHiddenText || this.excludedHiddenText) {
          this.formattedText = this.formatTextWithWhiteCharacters(this.data.text, this.data?.manipulations?.[1]?.characters ?? this.data?.manipulations?.[0]?.characters);
        }

        // if (this.data.source.contextSimilarity) {
        //   this.formattedSentence = this.formatSentenceWithContext();
        // }

        this.updateTabs(data);
      } else {
        this.clearTabs();
      }
    });

    this.reportService.selectManipulationTypeObject.subscribe((data: any) => {
      this.manipulationType = data;

      if (this.analysisType === 'manipulation' && this.manipulationType === 'hiddenText') {
        console.log('im on hidden text');

        this.isOnCharReplacement = false;
        this.isOnHiddenText = true;
      }
    })
  }

formatSentenceWithContext(sentence?:string): string {
    let splitParts
    if(sentence && sentence?.includes('class="context">')){
      return splitParts
    }else{
      if(sentence){
        splitParts = sentence.split(/(<em>.*?<\/em>)/g);
      }else{
        splitParts = this.isOriginal ? this.data.sentence.split(/(<em>.*?<\/em>)/g) :  this.translatedObj.source.sourceSentence.split(/(<em>.*?<\/em>)/g);
      }
    }
    let formattedSentence = '';

    splitParts.forEach(part => {
      if (part.startsWith('<em>')) {
        formattedSentence += part;
      } else {
        // Split the part by whitespace to preserve spaces and wrap only non-whitespace parts
        const words = part.split(/(\s+)/);
        words.forEach(word => {
          if (word.trim().length > 0) {
            formattedSentence += `<span class="context">${word}</span>`;
          } else {
            formattedSentence += word; // Preserve whitespace
          }
        });
      }
    });
    this.formattedText = formattedSentence;
    this.cdr.detectChanges();
    return formattedSentence;
  }

  clearSentenceInformation() {
    this.reportService.sentenceInformation(null, '');
  }

  updateTabs(data: Data) {
    this.isOriginal = data.isOriginal;
    this.isTranslated = data.isTranslated;
    this.isAi = data.isAi;
    this.isManipulation = data.isManipulated.isManipulated || this.excludedCharReplacement || this.excludedHiddenText;


    if (this.analysisType == 'none') {
      if (this.isOriginal) {
        this.activeTab('original');
      } else if (this.isTranslated) {
        this.activeTab('translated');
      } else if (this.isAi) {
        this.activeTab('ai');
      } else if (this.isManipulation) {
        this.activeTab('manipulation');
      }
    } else if (this.analysisType == 'Original' || this.analysisType == 'Translated') {
        this.activeTab(this.analysisType.toLowerCase());
    } else if (this.analysisType == 'ai') {
      if (this.isAi) {
        this.activeTab('ai');
      }
    } else if (this.analysisType == 'manipulation') {
      console.log('im on manipulation', this.manipulationType);


      if (this.isManipulation) {
        this.activeTab('manipulation');
      }

      if (this.manipulationType === 'hiddenText') {
        this.isOnCharReplacement = false;
        this.isOnHiddenText = true;
      }
    }

  }

  clearTabs() {
    this.isOriginal = false;
    this.isTranslated = false;
    this.isAi = false;
    this.isManipulation = false;
    this.sentence = '';
    this.source = '';
    this.sentenceManipulation = '';
  }

  activeTab(tab: string) {
    this.isOriginal = tab === 'original';
    this.isTranslated = tab === 'translated';
    this.isAi = tab === 'ai';
    this.isManipulation = tab === 'manipulation';
    if (this.data) {
      switch (tab) {
        case 'original':
          this.sentence = this.data.sentence;
          if (this.data.source?.contextSimilarity && this.context_similarity && this.data.source?.percentage < 60 && parseInt(this.data.source?.contextSimilarityPercentage) > this.contextSimilarityThreshold) {
            this.sentence = this.formatSentenceWithContext();
          }
          this.source = this.isOriginal ? this.data?.source?.url : this.translatedObj?.source?.url;
          this.isOriginalLanguage = true
          this.commentValue = this.data.comment
          this.showExclude = this.isOriginalExcluded
          if (this.showExclude){
            this.isEditable = false
            this.showCommentButtons = false
          }
          break;
        case 'translated':

          this.sentence = this.translatedObj?.sentence;
         if(this.analysisType === 'none'){
          for(let translated of this.data.translated){
            if (translated?.source?.contextSimilarity && this.context_similarity && translated?.source?.percentage < 60 && translated?.source?.contextSimilarityPercentage > this.contextSimilarityThreshold) {
              this.sentence = this.formatSentenceWithContext(translated?.source?.sentence);
              translated.sentence = this.sentence
            }
          }
         }else{
          this.sentence = this.translatedObj?.sentence;
          if (this.translatedObj?.source?.contextSimilarity && this.context_similarity && this.translatedObj?.source?.percentage < 60 && this.translatedObj?.source?.contextSimilarityPercentage > this.contextSimilarityThreshold) {
            this.sentence = this.formatSentenceWithContext(this.sentence)
          }
         }
          this.source =  this.translatedObj?.source?.url;
          this.isOriginalLanguage = false
          this.commentValue =  this.translatedObj?.comment
          this.showExclude = this.isTranslationExcluded
          if (this.showExclude) {
            this.isEditable = false
            this.showCommentButtons = false
          }
          break;
        case 'ai':
          // Handle AI-specific logic if needed
          break;
        case 'manipulation':
          this.generateManipulatedSentence(this.data);
          if (this.manipulationType == 'hiddenText') {
            this.formattedText = this.formatTextWithWhiteCharacters(this.data.text, this.data?.manipulations?.[1]?.characters ?? this.data?.manipulations?.[0]?.characters)
          }
          break;
      }
    }
  }

  generateManipulatedSentence(data: Data) {
    if (data.manipulations && data.manipulations.length > 0) {
      const includedCharacters = data.manipulations[0]?.includedCharacters?.map(c => c.character);
      const excludedCharacters = data.manipulations[0]?.excludedReplacedCharacters?.map(c => c.character);
      let manipulatedSentence = data.text;

      if (includedCharacters?.length > 0) {
        includedCharacters.forEach(char => {
          const regExp = new RegExp(`(${char})(?![^<]*>)`, 'g');
          manipulatedSentence = manipulatedSentence.replace(regExp, '<span class="highlight-red">$1</span>');
        });
        excludedCharacters.forEach(char => {
          const regExp = new RegExp(`(${char})(?![^<]*>)`, 'g');
          manipulatedSentence = manipulatedSentence.replace(regExp, '<span class="highlight-grey">$1</span>');
        });
      } else if (excludedCharacters?.length > 0) {
        includedCharacters.forEach(char => {
          const regExp = new RegExp(`(${char})(?![^<]*>)`, 'g');
          manipulatedSentence = manipulatedSentence.replace(regExp, '<span class="highlight-red">$1</span>');
        });
        excludedCharacters.forEach(char => {
          const regExp = new RegExp(`(${char})(?![^<]*>)`, 'g');
          manipulatedSentence = manipulatedSentence.replace(regExp, '<span class="highlight-grey">$1</span>');
        });
      }

      this.sentenceManipulation = manipulatedSentence;
      this.cdr.detectChanges();
    } else {
      this.sentenceManipulation = data.sentence;
      this.cdr.detectChanges()
    }
  }

  toggleSelectAll(event: any) {
    const checked = event.target.checked;
    if (this.modalType == 'exclude') {
      this.selectionState = this.selectionState.fill(checked);
    } else if (this.modalType == 'include') {
      this.excludedSelectionState = this.excludedSelectionState.fill(checked);
    }
  }

  // Function to include character replacements
  includeCharacters(isIncluded) {
    this.includeClickCount++;
    this.manipulationAction = 'include';
    if (this.includeClickCount === (isIncluded ? 1 : 2)) {
      const toggledCharacters = this.excluded_result.filter((char: any, index: any) => this.excludedSelectionState[index]);

      swal.fire({
        title: 'Are you sure you want to include the selected characters?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinnerService.show();
          this.reportService
            .toggleReplacedCharacters(this.data.submissionId, toggledCharacters, 2)
            .pipe()
            .subscribe((data: any) => {
              this.reportComponent.getS3Json(data?.presignedUrlJson);
              this.toastr.success('Characters included successfully');
              this.resetState()
              this.reportService.sentenceInformation(null, '');
              this.spinnerService.hide();
            })
        } else if (result.isDenied) {
          swal.fire('Characters are  not included', '', 'info');
          this.resetState()
          this.reportService.sentenceInformation(null, '');
          this.spinnerService.hide();
        }
      });

    } else {
      this.showInsideModal = true;
      this.modalType = 'include';
    }
  }

  // Function to exclude character replacements
  excludeCharacters(isExcluded) {
    this.excludeClickCount++;
    this.manipulationAction = 'exclude';
    if (this.excludeClickCount === (isExcluded ? 1 : 2)) {
      const toggledCharacters = this.result.filter((char: any, index: any) => this.selectionState[index]);
      swal.fire({
        title: 'Are you sure you want to exclude the selected characters?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinnerService.show();
          this.reportService
            .toggleReplacedCharacters(this.data.submissionId, toggledCharacters, 1)
            .pipe()
            .subscribe((data: any) => {
              this.reportComponent.getS3Json(data?.presignedUrlJson);
              this.toastr.success('Characters excluded successfully');

              this.resetState();
              this.reportService.sentenceInformation(null, '');
              this.spinnerService.hide();
            })
        } else if (result.isDenied) {
          swal.fire('Characters are  not excluded', '', 'info');
          this.resetState();
        }
      });
    } else {
      // this.result = this.data.manipulations[0].includedCharacters
      this.showInsideModal = true;
      this.modalType = 'exclude';
      this.spinnerService.hide();
    }
  }

  // isButtonDisabled(): boolean {
  //   return !this.selectionState.some(state => state);
  // }

  resetState() {
    this.showInsideModal = false;
    this.excludeClickCount = 0;
    this.includeClickCount = 0;
  }

  excludeSentence(lang?) {
    this.excludeClickCount++;
    let isExcluded;
    if (this.isOriginal) {
      isExcluded = this.data.isExcluded;
    } else if (this.isTranslated) {
      isExcluded = this.translatedObj?.isExcluded;
    }


    if (this.excludeClickCount === (isExcluded ? 1 : 2)) {


      this.sendExcludeActionsRequest(this.data, isExcluded,lang);
    } else {
      this.showExclude = !this.showExclude;

      if (!isExcluded) {
        this.isEditable = true; // Set to edit mode when sentence is not excluded
        this.showCommentButtons = false
      }
    }
  }

  sendExcludeActionsRequest(data: any, isExcluded: boolean,lang?) {

    console.log(lang,"langlang");


    this.spinnerService.show();
    const serviceMethod = isExcluded ? 'includeSentence' : 'excludeSentence';
    const successMessage = isExcluded ? 'Include Sentence' : 'Exclude Sentence';
    const infoMessage = isExcluded ? 'Sentence included successfully!' : 'Sentence excluded successfully!';

    const language = this.isOriginalLanguage ? this.submission.originalLanguage : this.toggledLang?this.toggledLang:lang;


    this.reportService[serviceMethod](
      data.submissionId,
      data.id,
      this.isOriginal,
      this.commentValue,
      language
    ).subscribe((data: {infoToSend:string[]}) => {
      this.toastr.success(infoMessage, successMessage);
      this.spinnerService.hide();
      this.reportService.newS3JsonSubject.next(data.infoToSend[0]);
      this.isEditable = isExcluded;
      this.showExclude = !isExcluded;
      this.includeClickCount = 0;
      this.excludeClickCount = 0;
      // Reset the button text and other related states
      if (isExcluded) {
        this.data.isExcluded = false;
        if (this.isTranslated && this.translatedObj) {
          this.translatedObj.isExcluded = false;
        }
      } else {
        this.data.isExcluded = true;
        if (this.isTranslated && this.translatedObj) {
          this.translatedObj.isExcluded = true;
        }
      }
      // this.clearSentenceInformation()
    });
  }

  commentAction(action: 'update' | 'discard') {
    this.spinnerService.show();
    if (action === 'update') {
      this.isEditable = true
      this.reportService.updateComment(
        this.commentValue,
        this.data.submissionId,
        parseInt(this.data.id.toString()),
        this.isOriginal,
        this.selectedTransltaionCode
      ).subscribe((data:{message:string,presignedUrlJson:string}) => {
        this.isEditable = false;
        this.spinnerService.hide();
        this.reportComponent.getS3Json(data.presignedUrlJson);
      });
    } else {
      this.spinnerService.hide();
      this.commentValue = this.data.comment;
      this.isEditable = false;
    }
  }


  get excludeButtonText(): string {
    if(this.isOriginal){
      return this.data.isExcluded ? this.translate.instant('new_entries.include_matching_sentence') : this.translate.instant('new_entries.exclude_matching_sentence');
    } else if (this.isTranslated) {
      return this.translatedObj?.isExcluded ? this.translate.instant('new_entries.include_matching_sentence') : this.translate.instant('new_entries.exclude_matching_sentence');
    }
}

  get excludeButtonAriaLabel(): string {
    return this.data.isExcluded ? this.translate.instant('new_entries.include_sentence_as_match') : this.translate.instant('new_entries.exclude_sentence_as_match');
  }

  get manipulationExcludeButtonText(): string {
    return this.data.manipulations[1]?.isExcluded ? this.translate.instant('report.include') : this.translate.instant('report.exclude');
  }

  formatTextWithWhiteCharacters(text: string, characters: any[]): string {
    // Create an array from the text for easier manipulation
    let textArray = text.split('');
    let nonSpaceIndex = 0;

    for (let i = 0; i < textArray.length; i++) {
      if (textArray[i] !== ' ' && (characters && characters.length > 0)) {
        characters.forEach(char => {
          if (char.isWhite && nonSpaceIndex === char.index && textArray[i] === char.character) {
            if (this.data?.manipulations?.[1]?.isExcluded ?? this.data?.manipulations?.[0]?.isExcluded) {
              textArray[i] = `<span class="whiteChar excluded" style="background: rgb(93, 174, 255) !important; color: #000 !important">${textArray[i]}</span>`;
            } else {
              textArray[i] = `<span class="whiteChar" style="background: rgb(93, 174, 255) !important; color: #000 !important">${textArray[i]}</span>`;
            }
          }
        });
        nonSpaceIndex++;
      }
    }
    // Join the array back into a string
    return textArray.join('');
  }

  getClassificationText(data) {
    let correctData = data;
    if(this.analysisType === 'Translated'){
      correctData = this.translatedObj
    }
    const similarity = correctData?.similarity || correctData?.source?.percentage;
    const proximity = correctData?.source?.averageDistance/100 || 0; // Assume proximity is 0 if not provided
    const contextSimilarity = parseFloat(correctData?.source?.contextSimilarityPercentage) || 0;
    if (data.isExcluded) {
      return 'Excluded';
    } else if(data?.isCitation){
      return 'Cited Text';
    } else if (similarity === 100 || (similarity >= 80 && proximity < 2.00)) {
      return 'Exact Match';
    } else if ((similarity >= 60 && similarity < 80 && proximity < 2.00) ||
               (similarity >= 60 && proximity >= 2.00 && proximity <= 3.00 && contextSimilarity > this.contextSimilarityThreshold)) {
      return 'Altered text';
    } else if (similarity < 60 && contextSimilarity > this.contextSimilarityThreshold) {
      return 'Context similarity';
    }
  }

  getBgColor(data) {
    let correctData = data;

    if(this.analysisType === 'Translated'){
      correctData = this.translatedObj
    }
    const similarity = correctData?.similarity || correctData?.source?.percentage;
    const proximity = correctData?.source?.averageDistance/100 || 0; // Assume proximity is 0 if not provided
    const contextSimilarity = parseFloat(correctData?.source?.contextSimilarityPercentage) || 0;

    if (data?.isExcluded) {
      return '#d9d9d9'; // Gray color for Excluded
    } else if(data?.isCitation){
      return '#BDF4DD';
    } else if (similarity === 100 || (similarity >= 80 && proximity < 2.00)) {
      return '#FF6666'; // Red color for Exact Match
    } else if ((similarity >= 60 && similarity < 80 && proximity < 2.00) ||
               (similarity >= 60 && proximity >= 2.00 && proximity <= 3.00 && contextSimilarity > this.contextSimilarityThreshold)) {
      return '#B3D9FF'; // Blue color for Altered text
    } else if (similarity < 60 && contextSimilarity > this.contextSimilarityThreshold) {
      return '#FEE5B3'; // Yellow color for Context similarity
    }
  }

  toggleHiddenText(id: any) {
    swal.fire({
      title: 'Are you sure?',
      text: this.data?.manipulations?.[1]?.isExcluded ?? this.data?.manipulations?.[0]?.isExcluded ? 'You are about to include this sentence' : 'You are about to exclude this sentence',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.reportService.toggleWhiteCharacterSentences(this.data.submissionId, id, !this.data?.manipulations?.[1]?.isExcluded || null)
        .pipe()
        .subscribe((res: any) => {
          if (res) {
            this.reportComponent.getS3Json(res?.presignedUrlJson);
          }
          this.reportService.sentenceInformation(null, '');
          this.toastr.success(this.data?.manipulations?.[1]?.isExcluded ?? this.data?.manipulations?.[0]?.isExcluded ? 'Sentence included successfully' : 'Sentence excluded successfully');
          this.spinnerService.hide();
        });
      } else if (result.isDismissed) {
        swal.fire(this.data?.manipulations?.[1]?.isExcluded ?? this.data?.manipulations?.[0]?.isExcluded ? 'Sentence is not included' : 'Sentence is not excluded', '', 'info');
      }
    });
  }

  onRadioChange(sourceId: string) {
    this.selectedSource = sourceId;
  }

  changeSource() {
    this.changeSourceClickCount++;
    this.selectedSentenceSources = this.isOriginal ? this.data.secondarySources : this.translatedObj?.secondarySources
    if (this.changeSourceClickCount === 2) {
      //If click count is 2 -> Send the request
      if (this.selectedSource) {
        this.sendChangeSourceRequest(this.data);
      } else {
        this.changeSourceClickCount--;
        this.toastr.info(
          'Please select source from the list within the window.',
          'Source not selected'
        );
      }
    } else {
      //If click count is 1 -> Display the Secondary Sources
      this.isChangeSource = !this.isChangeSource;
      this.showExclude = false;
    }
  }

  sendChangeSourceRequest(sentence: any) {
    const language = this.isOriginalLanguage ? this.submission.originalLanguage : this.toggledLang;
    this.spinnerService.show();
    this.reportService
      .changeSentenceSource(
        this.selectedSource,
        parseInt(sentence.id),
        this.data.submissionId,
        this.isOriginal,
        language
      )
      .subscribe((data: any) => {
        this.reportService.newS3JsonSubject.next(data['presignedUrlJson']);
        this.toastr.success(
          'Sources has been changed successfully',
          'Source changed'
        );
        this.clearSentenceInformation()
      this.spinnerService.hide();
      });
  }

  toggleRow(lang: string): void {
    if (this.toggledLang === lang) {
      this.toggledLang = null;
    } else {
      this.toggledLang = lang;
    }
    this.reportService.selectedTransltaionCodeSubject.next(lang)
  }

  openManipulationTab(tab: string) {
    if (tab === 'charReplacement') {
      this.isOnCharReplacement = true;
      this.isOnHiddenText = false;
    } else if (tab === 'hiddenText') {
      this.isOnCharReplacement = false;
      this.isOnHiddenText = true;
    }
  }

  changeSourceModal(lang){
    console.log('lang',lang)
  }
}
