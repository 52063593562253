<div class="professorLicense">
   <div routerLink="full-integration" routerLinkActive="active">
      <span> Institutional </span>
   </div>

   <div routerLink="basic-integration" routerLinkActive="active">
      <span>{{ 'general.basic' | translate }}</span>
   </div>
</div>
<router-outlet></router-outlet>
