<div class="documents-2 shadow white-bg">
   <h3
      *ngIf="
         !(
            this.submissionsNumber == 0 &&
            submissionName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            professorId == '-1'
         )
      "
      class="filtersContainer"
   >
      <h4>{{ 'general.submissions' | translate }}</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_institution' | translate }}
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">{{ faculty.name }}</option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">{{ 'general.select_institution_first' | translate }}</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_department' | translate }}
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div>
            <select [(ngModel)]="professorId" (change)="filterByProfessor()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_educator' | translate }}
               </option>
               <ng-container *ngFor="let professor of this.professors">
                  <option value="{{ professor.id }}">
                     {{ professor.name }}
                  </option>
               </ng-container>

               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div>
            <input
               [placeholder]="'placeholders.search_by_submission_name' | translate"
               [(ngModel)]="submissionName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </h3>

   <div class="table_container" *ngIf="this.submissionsNumber != 0">
      <table>
         <tr>
            <th>
               <h5 class="bold left">{{ 'general.table.name' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.full_name' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'app.institution' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.department' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.submission_date' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.table.number_of_words' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.language' | translate }}<br/>{{ 'report.similarity' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.translated_language' | translate }}<br/>{{ 'report.similarity' | translate }}</h5>
            </th>
            <th>
              <h5 class="bold">{{ 'report.ai_index' | translate }}</h5>
           </th>
            <!-- <th>
                <h5 class="bold">{{ 'app.grade' | translate }}</h5>
             </th> -->
            <th>
               <h5 class="bold">{{ 'general.report' | translate }}</h5>
            </th>
         </tr>
         <tr
            *ngFor="
               let submission of this.submissions
                  | paginate
                     : {
                          itemsPerPage: 5,
                          currentPage: page,
                          totalItems: this.submissionsNumber
                       }
            "
         >
            <ng-container *ngIf="submission.showSubmission == true">
               <td>
                  <h5 class="left">
                     <span
                        *ngIf="submission.draftId !== null"
                        style="font-weight: 700"
                        >{{ 'general.table.draft' | translate }}: </span
                     >{{ submission.title }}
                  </h5>
               </td>
               <td>
                  <h5>
                     {{ submission.user.name }}
                  </h5>
               </td>
               <td>
                  <h5>{{ submission.user.Faculty.name }}</h5>
               </td>
               <td>
                  <h5>{{ submission.user.Department.name }}</h5>
               </td>

               <td>
                  <h5>{{ submission.createdAt | date: 'dd/MM/yyyy' }}</h5>
               </td>

               <td>
                  <h5>{{ submission.wordCount }}</h5>
               </td>
               <td>
                  <h5
                     *ngIf="submission.processed == 1"
                     [ngClass]="{
                        'red-border':
                           submission.originalPercentage.toFixed(2) > 20,
                        'green-border':
                           submission.originalPercentage.toFixed(2) >= 0 &&
                           submission.originalPercentage.toFixed(2) < 5,
                        'orange-border':
                           submission.originalPercentage.toFixed(2) >= 5 &&
                           submission.originalPercentage.toFixed(2) <= 20
                     }"
                  >
                     <span *ngIf="submission.originalLanguage !== null"
                        >{{ submission.originalLanguage | uppercase }}
                     </span>
                     <span *ngIf="submission.originalLanguage == null">
                        --
                     </span>

                     {{ submission.originalPercentage.toFixed(2) }}%
                  </h5>

                  <h5 *ngIf="submission.processed == 2" class="gray-border">
                     {{ 'app.suspended' | translate }}
                  </h5>
                  <h5
                     *ngIf="
                        submission.processed == 0 || submission.processed == -1
                     "
                     class="gray-border"
                  >
                     {{ 'report.processing' | translate }}
                  </h5>
               </td>
               <td>
                  <h5
                     *ngIf="submission.processed == 1"
                     [ngClass]="{
                        'red-border':
                           submission.translatedPercentage.toFixed(2) > 20,

                        'green-border':
                           submission.translatedPercentage.toFixed(2) >= 0 &&
                           submission.translatedPercentage.toFixed(2) < 5,

                        'orange-border':
                           submission.translatedPercentage.toFixed(2) >= 5 &&
                           submission.translatedPercentage.toFixed(2) <= 20,

                        'gray-border': submission.translatedLanguage == null
                     }"
                  >
                     <span *ngIf="submission.translatedLanguage !== null"
                        >{{ submission.translatedLanguage | uppercase }}
                     </span>
                     <span *ngIf="submission.translatedLanguage == null">
                        --
                     </span>
                     <ng-container
                        *ngIf="submission.translatedLanguage !== null"
                     >
                        {{ submission.translatedPercentage.toFixed(2) }}%
                     </ng-container>

                     <ng-container
                        *ngIf="submission.translatedLanguage == null"
                     >
                        ---
                     </ng-container>
                  </h5>
               </td>
               <td>
                  <span
                     *ngIf="submission.processed == 1 && submission.aiDetection"
                     [ngClass]="{
                        'red-border': submission.aiPercentage > 20,
                        'green-border':
                           submission.aiPercentage >= 0 &&
                           submission.aiPercentage < 5,
                        'orange-border':
                           submission.aiPercentage >= 5 &&
                           submission.aiPercentage <= 20
                     }"
                     ><span>{{ 'general.ai' | translate }}</span>
                     {{ submission.aiPercentage }}%</span
                  >
                  <h5
                     *ngIf="
                        (submission.processed == 0 ||
                        submission.processed == -1) && submission.aiDetection
                     "
                     class="gray-border"
                  > -
                  </h5>
                  <h5 *ngIf="submission.processed == 2 && submission.aiDetection" class="gray-border">
                     {{ 'app.suspended' | translate }}
                  </h5>
                  <h5 *ngIf="!submission.aiDetection" class="gray-border">
                     N/A
                  </h5>
               </td>
               <!-- <td>6</td> -->
               <td>
                  <a
                     *ngIf="submission.processed == 1"
                     (click)="goToLink(submission.id)"
                     class="reportBtn"
                     >{{ 'general.report' | translate }}</a
                  >

                  <a
                     *ngIf="
                        submission.processed == 0 || submission.processed == -1
                     "
                     class="disable-btn"
                     >{{ 'general.report' | translate }}</a
                  >
               </td>
            </ng-container>
         </tr>
      </table>
   </div>

   <div
      class="zeroState"
      *ngIf="
         this.submissionsNumber == 0 &&
         (submissionName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            professorId != '-1')
      "
   >
      {{ 'app.there_are_no_submissions' | translate }}
   </div>
   <pagination-controls
      *ngIf="this.submissionsNumber != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.submissionsNumber == 0 &&
         submissionName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         professorId == '-1'
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">{{ 'app.your_educators_dont_have_any_submissions' | translate }}</h5>
   </div>
</div>
