import { Component, ViewChild, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexChart,
  ApexXAxis,
  ApexFill,
  ChartComponent,
  ApexStroke,
  ApexMarkers,
  ApexTooltip,
  ApexLegend,
  ApexGrid
} from "ng-apexcharts";
import { ReportService } from '../../services/report.service';
import { DocumentData } from '../../services/document-data.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  fill: ApexFill;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  colors: string[];
  legend: ApexLegend;
  grid: ApexGrid;
};

@Component({
  selector: 'app-cohort',
  templateUrl: './cohort.component.html',
  styleUrls: ['./cohort.component.scss']
})
export class CohortComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input() cohortValues: number[] = [0, 0, 0, 0, 0];
  @Input() submissionValues: number[] = [0, 0, 0, 0, 0];

  manipulationsMax: number;
  wordCountMax: number;
  normalizedCohortValues: number[];
  normalizedSubmissionValues: number[];
  isOnAnalysis: boolean;
  @Output() isOnAnalysisChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private reportService:ReportService, private documentDataService: DocumentData) { }

  ngOnInit(): void {
    this.manipulationsMax = Math.max(this.cohortValues[3], this.submissionValues[3]);
    this.wordCountMax = Math.max(this.cohortValues[4], this.submissionValues[4]);

    this.normalizedCohortValues = [
      this.cohortValues[0],
      this.cohortValues[1],
      this.cohortValues[2],
      isNaN(parseFloat(((this.cohortValues[3] / this.manipulationsMax) * 100).toFixed(2))) ? 0 : parseFloat(((this.cohortValues[3] / this.manipulationsMax) * 100).toFixed(2)),
      isNaN(parseFloat(((this.cohortValues[4] / this.wordCountMax) * 100).toFixed(2))) ? 0 : parseFloat(((this.cohortValues[4] / this.wordCountMax) * 100).toFixed(2))
    ];

    this.normalizedSubmissionValues = [
      this.submissionValues[0],
      this.submissionValues[1],
      this.submissionValues[2],
      isNaN(parseFloat(((this.submissionValues[3] / this.manipulationsMax) * 100).toFixed(2))) ? 0 : parseFloat(((this.submissionValues[3] / this.manipulationsMax) * 100).toFixed(2)),
      isNaN(parseFloat(((this.submissionValues[4] / this.wordCountMax) * 100).toFixed(2))) ? 0 : parseFloat(((this.submissionValues[4] / this.wordCountMax) * 100).toFixed(2))
    ];

    this.chartOptions = {
      series: [
        {
          name: "Cohort",
          data: this.normalizedCohortValues
        },
        {
          name: "Submission",
          data: this.normalizedSubmissionValues
        }
      ],
      chart: {
        height: 375,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      title: {
        text: ""
      },
      stroke: {
        width: 2,
        colors: ["#F2A202", "#008FFB"],
      },
      fill: {
        opacity: 0.2
      },
      markers: {
        size: 0
      },
      xaxis: {
        categories: ["Similarity", "Translation", "AI Authorship", "Manipulations", "Word Count"],
        labels: {
          style: {
            colors: '#333',
            fontSize: '14px'
          }
        }
      },
      grid: {
        borderColor: '#666',
        strokeDashArray: 4,
      },
      tooltip: {
        y: {
          formatter: function (value: number) {
            return value.toFixed(2) + "%";
          }
        }
      },
      colors: ["rgba(242, 162, 2, 0.2)", "rgba(0, 143, 251, 0.2)"],
      legend: {
        position: 'bottom', // Adjust legend position
        horizontalAlign: 'center', // Center the legend horizontally
        offsetY: -50, // Move the legend closer to the radar chart
        labels: {
          colors: ['#333'],
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2,
          fillColors: ['#F2A202', '#008FFB'],
        }
      }
    };
  }

  toggleIsOnAnalysis(): void {
    this.isOnAnalysis = !this.isOnAnalysis;
    this.isOnAnalysisChange.emit(this.isOnAnalysis);
    this.documentDataService.setCurrentPage(1)
    if (this.isOnAnalysis) {
      this.reportService.selectAnalysisTypeSubject.next('analysis');
    } else {
      this.reportService.selectAnalysisTypeSubject.next('none');
    }
  }

}
