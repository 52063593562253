<header role="banner" class="banner">
   <img (click)="goToHome()" class="logo" tabindex="0" aria-label="Inspera Originality" src="../../../../../../assets/report_v2/inspera_originality.svg" alt="">
  <section>
    <button (click)="toggleMenuBar('submissionDetails')" tabindex="-1" data-menu-bar-key="submissionDetails" class="banner_options_option submission_details" [class.menu-open]="activeMenuBar['submissionDetails']" appCloseMenu>
      <button class="d-flex inside-btn" tabindex="0">
        <img src="../../../../../../assets/report_v2/submission_details.svg" alt="">
        <p class="fontColor" aria-label="See submission details. Click to see the details.">{{ 'new_entries.details' | translate}}</p>
      </button>
      <article class="dropdown menubar" aria-label="Submission Details Panel" cdkTrapFocus>
        <head class="menubar-head" tabindex="0" aria-label="Submission Details Header">
          <h3 tabindex="0" aria-label="Submission details title" role="heading">{{ 'new_entries.submission_details' | translate }}</h3>
          <button tabindex="0" role="button" aria-label="button to close panel" class="close-menubar" (click)="closeMenuBar('submissionDetails', $event)"><img src="../../../../../../assets/report_v2/close_big.svg" alt="Close button for the submission details."></button>
        </head>
        <article class="menubar-body" tabindex="0" aria-label="Submission Details body">
          <section tabindex="0" [attr.aria-label]="'Document title is. ' + bannerDetails.title" aria-live="polite">
            <h6>{{ 'general.table.name' | translate }}</h6>
            <p>{{ bannerDetails.title }}</p>
          </section>
          <section tabindex="0" [attr.aria-label]="'Document Language is. ' + getLanguageName(bannerDetails.original_language)" aria-live="polite">
            <h6 >{{ 'report.document_language' | translate }}</h6>
            <p>{{ getLanguageName(bannerDetails.original_language) }}</p>
          </section>
          <section *ngIf="bannerDetails.translated_language && bannerDetails.translated_language.length !== 2" tabindex="0" [attr.aria-label]="'Translated Language is. ' + getLanguageName(bannerDetails.translated_language)" aria-live="polite">
            <h6>{{ 'app.upload.translated_language' | translate }}</h6>
            <p>{{ getLanguageName(bannerDetails.translated_language) }}</p>
          </section>
          <section tabindex="0" [attr.aria-label]="'AI authorship analysis is. ' + (bannerDetails.ai_authorship_analysis ? 'On' : 'Off')" aria-live="polite">
            <h6>{{ 'new_entries.ai_authorship_analysis' | translate }}</h6>
            <ng-container *ngIf="bannerDetails.ai_authorship_analysis; else off">
              <p>{{ 'new_entries.on' | translate }}</p>
            </ng-container>
            <ng-template #off>
              <p>{{ 'new_entries.off' | translate }}</p>
            </ng-template>

              <!-- <p *ngIf="bannerDetails.ai_authorship_analysis; else off">On</p>
              <p #off>Off</p> -->
          </section>
          <section tabindex="0" [attr.aria-label]="'File size is. ' + bannerDetails.file_size" aria-live="polite">
            <h6>{{ 'new_entries.file_size' | translate }}</h6>
            <p>{{ bannerDetails.file_size }}</p>
          </section>
          <section tabindex="0" [attr.aria-label]="'Word count is. ' + bannerDetails.word_count" aria-live="polite">
            <h6>{{ 'new_entries.word_count' | translate }}</h6>
            <p>{{ bannerDetails.word_count }}</p>
          </section>
          <section tabindex="0" [attr.aria-label]="'Page count is. ' + bannerDetails.page_count" aria-live="polite">
            <h6>{{ 'new_entries.page_count' | translate }}</h6>
            <p>{{ bannerDetails.page_count }}</p>
          </section>
        </article>
      </article>
    </button>
    <button tabindex="0" data-menu-bar-key="download" class="banner_options_option" (click)="toggleMenuBar('download')" [class.menu-open]="activeMenuBar['download']" appCloseMenu>
      <img src="../../../../../../assets/report_v2/download.svg" alt="Download Button">
      <p class="fontColor" aria-label="Download button. Click to see the documents available for download.">{{ 'general.download' | translate }}</p>
      <section role="menubar" class="dropdown" [attr.aria-hidden]="activeMenuBar['download'] ? 'false' : 'true'" cdkTrapFocus *ngIf="activeMenuBar['download']">
        <!-- <button tabindex="0" aria-label="Download Original Language Report" (click)="downloadPdfReport('mlplag')"><p>Original Language Report</p></button> -->
        <button tabindex="0" aria-label="Download Offline Report" (click)="downloadPdfReport('crossplag')"><p>{{ 'report.offline_report' | translate }}</p></button>
        <button tabindex="0" aria-label="Download Document File" (click)="downloadOriginalDocument()"><p>{{ 'general.document_file' | translate }}</p></button>
        <!-- <button tabindex="0" aria-label="Download AI Report" *ngIf="bannerDetails.ai_authorship_analysis" (click)="downloadAiReport()"><p>AI Report</p></button> -->
      </section>
    </button>
    <button tabindex="0" class="banner_options_option" (click)="openSettingsModal()">
      <img src="../../../../../../assets/report_v2/settings.svg" alt="Settings">
      <p class="fontColor" aria-label="Settings">{{ 'aria-labels.settings' | translate }}</p>
    </button>
    <button *ngIf="showExpandView" tabindex="0" (click)="openLink(newTab)" class="banner_options_option">
      <img src="../../../../../../assets/report_v2/open_in_another_tab.svg" alt="Open in another tab. Click in order to open the report in another tab.">
      <p class="fontColor" aria-label="Open in another tab name">{{ 'general.expand' | translate }}</p>
    </button>
    <button tabindex="0" role="link" (click)="openLink('https://support.inspera.com/hc/en-us/categories/15848147005085-Inspera-Originality')" class="banner_options_option">
      <img src="../../../../../../assets/report_v2/help-circle.svg" alt="Help Center">
      <p class="fontColor" aria-label="Help Center.">{{ 'general.help' | translate }}</p>
    </button>
  </section>
</header>
