  <section *ngIf="isOnAi" class="ai_bar isOnAi">
    <header>
      <section class="header-section">
        <section class="header-label" tabindex="0" aria-label="AI authorship">
          <img src="../../../../../../assets/report_v2/ai-authorship.svg" alt="AI authorship language logo/image">
          <section>
            <h3>{{ 'general.ai_authorship' | translate }}</h3>
            <span tabindex="0" aria-label="AI Authorship Index represents the level of text classified as AI-generated">{{ 'new_entries.classification_shows_probability_of_ai' | translate }}</span>
          </section>
        </section>
        <button (click)="toggleIsOnAi()" tabindex="0">
          <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow left. Click to go to the Summary.">
        </button>
      </section>
    </header>
    <section tabindex="0" class="similarity_overview" [ariaLabel]="'Document has ' + aiAnalysis.aiPercentage + '% AI similarity'">
      <p class="ai_similarity_percent">{{ 'new_entries.ai_authorship_index' | translate }}</p>
      <div>
        <mat-progress-bar id="ai" mode="determinate" [value]="aiAnalysis.aiPercentage" color="#462D86"></mat-progress-bar>
        <p class="paragraphStyle">{{ aiAnalysis.aiPercentage }}%</p>
      </div>
    </section>
    <section class="ai_score" tabindex="0">
      <aside class="alert_img">
        <img src="../../../../../../assets/report_v2/warning-circled-filled-ai.svg" alt="" />
      </aside>
      <h5 *ngIf="aiAnalysis.aiPercentage >=0 && aiAnalysis.aiPercentage <= 5" [innerHTML]="ai_percentage.low">{{ 'new_entries.low' | translate }}</h5>
      <h5 *ngIf="aiAnalysis.aiPercentage > 5 && aiAnalysis.aiPercentage <= 20" [innerHTML]="ai_percentage.medium">{{ 'new_entries.medium' | translate }}</h5>
      <h5 *ngIf="aiAnalysis.aiPercentage > 20 && aiAnalysis.aiPercentage <= 100" [innerHTML]="ai_percentage.high">{{ 'new_entries.high' | translate }}</h5>
    </section>
    <section class="insights" aria-label="Insights">
      <header class="insights_header" tabindex="0">
        <img src="../../../../../../assets/report_v2/ai-insights.svg" alt="Insights Icon">
        <h4>{{ 'new_entries.insights' | translate }}</h4>
      </header>
      <section class="insights_section" tabindex="0" aria-label="Insights Body">
        <section class="total_sentences" tabindex="0" [ariaLabel]="'The number of total sentences is: ' + totalSentences">
          <h5>{{ 'report.total_sentences' | translate }}</h5>
          <p>{{ totalSentences }}</p>
        </section>
        <section class="ai_sentences" tabindex="0" [ariaLabel]="'The number of AI-written sentences is: ' + aiSentences">
          <h5>{{ 'new_entries.ai_written_sentences_count' | translate }}:</h5>
          <p>{{ aiSentences }}</p>
        </section>
        <section class="human_sentences" tabindex="0" [ariaLabel]="'The number of human-written sentences is: ' + humanSentences">
          <h5>{{ 'new_entries.human_written_sentences_count' | translate }}:</h5>
          <p>{{ humanSentences }}</p>
        </section>
      </section>
    </section>

    <app-overview-disclaimer tabindex="0" [title]="'general.overview' | translate" [disclaimerText]="'new_entries.ai_authorship_index_description_2' | translate" [disclaimerLink]="'https://support.inspera.com/hc/en-us/sections/15852904618397-AI-Authorship'" [linkText]="'new_entries.learn_more' | translate"></app-overview-disclaimer>

    <!-- <section class="disclaimer open">
      <section>
        <h5>{{ 'other.disclaimer' | translate }}</h5>
        <p>Our AI authorship index provides high accuracy and detailed sentence-level insights, offering another layer of precaution against potential integrity issues. While it excels in its capabilities, it may occasionally yield a small percentage of false positives. Therefore, human review remains essential to ensure accuracy and reliability.</p>
      </section>
    </section> -->
  </section>

  <section *ngIf="!isOnAi" class="ai_bar">
    <header>
      <section class="header-section">
        <section class="header-label" tabindex="0" aria-label="AI authorship">
          <img src="../../../../../../assets/report_v2/ai-authorship.svg" alt="AI authorship language logo/image">
          <section>
            <h3>{{ 'general.ai_authorship' | translate }}</h3>
            <span tabindex="0" aria-label="AI Authorship Index represents the level of text classified as AI-generated">{{ 'new_entries.classification_shows_probability_of_ai' | translate }}</span>
          </section>
        </section>
        <button (click)="toggleIsOnAi()" tabindex="0">
          <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow right. Click to go to the AI authorship analysis.">
        </button>
      </section>
    </header>
    <section class="similarity_overview" [ariaLabel]="'Document has ' + aiAnalysis.aiPercentage + '% AI similarity'" tabindex="0">
      <div class="overview-head">
        <img src="../../../../../../assets/report_v2/ai-authorship_borderless.svg" alt="">
        <section class="overview">
          <div tabindex="0"><span>{{ 'new_entries.ai_written_sentences_count' | translate }}:</span><span class="answ">{{ aiSentences }}</span></div>
          <div tabindex="0"><span>{{ 'new_entries.human_written_sentences_count' | translate }}:</span><span class="answ">{{ humanSentences }}</span></div>
        </section>
        <div class="overview-tag"><p class="tag"><span class="round"></span>{{ (aiAnalysis.aiPercentage >= 20 ? 'new_entries.high_probability' : aiAnalysis.aiPercentage >= 5 ? 'new_entries.moderate_probability' : 'new_entries.low_probability') | translate }}</p></div>
      </div>
    </section>
  </section>
