import * as AppState from '../../store/app.state';
import { User } from '../../models/user';
import { createReducer } from '@ngrx/store';
import * as authActions from './actions/authentication.actions';
import { on } from '@ngrx/store';
import { state } from '@angular/animations';

/**
 * Authentication reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and added User state to the main state.
 */
export interface State extends AppState.State {
   user: UserState;
}

/**
 * An UserState interface, which represents the blueprint of the User State.
 */
export interface UserState {
   isAuthenticated: boolean;
   user: User;
   errorMessage: string;
}
/**
 * UserState initial state.
 */
export const initialState: UserState = {
   isAuthenticated: false,
   user: null,
   errorMessage: null,
};
/**
 * Auth Reducer used to modify User State depending on the action that is dispatched.
 */
export const authReducer = createReducer<UserState>(
   initialState,
   /**
    * On Login Success action updated current state with the following properties.
    */
   on(
      authActions.loginSuccess,
      authActions.ltiSuccess,
      (state, action): UserState => {
         return {
            ...state,
            isAuthenticated: true,
            user: action.user,
         };
      }
   ),

   /**
    * On Login Success action updated current state with the following properties.
    */
   on(
      authActions.currentUserSuccess,
      (state, action): UserState => {
         return {
            ...state,
            user: action.user,
         };
      }
   ),
   /**
    * On Login Failure action updated current state with the following properties.
    */
   on(authActions.loginFailure, (state) => {
      return {
         ...state,
         errorMessage: 'Wrong Credentials',
      };
   }),
   /**
    * On Google Login Success action updated current state with the following properties.
    */
   on(
      authActions.googleLoginSuccess,
      (state, action): UserState => {
         return {
            ...state,
            isAuthenticated: true,
            user: action.user,
         };
      }
   ),
   /**
    * On Google Login Failure action updated current state with the following properties.
    */
   on(authActions.googleLoginFailure, (state, action) => {
      return {
         ...state,
         errorMessage: action.error,
      };
   }),
   on(
      authActions.LTILoginFailure,
      (state, action): UserState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   /**
    * On LinkedIn Login Success action updated current state with the following properties.
    */
   on(
      authActions.linkedInLoginSuccess,
      (state, action): UserState => {
         return {
            ...state,
            isAuthenticated: true,
            user: action.user,
         };
      }
   ),
   on(
      authActions.LTILoginSuccess,
      (state, action): UserState => {
         action.user.isLtiUser = true;
         return {
            ...state,
            isAuthenticated: true,
            user: action.user,
         };
      }
   ),
   on(
      authActions.signUpSuccess,
      (state, action): UserState => {
         return {
            ...state,
            isAuthenticated: false,
            user: action.user,
         };
      }
   ),
   on(authActions.signUpFailure, (state, action) => {
      return {
         ...state,
         errorMessage: action.error,
      };
   }),
   /**
    * On LinkedIn Login Failure updated current state with the following properties.
    */
   on(authActions.linkedInLoginFailure, (state, action) => {
      return {
         ...state,
         errorMessage: action.error,
      };
   }),
   /**
    * On Logout action updated current state with the following properties.
    */
   on(authActions.logout, (state) => {
      return {
         ...state,
         isAuthenticated: false,
         user: undefined,
      };
   }),
   /**
    * On Update User Info Success update current state with the following properties.
    */
   on(authActions.updateUserInfoSuccess, (state, action) => {
      let updatedUser = { ...state.user };
      updatedUser.name = `${action.firstName} ${action.lastName}`;
      return {
         ...state,
         user: updatedUser,
      };
   }),
   /**
    * On updateUserState update current state with the following properties.
    */
   on(authActions.updateUserState, (state, action) => {
      let updatedUser = { ...state.user };
      updatedUser.wordsToUse = action.userTotalCredits;
      return {
         ...state,
         user: updatedUser,
      };
   }),
   /**
    * On updateUserActivationState update current state with the following properties.
    */
   on(authActions.updateUserActivationState, (state, action) => {
      let user = { ...action.user };
      user.thirdPartyEmailAct = true;
      return {
         ...state,
         user: user,
      };
   })
);
