<section style="justify-content: flex-start">
   <!-- <h4 class="bold w-87">{{ 'app.users.user_panel' | translate }}</h4> -->
   <main style="width: 100%">
      <div class="cards">
         <div
            routerLink="academic-units-professors"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>{{ 'general.educators' | translate }}</div>
         </div>
         <div
            routerLink="academic-units-students"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>{{ 'general.students' | translate }}</div>
         </div>
      </div>

      <div>
         <router-outlet></router-outlet>
      </div>
   </main>
</section>
