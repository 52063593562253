<h4>{{ 'app.edit_student' | translate }}</h4>
<form [formGroup]="studentEdit">
   <div class="editimi-fakultetit-inputs">
      <div class="inside-inputs">
         <label for="studentName">{{ 'general.name' | translate }}</label>
         <input formControlName="studentName" type="text" />
      </div>
      <div
         *ngIf="
            studentEdit.controls.studentName.touched &&
            studentEdit.controls.studentName.invalid
         "
         class="invalid"
      >
         {{ 'general.student' | translate }} {{ 'general.name' | translate }} {{ 'aria-labels.is_required' | translate }}*
      </div>
      <div
         *ngIf="studentEdit.controls.studentName.errors?.minlength"
         class="invalid"
      >
         {{ 'general.student' | translate }} {{ 'general.name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
      </div>

      <div class="inside-inputs">
         <label for="studentEmail">{{ 'general.email' | translate }}</label>
         <input
            formControlName="studentEmail"
            type="email"
            [readonly]="this.selectedUser.status === 1 ? true : false"
         />
      </div>
      <div
         *ngIf="
            studentEdit.controls.studentEmail.touched &&
            studentEdit.controls.studentEmail.invalid
         "
         class="invalid"
      >
         {{ 'login.email_required' | translate }}*
      </div>

      <div
         *ngIf="studentEdit.controls.studentEmail.errors?.minlength"
         class="invalid"
      >
         {{ 'general.student' | translate }} email {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
      </div>
      <div class="inside-inputs">
         <label for="documentLanguage">{{ 'general.language' | translate }}</label>
         <select
            name=""
            id=""
            formControlName="documentLanguage"
            (change)="this.languageSelected(1)"
         >
            <option value="-1">{{ 'general.choose_language' | translate }}</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedTranslateLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>

      <div class="inside-inputs">
         <label for="translateTo">{{ 'individual.upload.translate_document' | translate }}</label>
         <select
            name=""
            id=""
            formControlName="translateTo"
            (change)="this.languageSelected(2)"
         >
            <option value="-1">{{ 'general.choose_language' | translate }}</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedDocLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>
   </div>
   <div
      class="editimi-fakultetit-fshij-perfundo"
      style="display: flex; justify-content: space-evenly"
   >
      <button
         [disabled]="studentEdit.invalid"
         [ngClass]="{
            disable: studentEdit.invalid
         }"
         type="submit"
         class="submit"
         (click)="update()"
      >
         {{ 'app.confirm' | translate }}
      </button>
      <button
         [disabled]="this.selectedUser.status === 1 ? true : false"
         [ngClass]="{
            disable: this.selectedUser.status === 1 ? true : false
         }"
         class="submit"
         (click)="resendActivationEmail()"
      >
         Ri dergo email'in konfirmues
      </button>
   </div>
</form>
