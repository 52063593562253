import { Component, Input, OnInit } from '@angular/core';
import {ReportService} from "../../../services/report.service";
import { take } from 'rxjs/operators';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit{
  submissionId: string;

  constructor(private reportService: ReportService, private submissionsService: SubmissionsService, private route: ActivatedRoute, private spinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.reportService.toggleQuotesObject.subscribe((data) => {
      this.toggleChecked = data;
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.submissionId = params.get('id');
    });

  }

  @Input() isOnAi!: boolean;
  @Input() isOnAnalysis!: boolean;
  @Input() isOnManipulation!: boolean;
  // @Input() toggleChecked: boolean
  @Input() context_similarity: boolean
  toggleChecked

  toggleQuotes() {
    this.spinnerService.show()
    this.submissionsService.toggleQuotes(this.submissionId).subscribe((data)=>{
      this.reportService.toggleQuotesSubject.next(this.toggleChecked)
      this.spinnerService.hide()
    })
  }
}
