<article [ngClass]="{'isOnAi': isOnAi, 'default': !isOnAi && !isOnAnalysis && !isOnManipulation}" class="document_legend" aria-label="Document highlighting legend" focusable>
  <ng-container *ngIf="!isOnAi && !isOnAnalysis && !isOnManipulation">
    <section class="original_lang" role="group">
      <h5><img src="../../../../../../assets/report_v2/original_lang_v2.svg" alt="Original Language  Analysis">{{ 'app.original_language' |translate }}</h5>
      <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.original_language_legend' | translate" aria-label="'The Original Language Similarity analysis highlights similarities between this document and other sources in the same language, offering insights into sentence similarity.'">
        <img src="../../../../../../assets/report_v2/info.svg" alt="Info Button to display a tooltip for the legend." />
      </button>
    </section>
    <section class="translated_lang" role="group">
      <h5><img src="../../../../../../assets/report_v2/translated_lang_v2.svg" alt="Translated Language  Analysis">{{ 'app.upload.translated_language' | translate }}</h5>
      <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.translated_language_legend' | translate" aria-label="'The Translated Language Similarity analysis highlights similarities between this document and other sources in different languages, offering insights into sentence similarity across multiple languages.'">
        <img src="../../../../../../assets/report_v2/info.svg" alt="Info Button to display a tooltip for the legend." />
      </button>
    </section>
    <section class="ai" role="group">
      <h5><img src="../../../../../../assets/report_v2/ai-authorship_v2.svg" alt="AI Authorship">{{ 'general.ai_authorship' | translate }}</h5>
      <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.ai_legend' | translate" aria-label="'The AI Authorship analysis highlights sentences potentially generated by AI and provides a probability classification indicating the likelihood of AI Authorship for a given sentence.'">
        <img src="../../../../../../assets/report_v2/info.svg" alt="Info Button to display a tooltip for the legend." />
      </button>
    </section>
    <section class="manipulation" role="group">
      <h5><img src="../../../../../../assets/report_v2/manipulations_icon_v2.svg" alt="Manipulations">{{ 'new_entries.manipulations' | translate }}</h5>
      <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.manipulation_legend' | translate" aria-label="'The Manipulation analysis detects manipulations like character replacements, hidden text, and text presented as image by highlighting their frequency and placement in the document'">
        <img src="../../../../../../assets/report_v2/info.svg" alt="Info Button to display a tooltip for the legend." />
      </button>
    </section>
  </ng-container>

   <ng-container *ngIf="isOnAi && !isOnManipulation">
     <section class="ai_authorship" role="group">
       <h5>{{ 'general.ai_authorship' | translate }}</h5>
       <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'aria-labels.ai_authorship_likelihood' | translate" aria-label="'This detection analysis suggests that the sentence contains a likelihood of AI authorship, due to the patterns present within the text.'">
         <img src="../../../../../../assets/report_v2/info-empty.svg" alt="Info Button to display a tooltip for the legend." />
       </button>
     </section>
   </ng-container>

   <ng-container *ngIf="isOnAnalysis && !isOnManipulation">
     <section class="exact_match" role="group">
       <h5>{{ 'report.exact_match' | translate }}</h5>
       <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.exact_match_description' | translate" aria-label="'Sentences flagged as exact matches are highlighted in red and show a high degree of similarity to the matching sources. The threshold for defining an exact match is adjustable and defaults to a preset value unless changed.'">
         <img src="../../../../../../assets/report_v2/info-empty.svg" alt="Info Button to display a tooltip for the legend." />
       </button>
     </section>
     <section class="possibly_altered" role="group">
       <h5>{{ 'report.possibly_altered_text' | translate }}</h5>
       <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.altered_text_description' | translate" aria-label="'Sentences flagged as altered text are highlighted in blue and show a high degree of similarity to matching sources. The threshold for defining altered text is adjustable and defaults to a preset value unless changed.'">
         <img src="../../../../../../assets/report_v2/info-empty.svg" alt="Info Button to display a tooltip for the legend." />
       </button>
     </section>
    <section class="context_similarity" role="group" *ngIf="this.context_similarity">
      <h5>{{ 'new_entries.contextual_similarity' | translate }}</h5>
      <button aria-live="polite" matTooltipClass="new_report_tooltip"
        [matTooltip]="'new_entries.contextual_similarity_legend' | translate"
        aria-label="'Sentences flagged for context similarity are highlighted in orange and display a high level of contextual similarity to matching sources. The threshold for contextual similarity is adjustable and defaults to a preset value unless modified.">
        <img src="../../../../../../assets/report_v2/info-empty.svg"
          alt="Info Button to display a tooltip for the legend." />
      </button>
    </section>
    <section class="marked_quotation" role="group">
      <div>
        <h5>{{ 'new_entries.cited_text' | translate }}</h5>
        <button aria-live="polite" matTooltipClass="new_report_tooltip"
          [matTooltip]="'new_entries.properly_cited_sources' | translate"
          aria-label="'Sentences highlighted in green indicate properly cited sources within the document. You can toggle these highlights off to get a clearer view.">
          <img src="../../../../../../assets/report_v2/info-empty.svg"
            alt="Info Button to display a tooltip for the legend." />
        </button>
      </div>
    <mat-slide-toggle (change)="toggleQuotes()" [(ngModel)]="toggleChecked" color="primary"
      aria-label="Input switch used in order to enable or disable 'Cited Text' highlighting.">
    </mat-slide-toggle>
    </section>
    <section class="removed_highlight" role="group">
      <h5>{{ 'report.excluded' | translate }}</h5>
      <button aria-live="polite" matTooltipClass="new_report_tooltip" [matTooltip]="'new_entries.excluded_sentences' | translate" aria-label="'Sentences highlighted in grey have been excluded from the Originality Report based on the educator's assessment. An optional description of the reason for their exclusion may be provided.">
        <img src="../../../../../../assets/report_v2/info-empty.svg" alt="Info Button to display a tooltip for the legend." />
      </button>
    </section>
   </ng-container>
</article>
