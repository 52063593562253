<section class="flex statistics-adm">

   <main style="width: 100%">
      <div class="d-flex justify-flex-end">
         <div class="dropdown">
            <button class="dropbtn">
               <i class="fa fa-download"></i> {{ 'general.export_data' | translate }}
            </button>
            <div class="dropdown-content">
               <a (click)="exportToExcel()">{{ 'general.export_as_excel' | translate }} (.xlsx)</a>
               <a (click)="exportToPDF()">{{ 'general.export_as_pdf' | translate }}</a>
               <a (click)="exportToCSV()">{{ 'general.export_as_csv' | translate }}</a>
            </div>
         </div>
      </div>
      <div #contentToCapture>
         <div class="overview overview1 white border shadow">
            <h2>{{ 'general.overview' | translate }}</h2>
            <div>
               <!-- <div>
                     <img src="../../../../assets/images/newIcons/18.Faculty.png" alt="">
                        <div>
                           {{ 'app.institutions' | translate }}<span>{{
                              this.institutionData?.nrOfFaculties || 0
                           }}</span>
                        </div>
                     </div>
                     <div>
                        <img src="../../../../assets/images/newIcons/19.Department_Color.png" alt="">
                        <div>
                           {{ 'general.departments' | translate }}<span>{{
                              this.institutionData?.nrOfDepartments || 0
                           }}</span>
                        </div>
                     </div> -->
               <div>
                  <img src="../../../../assets/images/newIcons/1.Subject_Color.png" alt="">
                  <div>
                     {{ 'general.subjects' | translate }}<span>{{
                        this.institutionData?.nrOfSubjects || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/2.Assignments_Color.png" alt="">
                  <div>
                     {{ 'general.assignments' | translate }}
                     <span>{{ this.institutionData?.nrOfAssignments || 0 }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/22.Student_Color.png" alt="">
                  <div>
                     {{ 'general.students' | translate }}
                     <span> {{ this.institutionData?.nrOfStudents || 0 }} </span>
                  </div>
               </div>
               <!-- <div>
                        <img src="../../../../assets/images/newIcons/21.ActiveUser.png" alt="">
                        <div>
                           Active users
                           <span>
                              {{
                                 this.institutionData?.nrOfActiveUsers || 0
                              }}
                           </span>
                        </div>
                     </div> -->
               <div>
                  <img src="../../../../assets/images/newIcons/20.Professor_Color.png" alt="">
                  <div>
                     {{ 'general.educators' | translate }}<span>{{
                        this.institutionData?.nrOfProfessors || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/23.StudentSubmission_Color.png" alt="">
                  <div>
                     {{ 'app.student_submissions' | translate }}<span>{{
                        this.institutionData?.nrOfStudentSubmissions || 0
                        }}</span>
                  </div>
               </div>
               <!-- <div>
                        <img src="../../../../assets/images/newIcons/24.Profesor-submissons.png" alt="">
                        <div>
                           {{ 'app.educator_submissions' | translate }}<span
                              >{{
                                 this.institutionData?.nrOfProfessorSubmissions || 0
                              }}</span
                           >
                        </div>
                     </div> -->
               <!-- <div>
                        <img src="../../../../assets/images/newIcons/7.Thesis_Bachelor_Color.png" alt="">
                        <div>
                           Bachelor theses<span>{{
                              this.institutionData?.nrOfBachelorThesis || 0
                           }}</span>
                        </div>
                     </div>
                     <div>
                        <img src="../../../../assets/images/newIcons/8.Thesis_Master_Color.png" alt="">
                        <div>
                           Master theses<span>{{
                              this.institutionData?.nrOfMasterThesis || 0
                           }}</span>
                        </div>
                     </div>
                     <div>
                        <img src="../../../../assets/images/newIcons/9.Thesis_PHD_Color.png" alt="">
                        <div>
                           Ph.D theses<span>{{
                              this.institutionData?.nrOfPhdThesis || 0
                           }}</span>
                        </div>
                     </div> -->
               <div>
                  <img src="../../../../assets/images/newIcons/10.HighSimilarity.png" alt="">
                  <div>
                     {{ 'report.high_similarity' | translate }}<span>{{
                        this.institutionData
                        ?.nrOfHighSimilaritySubmissions || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/11.MediumSimilarity.png" alt="">
                  <div>
                     {{ 'report.medium_similarity' | translate }}<span>{{
                        this.institutionData
                        ?.nrOfMediumSimilaritySubmissions || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/12.LowSimilarity.png" alt="">
                  <div>
                     {{ 'report.low_similarity' | translate }}<span>{{
                        this.institutionData
                        ?.nrOfLowSimilaritySubmissions || 0
                        }}</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="overview white border shadow">
            <h2>{{ 'app.institution_ratios' | translate }}</h2>
            <div>
               <div>
                  <img src="../../../../assets/images/newIcons/25.Student-Profesor-Ratio.png" alt="">
                  <div>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.educator' | translate
                     }}
                     <span>{{ this.institutionData?.nrOfStudents || 0 }} : {{this.institutionData?.nrOfProfessors ||
                        0}}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/26.Student-Subject.png" alt="">
                  <div>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.subjects' | translate
                     }}
                     <span>{{
                        this.institutionData?.nrOfStudents || 0
                        }} : {{ this.institutionData?.nrOfSubjects || 0 }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/27.Student-Submission-Ratio.png" alt="">
                  <div>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' |
                     translate
                     }}<span>{{
                        this.institutionData?.nrOfStudents || 0
                        }} : {{
                        this.institutionData?.nrOfStudentSubmissions || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/28.Profesor-Subject-Ratio.png" alt="">
                  <div>
                     {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.subjects' | translate
                     }}<span>{{
                        this.institutionData?.nrOfProfessors || 0
                        }} : {{
                        this.institutionData?.nrOfSubjects || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/29.Profesor-Assignments-Ratio.png" alt="">
                  <div>
                     {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.assignment' |
                     translate
                     }}<span>{{
                        this.institutionData?.nrOfProfessors || 0
                        }} : {{
                        this.institutionData?.nrOfAssignments || 0
                        }}</span>
                  </div>
               </div>
               <div>
                  <img src="../../../../assets/images/newIcons/30.Professor-Submission-Ratio.png" alt="">
                  <div>
                     {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' |
                     translate
                     }}<span>{{
                        this.institutionData?.nrOfProfessors || 0
                        }} : {{
                        this.institutionData?.nrOfProfessorSubmissions || 0
                        }}</span>
                  </div>
               </div>
               <!-- <div>
                        <img src="../../../../assets/images/newIcons/31.Bachelor-professors.png" alt="">
                        <div>
                           Bachelors-{{ 'other.to' | translate }}-{{ 'general.educator' | translate }}<span
                           >{{
                              this.institutionData?.nrOfBachelorThesis || 0
                           }} : {{
                              this.institutionData?.nrOfProfessors || 0
                           }}</span
                        >
                        </div>
                     </div>
                     <div>
                        <img src="../../../../assets/images/newIcons/32.Master-professors.png" alt="">
                        <div>
                           Masters-{{ 'other.to' | translate }}-{{ 'general.educator' | translate }}<span
                           >{{
                              this.institutionData?.nrOfMasterThesis || 0
                           }} : {{
                              this.institutionData?.nrOfProfessors || 0
                           }}</span
                        >
                        </div>
                     </div>
                     <div>
                        <img src="../../../../assets/images/newIcons/32.PhD-professors.png" alt="">
                        <div>
                           Ph.D.-{{ 'other.to' | translate }}-{{ 'general.educator' | translate }}<span
                           >{{
                              this.institutionData?.nrOfPhdThesis || 0
                           }} : {{
                              this.institutionData?.nrOfProfessors || 0
                           }}</span
                        >
                        </div>
                     </div> -->
            </div>
         </div>
         <div class="white-bg border shadow">
            <div class="filtersContainer">
               <select name="" id="" (change)="this.filter($event.target.value)">
                  <option value="1">{{ 'app.student_submissions' | translate }}</option>
                  <option value="2">{{ 'general.students' | translate }}</option>
                  <!-- <option value="3">{{ 'general.theses' | translate }}</option> -->
                  <!-- <option value="4">{{ 'app.educator_submissions' | translate }}</option> -->
                  <option value="5">{{ 'general.educators' | translate }}</option>
               </select>
            </div>

            <ng-container *ngIf="this.graphToDisplay === 1">
               <app-submissions-institutional></app-submissions-institutional>
            </ng-container>
            <ng-container *ngIf="this.graphToDisplay === 2">
               <app-users-institutional></app-users-institutional>
            </ng-container>
            <ng-container *ngIf="this.graphToDisplay === 3">
               <app-thesis-institutional></app-thesis-institutional>
            </ng-container>
            <ng-container *ngIf="this.graphToDisplay === 4">
               <app-submissions-basic></app-submissions-basic>
            </ng-container>
            <ng-container *ngIf="this.graphToDisplay === 5">
               <app-users-basic></app-users-basic>
            </ng-container>
         </div>
         <div class="active-users-statistics">
            <div class="white-bg shadow border active-users">
               <div class="flex headerCard">
                  <img src="../../../../assets/images/newIcons/33.ProfessorWithCreatedAssigmnents.png" alt="">
                  <h5 class="bold">{{ 'app.educators_with_most_created_assignments' | translate }}</h5>
               </div>
               <div class="content">
                  <ng-container *ngIf="this.assignmentsStatistics?.length != 0">
                     <ng-container *ngFor="let user of this.assignmentsStatistics">
                        <div class="flex">
                           <i class="fas fa-file"></i>
                           <span><span class="user_count">{{ user._count.Assignments }}</span> {{ 'general.assignments'
                              |
                              translate }} </span>
                           <h6>{{ user.name }}</h6>
                        </div>
                     </ng-container>
                  </ng-container>
                  <ng-contaier *ngIf="this.assignmentsStatistics?.length == 0" class="zeroState">{{ 'new_entries.no_assignments' | translate }}
                     yet</ng-contaier>
               </div>
            </div>
            <div class="white-bg shadow border active-users">
               <div class="flex withFilters">
                  <div class="flex headerCard">
                     <img src="../../../../assets/images/newIcons/34.UserWithMostUploadedSubmission.png" alt="">
                     <h5 class="bold">{{ 'app.students_with_the_most_submissions' | translate }}</h5>
                  </div>
                  <!-- <select name="" id="" (change)="changeType($event.target.value)">
                           <option value="4" selected>{{ 'general.educator' | translate }}</option>
                           <option value="5">{{ 'general.student' | translate }}</option>
                        </select> -->
               </div>
               <div class="content">
                  <ng-container *ngIf="this.submissionsStatistics?.length != 0">
                     <ng-container *ngFor="let user of this.submissionsStatistics">
                        <div class="flex">
                           <i class="fas fa-file"></i>
                           <span><span class="user_count">{{ user._count.Submissions }}</span>{{ 'general.submissions' |
                              translate }}</span>
                           <h6>{{ user.name }}</h6>
                        </div>
                     </ng-container>
                  </ng-container>
                  <div class="noSubmissions zeroState" *ngIf="this.submissionsStatistics?.length == 0">{{
                     'app.there_are_no_submissions' | translate }}</div>
               </div>
            </div>
            <!-- <div class="white-bg shadow border active-users">
                     <div class="flex withFilters">
                        <div class="flex headerCard">
                           <img src="../../../../assets/images/newIcons/35.LatestThesisSubmited.png" alt="">
                           <h5 class="bold">{{ 'general.recent_theses_submissions' | translate }}</h5>
                        </div>
                        <select
                           name=""
                           id=""
                           (change)="changeThesisType($event.target.value)"
                        >
                           <option value="bachelor" selected>Bachelor</option>
                           <option value="master">{{ 'general.master' | translate }}</option>
                           <option value="phd">{{ 'general.phd' | translate }}.</option>
                        </select>
                     </div>
                     <div class="content" >
                        <ng-container *ngFor="let submission of thesisStatistics">
                           <div class="flex">
                              <i style="color: #70c598" class="far fa-file-alt"></i>
                              <span>{{ submission.thesis.name }} submissions</span>
                              <h6 class="shadow border">{{ submission.user.name }}</h6>
                           </div>
                        </ng-container>
                     </div>
                  </div> -->
         </div>
      </div>
   </main>
</section>
