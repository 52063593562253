<nav role="navigation" class="document_nav" aria-label="Navigation, used to tell on which page the document is.">
  <div role="tablist">
    <button
      *ngFor="let page of visiblePages"
      role="tab"
      [ngClass]="{ 'nav_tab': true, 'active_nav': page === activeSectionIndex }"
      (click)="setActiveSection(page)"
    >
      <span [ngClass]="{has_highlights: hasHighlightedSentences(page)}">{{ page.ellipsis ? '...' : page }}</span>
    </button>
  </div>
</nav>
