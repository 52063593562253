<h4>{{ 'app.invite_student' | translate }}</h4>
<form [formGroup]="studentInvite">
   <div class="editimi-fakultetit-inputs">
      <div class="inside-inputs">
         <label for="studentName">{{ 'app.student_name' | translate }}</label>
         <input formControlName="studentName" type="text" />
      </div>
      <div
         *ngIf="
            studentInvite.controls.studentName.touched &&
            studentInvite.controls.studentName.invalid
         "
         class="invalid"
      >
         {{ 'general.student' | translate }} {{ 'general.name' | translate }} {{ 'aria-labels.is_required' | translate }}*
      </div>
      <div
         *ngIf="studentInvite.controls.studentName.errors?.minlength"
         class="invalid"
      >
         {{ 'general.student' | translate }} {{ 'general.name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
      </div>
      <div class="inside-inputs">
         <label for="studentEmail">{{ 'general.email' | translate }}</label>
         <input formControlName="studentEmail" type="email" />
      </div>
      <div
         *ngIf="
            studentInvite.controls.studentEmail.touched &&
            studentInvite.controls.studentEmail.invalid
         "
         class="invalid"
      >
         {{ 'login.email_required' | translate }}*
      </div>

      <div
         *ngIf="studentInvite.controls.studentEmail.errors?.minlength"
         class="invalid"
      >
         {{ 'general.student' | translate }} email {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
      </div>
      <div class="inside-inputs">
         <label for="documentLanguage">{{ 'general.language' | translate }}</label>
         <select
            name=""
            id=""
            formControlName="documentLanguage"
            (change)="this.languageSelected(1)"
         >
            <option value="0" disabled selected>{{ 'general.choose_language' | translate }}</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedTranslateLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>

      <div class="inside-inputs">
         <label for="translateTo">{{ 'individual.upload.translate_document' | translate }}</label>
         <select
            name=""
            id=""
            formControlName="translateTo"
            (change)="this.languageSelected(2)"
         >
            <option value="0" disabled selected>{{ 'general.choose_language' | translate }}</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedDocLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>
   </div>
   <div class="editimi-fakultetit-fshij-perfundo">
      <button
         [disabled]="studentInvite.invalid"
         [ngClass]="{
            disable: studentInvite.invalid
         }"
         type="submit"
         class="submit"
         (click)="update()"
      >
         Send Invitation
      </button>
   </div>
</form>
