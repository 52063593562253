import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class PdfExportService {

  constructor() { }

  public captureAndExportPDF(element: HTMLElement, fileName: string = 'Report.pdf'): void {
    if (element) {
      html2canvas(element, { scale:2 }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(fileName);
      }).catch(error => {
        console.error('Could not capture the element to export as PDF', error);
      });
    } else {
      console.error('Element not found.');
    }
  }
}
