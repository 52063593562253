<section>
   <!-- <h4 class="bold w-87">{{ 'login.onboarding_panel' | translate }}</h4> -->
   <main class="flex register-profesori">
      <div class="links">
         <!-- <a routerLink="faculty-register" routerLinkActive="active"
            >{{ 'app.institutions' | translate }}</a>
         <a routerLink="department-register" routerLinkActive="active"
            >{{ 'general.departments' | translate }}</a> -->
         <a routerLink="course-register" routerLinkActive="active"
            >{{ 'general.subjects' | translate }}</a>
         <a routerLink="professor-register" routerLinkActive="active"
            >{{ 'general.educators' | translate }}</a>
         <a routerLink="student-register" routerLinkActive="active"
            >{{ 'general.students' | translate }}</a>
      </div>
      <div class="shadowBorder">
         <router-outlet></router-outlet>
      </div>
   </main>
</section>
