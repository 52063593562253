<main class="document_viewer" style="background-color: white;" role="main"
      aria-label="Pdf viewer, you can use arrow up or down in order to read the document.">
  <pdf-viewer
    #viewer
    id="viewer"
    (pagechanging)="pageChanging($event)"
    [src]="pdfSrc"
    [zoom]="zoom"
    [render-text]="true"
    [fit-to-page]="true"
    [external-link-target] ="'none'"
    [zoom-scale]="'page-width'"
    [autoresize]="true"
    [page]="selectedPage"
    (page-rendered)="pageRendered($event)"
    [original-size]="false"
    aria-label="PDF document, scroll to see other pages."
    (after-load-complete)="onPdfLoad($event)"
  >
  </pdf-viewer>
  <!-- <section class="pdf_btns">
    <button (click)="zoomIn()">+</button>
    <button (click)="zoomOut()">-</button>
  </section> -->
</main>
