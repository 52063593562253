import { Component, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import * as userActions from '../../../../authModule/state/actions/authentication.actions';
import { UploadfileService } from '../../upload-file/Service/uploadfile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-upload-draft-file',
   templateUrl: './upload-draft-file.component.html',
   styleUrls: ['./upload-draft-file.component.scss'],
})
export class UploadDraftFileComponent implements OnInit, OnDestroy {
   uploaded = 0;
   /**
    * Used to store uploaded file.
    */
   fileToUpload: File = null;
   /**
    * Array of uploaded files.
    */
   files = [];
   /**
    * Variables used to allow/deny user document upload.
    */
   allowToSubmitDocument: boolean = false;
   /**
    * Used to check if used trial has ended or not.
    */
   trial: boolean = false;
   /**
    * User document preview url.
    */
   previewSrc: any;
   /**
    * Upload from used to get user input from forms.
    */
   uploadForm: any;
   /**
    * Language that user want to check their document similarity in a translated language.
    */
   documentTranslatedLanguage: string = undefined;

   /**
    * Key that used to generate preview url for document preview.
    */
   previewKey: any;
   /**
    * Boolean value used to show/hide advanced options of the form.
    */
   advancedOptionsIndex: boolean = false;
   /**
    * Boolean value used to get user consent to archive the file.
    */
   archive: boolean = true;
   /**
    * Document citation style picked by user.
    */
   citationStyle: string = '';
   /**
    * Language of the document selected by user.
    */
   languageSelected: boolean = false;
   /**
    * List of the available languages.
    */
   languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
      {
         text: 'Danish',
         value: 'da'
      },
      {
         text: 'Estonian',
         value: 'et'
      }
   ];
   /**
    * List of third party libraries to check for document.
    */
   thirdPartyLib: Array<any> = [
      {
         description: 'ScienceDirect',
         isChecked: false,
         information:
            'Searching ScienceDirect content may involve delays on checking your document.',
      },
      {
         description: 'Scopus',
         isChecked: false,
         information:
            'Searching Scopus metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CORE',
         isChecked: false,
         information:
            'Searching CORE content may involve delays on checking your document. ',
      },
      {
         description: 'IEEE',
         isChecked: false,
         information:
            'Searching IEEE metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'arXiv.org',
         isChecked: false,
         information:
            'Searching arXiv metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Crossref',
         isChecked: false,
         information:
            'Searching Crossref metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Europeana',
         isChecked: false,
         information:
            'Searching Europeana metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CaseLaw Access Project',
         isChecked: false,
         information:
            'Searching CaseLaw content may involve delays on checking your document.',
      },
   ];

   thirdPartyLibraries: any = this.thirdPartyLib;

   uploadedFile: any;
   /**
    * Used to check if the file is converted from docx to pdf.
    */
   converted: any;

   /**
    * Is the current pdf producer allowed
    */
   allowedProducer;
   /**
    * User from store
    */
   user;
   /**
    * User Subscriber
    */
   user$;
   documentCredits;
   disableField: boolean = false;
   draftId;
   currentSubmissionDetails;
   constructor(
      private fileUpload: UploadfileService,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private submissionService: SubmissionsService,
      private toastrService: ToastrService,
      private router: Router,
      private store: Store,
      private route: ActivatedRoute,
      public translate: TranslateService
   ) {}

   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.route.params.subscribe((params) => {
         this.draftId = params.draftId;
      });

      this.uploadForm = this.fb.group({
         documentTitle: ['', [Validators.required]],
         fullName: ['', [Validators.required]],
         dateOfUpload: ['', [Validators.required]],
         documentSize: ['', [Validators.required]],
         documentCredits: ['', [Validators.required]],
         documentLanguage: ['', [Validators.required]],
         documentTranslatedLanguage: [''],
         thirdPartyLibs: this.fb.array(this.thirdPartyLib),
      });

      this.store.dispatch(userActions.currentUser());

      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;

            if (this.user.allowedLanguages !== null) {
               this.disableField = true;
            }
         }
      });
   }

   /**
    * Method used to trigger file upload.
    */
   upload() {
      $('.dropzone').trigger('click');
   }
   /**
    * Method used to upload, update & preview file at upload form.
    */
   onFileSelected(event: any) {
      this.fileToUpload = <File>event.target.files[0];
      const firstName = this.user?.name.split(' ')[0];
      const lastName = this.user?.name.split(' ')[1];
      this.form.fullName.setValue(this.user?.name);
      let fileSize = this.formatBytes(this.fileToUpload.size);
      this.form.documentSize.setValue(fileSize);

      let date = new Date();
      let dateString =
         date.getDate() +
         '/' +
         (date.getMonth() + 1) +
         '/' +
         date.getFullYear();
      this.form.dateOfUpload.setValue(dateString);
      this.spinner.show();
      this.fileUpload
         .generatePresignedURL(this.fileToUpload.type, firstName, lastName)
         .pipe(first())
         .subscribe(
            (data) => {
               this.submissionService
                  .getUserCurrentSubmissionDetails(this.draftId)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.currentSubmissionDetails =
                           data.currentSubmissionDetails;
                        this.form.documentTitle.setValue(
                           this.currentSubmissionDetails.title
                        );
                        this.form.fullName.setValue(
                           this.currentSubmissionDetails.author
                        );
                        this.form.documentLanguage.setValue(
                           this.currentSubmissionDetails.originalLanguage
                        );
                        this.form.documentTranslatedLanguage.setValue(
                           this.currentSubmissionDetails.translatedLanguage
                        );
                     },
                     (error) => {
                        console.log('error', error);
                     }
                  );

               if (data?.showAlert) {
                  this.spinner.hide();
               } else {
                  this.previewKey = data.key;
                  this.fileUpload
                     .uploadfileAWSS3(data.presignedS3Url, this.fileToUpload)
                     .pipe(first())
                     .subscribe(
                        (data1) => {
                           this.fileUpload
                              .generatePresignedURLPreview(
                                 this.previewKey,
                                 this.fileToUpload.type,
                                 firstName,
                                 lastName
                              )
                              .pipe(first())
                              .subscribe((data) => {
                                 this.documentCredits = data.documentCredits;
                                 setTimeout(() => {
                                    this.previewSrc =
                                       data.presignedS3UrlPreview;
                                    this.uploadedFile = data.Key;
                                    this.converted = data.converted;
                                    this.allowToSubmitDocument =
                                       data.allowSubmission.allowToSubmitDocument;
                                    this.trial = data.allowSubmission.trail;
                                    this.allowedProducer = data.allowedProducer;
                                    if (this.allowedProducer) {
                                       this.showNotAllowedProducerMessage();
                                    }
                                    this.form.documentCredits.setValue(
                                       this.documentCredits
                                    );
                                    if (this.user.Institution !== null) {
                                       this.form.documentTranslatedLanguage.setValue(
                                          this.user.allowedLanguages
                                             .targetLanguage
                                       );
                                       this.form.documentLanguage.setValue(
                                          this.user.allowedLanguages
                                             .documentLanguage
                                       );
                                    } else {
                                       this.form.documentTranslatedLanguage.setValue(
                                          -1
                                       );
                                       this.form.documentLanguage.setValue(-1);
                                    }
                                 }, 500);

                                 this.uploaded = 1;
                                 this.spinner.hide();
                              }),
                              (error) => {
                                 this.spinner.hide();
                                 console.log('error3', error);
                              };
                        },
                        (error) => {
                           this.spinner.hide();
                           console.log('error2', error);
                        }
                     );
               }
            },
            (error) => {
               this.spinner.hide();
               console.log('error1', error);
            }
         );
   }
   /**
    * Method used to get controls from formGroup
    */
   get form() {
      return this.uploadForm.controls;
   }
   /**
    * Method used to get libraries from array from upload from.
    */
   get libraries() {
      return <FormArray>this.uploadForm.get('thirdPartyLibs');
   }

   /**
    * Method used to format bytes for the file upload.
    * @param bytes
    */
   formatBytes(bytes) {
      var marker = 1024; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB

      if (bytes < kiloBytes) return bytes + ' Bytes';
      else if (bytes < megaBytes)
         return (bytes / kiloBytes).toFixed(decimal) + ' KB';
      else if (bytes < gigaBytes)
         return (bytes / megaBytes).toFixed(decimal) + ' MB';
   }
   /**
    * Method used to create submission when uploading a document.
    */
   async createSubmission() {
      if (this.user.nrOfDrafts === 0) {
         const result = await swal.fire({
            title: 'Something is wrong!',
            text: "You don't have draft submissions available to submit the document",
            icon: 'error',
            confirmButtonText: 'Store',
            confirmButtonColor: '#F3001E',
            allowOutsideClick: false,
         });
         if (result.isConfirmed) {
            this.router.navigateByUrl('/individual/add-credits');
            return;
         }
      }

      this.spinner.show();

      this.submissionService
         .createDraftSubmission(
            this.form.documentTitle.value,
            this.uploadedFile,
            this.form.fullName.value,
            this.form.dateOfUpload.value,
            this.form.documentSize.value,
            this.form.documentLanguage.value,
            this.form.documentTranslatedLanguage.value,
            this.archive,
            this.citationStyle,
            this.thirdPartyLibraries,
            this.converted,
            this.currentSubmissionDetails.id
         )
         .subscribe(
            (data) => {
               setTimeout(() => {
                  this.router.navigateByUrl('/individual/dashboard');
               }, 1500);
               this.toastrService.success(this.translate.instant('report.document_is_uploaded_successfully'));
               this.spinner.hide();
            },
            (error) => {
               console.log('error Submit', error);
               this.spinner.hide();
            }
         );
   }

   /**
    * A lifecycle hook that is called when a directive, pipe, or service is destroyed.
    * Use for any custom cleanup that needs to occur when the instance is destroyed.
    */
   ngOnDestroy() {
      this.user$.unsubscribe();
   }

   /**
    * Method to display modal that the producer is not allowed
    */
   async showNotAllowedProducerMessage() {
      const result = await swal.fire({
         title: this.translate.instant('app.this_file_has_some_issues'),
         text: this.translate.instant('app.try_converting_your_file_to_docx_and_then_convert_it_to_pdf'),
         icon: 'warning',
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.i_understand'),
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }

   /**
    * Method used to show warning toaster.
    * @param message
    */
   showWarningMessage(message) {
      this.toastrService.warning(message);
   }
}
