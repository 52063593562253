<section class="admin-dashboard" style="justify-content: flex-start">
   <!-- <h4 class="bold w-87">Administration panel</h4> -->
   <main>
      <!-- <div class="cards">
         <div
            routerLink="academic-units-faculties"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>{{ 'app.institutions' | translate }}</div>
         </div>
         <div
            routerLink="academic-units-departments"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>{{ 'general.departments' | translate }}</div>
         </div>
         <div
            routerLink="academic-units-courses"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>{{ 'general.subjects' | translate }}</div>
         </div>
         <div
            routerLink="academic-units-thesis"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>{{ 'general.theses' | translate }}</div>
         </div>
      </div> -->
      <app-statistics-dashboard></app-statistics-dashboard>
      <!-- <div class="shadowBorder">
         <router-outlet></router-outlet>
      </div> -->
   </main>
</section>
