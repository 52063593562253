// *** Angular
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// *** Packages
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

// *** Actions
import * as professorActions from '../state/professor/action/professor.actions';

// *** Selectors
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { getInstitutionProfessorsStateDetails } from '../state/professor/selector/professor.selectors';

// *** Services
import { CourseService } from 'src/app/services/course.service';
import { DepartmentService } from 'src/app/services/department.service';
import { FacultyService } from 'src/app/services/faculty.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from 'src/app/services/excel.service';
import { UploadfileService } from 'src/app/individual-components/individual-uploads/upload-file/Service/uploadfile.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-professor-register',
   templateUrl: './professor-register.component.html',
   styleUrls: ['./professor-register.component.scss'],
})
export class ProfessorRegisterComponent implements OnInit, OnDestroy {
   /**
    * Variable used to know if user is using excel form or manual form
    */
   excel = false;
   /**
    * Variable used to store faculties of institution
    */
   faculties;
   /**
    * Variable used to store departments of selected faculty
    */
   departments = [];

   /**
    * Variable used to store selected department courses
    */
   courses = [];

   /**
    * Form group used to get user input
    */
   professorRegister: FormGroup;

   /**
    * Variable stores configuration of ngx-select-dropdown
    */
   config;
   /**
    * Variables used to store information of user retrived from store
    */
   user$;
   user;
   /**
    * Variable used to store value of words left for institution
    */
   institutionWordsLeft;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer;
   /**
    * Variable used to store a boolean value for validating email.
    */
   emailExists: boolean = false;
   getInstitutionProfessorsStateDetails$: any;

   /**
    * Variable used to store selected Faculty for bulk upload method
    */
   selectedFaculty: string;
   /**
    * Variable used to store selected Department for bulk upload method
    */
   selectedDepartment: string;
   fileToUpload: File;
   showErrors: boolean = false;
   errorFromExcel = [];
   currentUser$: any;
   currentUserDetailsSubscriber$: any;

   /**
    *
    * @param fb
    * @param facultyService
    * @param departmentService
    * @param courseService
    * @param store
    * @param userService
    * @param spinnerService
    * @param dialogRef
    * @param data
    * @param excelService
    */
   constructor(
      private fb: FormBuilder,
      private facultyService: FacultyService,
      private departmentService: DepartmentService,
      private courseService: CourseService,
      private store: Store,
      private userService: UserService,
      private spinnerService: NgxSpinnerService,
      @Optional() public dialogRef: MatDialogRef<any>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private excelService: ExcelService,
      private uploadfileService: UploadfileService,
      private toastrService: ToastrService,
      public translate: TranslateService
   ) {}
   ngOnDestroy(): void {
      this.currentUserDetailsSubscriber$.unsubscribe();
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.currentUser$ = this.store.select(getCurrentUser);
      this.currentUserDetailsSubscriber$ = this.store
         .select(getCurrentUser)
         .subscribe((data: User) => {
             this.user = data;
             this.institutionWordsLeft = this.user.Institution.wordsLeft;
         });

      this.config = {
         displayKey: 'title', //if objects array passed which key to be displayed defaults to description
         search: true, //true/false for the search functionality defaults to false,
         height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
         placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
         limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
         moreText: 'more', // text to be displayed when more than one items are selected like Option 1 + 5 more
         noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
         searchPlaceholder: 'Search', // label thats displayed in search input,
         searchOnKey: 'title', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      };
      this.facultyService
         .getFacultiesOfInstitution(0)
         .pipe(first())
         .subscribe(
            (data) => {
               this.faculties = data.faculties;
            },
            (error) => {
               console.log(error);
            }
         );
      this.professorRegister = this.fb.group({
         professorName: ['', [Validators.required, Validators.minLength(3)]],
         professorFaculty: ['', [Validators.required]],
         professorDepartment: ['', [Validators.required]],
         professorCourse: [''],
         professorEmail: ['', [Validators.required, Validators.email]],
         professorWordsToUse: [''],
      });

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data.professorRegistered !== null) {
               if (this.dialogRef) {
                  this.dialogRef.close();
               } else {
                  this.professorRegister.reset();
                  this.store.dispatch(
                     professorActions.setProfessorRegisterToNull()
                  );
               }
            }
         });
   }
   /**
    * Method is used to calculate number of words that will be left while administrator allocates words to be used to professor.
    */
   calculate() {
      this.form.professorWordsToUse.setValue(
         Math.floor(this.professorRegister.value.professorWordsToUse)
      );
      this.institutionWordsLeft = this.user.Institution.wordsLeft;
      if (this.professorRegister.value.professorWordsToUse < 0) {
         this.form.professorWordsToUse.setValue(0);
      } else {
         if (
            this.professorRegister.value.professorWordsToUse >
            this.user.Institution.wordsLeft
         ) {
            this.institutionWordsLeft = 0;
            this.form.professorWordsToUse.setValue(
               this.user.Institution.wordsLeft
            );
         } else {
            this.institutionWordsLeft =
               this.institutionWordsLeft -
               this.professorRegister.value.professorWordsToUse;
         }
      }
   }

   /**
    * Method is used to toggle between excel upload form and manual register form.
    */
   toggleForm(formTodisplay) {
      if(formTodisplay=='manual') {
         this.excel = false;
      } else {
         this.excel = true;
      }

   }
   /**
    * Method is used to create a professorRegister.
    */
   async submit() {
      const result = await Swal.fire({
         title: 'You are about to add educators, please confirm your action by clicking “Confirm”.',
         // icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            professorActions.professorRegister({
               professorName: this.professorRegister.value.professorName,
               professorFaculty: this.professorRegister.value.professorFaculty,
               professorDepartment:
                  this.professorRegister.value.professorDepartment,
               professorSubjects: this.professorRegister.value.professorCourse,
               professorEmail: this.professorRegister.value.professorEmail,
               professorWords: this.professorRegister.value.professorWordsToUse,
            })
         );
      }
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.professorRegister.controls;
   }
   /**
    * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
    */
   facultyChanged() {
      this.professorRegister.controls.professorDepartment.setValue('');

      this.departmentService
         .getDepartmentsOfFaculty(
            this.professorRegister.value.professorFaculty ||
               this.selectedFaculty
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.departments = data.departments;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Method is used to call a api to check if emails exist on database or not.
    */
   // validateEmail() {
   //    let time;
   //    time = 300;
   //    clearTimeout(this.timer);
   //    if (this.form.professorEmail.value.length !== 0) {
   //       this.timer = setTimeout(() => {
   //          this.spinnerService.show();
   //          this.userService
   //             .checkEmail(this.form.professorEmail.value)
   //             .pipe(first())
   //             .subscribe(
   //                (data) => {
   //                   if (data.sameEmails !== 0) {
   //                      this.emailExists = true;
   //                   } else {
   //                      this.emailExists = false;
   //                   }
   //                   this.spinnerService.hide();
   //                },
   //                (error) => {
   //                   this.spinnerService.hide();
   //                   console.log('error', error);
   //                }
   //             );
   //       }, time);
   //    } else {
   //       this.emailExists = false;
   //    }
   // }
   /**
    *  Method is used to get department id from dropdown and then to retrieve courses of that department.
    */
   departmentChanged() {
      this.courseService
         .courses(0, 0)
         .pipe(first())
         .subscribe(
            (data) => {
               this.courses = data.courses;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to download excel form for bulk uploads
    */

   downloadTemplate() {
      this.excelService
         .generateGetPresignedUrl('professor-register-form.xlsx')
         .pipe(first())
         .subscribe(
            (data) => {
               saveAs(data.urlToDownload, 'professor-register-form.xlsx');
               Swal.fire(this.translate.instant('app.document_saved'), '', 'success');
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to trigger file upload.
    */
   upload() {
      $('.dropzone').trigger('click');
   }

   /**
    * Method used to upload, update & preview file at upload form.
    */
   onFileSelected(event: any) {
      this.spinnerService.show();
      this.errorFromExcel = [];
      this.showErrors = false;
      this.fileToUpload = <File>event.target.files[0];
      this.excelService
         .generatePutPresignedUrl('Professor')
         .pipe(first())
         .subscribe(
            (response) => {
               this.uploadfileService
                  .uploadfileAWSS3(
                     response.presignedS3UrlUpload,
                     this.fileToUpload
                  )
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.excelService
                           .registerProfessors(
                              response.key,
                              this.selectedFaculty,
                              this.selectedDepartment
                           )
                           .pipe(first())
                           .subscribe(
                              (data) => {
                                 this.spinnerService.hide();
                                 console.log('data', data);
                                 if (data.errors.length !== 0) {
                                    this.errorFromExcel = data.errors;
                                    this.showErrors = true;
                                 } else {
                                    this.toastrService.success(data.message);
                                 }
                              },
                              (error) => {
                                 console.log('error', error);
                                 this.spinnerService.hide();
                              }
                           );
                     },
                     (error) => {
                        console.log('error', error);
                        this.spinnerService.hide();
                     }
                  );
            },
            (error) => {
               console.log('error', error);
               this.spinnerService.hide();
            }
         );
   }

   showAlert() {
      Swal.fire(
        this.translate.instant('notifications.cant_upload_file'),
        this.translate.instant('notifications.please_select_institution_and_department_then_upload_the_file'),
         'warning'
      );
   }

   changeStyle(index) {
      let element = document.getElementById(index);
      element.style.textDecoration = 'line-through';
   }
}
