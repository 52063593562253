<section class="flex register-profesori container">
   <main>
      <h4 class="bold">{{ 'app.register_departments' | translate }}</h4>
      <!-- <div class="nav">
         <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
            >{{ 'general.manual_form' | translate }}</a
         >
         <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
            >{{ 'app.upload.bulk_form' | translate }}</a
         >
      </div> -->
      <ng-container *ngIf="this.excel === false">
         <form action="" [formGroup]="departmentRegister">
            <ng-container formArrayName="departments">
               <ng-container
                  *ngFor="
                     let faculty of departmentsForm.controls;
                     let i = index
                  "
                  [formGroupName]="i"
               >
                  <div class="departmentInfo">
                     <div style="width: 100%">
                        <h5>{{ 'app.info.department_name' | translate }}</h5>
                        <input formControlName="departmentName" type="text" />
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentName.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentName.invalid
                           "
                           class="invalid"
                        >
                           {{ 'app.info.department_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
                        </div>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentName.errors?.minlength
                           "
                           class="invalid"
                        >
                           {{ 'app.info.department_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
                        </div>
                        <h5>{{ 'app.duration_in_years' | translate }}</h5>
                        <select formControlName="departmentYear">
                           <!-- <option value="-1" selected disabled>
                              {{ 'app.choose_year' | translate }}s
                           </option> -->
                           <option value="1">1</option>
                           <option value="2">2</option>
                           <option value="3">3</option>
                           <option value="4">4</option>
                           <option value="5">5</option>
                        </select>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentYear.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentYear.invalid
                           "
                           class="invalid"
                        >
                           {{ 'general.department' | translate }} duration {{ 'aria-labels.is_required' | translate }}*
                        </div>
                        <h5>{{ 'app.institution' | translate }}</h5>
                        <select formControlName="departmentFaculty">
                           <option selected disabled value="">
                              {{ 'general.select_institution' | translate }}
                           </option>

                           <ng-container
                              *ngFor="
                                 let faculty of this.facultiesDetails?.faculties
                              "
                           >
                              <option value="{{ faculty.id }}">
                                 {{ faculty.name }}
                              </option>
                           </ng-container>
                        </select>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentFaculty.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentFaculty.invalid
                           "
                           class="invalid"
                        >
                           {{ 'general.department' | translate }} {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
                        </div>
                        <h5>{{ 'app.level_of_studies' | translate }}</h5>
                        <select formControlName="departmentLevel">
                           <!-- <option value="-1" selected disabled>
                              Choose level
                           </option> -->
                           <option value="1">{{ 'general.bachelor' | translate }}</option>
                           <option value="2">{{ 'general.master' | translate }}</option>
                           <option value="3">{{ 'general.phd' | translate }}.</option>
                        </select>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentLevel.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentLevel.invalid
                           "
                           class="invalid"
                        >
                           {{ 'general.department' | translate }} {{ 'general.level' | translate }} {{ 'aria-labels.is_required' | translate }}*
                        </div>
                        <h5>{{ 'general.description' | translate }}</h5>
                        <textarea
                           formControlName="departmentDescription"
                           name=""
                           id=""
                           cols="30"
                           rows="2"
                        ></textarea>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentDescription.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentDescription.invalid
                           "
                           class="invalid"
                        >
                           {{ 'general.department' | translate }} {{ 'general.description' | translate }} {{ 'aria-labels.is_required' | translate }}*
                        </div>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentDescription.errors?.minlength
                           "
                           class="invalid"
                        >
                           {{ 'general.department' | translate }} description {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
                        </div>
                     </div>
                     <div>
                        <ng-container
                           *ngIf="departmentsForm.controls.length > 1"
                        >
                           <button
                              class="removeFaculty"
                              (click)="this.removeDepartment(i)"
                           >
                              - Remove
                           </button>
                        </ng-container>
                     </div>
                  </div>
               </ng-container>
            </ng-container>
            <div>
               <button (click)="this.addDepartment()" class="addMore">
                  + Add a new department
               </button>
            </div>
            <div class="center" [ngClass]="{ flex: dialogRef }">
               <button
                  class="submit cancelButton"
                  *ngIf="dialogRef"
                  (click)="closeModal()"
               >
                  {{ 'general.cancel' | translate }}
               </button>
               <button
                  [disabled]="departmentRegister.invalid"
                  [ngClass]="{
                     disable: departmentRegister.invalid
                  }"
                  type="submit"
                  (click)="submit()"
                  class="submit"
               >
                  {{ 'general.register' | translate }}
               </button>
            </div>
         </form>
      </ng-container>
      <ng-container *ngIf="this.excel === true">
         <div class="content">
            <div>
               <select class="border" name="" id="">
                  <option value="">1</option>
                  <option value="">2</option>
               </select>
               <div class="border">
                  <h6>{{ 'app.download_template' | translate }}</h6>
                  <div>
                     <i class="fas fa-download"></i>
                     <p>
                        Download and fill the relevant information within the
                        template
                     </p>
                  </div>
               </div>
            </div>
            <div>
               <select class="border" name="" id="">
                  <option value=""></option>
                  <option value=""></option>
               </select>
               <div class="border">
                  <div>
                     <h6>{{ 'general.upload_template' | translate }}</h6>
                     <i class="fas fa-upload"></i>
                  </div>
                  <p>
                     Upload the filled template to migrate your data
                     automatically
                  </p>
               </div>
            </div>
         </div>
      </ng-container>
   </main>
</section>
