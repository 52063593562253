// *** Angular
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
   FormArray,
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// *** Packages
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

// *** Actions
import * as subjectActions from '../state/subject/action/subject.actions';
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';
import { getInstitutionSubjectsStateDetails } from '../state/subject/selector/subject.selectors';

// *** Services
import { CourseService } from 'src/app/services/course.service';
import { DepartmentService } from 'src/app/services/department.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from 'src/app/services/excel.service';
import { UploadfileService } from 'src/app/individual-components/individual-uploads/upload-file/Service/uploadfile.service';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-course-register',
   templateUrl: './course-register.component.html',
   styleUrls: ['./course-register.component.scss'],
})
export class CourseRegisterComponent implements OnInit, OnDestroy {
  currentLanguage = localStorage.getItem('websiteLanguage');
   /**
    * Variable used to know if user is using excel form or manual form
    */
   excel = false;
   /**
    * Variable used to store faculties of institution
    */
   facultiesDetails;
   facultiesDetails$;
   /**
    * Variable used to store departments of selected faculty
    */
   departments = [];
   /**
    * Form group used to get user input
    */
   subjectRegister: FormGroup;
   /**
    * Array is used to store years of selected department.(if selected departments has 3 years course will have only those options not 4th year.)
    */
   years: any[];
   /**
    * Variable used to store a boolean value for validating course code.
    */
   codeExists: boolean = false;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer;
   /**
    * Array that used to store errors of X position on multiple courses registration by form.(errors are for validating course code.)
    */
   errors = [];

   /**
    * Used to store same courses code errors before submitting to api
    */
   sameCodes = [];
   getInstitutionSubjectsStateDetails$: any;

   /**
    * Variable used to store selected Faculty for bulk upload method
    */
   selectedFaculty: string;
   /**
    * Variable used to store selected Department for bulk upload method
    */
   selectedDepartment: string;
   fileToUpload: File;
   showErrors: boolean = false;
   errorFromExcel = [];
   showSpinner: any;

   constructor(
      private fb: FormBuilder,
      private departmentService: DepartmentService,
      private store: Store,
      private courseService: CourseService,
      private spinner: NgxSpinnerService,
      @Optional() public dialogRef: MatDialogRef<any>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private excelService: ExcelService,
      private uploadfileService: UploadfileService,
      private toastrService: ToastrService,
      private languageService: LanguageService,
      public translate: TranslateService
   ) {}
   ngOnDestroy(): void {
      this.facultiesDetails$.unsubscribe();
      this.getInstitutionSubjectsStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));
      this.facultiesDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.facultiesDetails = data.faculties;
            }
         });

      this.subjectRegister = this.fb.group({
         subject: this.fb.array([]),
      });

      this.addSubject();
      this.getInstitutionSubjectsStateDetails$ = this.store
         .select(getInstitutionSubjectsStateDetails)
         .subscribe((data) => {
            if (data.subjectRegistered !== null) {
               if (this.dialogRef) {
                  this.dialogRef.close();
               } else {
                  this.subjectRegister = this.fb.group({
                     subject: this.fb.array([]),
                  });
                  this.addSubject();
                  this.store.dispatch(
                     subjectActions.setSubjectRegisterToNull()
                  );
               }
            }
         });

      const academicYears = this.generateAcademicYears();
      this.subjectRegister.get('subjectGenerate').setValue(academicYears[0]);
   }

   generateAcademicYears(): string[] {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const startYear = currentMonth >= 8 ? currentYear : currentYear - 1;

    const academicYears = [];

    for (let i = 0; i < 7; i++) {
      const academicYear = `${startYear + i}/${startYear + i + 1}`;
      academicYears.push(academicYear);
    }

    return academicYears;
  }

   /**
    * Method is used to toggle between excel upload form and manual register form.
    */
   toggleForm(formTodisplay) {
      if(formTodisplay=='manual') {
         this.excel = false;
      } else {
         this.excel = true;
      }

   }
   /**
    * Method is used to create a subject.
    */
   async submit() {
      if (this.subjectRegister.value.subject.length >= 2) {
         this.sameCodes = [];
         for (let x in this.subjectRegister.value.subject) {
            if (!this.sameCodes.includes(parseInt(x))) {
               for (let y in this.subjectRegister.value.subject) {
                  if (
                     this.subjectRegister.value.subject[x].subjectCode ===
                        this.subjectRegister.value.subject[y].subjectCode &&
                     x !== y
                  ) {
                     if (!this.sameCodes.includes(parseInt(x))) {
                        this.sameCodes.push(parseInt(x));
                     }
                     if (!this.sameCodes.includes(parseInt(y))) {
                        this.sameCodes.push(parseInt(y));
                     }
                  }
               }
            }
         }
      }

      const result = await Swal.fire({
         title: this.translate.instant('notifications.you_are_about_to_add_subjects_please_confirm_your_action_by_clicking_confirm'),
         // icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            subjectActions.subjectsRegister({
               data: this.subjectRegister.value.subject,
            })
         );
      }
   }

   /**
    * Method use to get form controls.
    * Method is used to get faculties from @subjectRegister
    */
   get subjectForm() {
      return this.subjectRegister.get('subject') as FormArray;
   }

   /**
    * Method is used to add new subject on formGroup  @subjectRegister
    */
   addSubject() {
      const subject = this.fb.group({
         subjectName: ['', [Validators.required, Validators.minLength(3)]],
         subjectFaculty: ['', [Validators.required]],
         subjectDepartment: ['', [Validators.required]],
         subjectDescription: [
            '',
            [Validators.required, Validators.minLength(3)],
         ],
         subjectYear: ['1'],
         subjectCode: ['', [Validators.required, Validators.minLength(3)]],
         subjectType: ['', [Validators.required]],
         subjectGenerate: ['g2425'],
         // subjectYears: ['']
      });
      this.subjectForm.push(subject);
   }

   removeSubject(i) {
      this.subjectForm.removeAt(i);
      this.departments.splice(i, 1);
   }

   /**
    * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
    */
   facultyChanged(i) {
      let facultyId = this.subjectRegister.value.subject[i].subjectFaculty;
      this.subjectForm.value[i].subjectDepartment = '';

      let controls = this.subjectForm.controls[i] as FormGroup;
      let department = controls.controls.subjectDepartment as FormControl;
      department.setValue('');

      this.departmentService
         .getDepartmentsOfFaculty(facultyId || this.selectedFaculty)
         .pipe(first())
         .subscribe(
            (data) => {
               if (this.departments.length != 0) {
                  if (this.departments.length >= i) {
                     this.departments[i] = data.departments;
                  } else {
                     this.departments.push(data.departments);
                  }
               } else {
                  this.departments.push(data.departments);
               }
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   departmentSelected(i, departmentId) {
      this.years = [];

      for (let department of this.departments[i]) {
         if (department.id === departmentId) {
            for (let index = 1; index <= department.year; index++) {
               this.years.push({ index });
            }
         }
      }
      let controls = this.subjectForm.controls[i] as FormGroup;
      let subjectYears = controls.controls.subjectYears as FormControl;
      subjectYears.setValue(this.years);
   }

   /**
    * Method is used to call a api to check if subject coude exist on database or not.
    */
   validateCode(code, i) {
      let time;
      time = 500;

      clearTimeout(this.timer);
      if (code.value.length !== 0) {
         this.timer = setTimeout(() => {
            this.showSpinner = i;
            this.courseService
               .checkCode(code.value)
               .pipe(first())
               .subscribe(
                  (data) => {
                     let newErrors = [...this.errors];
                     if (data.sameCourseCode !== 0) {
                        this.codeExists = true;
                        if (!newErrors.includes(i)) {
                           newErrors.push(i);
                        }
                        this.errors = newErrors;
                     } else {
                        this.codeExists = false;
                        for (let x = 0; x < newErrors.length; x++) {
                           if (newErrors[x] === i) {
                              newErrors.splice(x, 1);
                           }
                           this.errors = newErrors;
                        }
                     }
                     this.showSpinner = -1;
                  },
                  (error) => {
                     this.showSpinner = -1;
                     console.log('error', error);
                  }
               );
         }, time);
      } else {
         this.codeExists = false;
      }
   }

   /**
    * Method used to trigger file upload.
    */
   upload() {
      $('.dropzone').trigger('click');
   }

   showAlert() {
      Swal.fire(
         this.translate.instant('notifications.cant_upload_file'),
         this.translate.instant('notifications.please_select_institution_and_department_then_upload_the_file'),
         'warning'
      );
   }

   /**
    * Method used to upload, update & preview file at upload form.
    */
   onFileSelected(event: any) {
      this.errorFromExcel = [];
      this.showErrors = false;
      this.spinner.show();
      this.fileToUpload = <File>event.target.files[0];
      this.excelService
         .generatePutPresignedUrl('Course')
         .pipe(first())
         .subscribe(
            (response) => {
               this.uploadfileService
                  .uploadfileAWSS3(
                     response.presignedS3UrlUpload,
                     this.fileToUpload
                  )
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.excelService
                           .registerCourses(
                              response.key,
                              this.selectedFaculty,
                              this.selectedDepartment
                           )
                           .pipe(first())
                           .subscribe(
                              (data) => {
                                 this.spinner.hide();
                                 console.log('data', data);
                                 if (data.errors.length !== 0) {
                                    this.errorFromExcel = data.errors;
                                    this.showErrors = true;
                                 } else {
                                    this.toastrService.success(data.message);
                                 }
                              },
                              (error) => {
                                 console.log('error', error);
                                 this.spinner.hide();
                              }
                           );
                     },
                     (error) => {
                        console.log('error', error);
                        this.spinner.hide();
                     }
                  );
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide();
            }
         );
   }

   /**
    * Method used to download excel form for bulk uploads
    */

   downloadTemplate() {
      this.excelService
         .generateGetPresignedUrl('course-register-form.xlsx')
         .pipe(first())
         .subscribe(
            (data) => {
               saveAs(data.urlToDownload, 'course-register-form.xlsx');
               Swal.fire(this.translate.instant('app.document_saved'), '', 'success');
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   changeStyle(index) {
      let element = document.getElementById(index);
      element.style.textDecoration = 'line-through';
   }
}
