// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';

// *** Actions
import * as thesisSubmissionsActions from '../state/action/thesis-statistics.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';

// *** Selectors
import { getThesisSubmissionsStatisticsDetails } from '../state/selector/thesis-statistics.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';
import { ThesisService } from 'src/app/services/thesis.service';
import { Router } from '@angular/router';
@Component({
   selector: 'app-thesis-statistics',
   templateUrl: './thesis-statistics.component.html',
   styleUrls: ['./thesis-statistics.component.scss'],
})
export class ThesisStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable used to store theses id that is been clicked to exapnd for more information
    */
   thesisId: any;
   /**
    * Variable is used to store facultyId to filter thesis of specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Variable is used to store departmentId that will be used to filter thesis of specific department
    */
   departmentId = '-1';
   /**
    * Variable is used to store professorId that will be used to filter thesis of specific professor
    */
   professorId = '-1';
   /**
    * Variabel is used to store level of thesis to filter them by level(Bachelor, Master and Ph.D)
    */
   level = '-1';
   /**
    * Variable used to store information retrieved from service about overview of thesis statistics
    */
   thesisOverviewStatistics: any;
   /**
    * Variable used to store student name to retrieve thesis of specific student
    */
   studentName: any;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   getFiltersState = true;
   /**
    * Variable used to store information of thesis statistics
    */
   statistics;
   /**
    * Variables used to retrieve information from store
    */
   thesisSubmissions$;
   thesisSubmissions;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variabels used to retrieve professors of insitution from store.
    */
   professors;
   professors$;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   getFiltersStateDetails$: any;
   getThesisSubmissionsStatisticsDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getInstitutionProfessorsStateDetails$: any;
   constructor(
      private thesisService: ThesisService,
      private store: Store,
      private departmentService: DepartmentService,
      private administratorStatistics: AdministratorStatisticsService, 
      private router:Router
   ) {}
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.getThesisSubmissionsStatisticsDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.thesisSubmissionsFilters.faculty != null) {
               this.facultyId = data.thesisSubmissionsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.departments = data.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.thesisSubmissionsFilters.department != null) {
               this.departmentId = data.thesisSubmissionsFilters.department;
            }
            if (data.thesisSubmissionsFilters.professor != null) {
               this.professorId = data.thesisSubmissionsFilters.professor;
            }
            if (data.thesisSubmissionsFilters.student != null) {
               this.studentName = data.thesisSubmissionsFilters.student;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         thesisSubmissionsActions.loadThesisSubmissionStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
            level: this.level == '-1' ? undefined : this.level,
            studentName: this.studentName,
         })
      );

      this.getThesisSubmissionsStatisticsDetails$ = this.store
         .select(getThesisSubmissionsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.thesisSubmissions = data;
            }
         });

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data.professors !== null && this.professors == undefined) {
               this.professors = data.professors;
            }
         });

      this.administratorStatistics
         .thesisSubmissionStatisticsOverview()
         .pipe(first())
         .subscribe(
            (data) => {
               this.thesisOverviewStatistics = data;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to exapnd thesis information
    * @param thesisId
    */
   expand(thesisId) {
      this.thesisId = thesisId;
      if (this.expandInfo !== thesisId) {
         this.expandInfo = thesisId;
         this.store.dispatch(
            thesisSubmissionsActions.loadThesisSubmissionDetails({
               thesisId: this.thesisId,
            })
         );
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used to filter thesis by faculty
    */
   selectedFaculty() {
      this.departmentId = '-1';
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            professor: this.professorId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         thesisSubmissionsActions.loadThesisSubmissionStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
            level: this.level == '-1' ? undefined : this.level,
            studentName: this.studentName,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter thesis by given values
    */
   filterThesis() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            professor: this.professorId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         thesisSubmissionsActions.loadThesisSubmissionStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
            level: this.level == '-1' ? undefined : this.level,
            studentName: this.studentName,
         })
      );
   }
   /**
    * Function is used to filter thesis by student
    */
   studentFilter() {
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            professor: this.professorId,
            student: this.studentName,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            thesisSubmissionsActions.loadThesisSubmissionStatistics({
               page: this.page,
               facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
               departmentId:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               professorId:
                  this.professorId == '-1' ? undefined : this.professorId,
               level: this.level == '-1' ? undefined : this.level,
               studentName: this.studentName,
            })
         );
      }, time);
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         thesisSubmissionsActions.loadThesisSubmissionStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
            level: this.level == '-1' ? undefined : this.level,
            studentName: this.studentName,
         })
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professorId = '-1';
      this.level = '-1';
      this.studentName = undefined;
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            professor: this.professorId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         thesisSubmissionsActions.loadThesisSubmissionStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
            level: this.level == '-1' ? undefined : this.level,
            studentName: this.studentName,
         })
      );
   }

   goToLink(id: string): void {
      const currentUserRole = JSON.parse( localStorage.getItem('role'))
      if( currentUserRole !== 10 && currentUserRole !== 9){
         let url = `${environment.currentUrl}/professor/submission/${id}/report/v2`;
         window.open(url, '_blank');
      }else{
         this.router.navigateByUrl(`${environment.currentUrl}/professor/submission/${id}/report/v2`)
      }
   }
}
