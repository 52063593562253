import { createAction, props } from '@ngrx/store';

export const departmentsFilters = createAction(
   '[Filter] Administrator Departments Filter ',
   props<{ faculty?: any }>()
);

export const subjectsFilters = createAction(
   '[Filter] Administrator Subjects Filter ',
   props<{ faculty?: any; department?: any; professor?: any; student?: any }>()
);

export const assignmentFilters = createAction(
   '[Filter] Administrator Assignments Filter ',
   props<{ faculty?: any; department?: any; subject?: any; professor?: any }>()
);

export const professorsFilters = createAction(
   '[Filter] Administrator Professors Filter ',
   props<{ faculty?: any; department?: any; subject?: any }>()
);

export const studentsFilters = createAction(
   '[Filter] Administrator Students Filter',
   props<{ faculty?: any; department?: any; subject?: any }>()
);

export const assignmentSubmissionsFilters = createAction(
   '[Filter] Administrator Assignment Submissions Filter',
   props<{
      faculty?: any;
      department?: any;
      professor?: any;
      subject?: any;
      student?: any;
      assignment?: any;
      submission?:any
   }>()
);

export const professorSubmissionsFilters = createAction(
   '[Filter] Administrator Professor Submissions Filter',
   props<{ faculty?: any; department?: any; professor?: any}>()
);

export const thesisSubmissionsFilters = createAction(
   '[Filter] Administrator Thesis Submissions Filter ',
   props<{ faculty?: any; department?: any; professor?: any; student?: any }>()
);

export const submissionsFilters = createAction(
   '[Filter] Administrator Thesis Submissions Filter ',
   props<{ faculty?: any; department?: any; professor?: any; student?: any }>()
);
