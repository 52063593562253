import { Injectable } from '@angular/core';
import {
   CanActivate,
   ActivatedRouteSnapshot,
   UrlTree,
   Router,
   RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
/**
 * Guard is used to secure routes on client side so if a student tries to have access on professor route the auth guard will not activate requested guard
 */
@Injectable({
   providedIn: 'root',
})
export class AuthGuard implements CanActivate {
   /**
    * Class constructor
    * @param authServiceService
    * @param router
    */
   constructor(
      private authServiceService: AuthService,
      private router: Router
   ) {}
   /**
    * Method used to check if the path can be activated upon specific conditions.
    */
   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // Check if the current URL includes '/administrator/submission/', '/professor/submission/', or '/student/submission/'
      if (state.url.includes('/administrator/submission/') || state.url.includes('/professor/submission/') || state.url.includes('/student/submission/')) {
         return true; // Skip further checks and allow access
      }

   
      if (this.authServiceService.isLoggedInRole()) {
         if (route.data.roles && route.data.roles.includes(this.authServiceService.isLoggedInRole())) {
            return true;
         } else {
            return this.router.parseUrl('/notauthorized');
         }
      } else {
            return this.router.parseUrl('/login');
      }
   }
   
}
