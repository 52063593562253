import { AuthService } from './services/auth.service';
import {
   HttpEvent,
   HttpHandler,
   HttpRequest,
   HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
/**
 * Interceptor used for error handling
 */
@Injectable()
export class ErrorInterceptorService {
   /**
    * Class Constructor
    * @param toastrService
    */
   constructor(
      private toastrService: ToastrService,
      private authService: AuthService,
      private router:Router,
      public translate: TranslateService,
      private spinnerService:NgxSpinnerService
   ) {}

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
         catchError((response: HttpErrorResponse) => {
            if (response.status === 401) {
               this.authService.logout();
            }
            if (response.status === 403 && !request.url.includes('s3.eu-central-1.amazonaws.com')) {
               this.router.navigate(['/notauthorized']);
            }
            let errorMessage = '';
            if (response.error instanceof ErrorEvent) {
               // client-side error
               if (response.error) {
                  if (response.error.message) {
                     errorMessage = response.error.message;
                     this.toastrService.error(errorMessage);
                     this.spinnerService.hide();

                  } else {
                     this.toastrService.error(this.translate.instant('notifications.something_went_wrong'));
                     this.spinnerService.hide();
                  }
               } else {
                  this.toastrService.error(this.translate.instant('notifications.something_went_wrong'));
                  this.spinnerService.hide();
               }
            } else {
               // server-side error
               console.log('SERVER SIDE ERROR!!!');
               console.log('response', response);
               if (!request.url.includes('s3.eu-central-1.amazonaws.com')) {
               if (response.error) {
                  if (response.error.message) {
                     errorMessage = response.error.message;
                     console.log(errorMessage,"errorMessage");
                     this.toastrService.error(errorMessage);
                  } else {
                     this.toastrService.error(this.translate.instant('notifications.something_went_wrong'));
                     this.spinnerService.hide();
                  }
               } else if (response.status === 504) {
                  this.toastrService.error(this.translate.instant('notifications.we_are_experiencing_high_traffic_please_try_again_in_few_minutes_or_contact_us_for_help'));
                  this.spinnerService.hide();
               }  else {
                  this.toastrService.error(this.translate.instant('notifications.something_went_wrong'));
                  this.spinnerService.hide();
               }
            }
            }
            return throwError(errorMessage);
         })
      );
   }
}
