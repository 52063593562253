import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
   selector: 'from-lti-activization',
   templateUrl: './from-lti-activization.component.html',
   styleUrls: ['./from-lti-activization.component.scss'],
})
export class fromLtiActivization implements OnInit {
   /**
    * Form group to get email values
    */
   activateUserForm: FormGroup;
   /**
    * Component constructor
    * @param authService
    * @param fb
    * @param toastrService
    * @param spinnerService
    * @param router
    */
   constructor(
      private authService: AuthService,
      private fb: FormBuilder,
      private toastrService: ToastrService,
      private spinnerService: NgxSpinnerService,
      private router: Router
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.activateUserForm = this.fb.group({
         email: ['', [Validators.required, Validators.email]],
      });
   }

   /**
    * Method used to get controls from formGroup
    * @constructor
    */
   get FormValues() {
      return this.activateUserForm.controls;
   }

   /**
    * Method used to call forget password method service
    */
   submit() {
      this.spinnerService.show();
      this.authService
         .activateAccount(this.activateUserForm.value.email)
         .pipe(first())
         .subscribe(
            (data) => {
               this.spinnerService.hide();
               this.showToasterSuccess('Please check your email.');
               this.router.navigateByUrl('/login');
            },
            (error) => {
               console.log('Error:', error);
               this.spinnerService.hide();
            }
         );
   }

   /**
    * Toaster used to show a success message toaster
    * @param message
    */
   showToasterSuccess(message) {
      this.toastrService.success(message);
   }

   /**
    * Toaster used to show a success message toaster
    * @param message
    */
   showToasterError(message) {
      this.toastrService.error(message);
   }

   /**
    * Toaster used to show a warning message toaster
    * @param message
    */
   showToasterWarning(message) {
      this.toastrService.warning(message);
   }
}
