import { Injectable } from '@angular/core';
import * as iso6391 from 'iso-639-1';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})


export class LanguageService {
  languages = [
    {
       text: 'English',
       value: 'en',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Albanian',
       value: 'sq',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'German',
       value: 'de',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Italian',
       value: 'it',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'French',
       value: 'fr',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Spanish',
       value: 'es',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Greek',
       value: 'el',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Czech',
       value: 'cs',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Turkish',
       value: 'tr',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Slovak',
       value: 'sk',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Lithuanian',
       value: 'lt',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Latvian',
       value: 'lv',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Polish',
       value: 'pl',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Serbian',
       value: 'sr',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Macedonian',
       value: 'mk',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Portuguese',
       value: 'pt',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Dutch',
       value: 'nl',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Russian',
       value: 'ru',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Bulgarian',
       value: 'bg',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Hungarian',
       value: 'hu',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Romanian',
       value: 'ro',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Slovenian',
       value: 'sl',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Swedish',
       value: 'sv',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Finnish',
       value: 'fi',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Croatian',
       value: 'hr',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Bosnian',
       value: 'bs',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Norwegian',
       value: 'no',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Danish',
       value: 'da',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    },
    {
       text: 'Estonian',
       value: 'et',
       selectedDocLanguage: false,
       selectedTranslateLanguage: false,
    }
  ];

  findLanguageName(languageCode: string): string {
    try {
      return iso6391.default.getName(languageCode) || '-';
    } catch (error) {
      console.error('Error while finding language name:', error);
      return '-';
    }
  }

  storedLanguage: string = localStorage.getItem('websiteLanguage');
  private selectedLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.storedLanguage || 'en');
  selectedLanguage$: Observable<string> = this.selectedLanguageSubject.asObservable();

  setSelectedLanguage(languageCode: string): void {
    this.selectedLanguageSubject.next(languageCode);
  }

  private userIdSubject = new BehaviorSubject<string>(null);
  userId$ = this.userIdSubject.asObservable();

  setUserId(userId: string) {
    this.userIdSubject.next(userId);
  }

  public getLanguageName(languageCode: string): string {
    const language = this.languages.find((lang) => lang.value === languageCode);
    return language ? language.text : 'Unknown Language';
  }

  private userLanguageSubject = new BehaviorSubject<string>(null);
  getUserLanguage$ = this.userLanguageSubject.asObservable();
}
