<div *ngIf="this.userRole == '1'" class="flex register-monitoruesi">
  <h4 class="bold">{{ 'general.help_center' | translate }}</h4>
  <a routerLink="/admin/register" class="plus active upload-mobile"
    ><svg
      is="plus"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="66"
      viewBox="0 0 33 66"
    >
      <text
        id="_"
        data-name="+"
        transform="translate(0 52)"
        fill="#fff"
        font-size="54"
        font-weight="700"
      >
        <tspan x="0" y="0">+</tspan>
      </text>
    </svg>
    <h5>{{ 'general.register' | translate }}</h5>
  </a>
</div>
<section class="help-center">
  <h4 class="bold w-87">{{ 'add_credits.single_support' | translate }}</h4>
  <div>
    <div class="links">
      <a routerLink="how-to-use" routerLinkActive="active"
        >{{ "help_center.how_to_use_crossplag.link" | translate }}
      </a>
      <a routerLink="faqs" routerLinkActive="active">{{
        "help_center.FAQS.link" | translate
      }}</a>
      <a routerLink="contact-us" routerLinkActive="active">{{ 'help_center.contact_us.contact_us' | translate }}</a>
    </div>
    <router-outlet></router-outlet>
  </div>
</section>
