// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Actions
import * as studentActions from '../state/action/students-statistics.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';

// *** Selectors
import { getStudentsStatisticsDetails } from '../state/selector/students-statistics.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
@Component({
   selector: 'app-students-statistics',
   templateUrl: './students-statistics.component.html',
   styleUrls: ['./students-statistics.component.scss'],
})
export class StudentsStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter student statistics within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter student statistics within specific department.
    */
   departmentId = '-1';
   /**
    * Variabel used to store student name that has been used to filter specific students
    */
   studentName: string;
   /**
    * Variable used to store subject that returend students that are registered on that subject
    */
   studentSubject: any;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable used to store students with their statistics
    */
   students;
   /**
    * Variable used to store student id that used to expand specific row on HTML
    */
   studentId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store total number of students without pagination
    */
   studentsNumber;
   /**
    * Variables used to store faculties of institution
    */
   faculties;
   faculties$;
   /**
    * Varibale used to store boolean value and used to know if there is a filter activated or not
    */
   getFiltersStateDetails = true;
   getFiltersStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getStudentsStatisticsDetails$: any;
   getFiltersState;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router
   ) {}

   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getStudentsStatisticsDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.studentsFilters.faculty != null) {
               this.facultyId = data.studentsFilters.faculty;
               if (this.facultyId != '-1' && getFiltersStateDetails) {
                  this.departmentService
                     .getDepartmentsOfFaculty(this.facultyId)
                     .pipe(first())
                     .subscribe(
                        (departmentsDetails) => {
                           this.departments = departmentsDetails.departments;
                           if (data.studentsFilters.department != null) {
                              this.departmentId =
                                 data.studentsFilters.department;
                           }
                           this.store.dispatch(
                              studentActions.loadStudentsStatistics({
                                 page: this.page,
                                 facultyId:
                                    this.facultyId == '-1'
                                       ? undefined
                                       : this.facultyId,
                                 departmentId:
                                    this.departmentId == '-1'
                                       ? undefined
                                       : this.departmentId,
                                 studentName: this.studentName,
                                 subject: this.studentSubject
                              })
                           );
                        },
                        (error) => {
                           console.log(error);
                        }
                     );
               }
            }
            if (data.studentsFilters.subject != null) {
               this.studentSubject = data.studentsFilters.subject;
               this.changePage(1)
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         studentActions.loadStudentsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            studentName: this.studentName,
            subject: this.studentSubject
         })
      );

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getStudentsStatisticsDetails$ = this.store
         .select(getStudentsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.students = data.studentsStatistics;
               this.studentsNumber = data.studentsNumber;
            }
         });
   }
   /**
    * Function used to filter students by name
    */
   studentFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            studentActions.loadStudentsStatistics({
               page: this.page,
               facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
               departmentId:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               studentName: this.studentName,
               subject: this.studentSubject
            })
         );
      }, time);
   }
   /**
    * Function used to filter students by subject
    */
   subjectFilter() {
      // this.store.dispatch(
      //    filtersActions.studentsFilters({
      //       department: this.departmentId,
      //       faculty: this.facultyId,
      //       subject: this.studentSubject,
      //    })
      // );
      this.getFiltersState = false;
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            studentActions.loadStudentsStatistics({
               page: this.page,
               facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
               departmentId:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               studentName: this.studentName,
               subject: this.studentSubject,
            })
         );
      }, time);
   }
   /**
    * Function used to filter students by faculty
    */
   selectedFaculty() {
      this.departmentId = '-1';
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.studentsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.studentSubject,
         })
      );

      this.store.dispatch(
         studentActions.loadStudentsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            studentName: this.studentName,
            subject: this.studentSubject,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function used to filter students by department
    */
   selectedDepartment() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.studentsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.studentSubject,
         })
      );
      this.store.dispatch(
         studentActions.loadStudentsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            studentName: this.studentName,
            subject: this.studentSubject
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         studentActions.loadStudentsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            studentName: this.studentName,
            subject:this.studentSubject
         })
      );
   }
   /**
    * Function is used to exapnd thesis information
    * @param studentId
    */
   expand(studentId) {
      this.studentId = studentId;
      if (this.expandInfo !== studentId) {
         this.expandInfo = studentId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.studentSubject = undefined;
      this.studentName = undefined;
      this.page = 1;
      this.store.dispatch(
         filtersActions.studentsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.studentSubject,
         })
      );
      this.store.dispatch(
         studentActions.loadStudentsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            studentName: this.studentName,
            subject: this.studentSubject,
         })
      );
   }
   /**
    * Function is used when clicked on number of subject of student that redirected on subjects statistics
    * @param studentName
    */
   filterSubjects(studentName) {
      this.store.dispatch(
         filtersActions.subjectsFilters({
            student: studentName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/subjects'
      );
   }
   /**
    * Function is used when clicked on number of submissions on assigments of user to redirect to assigments submissions with filter activated to clicked student.
    * @param studentName
    */
   filterAssignmentsSubmissions(studentName) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            student: studentName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }
   /**
    * Function is used when clicked on number of thesis of user to redirect to thesis submissions with filter activated to clicked student.
    * @param studentName
    */
   filterThesisSubmissions(studentName) {
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            student: studentName,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/submissions/thesis');
   }
}
