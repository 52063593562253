<div class="file">
    <pdf-viewer
    id="viewer"
    [src]="data.src"
    [render-text]="true"
    [fit-to-page]="true"
    style="
       display: block;
       overflow: auto;
       height: 100% !important;
       scroll-behavior: smooth;
       border-radius: 10px;
    "
 ></pdf-viewer>
    <!-- <div
       style="
          background: #eee;
          padding-bottom: 1%;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
       "
    >
       <h6>{{ 'individual.upload.document_preview' | translate }}</h6>
       <p>{{ 'individual.upload.check_your_document' | translate }}</p>
    </div> -->
 </div>
