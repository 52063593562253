<section class="marketPlace">
   <h4 class="bold w-87">{{ 'add_credits.billing_and_licenses' | translate }}</h4>
   <div class="shadowBorder nav">
      <h3>{{ 'app.offers' | translate }}</h3>
      <img src="../../../../../assets/images/icons/plans.png" alt="" />
   </div>

   <div
      *ngIf="offers?.customOffers?.length == 0 && offers?.offers?.length == 0 && licenses?.length == 0"
      class="shadowBorder zero-state"
   >
      <p>
         You don`t have any plan active yet! <br />
         Please click on <b>“{{ 'app.get_quote' | translate }}”</b> {{ 'app.offer.to_create_your_plan' | translate }}.
      </p>
      <button (click)="newOfferModal()" class="submit">{{ 'app.get_quote' | translate }}</button>
   </div>
   <div
      *ngIf="offers?.customOffers?.length != 0 || offers?.offers?.length != 0 || licenses?.length != 0"
      class="shadowBorder content"
   >
      <h2 *ngIf="licenses?.length > 0">{{ 'add_credits.your_active_plan' | translate }}</h2>
      <div
         class="offersTable"
         *ngFor="let offer of this.licenses; let i = index"
      >
         <div class="header">
            <div>
               <span><b>{{ 'add_credits.active_plan' | translate }}</b></span>
               <h2>{{ offer.Offer.title }}</h2>
            </div>
            <a (click)="moreOfferDetails(i, 'activePlan')">{{ 'general.expand' | translate }}</a>
         </div>
         <div class="offerDetails" *ngIf="i !== moreActiveLicenseDetailsIndex">
            <span
               >{{ 'general.students' | translate }}: <b>{{ offer.Offer.students }}</b></span
            >
            <span
               >{{ 'general.educators' | translate }}: <b>{{ offer.Offer.professors }}</b></span
            >
            <!-- <span
               >{{ 'general.crosslingual' | translate }}:
               <b>
                  {{
                     offer.Offer.crossLingual['assignments'] === true ||
                     offer.Offer.crossLingual['professorUploads'] === true ||
                     offer.Offer.crossLingual['thesis'] === true
                        ? 'Yes'
                        : 'No'
                  }}</b
               ></span
            > -->
            <span
               >{{ 'general.license_duration' | translate }}: <b>{{ offer.paidPeriod }}</b></span
            >
            <!-- <span
               >{{ 'app.remaining_days' | translate }}: <b>{{ offer.remainingDays }}</b></span
            > -->
            <span
               >{{ 'general.contract_value' | translate }}: <b>{{ offer.paidPrice }}$</b></span
            >
         </div>
         <div
            class="offerExpandedDetails"
            *ngIf="i == moreActiveLicenseDetailsIndex"
         >
            <div>
               <h5>{{ 'general.number_of_students' | translate }}:</h5>
               <b>{{ offer.Offer.students }}</b>
               <!-- <a (click)="changeEditInput('student')">{{ 'general.add_user' | translate }}</a> -->
            </div>
            <div>
               <h5>{{ 'general.number_of_educators' | translate }}:</h5>
               <b>{{ offer.Offer.professors }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <div>
               <h5>{{ 'general.license_duration' | translate }}:</h5>
               <b>{{ offer.Offer.commitment }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'general.teaching_assistants' | translate }}:</h5>
               <b>{{ offer.Offer.teachingAssistants }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.academic_integrity_officer' | translate }}:</h5>
               <b>{{ offer.Offer.academicIntegrityOfficers }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.subscriptions' | translate }}:</h5>
               <b>{{ offer.Offer.subscriptions == 1 ? true : false }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.thesis_estimations' | translate }}:</h5>
               <div>
                  <div>
                     <b>Bachelor: </b>
                     <span> {{ offer.Offer.bachelor }}</span>
                  </div>
                  <div>
                     <b>{{ 'general.master' | translate }}: </b>
                     <span> {{ offer.Offer.master }}</span>
                  </div>
                  <div>
                     <b>{{ 'general.phd' | translate }}: </b>
                     <span> {{ offer.Offer.phd }}</span>
                  </div>
               </div> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.document_upload' | translate }}:</h5>
               <b>{{ offer.Offer.professorIndividualUploads }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!--<div>
               <h5>{{ 'general.crosslingual' | translate }}:</h5>
                <div>
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="assignment"
                        [checked]="offer.Offer.crossLingual['assignments']"
                     /><label for="assignment">{{ 'general.assignment_level' | translate }}</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="thesis"
                        [checked]="offer.Offer.crossLingual['thesis']"
                     /><label for="thesis">{{ 'general.thesis' | translate }}</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="professorUpload"
                        [checked]="offer.Offer.crossLingual['professorUploads']"
                     /><label for="professorUpload"
                        >{{ 'general.document_uploads' | translate }}</label
                     ></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('crossLingual')">{{ 'general.change' | translate }}</a> -->
            <!-- </div>  -->
            <div>
               <h5>{{ 'general.payment_preference' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Monthly"
                        [checked]="offer.Offer.billingPlan == 'Monthly'"
                     /><label for="Monthly">{{ 'general.monthly' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Annual"
                        [checked]="offer.Offer.billingPlan == 'Annual'"
                     /><label for="Annual">{{ 'general.annual' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Full"
                        [checked]="offer.Offer.billingPlan == 'OnePayment'"
                     /><label for="Full">{{ 'general.pre_paid' | translate }}</label></span
                  >
               </div>
               <!-- <a (click)="changeEditInput('billingPlan')">{{ 'general.change' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'add_credits.support' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Basic"
                        [checked]="offer.Offer.support == 'Basic'"
                     /><label for="Basic">{{ 'general.basic' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Standard"
                        [checked]="offer.Offer.support == 'Standard'"
                     /><label for="Standard">{{ 'general.standard' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Premium"
                        [checked]="offer.Offer.support == 'Premium'"
                     /><label for="Premium">{{ 'general.premium' | translate }}</label></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('support')">{{ 'general.change' | translate }}</a> -->
            <!-- </div> -->
            <div>
               <h5>{{ 'general.contract_value' | translate }}:</h5>
               <b>{{ offer.paidPrice }} $</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'app.remaining_days' | translate }}:</h5>
               <b>{{ offer.remainingDays }}</b>
            </div> -->
            <!-- <div>
               <h5>{{ 'general.license_duration' | translate }}:</h5>
               <b>{{ offer.commitment }} year</b>
            </div> -->
            <div>
               <h5>{{ 'app.license_expiry_date' | translate }}:</h5>
               <b >{{ licenseEndDate }}</b>
            </div>
         </div>
      </div>
      <h2 *ngIf="offers?.offers?.length != 0">{{ 'app.our_offers' | translate }}</h2>
      <div
         class="offersTable"
         *ngFor="let offer of this.offers?.offers; let i = index"
      >
         <div class="header">
            <div>
               <span>{{ 'general.plan_name' | translate }}</span>
               <h2>{{ offer.title }}</h2>
            </div>
            <a (click)="moreOfferDetails(i, 'offer')">{{ 'general.expand' | translate }}</a>
         </div>
         <div class="offerDetails" *ngIf="i !== moreOfferDetailsIndex">
            <span
               >{{ 'general.license_duration' | translate }}: <b>{{ offer.commitment }}</b></span
            >
            <span
               >{{ 'general.students' | translate }}: <b>{{ offer.students }}</b></span
            >
            <span
               >{{ 'general.educators' | translate }}: <b>{{ offer.professors }}</b></span
            >
            <!-- <span
               >{{ 'general.crosslingual' | translate }}:
               <b>
                  {{
                     offer.crossLingual['assignments'] === true ||
                     offer.crossLingual['professorUploads'] === true ||
                     offer.crossLingual['thesis'] === true
                        ? 'Yes'
                        : 'No'
                  }}</b
               ></span
            > -->
            <span
               >{{ 'general.payment_preference' | translate }}: <b>{{ offer.billingPlan }}</b></span
            >
            <span
               >{{ 'general.contract_value' | translate }}: <b>{{ offer.price }}$</b></span
            >
         </div>
         <div class="offerExpandedDetails" *ngIf="i == moreOfferDetailsIndex">
            <div>
               <h5>{{ 'general.number_of_students' | translate }}:</h5>
               <b>{{ offer.students }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <div>
               <h5>{{ 'general.number_of_educators' | translate }}:</h5>
               <b>{{ offer.professors }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <div>
               <h5>{{ 'general.license_duration' | translate }}:</h5>
               <b>{{ offer.commitment }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'general.teaching_assistants' | translate }}:</h5>
               <b>{{ offer.teachingAssistants }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.academic_integrity_officer' | translate }}:</h5>
               <b>{{ offer.academicIntegrityOfficers }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.thesis_estimations' | translate }}:</h5>
               <div>
                  <div>
                     <b>Bachelor</b>
                     <span>{{ offer.bachelor }}</span>
                  </div>
                  <div>
                     <b>{{ 'general.master' | translate }}</b>
                     <span>{{ offer.master }}</span>
                  </div>
                  <div>
                     <b>{{ 'general.phd' | translate }}</b>
                     <span>{{ offer.phd }}</span>
                  </div>
               </div> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.document_upload' | translate }}:</h5>
               <b>{{ offer.professorIndividualUploads }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.crosslingual' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="assignment"
                        [checked]="offer.crossLingual['assignments']"
                     /><label for="assignment">{{ 'general.assignment_level' | translate }}</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="thesis"
                        [checked]="offer.crossLingual['thesis']"
                     /><label for="thesis">{{ 'general.thesis' | translate }}</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="professorUpload"
                        [checked]="offer.crossLingual['professorUploads']"
                     /><label for="professorUpload"
                        >{{ 'general.document_uploads' | translate }}</label
                     ></span
                  >
               </div>-->
               <!-- <a (click)="changeEditInput('crossLingual')">{{ 'general.change' | translate }}</a> -->
            <!-- </div>  -->
            <div>
               <h5>{{ 'general.payment_preference' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Monthly"
                        [checked]="offer.billingPlan == 'Monthly'"
                     /><label for="Monthly">{{ 'general.monthly' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Annual"
                        [checked]="offer.billingPlan == 'Annually'"
                     /><label for="Annual">{{ 'general.annual' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="full"
                        [checked]="offer.billingPlan == 'OnePayment'"
                     /><label for="full">{{ 'general.pre_paid' | translate }}</label></span
                  >
               </div>
               <!-- <a (click)="changeEditInput('billingPlan')">{{ 'general.change' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'add_credits.support' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Basic"
                        [checked]="offer.support == 'Basic'"
                     /><label for="Basic">{{ 'general.basic' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Standard"
                        [checked]="offer.support == 'Standard'"
                     /><label for="Standard">{{ 'general.standard' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Premium"
                        [checked]="offer.support == 'Premium'"
                     /><label for="Premium">{{ 'general.premium' | translate }}</label></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('support')">{{ 'general.change' | translate }}</a> -->
            <!-- </div> -->
            <div>
               <h5>{{ 'general.contract_value' | translate }}:</h5>
               <b>{{ offer.price }}$</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
         </div>
      </div>
      <h2 *ngIf="offers?.customOffers?.length != 0">{{ 'app.offer.your_offers' | translate }}</h2>
      <div
         class="offersTable"
         *ngFor="let offer of this.offers?.customOffers; let i = index"
      >
         <div class="header">
            <div>
               <span>{{ 'general.plan_name' | translate }}</span>
               <h2>{{ offer.title }}</h2>
            </div>
            <a (click)="moreOfferDetails(i, 'customOffers')">{{ 'general.expand' | translate }}</a>
         </div>
         <div class="offerDetails" *ngIf="i !== moreCustomOfferDetailsIndex">
            <span
               >{{ 'general.license_duration' | translate }}: <b>{{ offer.commitment }}</b></span
            >
            <span
               >{{ 'general.students' | translate }}: <b>{{ offer.students }}</b></span
            >
            <span
               >{{ 'general.educators' | translate }}: <b>{{ offer.professors }}</b></span
            >
            <!-- <span
               >{{ 'general.crosslingual' | translate }}:
               <b>
                  {{
                     offer.crossLingual['assignments'] === true ||
                     offer.crossLingual['professorUploads'] === true ||
                     offer.crossLingual['thesis'] === true
                        ? 'Yes'
                        : 'No'
                  }}</b
               ></span
            > -->
            <span
               >{{ 'general.payment_preference' | translate }}: <b>{{ offer.billingPlan }}</b></span
            >
            <a class="contact_us" (click)="contactUsModal(offer)"
               >{{ 'help_center.contact_us.contact_us' | translate }}</a
            >
         </div>
         <div
            class="offerExpandedDetails"
            *ngIf="i == moreCustomOfferDetailsIndex"
         >
            <div>
               <h5>{{ 'general.number_of_students' | translate }}:</h5>
               <b>{{ offer.students }}</b>
               <!-- <a (click)="changeEditInput('student')">{{ 'general.add_user' | translate }}</a> -->
            </div>
            <div>
               <h5>{{ 'general.number_of_educators' | translate }}:</h5>
               <b>{{ offer.professors }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <div>
               <h5>{{ 'general.license_duration' | translate }}:</h5>
               <b>{{ offer.commitment }}</b>
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'general.teaching_assistants' | translate }}:</h5>
               <b>{{ offer.teachingAssistants }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.academic_integrity_officer' | translate }}:</h5>
               <b>{{ offer.academicIntegrityOfficers }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.subscriptions' | translate }}:</h5>
               <b>{{ offer.subscriptions == 1 ? true : false }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.thesis_estimations' | translate }}:</h5>
               <div>
                  <div>
                     <b>Bachelor: </b>
                     <span> {{ offer.bachelor }}</span>
                  </div>
                  <div>
                     <b>{{ 'general.master' | translate }}: </b>
                     <span> {{ offer.master }}</span>
                  </div>
                  <div>
                     <b>{{ 'general.phd' | translate }}: </b>
                     <span> {{ offer.phd }}</span>
                  </div>
               </div> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.document_upload' | translate }}:</h5>
               <b>{{ offer.professorIndividualUploads }}</b> -->
               <!-- <a>{{ 'general.add_user' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.crosslingual' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="assignment"
                        [checked]="offer.crossLingual['assignments']"
                     /><label for="assignment">{{ 'general.assignment_level' | translate }}</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="thesis"
                        [checked]="offer.crossLingual['thesis']"
                     /><label for="thesis">{{ 'general.thesis' | translate }}</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="professorUpload"
                        [checked]="offer.crossLingual['professorUploads']"
                     /><label for="professorUpload"
                        >{{ 'general.document_uploads' | translate }}</label
                     ></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('crossLingual')">{{ 'general.change' | translate }}</a> -->
            <!-- </div>  -->
            <div>
               <h5>{{ 'general.payment_preference' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Monthly"
                        [checked]="offer.billingPlan == 'Monthly'"
                     /><label for="Monthly">{{ 'general.monthly' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Annual"
                        [checked]="offer.billingPlan == 'Annually'"
                     /><label for="Annual">{{ 'general.annual' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Full"
                        [checked]="offer.billingPlan == 'OnePayment'"
                     /><label for="Full">{{ 'general.pre_paid' | translate }}</label></span
                  >
               </div>
               <!-- <a (click)="changeEditInput('billingPlan')">{{ 'general.change' | translate }}</a> -->
            </div>
            <!-- <div>
               <h5>{{ 'add_credits.support' | translate }}:</h5>
               <div>
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Basic"
                        [checked]="offer.support == 'Basic'"
                     /><label for="Basic">{{ 'general.basic' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Standard"
                        [checked]="offer.support == 'Standard'"
                     /><label for="Standard">{{ 'general.standard' | translate }}</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Premium"
                        [checked]="offer.support == 'Premium'"
                     /><label for="Premium">{{ 'general.premium' | translate }}</label></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('support')">{{ 'general.change' | translate }}</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>{{ 'general.contract_value' | translate }}:</h5>
               <b>{{ offer.price }}$</b>
            </div> -->
            <h1 class="center">
               <button class="submit m-auto" (click)="editOfferModal(offer)">
                  <i class="fa-solid fa-pen-to-square"></i>{{ 'general.manage' | translate }}
               </button>
            </h1>
         </div>
      </div>


      <div class="center">
         <button (click)="newOfferModal()" class="submit">{{ 'app.get_quote' | translate }}</button>
      </div>
   </div>
</section>
