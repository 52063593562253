<section class="admin_settings">
  <h1>{{ 'new_entries.settings_and_preferences' | translate }}</h1>
  <p>{{ 'new_entries.customize_analysis' | translate }}. <a href="https://support.inspera.com/hc/en-us/articles/20849970302365-Administrator-Institutional-Settings" target="_blank">{{ 'new_entries.learn_more' | translate }}</a></p>

  <form [formGroup]="admin_settings">
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.translation_similarity' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.translation_similarity_languages' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" [formControl]="translatedLanguageEnabled" />
          <span class="slider round"></span>
        </label>
      </section>
      <section class="body" *ngIf="translatedLanguageEnabled.value">
        <p>{{ 'new_entries.select_languages' | translate }}</p>
        <section class="language-selection">
          <mat-form-field>
            <mat-select [formControl]="selectedTranslatedLanguages" multiple>
              <mat-option *ngFor="let  language of languages" [value]="language.value" [disabled]="selectedTranslatedLanguages.value.length >= 3 && !isSelected(language.value)">
                {{ language.text }}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="selectedTranslatedLanguages.value.length > 0">
              {{ selectedTranslatedLanguages.value.length }}/3 selected
            </mat-hint>
          </mat-form-field>
        </section>
        <section class="invalid" *ngIf="selectedTranslatedLanguages.errors?.maxlength">
          You can select up to 3 languages only.
        </section>
      </section>
    </section>

    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'general.enable_ai_prediction' | translate }}:
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.ai_prediction_capability' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="aiDetection" />
          <span class="slider round"></span>
        </label>
      </section>
    </section>

    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.add_document_to_archive' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.archive_assignments' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="archive"/>
          <span class="slider round"></span>
        </label>
      </section>
    </section>

    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.submission_pre_check' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.allow_pre_checks' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="submissionPreCheck" />
          <span class="slider round"></span>
        </label>
      </section>
    </section>

    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.contextual_similarity' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.contextual_similarity_in_report' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="contextualSimilarity" />
          <span class="slider round"></span>
        </label>
      </section>
        <div class="threshold-input" *ngIf="this.form.contextualSimilarity.value">
          <div class="threshold-inputs">
            <div class="contextSimilarityUI">
              <label class="sm_title">{{ 'new_entries.minimum_threshold' | translate }}:</label>
              <section class="input-percentage">
                <input formControlName="contextualSimilaritiesThreshold" type="number" min="0" max="100" />
                %
              </section>
            </div>
          </div>
        </div>
    </section>

    <section class="single_form_input">
      <section class="header">
        <h3>
           {{ 'new_entries.content_access' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.access_content_tooltip' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <select name="" id="" formControlName="contentAccess" class="outlined">
          <option value="restricted">{{ 'new_entries.restricted' | translate }}</option>
          <option value="open">{{ 'new_entries.open' | translate }}</option>
          <option value="closed">{{ 'new_entries.closed' | translate }}</option>
        </select>
      </section>
      <section *ngIf="selectedContentValue" class="info">
        <img src="../../../assets/report_v2/info-blue.svg" alt="">
        <p>{{ (selectedContentValue === 'restricted' ? 'new_entries.restricted_info' : (selectedContentValue === 'open' ? 'new_entries.opened_info' : 'new_entries.close_info')) | translate }}</p>
        <a href="https://support.inspera.com/hc/en-us/articles/21237980575005-Access-Content-Feature" target="_blank">{{ 'new_entries.learn_more' | translate }}</a>
      </section>

    </section>

    <section class="form_btns">
      <button class="cancel_btn" (click)="cancel()" [disabled]="!isFormChanged" [ngClass]="{'disabled': !isFormChanged}">{{ 'general.reset' | translate }}</button>
      <button class="submit_btn" (click)="submitSettings()" [disabled]="!isFormChanged" [ngClass]="{'disabled': !isFormChanged}">{{ 'general.submit' | translate }}</button>

    </section>

  </form>
</section>
