// *** Angular
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

// *** Components
import { EditCourseComponent } from '../modals/edit-course/edit-course.component';
import { EditSubjectsGenerationComponent } from '../modals/edit-subjects-generation/edit-subjects-generation.component';

// *** Actions
import * as subjectActions from '../state/subject/action/subject.actions';
import * as facultyActions from '../state/faculty/action/faculty.actions';
import * as professorActions from '../../administrator-components/state/professor/action/professor.actions';

// *** Selectors
import { getInstitutionSubjectsStateDetails } from '../state/subject/selector/subject.selectors';
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';
import { getInstitutionProfessorsStateDetails } from '../../administrator-components/state/professor/selector/professor.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { CourseRegisterComponent } from '../course-register/course-register.component';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { PdfExportService } from 'src/app/services/pdf-export.service';
import { CourseService } from 'src/app/services/course.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-units-courses',
   templateUrl: './units-courses.component.html',
   styleUrls: ['./units-courses.component.scss'],
})
export class UnitsCoursesComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store retrieved Courses from service.
    */
   courses: any;
   courses$;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 10;
   /**
    *
    */
   generation: any = '-1';
   /**
    * Variable is used to store professors of institution that are retrived from store
    */
   professors$: any;
   professors;
   /**
    * Variable is used to store faculties of institution that are retrived from store
    */
   faculties$: any;
   faculties;
   /**
    * Variable is used to store selcted faculty id that is used to retrieve departments of that faculty and also is used to filter courses by faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departments of selected faculty from service
    */
   departments: any;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   /**
    * Variable is used to store selected department id that is been used for filtering courses.
    */
   departmentId = -1;
   /**
    * Variable is used to store selected professor id that is been used for filtering courses.
    */
   professorId = '-1';
   /**
    * Variable is used to store selected status value that is been used for filtering courses.
    */
   status = '-1';
   /**
    * Variable is used to store selected course type (Mandatory or Elective) id that is been used for filtering courses.
    */
   type = -1;
   /**
    * Variable is used to store course name that is been used for filtering courses.
    */
   name = '';
   @ViewChild('contentToCapture') contentToCapture: ElementRef;

   constructor(
      public dialog: MatDialog,
      private store: Store,
      private departmentService: DepartmentService,
      private csvExportService: CsvExportService,
      private excelExportService: ExcelExportService,
      private pdfExportService: PdfExportService,
      private spinner: NgxSpinnerService,
      private courseService: CourseService,
      private translate: TranslateService
   ) { }
   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
      this.professors$.unsubscribe();
      this.courses$.unsubscribe();
   }
   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.professors$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.professors = data.professors;
            }
         });

      this.store.dispatch(
         subjectActions.loadSubjectsDetails({ generation: 0, page: this.page })
      );

      this.courses$ = this.store
         .select(getInstitutionSubjectsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.courses = data;
            }
            if (data.subjectRegistered !== null) {
               this.store.dispatch(subjectActions.setSubjectRegisterToNull());
               this.resetFilter();
            }
         });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         subjectActions.loadSubjectsDetails({
            generation:
               this.generation === 'g2122' ? undefined : this.generation,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId === -1 ? undefined : this.departmentId,
            professor: this.professorId === '-1' ? undefined : this.professorId,
            status: this.status === '-1' ? undefined : this.status,
            subjectType: this.type === -1 ? undefined : this.type,
            name: this.name,
         })
      );
   }

   /**
    * Method is used to open a modal that will contain a course information to edit or delete.
    * @param id
    */
   openModal(id) {
      this.dialog.open(EditCourseComponent, {
         data: {
            subjectId: id,
            generation:
               this.generation === 'g2122' ? undefined : this.generation,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId === -1 ? undefined : this.departmentId,
            professor: this.professorId === '-1' ? undefined : this.professorId,
            status: this.status === '-1' ? undefined : this.status,
            subjectType: this.type === -1 ? undefined : this.type,
            name: this.name,
         },
         width: '50%',
         height: '90%',
      });
   }

   /**
    * Method is used to open a modal that will contain a course information to register subjects.
    */
   registerSubjectModal() {
      this.dialog.open(CourseRegisterComponent, {
         width: '50%',
         height: '95%',
      });
   }

   /**
    * Function is used to filter courses by given values
    */
   filterCourses() {
      this.page = 1;

      if (this.generation == 'All') {
         this.generation = '-1';
      }


      if (this.professorId == 'All') {
         this.professorId = '-1';
      }

      if (this.status == 'All') {
         this.status = '-1';
      }

      this.store.dispatch(
         subjectActions.loadSubjectsDetails({
            generation:
               this.generation === '-1' ? undefined : this.generation,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId === -1 ? undefined : this.departmentId,
            professor: this.professorId === '-1' ? undefined : this.professorId,
            status: this.status === '-1' ? undefined : this.status,
            subjectType: this.type === -1 ? undefined : this.type,
            name: this.name,
         })
      );
   }

   /**
    * Method is used to open a modal that will contain a course information to edit or delete.
    * @param id
    */
   openGenerationChangeModal() {
      this.dialog.open(EditSubjectsGenerationComponent, {
         data: {
            courses: this.courses,
         },
         width: '100%',
         height: '90%',
      });
      this.page = 1;
   }
   /**
    * Function is used to get selected faculty and after that to filter courses and get departments of selected faculty.
    */
   selectedFaculty() {
      this.page = 1;
      this.departmentId = -1;
      this.store.dispatch(
         subjectActions.loadSubjectsDetails({
            generation:
               this.generation === 'g2122' ? undefined : this.generation,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId === -1 ? undefined : this.departmentId,
            professor: this.professorId === '-1' ? undefined : this.professorId,
            status: this.status === '-1' ? undefined : this.status,
            subjectType: this.type === -1 ? undefined : this.type,
            name: this.name,
         })
      );
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter courses by title
    */
   titleFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            subjectActions.loadSubjectsDetails({
               generation:
                  this.generation === 'g2122' ? undefined : this.generation,
               page: this.page,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId === -1 ? undefined : this.departmentId,
               professor:
                  this.professorId === '-1' ? undefined : this.professorId,
               status: this.status === '-1' ? undefined : this.status,
               subjectType: this.type === -1 ? undefined : this.type,
               name: this.name,
            })
         );
      }, time);
   }
   /**
    * Fucntion is used to remove values from filters and reset them.
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = -1;
      this.generation = '-1';
      this.professorId = '-1';
      this.status = '-1';
      this.type = -1;
      this.name = '';
      this.page = 1;
      this.store.dispatch(
         subjectActions.loadSubjectsDetails({
            generation:
               this.generation === '-1' ? undefined : this.generation,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId === -1 ? undefined : this.departmentId,
            professor: this.professorId === '-1' ? undefined : this.professorId,
            status: this.status === '-1',
            subjectType: this.type === -1 ? undefined : this.type,
            name: this.name,
         })
      );
   }

   prepareExportData(data) {
      let rows = [
         ["Subjects"],
         [""],
         [
            "Subject name",
            "Educators",
            "No. of students",
            "Academic Year",
            "Type",
            "Status",
         ],
      ]

      data.courses.forEach(course => {
         let generation =  `20${course.generation.substring(1, 3)} / 20${course.generation.substring(3, 5)}`

         rows.push([
            course.title,
            this.getProfessors(course.professorCourses),
            course.students,
            generation,
            course.type === 1 ? 'Mandatory' : 'Elective',
            course.status ? this.translate.instant('general.active') : 'Inactive',
         ]);
      });

      return rows
   }

   getProfessors(professorCourses) {
      if (professorCourses.length > 0) {
         return professorCourses.map(prof => prof.User.name).join('; ');
      } else {
         return '-- / --';
      }
   }

   exportToCSV() {
      this.spinner.show();
      this.courseService.courses(
         this.generation === '-1' ? undefined : this.generation,
         -1,
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId === -1 ? undefined : this.departmentId,
         this.professorId === '-1' ? undefined : this.professorId,
         this.status === '-1',
         this.type === -1 ? undefined : this.type,
         this.name,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.csvExportService.exportToCSV(rows, 'Subjects - Subjects Reports');
         this.spinner.hide();
      })
   }

   exportToExcel() {
      this.spinner.show();
      this.courseService.courses(
         this.generation === '-1' ? undefined : this.generation,
         -1,
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId === -1 ? undefined : this.departmentId,
         this.professorId === '-1' ? undefined : this.professorId,
         this.status === '-1',
         this.type === -1 ? undefined : this.type,
         this.name,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.excelExportService.exportToExcel(rows, 'Subjects - Subjects Reports');
         this.spinner.hide();
      })
   }

   exportToPDF() {
      this.spinner.show()
      if (this.contentToCapture) {
         this.pdfExportService.captureAndExportPDF(this.contentToCapture.nativeElement, 'Subjects - Subjects Reports.pdf');
         this.spinner.hide()
      } else {
         console.error('Content to capture is not available yet.');
         this.spinner.hide()
      }
   }
}
