import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input() progress: number = 0;
  progressBarWidth: string = '0%';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progress) {
      this.updateProgressBarWidth();
      console.log('Progress:', this.progress);

    }
  }

  public updateProgressBarWidth() {
    this.progressBarWidth = `${this.progress}%`;
  }
}
