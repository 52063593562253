import {
   Component,
   ElementRef,
   OnInit,
   Renderer2,
   ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-navbar-student',
   templateUrl: './navbar-student.component.html',
   styleUrls: ['./navbar-student.component.scss'],
})
export class NavbarStudentComponent implements OnInit {
   @ViewChild('toggleButton') toggleButton: ElementRef;
   @ViewChild('navbar') navbar: ElementRef;
   userRole;
   expandedToggler;
   i;
   currentLanguage: string = localStorage.getItem('websiteLanguage');
   user: any;

   constructor(private authServiceService: AuthService,
      private renderer: Renderer2,
      public router: Router, private languageService: LanguageService, public translate: TranslateService) {
      if (!this.hasRoute('/report')) {
         this.renderer.listen('window', 'click', (e: Event) => {
            if (
               // e.target !== this.toggleButton.nativeElement &&
               e.target !== this.toggleButton.nativeElement.children[0] &&
               e.target !== this.toggleButton.nativeElement.children[1] &&
               e.target !== this.toggleButton.nativeElement.children[2]
            ) {
               this.expandedToggler = -1;
            }
         });
      }
   }

   ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });

      this.expandedToggler = -1;
      this.user = JSON.parse(localStorage.getItem('user'))
      console.log('this.user', this.user);
      
   }

   /**
    * Method checks if the route contains a specific route url. Used to hide app header & nav component at report url.
    * @param route
    */
   public hasRoute(route: string) {
      return this.router.url.includes(route);
   }

   toggler() {
      if (this.expandedToggler == -1) {
         this.expandedToggler = 1;
      } else {
         this.expandedToggler = -1;
      }
   }

}
