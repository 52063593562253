// *** Angular
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

// *** Packages
import Swal from 'sweetalert2';

// *** Actions
import * as departmentActions from '../state/department/action/department.actions';
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';
import { getInstitutionDepartmentsStateDetails } from '../state/department/selector/department.selectors';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
   selector: 'app-department-register',
   templateUrl: './department-register.component.html',
   styleUrls: ['./department-register.component.scss'],
})
export class DepartmentRegisterComponent implements OnInit, OnDestroy {
  currentLanguage = localStorage.getItem('websiteLanguage');
   /**
    * Variable used to know if user is using excel form or manual form
    */
   excel = false;

   /**
    * Variable used to store faculties of institution
    */
   facultiesDetails$;

   /**
    * Form group used to get user input
    */
   departmentRegister: FormGroup;

   /**
    * Variable where we store facultiesDetails that are returned from store.
    */
   facultiesDetails;
   getInstitutionDepartmentsStateDetails$: any;
   constructor(
      private fb: FormBuilder,
      private store: Store,
      private languageService: LanguageService,
      public translate: TranslateService,
      @Optional() public dialogRef: MatDialogRef<any>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
   ) {}
   ngOnDestroy(): void {
      this.facultiesDetails$.unsubscribe();
      this.getInstitutionDepartmentsStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));
      this.facultiesDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.facultiesDetails = data;
            }
         });
      this.departmentRegister = this.fb.group({
         departments: this.fb.array([]),
      });
      this.addDepartment();
      this.getInstitutionDepartmentsStateDetails$ = this.store
         .select(getInstitutionDepartmentsStateDetails)
         .subscribe((data) => {
            if (data.departmentRegistered !== null) {
               if (this.dialogRef) {
                  this.dialogRef.close();
               } else {
                  this.departmentRegister = this.fb.group({
                     departments: this.fb.array([]),
                  });
                  this.addDepartment();
                  this.store.dispatch(
                     departmentActions.setRegisterDepartmentToNull()
                  );
               }
            }
         });
   }
   toggleForm(formTodisplay) {
      if(formTodisplay=='manual') {
         this.excel = false;
      } else {
         this.excel = true;
      }

   }
   /**
    * Method is used to create department
    */
   async submit() {
      const result = await Swal.fire({
         title: 'You are about to add departments, please confirm your action by clicking “Confirm”.',
         // icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            departmentActions.departmentsRegister({
               data: this.departmentRegister.value.departments,
            })
         );
      }
   }

   /**
    * Method is used to get faculties from @departmentRegister
    */
   get departmentsForm() {
      return this.departmentRegister.get('departments') as FormArray;
   }

   /**
    * Method is used to add new departments on formGroup  @departmentRegister
    */
   addDepartment() {
      const departments = this.fb.group({
         departmentName: ['', [Validators.required, Validators.minLength(3)]],
         departmentDescription: [
            '',
            [Validators.required, Validators.minLength(3)],
         ],
         departmentYear: ['', [Validators.required]],
         departmentFaculty: ['', [Validators.required]],
         departmentLevel: ['', [Validators.required]],
      });
      this.departmentsForm.push(departments);
   }

   /**
    * Method is used to remove element from formGroup @departmentRegister
    * @param i
    */
   removeDepartment(i) {
      this.departmentsForm.removeAt(i);
   }

   closeModal() {
      this.dialogRef.close();
   }
}
