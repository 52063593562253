// *** Angular
import { Router } from '@angular/router';
import {
   Component,
   ElementRef,
   OnInit,
   Renderer2,
   ViewChild,
} from '@angular/core';

// *** Services
import { AuthService } from '../../services/auth.service';
@Component({
   selector: 'app-navbar-administrator',
   templateUrl: './navbar-administrator.component.html',
   styleUrls: ['./navbar-administrator.component.scss'],
})
export class NavbarAdministratorComponent implements OnInit {
   @ViewChild('toggleButton') toggleButton: ElementRef;
   @ViewChild('navbar') navbar: ElementRef;
   userRole;
   expandedToggler;
   i;
   /**
    * Component Constructor
    * @param authServiceService
    * @param renderer
    * @param router
    */
   constructor(
      private authServiceService: AuthService,
      private renderer: Renderer2,
      public router: Router
   ) {
      if (!this.hasRoute('/report')) {
         this.renderer.listen('window', 'click', (e: Event) => {
            if (
               // e.target !== this.toggleButton.nativeElement &&
               e.target !== this.toggleButton.nativeElement.children[0] &&
               e.target !== this.toggleButton.nativeElement.children[1] &&
               e.target !== this.toggleButton.nativeElement.children[2]
            ) {
               this.expandedToggler = -1;
            }
         });
      }
   }

   ngOnInit(): void {
      this.expandedToggler = -1;
      this.userRole = this.authServiceService.isLoggedInRole();
   }

   toggler() {
      if (this.expandedToggler == -1) {
         this.expandedToggler = 1;
      } else {
         this.expandedToggler = -1;
      }
   }

   public hasRoute(route: string) {
      return this.router.url.includes(route);
   }
}
