<div class="documents-2 shadow white-bg">
   <div
      *ngIf="!(this.totalFaculties == 0 && facultyName == undefined)"
      class="filtersContainer"
   >
      <h4>{{ 'general.overview' | translate }}</h4>
      <div class="selectors">
         <div>
            <input
               [placeholder]="'placeholders.search_by_name' | translate"
               [(ngModel)]="facultyName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </div>

   <div *ngIf="this.totalFaculties != 0" class="tableWithExpandedInfoFaculties tablewithRatios">
      <ng-container
         *ngFor="
            let faculty of this.faculties
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalFaculties
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">{{ faculty.name }}</h4>
               <div class="container_data">
                  <div>
                     <span>{{ 'general.departments' | translate }}</span>
                     <span>{{ 'general.educators' | translate }}</span>
                     <span>{{ 'general.students' | translate }}</span>
                     <span>{{ 'general.subjects' | translate }}</span>
                     <span>{{ 'general.assignments' | translate }}</span>
                     <span>{{ 'general.submissions' | translate }}</span>
                     <span>{{ 'general.theses' | translate }}</span>
                     <span>{{ 'general.document_language_similarity' | translate }}</span>
                     <span>{{ 'general.translation_language_similarity' | translate }}</span>
                  </div>
                  <div>
                     <span
                        (click)="departmentsFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.departments }}</span
                     >
                     <span
                        (click)="professorFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.professors }}</span
                     >
                     <span
                        (click)="studentsFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.students }}</span
                     >
                     <span
                        (click)="subjectsFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.subjects }}</span
                     >
                     <span
                        (click)="assignmentFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.assignments }}</span
                     >
                     <span
                        class="bold"
                        >{{ faculty.assignmentsSubmissions + faculty.professorsSubmissions }}</span
                     >

                     <span
                        (click)="thesisFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.thesis }}</span
                     >
                     <span class="bold">{{
                        faculty.submissions._avg.originalPercentage === null
                           ? 0
                           : faculty.submissions._avg.originalPercentage.toFixed(
                                2
                             )
                     }}%</span>
                     <span class="bold">{{
                        faculty.submissions._avg.translatedPercentage === null
                           ? 0
                           : faculty.submissions._avg.translatedPercentage.toFixed(
                                2
                             )
                     }}%</span>
                  </div>
               </div>
               <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >{{ 'general.expand' | translate }}</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >{{ 'general.collapse' | translate }}</a
               >
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }" class="moreInfo"
            >
            <div class="details">
                     <span> {{ 'app.most_used_language' | translate }}:
                      <b>{{ setLanguageName(faculty.language?faculty.language[0]:'-') }}</b>
                       <!-- <b>{{
                          faculty.language === undefined ? '-'
                             : faculty.language[0]
                       }}</b> -->
                     </span>
                     <span>Students submissions <b class="clickable" (click)="assignmentSubmissionsFilter(faculty.id)">{{ faculty.assignmentsSubmissions }}</b></span>
                     <span>{{ 'app.educators_submissions' | translate }} <b class="clickable" (click)="professorSubmissionsFilter(faculty.id)" >{{ faculty.professorsSubmissions }}</b></span>
                     <span>
                           Bachelor theses: <b>{{ faculty.bachelor }}</b></span
                        >
                        <span>
                           Master theses: <b>{{ faculty.master }}</b></span
                        >
                        <span>
                           {{ 'app.phd_thesis' | translate }}: <b>{{ faculty.phd }}</b></span
                        >
                        <span>
                           {{ 'report.high_similarity_submissions' | translate }}:
                           <b>{{ faculty.highSimilaritySubmissions  }}</b></span
                        >
                        <span>
                           {{ 'report.medium_similarity_submissions' | translate }}:
                           <b>{{ faculty.mediumSimilaritySubmissions }}</b></span
                        >
                        <span>
                           {{ 'report.low_similarity_submissions' | translate }}:
                           <b>{{ faculty.lowSimilaritySubmissions }}</b></span
                        >

            </div>
            <div class="ratios">
              {{ 'app.ratios' | translate }}
            </div>
            <div class="details">
               <span> {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.subjects' | translate }}:
                 <b>{{ faculty.professors }} : {{ faculty.subjects }}</b>
               </span>
               <span>
                  {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.student' | translate }}: <b>{{ faculty.professors }} : {{ faculty.students }}</b></span
                  >
                  <span>
                     {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' | translate }}: <b>{{ faculty.professors }} : {{ faculty.professorsSubmissions }} </b></span
                  >
                  <span>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.subjects' | translate }}: <b>{{ faculty.students }} : {{ faculty.subjects }}</b></span
                  >
                  <span>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' | translate }}:
                     <b>{{ faculty.students }} : {{ faculty.assignmentsSubmissions }}</b></span
                  >
      </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="this.totalFaculties == 0 && facultyName != undefined"
   >
      No schools found
   </div>
   <pagination-controls
      *ngIf="this.totalFaculties != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="this.totalFaculties == 0 && facultyName == undefined"
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">{{ 'app.there_are_no_schools_registered_in_your_instutition' | translate }}.
          <a
           routerLink ="/administrator/register-module/faculty-register"
           routerLinkAction="active">{{ 'general.click_here' | translate }}</a>
           {{ 'app.to_begin_registering_your_first_institution' | translate }}.</h5>
   </div>
</div>
