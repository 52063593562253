<main class="log-in">
   <div class="left-side">
      <img src="../../assets/images/Group (2)-white.png" alt="" />
      <div>
         <img *ngIf="profile == 'individual'" src="../../../assets/images/individualIconTransparent.png" alt="">
         <img *ngIf="profile == 'business'" src="../../../assets/images/businessLogoTransparent.png" alt="">
         <img *ngIf="profile == 'institution'" src="../../../assets/images/institutionLogoTransparent.png" alt="">
         <h1 *ngIf="!profile">{{ 'general.most_advanced_plagiarism_tool' | translate }}.</h1>
         <h1 *ngIf="profile == 'individual'">{{ 'app.individual_profile' | translate }}</h1>
         <h1 *ngIf="profile == 'institution'">{{ 'app.institution_profile' | translate }}</h1>
         <h1 *ngIf="profile == 'business'">{{ 'app.business_profile' | translate }}</h1>
         <p *ngIf="!profile" [innerHtml]="'login.crossplag_description' | translate">
         </p>
         <p *ngIf="profile == 'individual'"> Our plagiarism detection tool helps students, teachers, writers, and bloggers ensure originality in their work by comparing each writing with more than 100 billion texts worldwide.
         </p>
         <a class="readMore" *ngIf="profile == 'individual'" #readMoreIndividual href="https://crossplag.com/crossplag-for-individuals/" target="_blank">{{ 'app.read_more' | translate }}</a>
         <p *ngIf="profile == 'institution'">{{ 'app.our_self_provisioning_plagiarism_detection_tool_is_affordable' | translate }}.
         </p>
         <a class="readMore" *ngIf="profile == 'institution'" #readMoreInstitution [href]="readMoreClicked?'#':'https://crossplag.com/crossplag-for-education/'" target="_blank">{{ 'app.read_more' | translate }}</a>
         <p *ngIf="profile == 'business'">{{ 'app.our_similarity_report_api_enables_your_business_to_ensure_originality' | translate }}.
         </p>
         <a class="readMore" *ngIf="profile == 'business'" #readMoreBusiness href="https://crossplag.com/crossplag-for-education/" target="_blank">{{ 'app.read_more' | translate }}</a>
      </div>
      <h6>© {{ 'general.rights_reserved' | translate }} - Inspera AS</h6>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 1030 1030" style="enable-background:new 0 0 1030 1030;" xml:space="preserve">
  <style type="text/css">
   .st0{fill:#ffffff36;}
  </style>
  <g>
     <g>
        <circle class="st0" cx="137" cy="716.5" r="115.1"/>
        <circle class="st0" cx="410.7" cy="460.1" r="139"/>
        <circle class="st0" cx="834.3" cy="292.9" r="182.5"/>
     </g>
  </g>
  <g>
     <path class="st0" d="M131.9,232.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2l44-44c0.3-0.3,0.9-0.3,1.2,0
        c0.3,0.3,0.3,0.9,0,1.2l-44,44C132.3,232.1,132.1,232.2,131.9,232.2z"/>
  </g>
  <g>
     <path class="st0" d="M27.2,129.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2l98.7-98.7c0.3-0.3,0.9-0.3,1.2,0
        c0.3,0.3,0.3,0.9,0,1.2L27.8,129C27.6,129.1,27.4,129.2,27.2,129.2z"/>
  </g>
  </svg>
   </div>
   <div class="right-side">
      <!-- <i class="fas fa-arrow-left"></i> -->
     <span *ngIf="profile" routerLink="/login" class="go-back">{{ 'login.already_have_account' | translate }}? {{ 'login.sign_in' | translate }}</span>
     <div class="dispalyOnDesktop">
      <h2 *ngIf="!profile">{{ 'intro.welcome_to_inspera' | translate }}</h2>
     </div>
<div class="displayOnMobile">
   <h2>{{ 'intro.welcome_to_inspera' | translate }}</h2>
   <img src="../../../assets/images/Crossplag_Logo (3).png" alt="">
</div>
      <form class="form-container" *ngIf="profile == 'individual'" action="" (ngSubmit)="signUp()" [formGroup]="signupForm">
         <h3>{{ 'login.sign_up' | translate }}</h3>
         <div>
            <label for="">{{ 'general.first_name' | translate }}</label>
            <input
               class="capitalize"
               placeholder="{{ 'general.first_name' | translate }}"
               formControlName="first_name"
            />
            <div
               *ngIf="form.first_name.invalid && form.first_name.touched"
               class="invalid"
            >
               <div *ngIf="form.first_name.errors.required">
                  {{ 'validators.first_name_required' | translate }}
               </div>
               <div
                  *ngIf="
                     form.first_name.errors.minlength &&
                     !form.first_name.errors.pattern
                  "
               >
                  {{ 'validators.first_name_minLength' | translate }}
               </div>
               <div *ngIf="form.first_name.errors.pattern">
                  {{ 'validators.first_name_only_letters' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'general.last_name' | translate }}</label>
            <input
               [placeholder]="'placeholders.last_name' | translate"
               formControlName="last_name"
               class="capitalize"
            />
            <div
               *ngIf="form.last_name.invalid && form.last_name.touched"
               class="invalid"
            >
               <div *ngIf="form.last_name.errors.required">
                  {{ 'validators.last_name_required' | translate }}
               </div>
               <div *ngIf="form.last_name.errors.pattern">
                  {{ 'validators.last_name_only_letters' | translate }}
               </div>
               <div
                  *ngIf="
                     form.last_name.errors.minlength &&
                     !form.last_name.errors.pattern
                  "
               >
                  {{ 'validators.last_name_minLength' | translate }}
               </div>
            </div>
         </div>

         <div>
            <label for="">{{ 'general.email' | translate }}</label>
            <input
               [placeholder]="'placeholders.email' | translate"
               formControlName="email"
            />
            <div
               *ngIf="form.email.invalid && form.email.touched"
               class="invalid"
            >
               <div *ngIf="form.email.errors.required">
                  {{ 'validators.email_required' | translate }}
               </div>
               <div *ngIf="form.email.errors.email">
                  {{ 'validators.valid_email' | translate }}
               </div>
            </div>
            <div class="invalid" *ngIf="invalidEmail">
               Disposable emails are not allowed
            </div>
         </div>
         <div class="terms">
            <input
               name="terms_and_conditions"
               id="terms_and_conditions"
               type="checkbox"
               formControlName="agree"
            />
            <label for="terms_and_conditions"></label>
            <a mat-button (click)="openModal()">
               {{ 'general.terms_and_conditions' | translate }}</a
            >
         </div>
         <div class="terms marketing_emails">
            <input
               name="emails"
               id="emails"
               type="checkbox"
               (click)="acceptMarketing()"
            />
            <label for="emails"></label>
            <a
               >{{ 'login.yes_i_would_like_to_receive_offers' | translate }}.
            </a>
         </div>
         <re-captcha
         formControlName="recaptcha"
         (resolved)="resolved($event)"
         [siteKey]="siteKey"
       ></re-captcha>
         <ng-container *ngIf="signupForm.valid">
            <button type="submit" class="submit">
               {{ 'general.join_now' | translate }}
            </button>
         </ng-container>
         <ng-container *ngIf="signupForm.invalid">
            <button
               [ngClass]="{ disable: signupForm.invalid }"
               [disabled]="signupForm.invalid"
               type="submit"
               class="submit"
               (click)="showToasterWarning('Please re-check your info')"
            >
               {{ 'general.join_now' | translate }}
            </button>
         </ng-container>
         <div class="social-container">
            <div class="seperator-line">
               <i class="line-seperator"></i>
               <span>{{ 'login.or' | translate }}</span>
               <i class="line-seperator"></i>
            </div>
            <div>
               <span>{{ 'login.sign_in_with' | translate }}</span>
            </div>
            <div>
               <span
                  >{{ 'login.by_continuing_i_agree_to_the' | translate }}
                  <a mat-button (click)="openModal()">
                     {{ 'help_center.privacy.policy' | translate }}, {{ 'general.terms_and_conditions' | translate }}</a
                  ></span
               >
            </div>
            <div class="social-buttons">
               <a
                  class="btn btn-social-icon btn-google"
                  (click)="signInWithGoogle()"
               >
                  <svg
                     viewBox="0 0 533.5 544.3"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                        fill="#4285f4"
                     />
                     <path
                        d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                        fill="#34a853"
                     />
                     <path
                        d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                        fill="#fbbc04"
                     />
                     <path
                        d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                        fill="#ea4335"
                     />
                  </svg>
                  <span class="social-button-text">{{
                     'login.google' | translate
                  }}</span>
               </a>

               <a
                  class="btn btn-social-icon btn-linkedin"
                  (click)="loginLinkedin()"
               >
                  <svg
                     version="1.1"
                     id="Layer_1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     x="0px"
                     y="0px"
                     viewBox="0 0 382 382"
                     style="enable-background: new 0 0 382 382"
                     xml:space="preserve"
                  >
                     <path
                        style="fill: #0077b7"
                        d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
       C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
       H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
       c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
       s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
       c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
       c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
       c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
       L341.91,330.654L341.91,330.654z"
                     />
                  </svg>
                  <span class="social-button-text">{{
                     'login.linkedin' | translate
                  }}</span>
               </a>
            </div>
         </div>
      </form>
      <form class="form-container" *ngIf="profile == 'institution'" action="" (ngSubmit)="signUp()" [formGroup]="signupForm">
         <h3>{{ 'login.sign_up' | translate }}</h3>
         <div>
            <label for="">{{ 'general.first_name' | translate }}</label>
            <input
               [placeholder]="'placeholders.first_name' | translate"
               class="capitalize"
               formControlName="first_name"
            />
            <div
               *ngIf="form.first_name.invalid && form.first_name.touched"
               class="invalid"
            >
               <div *ngIf="form.first_name.errors.required">
                  {{ 'validators.first_name_required' | translate }}
               </div>
               <div
                  *ngIf="
                     form.first_name.errors.minlength &&
                     !form.first_name.errors.pattern
                  "
               >
                  {{ 'validators.first_name_minLength' | translate }}
               </div>
               <div *ngIf="form.first_name.errors.pattern">
                  {{ 'validators.first_name_only_letters' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'general.last_name' | translate }}</label>
            <input
               [placeholder]="'placeholders.last_name' | translate"
               formControlName="last_name"
               class="capitalize"
            />
            <div
               *ngIf="form.last_name.invalid && form.last_name.touched"
               class="invalid"
            >
               <div *ngIf="form.last_name.errors.required">
                  {{ 'validators.last_name_required' | translate }}
               </div>
               <div *ngIf="form.last_name.errors.pattern">
                  {{ 'validators.last_name_only_letters' | translate }}
               </div>
               <div
                  *ngIf="
                     form.last_name.errors.minlength &&
                     !form.last_name.errors.pattern
                  "
               >
                  {{ 'validators.last_name_minLength' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'general.email' | translate }}</label>
            <input
               [placeholder]="'placeholders.email' | translate"
               formControlName="email"
            />
            <div
               *ngIf="form.email.invalid && form.email.touched"
               class="invalid"
            >
               <div *ngIf="form.email.errors.required">
                  {{ 'validators.email_required' | translate }}
               </div>
               <div *ngIf="form.email.errors.email">
                  {{ 'validators.valid_email' | translate }}
               </div>
            </div>
            <div class="invalid" *ngIf="invalidEmail">
               Disposable emails are not allowed
            </div>
         </div>
         <div>
            <label for="">{{ 'app.institution_name' | translate }}</label>
            <input
               [placeholder]="'app.institution_name' | translate"
               formControlName="institution"
            />
            <div
               *ngIf="form.institution.invalid && form.institution.touched"
               class="invalid"
            >
               <div *ngIf="form.institution.errors.required">
                  {{ 'app.institution_name' | translate }} {{ 'general.end_date' | translate }}
               </div>

               <div *ngIf="form.institution.errors.minlength">
                  {{ 'app.institution_name_should_have_more_than_3_characters' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'app.role' | translate }}</label>
            <input
               [placeholder]="'placeholders.example_roles' | translate"
               formControlName="role"
            />
            <div
               *ngIf="form.role.invalid && form.role.touched"
               class="invalid"
            >
               <div *ngIf="form.institution.errors.required">
                  {{ 'app.role' | translate }} {{ 'general.end_date' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'app.info.country' | translate }}</label>
            <input
               [placeholder]="'placeholders.institution_location' | translate"
               formControlName="country"
            />
            <div
               *ngIf="form.country.invalid && form.country.touched"
               class="invalid"
            >
               <div *ngIf="form.country.errors.required">
                  {{ 'app.info.country' | translate }} {{ 'aria-labels.is_required' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'login.does_institution_use_system_agains_plagiarism' | translate }}?*
            </label>
            <input
               placeholder=""
               formControlName="currentSystem"
            />
         </div>
         <div class="terms">
            <input
               name="terms_and_conditions"
               id="terms_and_conditions"
               type="checkbox"
               formControlName="agree"
            />
            <label for="terms_and_conditions"></label>
            <a mat-button (click)="openModal()">
               {{ 'general.terms_and_conditions' | translate }}</a
            >
         </div>
         <div class="terms marketing_emails">
            <input
               name="emails"
               id="emails"
               type="checkbox"
               (click)="acceptMarketing()"
            />
            <label for="emails"></label>
            <a
               >{{ 'login.yes_i_would_like_to_receive_offers' | translate }}.
            </a>
         </div>
         <re-captcha
         formControlName="recaptcha"
            (resolved)="resolved($event)"
            [siteKey]="siteKey"
          ></re-captcha>
            <ng-container *ngIf="signupForm.valid">
            <button type="submit" class="submit">
               {{ 'general.join_now' | translate }}
            </button>
         </ng-container>
         <ng-container *ngIf="signupForm.invalid">
            <button
               [ngClass]="{ disable: signupForm.invalid }"
               [disabled]="signupForm.invalid"
               type="submit"
               class="submit"
               (click)="showToasterWarning('Please re-check your info')"
            >
               {{ 'general.join_now' | translate }}
            </button>
         </ng-container>
      </form>
      <form  class="form-container" *ngIf="profile == 'business'" action="" (ngSubmit)="signUp()" [formGroup]="signupForm">
         <h3>{{ 'help_center.contact_us.contact_us' | translate }}</h3>
         <div>
            <label for="">{{ 'general.first_name' | translate }}</label>
            <input
               class="capitalize"
               [placeholder]="'placeholders.first_name' | translate"
               formControlName="first_name"
            />
            <div
               *ngIf="form.first_name.invalid && form.first_name.touched"
               class="invalid"
            >
               <div *ngIf="form.first_name.errors.required">
                  {{ 'validators.first_name_required' | translate }}
               </div>
               <div
                  *ngIf="
                     form.first_name.errors.minlength &&
                     !form.first_name.errors.pattern
                  "
               >
                  {{ 'validators.first_name_minLength' | translate }}
               </div>
               <div *ngIf="form.first_name.errors.pattern">
                  {{ 'validators.first_name_only_letters' | translate }}
               </div>
            </div>
         </div>
         <div>
            <label for="">{{ 'general.last_name' | translate }}</label>
            <input
               [placeholder]="'placeholders.last_name' | translate"
               formControlName="last_name"
               class="capitalize"
            />
            <div
               *ngIf="form.last_name.invalid && form.last_name.touched"
               class="invalid"
            >
               <div *ngIf="form.last_name.errors.required">
                  {{ 'validators.last_name_required' | translate }}
               </div>
               <div *ngIf="form.last_name.errors.pattern">
                  {{ 'validators.last_name_only_letters' | translate }}
               </div>
               <div
                  *ngIf="
                     form.last_name.errors.minlength &&
                     !form.last_name.errors.pattern
                  "
               >
                  {{ 'validators.last_name_minLength' | translate }}
               </div>
            </div>
         </div>

         <div>
            <label for="">{{ 'general.email' | translate }}</label>
            <input
               [placeholder]="'placeholders.email' | translate"
               formControlName="email"
            />
            <div
               *ngIf="form.email.invalid && form.email.touched"
               class="invalid"
            >
               <div *ngIf="form.email.errors.required">
                  {{ 'validators.email_required' | translate }}
               </div>
               <div *ngIf="form.email.errors.email">
                  {{ 'validators.valid_email' | translate }}
               </div>
            </div>
            <div class="invalid" *ngIf="invalidEmail">
               Disposable emails are not allowed
            </div>
         </div>
         <div>
            <label for="">{{ 'app.role' | translate }}</label>
            <input
               [placeholder]="'app.role' | translate"
               formControlName="role"
            />
            <div
               *ngIf="form.role.invalid && form.role.touched"
               class="invalid"
            >
               <div *ngIf="form.role.errors.required">
                  {{ 'app.role' | translate }} {{ 'general.end_date' | translate }}
               </div>
            </div>
         </div>

         <div>
            <label for="">{{ 'login.company' | translate }}</label>
            <input
               [placeholder]="'placeholders.company' | translate"
               formControlName="company"
            />
            <div
               *ngIf="form.company.invalid && form.company.touched"
               class="invalid"
            >
               <div *ngIf="form.company.errors.required">
                  {{ 'login.company' | translate }} {{ 'general.end_date' | translate }}
               </div>
            </div>
         </div>

         <div>
            <label for="">{{ 'app.info.country' | translate }}</label>
            <input
               [placeholder]="'app.info.country' | translate"
               formControlName="country"
            />
            <div
               *ngIf="form.country.invalid && form.country.touched"
               class="invalid"
            >
               <div *ngIf="form.country.errors.required">
                  {{ 'app.info.country' | translate }} {{ 'aria-labels.is_required' | translate }}
               </div>
            </div>
         </div>


         <div>
            <label for="">{{ 'general.message' | translate }}</label>
            <textarea   [placeholder]="'placeholders.message' | translate"
            formControlName="message" name="" id="" cols="30" rows="4"></textarea>
            <div
               *ngIf="form.message.invalid && form.message.touched"
               class="invalid"
            >
               <div *ngIf="form.message.errors.required">
                  {{ 'general.message' | translate }} {{ 'general.end_date' | translate }}
               </div>
            </div>
         </div>

         <div class="terms">
            <input
               name="terms_and_conditions"
               id="terms_and_conditions"
               type="checkbox"
               formControlName="agree"
            />
            <label for="terms_and_conditions"></label>
            <a mat-button (click)="openModal()">
               {{ 'general.terms_and_conditions' | translate }}</a
            >
         </div>
         <div class="terms marketing_emails">
            <input
               name="emails"
               id="emails"
               type="checkbox"
               (click)="acceptMarketing()"
            />
            <label for="emails"></label>
            <a
               >{{ 'login.yes_i_would_like_to_receive_offers' | translate }}.
            </a>
         </div>
         <re-captcha
         (resolved)="resolved($event)"
         [siteKey]="siteKey"
         formControlName="recaptcha"
       ></re-captcha>

         <ng-container *ngIf="signupForm.valid">
            <button type="submit" class="submit">
               {{ 'general.send' | translate }}
            </button>
         </ng-container>
         <ng-container *ngIf="signupForm.invalid">
            <button
               [ngClass]="{ disable: signupForm.invalid }"
               [disabled]="signupForm.invalid"
               type="submit"
               class="submit"
               (click)="showToasterWarning('Please re-check your info')"
            >
               {{ 'general.send' | translate }}
            </button>
         </ng-container>
      </form>
      <div *ngIf="!profile" class="right-side profileSelection">
         <p>{{ 'login.choose_profile' | translate }}</p>
         <div (click)="changeRoute('individual')">
          <img src="../../../assets/images/individualLogoLogin.png" alt="">
          <div>
             <div>
               <h4>{{ 'app.individual' | translate }}</h4>

             </div>
             <i class="fas fa-chevron-right"></i>
          </div>
         </div>
         <div (click)="changeRoute('institution')">
          <img src="../../../assets/images/institutionLogoLogin.png" alt="">
          <div>
            <div>
               <h4>{{ 'app.institution' | translate }}</h4>
            </div>
             <i class="fas fa-chevron-right"></i>
          </div>
         </div>
         <div (click)="changeRoute('business')">
          <img src="../../../assets/images/businessLogoLogin.png" alt="">
          <div>
            <div>
               <h4>{{ 'app.business' | translate }}</h4>
            </div>
             <i class="fas fa-chevron-right"></i>
          </div>
         </div>
         <hr>
         <span>{{ 'login.already_have_account' | translate }}?</span>
         <a routerLink="/login">{{ 'login.sign_in' | translate }}</a>
       </div>
   </div>
</main>
