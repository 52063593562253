import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userRole;
  isLoggedInFromApp;
  currentLanguage: string = localStorage.getItem('websiteLanguage');
  constructor(private authServiceService: AuthService, private languageService: LanguageService, public translate: TranslateService) {
    // translate.setDefaultLang('english');
  }

  ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });

    this.isLoggedInFromApp = localStorage.getItem('login-app') || false
    this.userRole = this.authServiceService.isLoggedInRole();
  }
}
