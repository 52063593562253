import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class AdministratorStatisticsService {
   constructor(private http: HttpClient) { }

   selectedYearSubject: BehaviorSubject<number> = new BehaviorSubject<number>(2024);
   selectedYearObservable = this.selectedYearSubject.asObservable();

   /**
    * Method used to call api for returning general data about institution.
    * @returns
    */
   institutionStatistics() {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/statistics`,
         {}
      );
   }

   /**
    * Method used to call api for returning top 5 professors with most assignments.
    * @returns
    */
   institutionAssignments() {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/assignments/statistics/overview`,
         {}
      );
   }

   /**
    * Method used to call api for returning top 5 professors or students with most submissions.
    * @returns
    */
   institutionSubmissions(type) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/submissions/${type}/statistics`,
         {}
      );
   }

   /**
    * Method used to call api for returning top 5 students with most submissions on their assignments.
    * @returns
    */
   institutionThesis(type: string) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/thesis/${type}/statistics`,
         {}
      );
   }

   /**
    * Method used to call api for returning data for submissions(Students) that will be displayed on graph.
    * * @returns
    */
   institutionSubmissionsStatistics(year) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/submissions/statistics/?year=${year}`,
         {}
      );
   }

   /**
    * Method used to call api for returning data for submissions(Professors) that will be displayed on graph.
    * * @returns
    */
   institutionSubmissionsStatisticsBasic(year) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/submissions/statistics/basic/?year=${year}`,
         {}
      );
   }

   /**
    * Method used to call api for returning data for Users(Professors) that will be displayed on graph.
    * * @returns
    */
   institutionThesisSubmissionsStatistics(year) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/thesis/submissions/statistics/?year=${year}`,
         {}
      );
   }

   /**
    * Method used to call api for returning data for Users(Students) that will be displayed on graph.
    * * @returns
    */
   institutionUsersStatistics(year) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/users/statistics/?year=${year}`,
         {}
      );
   }

   /**
    * Method used to call api for returning data for submissions that will be displayed on graph.
    * * @returns
    */
   academicUnitsStatisticsOverview() {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/academic-units/statistics/overview`,
            {}
         )
         .pipe();
   }

   /**
    * Method used to call api for returning data for submissions that will be displayed on graph.
    * * @returns
    */
   academicUnitsFacultiesStatistics(page?: number, title?: string) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/faculties/statistics/?page=${page}` +
            (title ? `&facultyName=${title}` : '')
         )
         .pipe();
   }

   /**
    * Method used to call api for returning data for submissions that will be displayed on graph.
    * * @returns
    */
   academicUnitsDepartmentsStatistics(
      page?: number,
      title?: string,
      faculty?: string
   ) {
      console.log('qetu page', page);

      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/departments/statistics/?page=${page}` +
            (title ? `&departmentName=${title}` : '') +
            (faculty ? `&facultyId=${faculty}` : '')
         )
         .pipe();
   }

   academicUnitsSubjectsStatistics(
      page?: number,
      title?: string,
      faculty?: string,
      department?: string,
      professor?: string,
      studentName?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/subjects/statistics/?page=${page}` +
            (title ? `&subjectTitle=${title}` : '') +
            (faculty ? `&facultyId=${faculty}` : '') +
            (department ? `&departmentId=${department}` : '') +
            (professor ? `&professor=${professor}` : '') +
            (studentName ? `&studentName=${studentName}` : '')
         )
         .pipe();
   }

   academicUnitsAssignmentsStatistics(
      page?: number,
      title?: string,
      faculty?: string,
      department?: string,
      subject?: string,
      professor?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/assignments/statistics/?page=${page}` +
            (title ? `&assignmentName=${title}` : '') +
            (faculty ? `&facultyId=${faculty}` : '') +
            (department ? `&departmentId=${department}` : '') +
            (subject ? `&subjectTitle=${subject}` : '') +
            (professor ? `&professor=${professor}` : '')
         )
         .pipe();
   }

   usersStatisticsOverview() {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/users/statistics/overview`,
            {}
         )
         .pipe();
   }

   professorsFullIntegrationStatistics(
      page?: string,
      faculty?: string,
      department?: string,
      professorName?: string,
      subject?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/users/statistics/professors/full-integration/?page=${page}` +
            (faculty ? `&facultyId=${faculty}` : '') +
            (department ? `&departmentId=${department}` : '') +
            (professorName ? `&professorName=${professorName}` : '') +
            (subject ? `&subject=${subject}` : '')
         )
         .pipe();
   }

   studentsStatistics(
      page?: string,
      faculty?: string,
      department?: string,
      studentName?: string,
      subject?
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/users/statistics/students/?page=${page}` +
            (faculty ? `&facultyId=${faculty}` : '') +
            (department ? `&departmentId=${department}` : '') +
            (studentName ? `&studentName=${studentName}` : '') +
            (subject ? `&subject=${subject}` : '')
         )
         .pipe();
   }

   professorsBasicStatistics(
      page?: string,
      faculty?: string,
      department?: string,
      name?: string,
      subject?
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/users/statistics/professors/basic/?page=${page}` +
            (faculty ? `&faculty=${faculty}` : '') +
            (department ? `&department=${department}` : '') +
            (name ? `&name=${name}` : '') +
            (subject ? `&subject=${subject}` : '')
         )
         .pipe();
   }

   assignmentsSubmissionsStatistics(
      page?: string,
      facultyId?: string,
      departmentId?: string,
      subject?: string,
      assignmentType?: string,
      submissionName?: string,
      assignmentName?: string,
      studentName?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/statistics/submissions/assignment/?page=${page}` +
            (facultyId ? `&facultyId=${facultyId}` : '') +
            (departmentId ? `&departmentId=${departmentId}` : '') +
            (subject ? `&subject=${subject}` : '') +
            (assignmentType ? `&assignmentType=${assignmentType}` : '') +
            (submissionName ? `&submissionName=${submissionName}` : '') +
            (assignmentName ? `&assignmentName=${assignmentName}` : '') +
            (studentName ? `&studentName=${studentName}` : '')
         )
         .pipe();
   }

   professorsSubmissionsStatistics(
      page?: string,
      facultyId?: string,
      departmentId?: string,
      professorId?: string,
      submissionName?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/statistics/submissions/professors/?page=${page}` +
            (facultyId ? `&facultyId=${facultyId}` : '') +
            (departmentId ? `&departmentId=${departmentId}` : '') +
            (professorId ? `&professorId=${professorId}` : '') +
            (submissionName ? `&submissionName=${submissionName}` : '')
         )
         .pipe();
   }

   thesisSubmissionsStatistics(
      page?: number,
      facultyId?: string,
      departmentId?: string,
      professorId?: string,
      type?: string,
      studentName?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/statistics/thesis/?page=${page}` +
            (facultyId ? `&facultyId=${facultyId}` : '') +
            (departmentId ? `&departmentId=${departmentId}` : '') +
            (professorId ? `&professorId=${professorId}` : '') +
            (type ? `&type=${type}` : '') +
            (studentName ? `&studentName=${studentName}` : '')
         )
         .pipe();
   }

   thesisSubmissionDetails(thesisId: string) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/thesis/${thesisId}/institution/statistics/details`
         )
         .pipe();
   }

   thesisSubmissionStatisticsOverview() {
      return this.http
         .get<any>(
            `${environment.apiUrl}/institution/statistics/thesis/overview`
         )
         .pipe();
   }

   institutionUsersStatisticsBasic(year) {
      return this.http.get<any>(
         `${environment.apiUrl}/institution/users/statistics/basic/?year=${year}`,
         {}
      );
   }
}
