import {Component, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent {
  title = 'score';
  warningColor: any;
  @Input() score = 10;
  @Input() isOnAnalysis!: boolean;
  @Input() isOnAi!: boolean;
  @Input() isOnManipulation!: boolean;
  @Input() docStatus: string = ' ';
  indexImage: any;
  warningText: any;

  originalityLevelClean: Record<string, string> = {};

  constructor(private translate: TranslateService) {

  }

  getOriginalityScore(): number {
    if (this.docStatus) {
      if (this.docStatus == 'High risk') {
        this.setWarningColor('#A50D0D');
        this.indexImage = '../../../../../../assets/report_v2/warning-circled-filled.svg';
        this.warningText = this.translate.instant('new_entries.high_risk_description');
        return 1;
      } else if (this.docStatus == 'Medium risk') {
        this.setWarningColor('#fffb00');
        this.indexImage = '../../../../../../assets/report_v2/warning-triangle-fillled.svg';
        this.warningText = this.translate.instant('new_entries.medium_risk_description');
        return 2;
      } else if (this.docStatus == 'Low risk') {
        this.setWarningColor('#269e44');
        this.indexImage = '../../../../../../assets/report_v2/check-circled-filled.svg';
        this.warningText = this.translate.instant('new_entries.low_risk_description');
        return 3;
      } else {
        this.setWarningColor('#A50D0D');
        return 0;
      }
    } else {
      return 0;
    }
  }

  private setWarningColor(color: string) {
    this.warningColor = color;
  }

  getScoreColor(): string {
    this.getOriginalityScore();
    return this.warningColor;
  }

  setBackgroundColor(): string {
    const scoreColor = this.getScoreColor();
    if (scoreColor) {
      const rgba = scoreColor.match(/[0-9a-fA-F]{2}/g)?.map((hex) => parseInt(hex, 16));
      return `rgba(${rgba?.join(',')}, 0.2)`
    } else {
      return `rgba(0, 0, 0, 0.2)`;
    }
  }

  openLink(link) {
    window.open(link, '_blank');
  }
}
