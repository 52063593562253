<main>
   <div class="scroll white-bg border">
      <h3
         *ngIf="
            !(
               this.departmentsDetails?.totalDepartments == 0 &&
               name == undefined &&
               faculty == '-1'
            )
         "
         class="filtersContainer"
      >
         <h4>{{ 'general.departments' | translate }}</h4>
         <div class="selectors">
            <div>
               <select [(ngModel)]="faculty" (change)="filterDepartments()">
                  <option value="-1" selected disabled>
                     {{ 'app.filter_by_institution' | translate }}
                  </option>
                  <ng-container *ngFor="let faculty of this.faculties">
                     <option
                        value="{{ faculty.id }}"
                        [selected]="faculty.id === this.faculty"
                     >
                        {{ faculty.name }}
                     </option>
                  </ng-container>
                  <option value="">{{ 'general.all' | translate }}</option>
               </select>
            </div>
            <div>
               <input
                  [placeholder]="'placeholders.search_by_name' | translate"
                  [(ngModel)]="name"
                  (ngModelChange)="titleFilter()"
                  type="text"
                  name="searchByName"
               />
            </div>
            <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
         </div>
      </h3>
      <div *ngIf="this.departmentsDetails?.totalDepartments != 0">
         <table>
            <tr>
               <th class="bold">{{ 'general.name' | translate }}</th>
               <th class="bold">{{ 'general.description' | translate }}</th>
               <th class="bold">{{ 'app.institution' | translate }}</th>
               <th class="bold">{{ 'general.submissions' | translate }}</th>
               <th class="bold">{{ 'general.edit' | translate }}</th>
            </tr>
            <ng-container
               *ngFor="
                  let department of this.departmentsDetails?.departments
                     | paginate
                        : {
                             itemsPerPage: pageSize,
                             currentPage: page,
                             totalItems:
                                this.departmentsDetails?.totalDepartments
                          }
               "
            >
               <tr class="shadow">
                  <td>
                     {{ department.name }}
                  </td>
                  <td [title]="department.description" class="addEllipsis">
                     {{ department.description }}
                  </td>
                  <td>
                     {{ department.Faculty.name }}
                  </td>
                  <td>
                     {{ department.submissions }}
                  </td>
                  <td>
                     <i (click)="this.openModal(department.id)" class="far fa-edit editButton"></i>
                  </td>
               </tr>
            </ng-container>
         </table>
      </div>
      <div class="paginationContainer">
         <div></div>
         <pagination-controls
         *ngIf="this.departmentsDetails?.totalDepartments != 0"
         class="num"
         (pageChange)="changePage($event)"
         responsive="true"
         previousLabel=""
         nextLabel=""
      ></pagination-controls>
      <div class=""
      *ngIf="
            !(
               this.departmentsDetails?.totalDepartments == 0 &&
               name == undefined &&
               faculty == '-1'
            )
         "
      >
         <button class="reportBtn" (click)="registerDepartmentModal()">
            <i class="fas fa-plus"></i>{{ 'app.register_departments' | translate }}
         </button>
      </div>
      </div>

      <div
         class="zeroState"
         *ngIf="
            this.departmentsDetails?.totalDepartments == 0 &&
            (name != undefined || faculty != '-1')
         "
      >
         No departments found
      </div>
      <div
         class="zero-state"
         *ngIf="
            this.departmentsDetails?.totalDepartments == 0 &&
            name == undefined &&
            faculty == '-1'
         "
      >
         <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
         <h5 class="bold">{{ 'app.there_are_no_departments_registered_in_your_instutition' | translate }} yet.<br/><span class="clickable" routerLink="/administrator/register-module/department-register">{{ 'general.click_here' | translate }}</span> {{ 'app.to_begin_registering_your_first_department' | translate }}.</h5>
      </div>
   </div>
</main>
