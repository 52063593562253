<section>
  <!-- <h4 class="bold w-87">{{ 'general.dashboard' | translate }}</h4> -->
  <main>
    <div class="statisticsNav">

      <!-- <button *ngIf="assignmentId" class="upload upload_btn shadow border"
        [routerLink]="['/student/upload/upload-file']" [queryParams]="{assignmentId: assignmentId}"
        routerLinkActive="active">+</button> -->

      <!-- <button *ngIf="!assignmentId" class="upload upload_btn shadow border" routerLink="/student/register/upload" routerLinkActive="active">+</button> -->

      <div class="statisticsNav-container" *ngIf="this.currentUserRole === 9 || this.currentUserRole === 5">
        <div routerLink="/student/statistics/overview" routerLinkActive="active" class="shadowBorder">
          {{ 'general.overview' | translate }}
        </div>
        <div routerLink="/student/statistics/academic-units/subjects" routerLinkActive="active" class="shadowBorder">
          {{ 'app.academic_units' | translate }}
        </div>
        <div routerLink="/student/statistics/submissions-statistics/assignments" routerLinkActive="active"
          class="shadowBorder">
          {{ 'general.submissions' | translate }}
        </div>
      </div>
    </div>
    <!-- <router-outlet></router-outlet> -->
  </main>
</section>
<section class="upload">
  <!-- <h4 class="bold w-87">{{ 'general.upload' | translate }}</h4> -->
  <div>
    <!-- <div class="links"> -->
    <!-- <a routerLink="upload-file" routerLinkActive="active">{{
            'individual.upload.file_upload' | translate
         }}</a> -->
    <!-- routerLink="input-text" routerLinkActive="active" -->
    <!-- <a  *ngIf="userRole == 4" routerLink="text-input"  routerLinkActive="active">{{ 'app.upload.bulk_upload' | translate }}</a> -->
    <!-- routerLink="web-import" routerLinkActive="active" -->
    <!-- <a style="opacity: 0.5; pointer-events: none">{{
            'individual.upload.web_import' | translate
         }}</a> -->
    <!-- </div> -->
    <router-outlet></router-outlet>
  </div>
</section>
