<div class="textEditorModal">
    <i class="fas fa-times-circle" (click)="closeModal()"></i>
    <h4> <b>{{ 'app.ai.feature_announcement' | translate }}</b> <span> | December 19, 2022</span></h4>
    <div class="modalImage">
        <div>

        </div>
        <img src="../../../assets/images/01.jpg" alt="">
    </div>
    <h2>{{ 'app.ai.ai_content_prediction_benefits' | translate }}</h2>
    <ul>
        <li><b>{{ 'general.academic_integrity' | translate }}:</b> {{ 'app.ai.ai_text_1' | translate }}.</li>
        <li><b>{{ 'app.accountability' | translate }}:</b> {{ 'app.ai.ai_text_2' | translate }}.</li>
        <li><b>{{ 'other.efficiency' | translate }}:</b> {{ 'app.ai.ai_text_3' | translate }}.</li>
    </ul>
    <div class="modalFooter">

        <button class="submit" routerLink="/individual/detector" (click)="closeModal()">{{ 'other.try_it' | translate }}</button>
    </div>
</div>
