<section class="statistics-dashboard">
   <!-- <h4 class="bold w-87">{{ 'general.dashboard' | translate }}</h4> -->
   <main style="width: 100%">
      <div class="cards-monitoruesi">
         <a
            routerLink="/administrator/statistics/overview"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>{{ 'individual.statistics.analytics' | translate }}</h4>
         </a>
         <a
            routerLink="/administrator/statistics/academic-units"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>{{ 'app.academic_units' | translate }}</h4>
         </a>
         <a
            routerLink="/administrator/dashboard/academic-units-courses"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>{{ 'general.subjects' | translate }}</h4>
         </a>
         <a
            routerLink="/administrator/statistics/submissions/assignment-submissions"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>{{ 'general.submissions' | translate }}</h4>
         </a>
      </div>
      <div>
         <router-outlet></router-outlet>
      </div>
   </main>
</section>
