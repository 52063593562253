<section>
   <div style="padding: 1%">
      Total Users
      <b>
         {{
            this.users[0]?.administrators +
               this.users[0]?.professors +
               this.users[0]?.teachingAssistants +
               this.users[0]?.academicIntegrityOfficers +
               this.users[0]?.students
         }}
      </b>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> {{ 'app.administrators' | translate }} </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.administrators }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="administratorsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> {{ 'general.academic_integrity_officers' | translate }} </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.academicIntegrityOfficers }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input
               type="number"
               [(ngModel)]="academicIntegrityOfficersNumber"
            /> </span
      ></ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> {{ 'app.users.professord' | translate }} </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.professors }}</b>
         </span>
      </ng-container>

      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="professorsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> {{ 'general.teaching_assistants' | translate }} </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.teachingAssistants }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="teachingAssistantsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> {{ 'general.students' | translate }} </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.students }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="studentsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<ng-container *ngIf="this.managingStarted === false">
   <div (click)="this.startManage()">{{ 'general.manage' | translate }}</div>
</ng-container>

<ng-container *ngIf="this.managingStarted === true">
   <div (click)="this.startManage()">{{ 'app.back' | translate }}</div>
   <div (click)="this.calculatePrice()">{{ 'app.calculate_price' | translate }}</div>
</ng-container>

<ng-container *ngIf="this.managingStarted === true">
   <div>
      <span> Calculated price : {{ this.calculatedPrice }} </span>
      <ng-container *ngIf="this.calculatedPrice !== 0">
         <h3>{{ 'general.save' | translate }} {{ this.calculatedPrice }}</h3>
      </ng-container>
   </div>
</ng-container>
