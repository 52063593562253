// language.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../models/language';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languagesUrl = 'assets/languages.json';

  constructor(private http: HttpClient) {}

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.languagesUrl);
  }

  getLanguageName(languageCode: string): Observable<string> {
    return this.http.get<Language[]>(this.languagesUrl).pipe(
      map((languages) => {
        const language = languages.find((lang) => lang.value === languageCode);
        return language ? language.text : '';
      })
    );
  }

  storedLanguage: string = localStorage.getItem('websiteLanguage');
  private selectedLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.storedLanguage || 'en');
  selectedLanguage$: Observable<string> = this.selectedLanguageSubject.asObservable();

  setSelectedLanguage(languageCode: string): void {
    this.selectedLanguageSubject.next(languageCode);
  }

  private userLanguageSubject = new BehaviorSubject<string>(null);
  getUserLanguage$ = this.userLanguageSubject.asObservable();

  getLanguageCode(languageName: string): Observable<string> {
    return this.http.get<Language[]>(this.languagesUrl).pipe(
      map((languages) => {
        const language = languages.find((lang) => lang.text === languageName);
        return language ? language.value : '';
      })
    );
  }
}
