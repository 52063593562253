import { Component, Inject } from "@angular/core";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-view-logs',
    templateUrl: './view_logs.component.html',
    styleUrls: ['./view_logs.component.scss']
})


export class ViewLogs {
  showList = false;
  expandable: boolean;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
    this.expandable = data.expandable;    
  }

  toggleShowList() {
    this.showList = !this.showList;
  }

  goToLink(id) {
    let url = `${environment.currentUrl}/administrator/submission/${id}/report/v2`;
         window.open(url, '_blank');
  }
}
