<div class="create-assignment shadowBorder" *ngIf="this.dataLoaded">
    <div class="shadowBorder assignment">
     <section class="heading_title">
       <h3>Assignment Details</h3>
       <button (click)="openLink('https://support.inspera.com/hc/en-us/articles/20858791561245-Assignment-Configuration-Settings-Navigation-Options')">Get more information</button>
     </section>
       <form action="" [formGroup]="assignmentForm">
          <div>
            <h3 class="terniary_heading">
              Translation similarity
              <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Specifies the languages for the translated language similarity check.">
                <img src="../../../../assets/report_v2/info.svg" alt="">
              </button>
            </h3>
           <div class="expandable_section">
             <label class="switch">
               <input type="checkbox" [formControl]="translatedLanguageEnabled"/>
               <span class="slider round"></span>
             </label>
             <span class="line" *ngIf="translatedLanguageEnabled.value"></span>
             <div *ngIf="translatedLanguageEnabled.value" style="width: 100%;">
               <p class="sm_title">Select languages</p>
               <div class="language-selection">
                 <mat-form-field>
                   <mat-select [formControl]="selectedTranslatedLanguages" multiple>
                     <mat-option *ngFor="let language of languages" [value]="language.value"
                       [disabled]="selectedTranslatedLanguages?.value?.length >= 3 && !isSelected(language.value)">
                       {{ language.text }}
                     </mat-option>
                   </mat-select>
                   <mat-hint *ngIf="selectedTranslatedLanguages?.value?.length > 0">
                     {{ selectedTranslatedLanguages?.value?.length }}/3 selected
                   </mat-hint>
                 </mat-form-field>
               </div>
             </div>
           </div>
             <!-- <select formControlName="translatedLanguage" (change)="this.languageSelected(2)">
                <option value="0" disabled selected>Choose Language</option>
                <ng-container *ngFor="let language of this.languages">
                   <option value="{{ language.value }}" *ngIf="language.selectedDocLanguage === false">
                      {{ language.text }}
                   </option>
                </ng-container>
             </select> -->
          </div>

          <div>
           <h3 class="terniary_heading">
             Contextual Similarity
             <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Analyzes the relationship between two sentences by evaluating their contextual similarities">
               <img src="../../../../assets/report_v2/info.svg" alt="">
             </button>
           </h3>

           <div>
              <label class="switch">
                 <input formControlName="contextualSimilarity" type="checkbox" />
                 <span class="slider round"></span>
              </label>
              <div class="threshold-input" *ngIf="this.form.contextualSimilarity.value">
                <div class="threshold-inputs">
                    <div>
                    <label class="sm_title">{{ 'new_entries.minimum_threshold' | translate }}:</label>
                    <section class="input-percentage">
                        <input formControlName="contextualSimilaritiesThreshold" type="number" min="0" max="100" />
                        %
                    </section>
                    </div>
                </div>
            </div>
           </div>
        </div>

        <div *ngIf="currentUser?.Institution?.settings['ai']">
         <h3 class="terniary_heading">
           {{ 'general.enable_ai_prediction' | translate }}
           <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Examines the document text for potentially AI-generated content">
             <img src="../../../../assets/report_v2/info.svg" alt="">
           </button>
         </h3>
         <div>
            <label class="switch">
               <input formControlName="aiDetection" type="checkbox" />

               <span class="slider round"></span>
            </label>
         </div>
        </div>

        <h3 class="additional_settings_heading pointer" [ngClass]="{'rotate': additionalSettings}" (click)="toggleAdditionalSettings()">Additional settings</h3>

        <div class="additional_settings_body" *ngIf="additionalSettings">
            <div *ngIf="this.currentUser?.Institution?.settings['archive']">
                <h3 class="terniary_heading">
                Document archive
                <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Store submissions from this assignment for future detection of potential student collusion">
                    <img src="../../../../assets/report_v2/info.svg" alt="">
                </button>
                </h3>
                <div>
                <label class="switch">
                    <input formControlName="archive" type="checkbox" />

                    <span class="slider round"></span>
                </label>
                </div>
            </div>

            <div>
            <h3 class="terniary_heading">
                {{ 'general.excluded_source_criteria' | translate }}
                <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Sources below the specified threshold percentage are excluded from the Originality Report.">
                <img src="../../../../assets/report_v2/info.svg" alt="">
                </button>
            </h3>
            <div class="never_date">
                <input formControlName="source_percentage_threshold" type="number"
                    [value]="currentUser?.Institution?.settings['source_percentage_threshold']" min="0" max="100" />
            </div>
            </div>

            <!-- <div *ngIf="currentUser?.Institution?.settings['anonymous_submissions']">
            <h3 class="terniary_heading">
                {{ 'general.anonymous_submissions' | translate }}
                <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Enabling this option ensures that user submission information remains undisclosed.">
                <img src="../../../../assets/report_v2/info.svg" alt="">
                </button>
            </h3>
            <div>
                <label class="switch">
                    <input formControlName="anonymous_submissions" type="checkbox" />

                    <span class="slider round"></span>
                </label>
            </div>
            </div> -->

            <div>
                <h3 class="terniary_heading">
                    {{ 'general.exclude_citations' | translate }}
                    <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Enabling this option will exclude citations from assignment submissions.">
                    <img src="../../../../assets/report_v2/info.svg" alt="">
                    </button>
                </h3>
                <div>
                    <label class="switch">
                        <input formControlName="exclude_citations" type="checkbox" />


                    <span class="slider round"></span>
                </label>
            </div>
            </div>

            <!-- <div *ngIf="currentUser?.Institution?.settings['submission_word_limit']">
            <h3 class="terniary_heading">
                Word limit for submission
                <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Specifies the minimum word count allowed for an assignment submission">
                <img src="../../../../assets/report_v2/info.svg" alt="">
                </button>
            </h3>
            <div class="never_date">
                <input formControlName="submission_word_limit" type="number"
                    [value]="currentUser?.Institution?.settings['submission_word_limit']" min="100" />
            </div>
            </div> -->

            <div>
            <h3 class="terniary_heading">
                Include URLs
                <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Enables users to add additional sources in the originality checking process">
                    <img src="../../../../assets/report_v2/info.svg" alt="">
                </button>
            </h3>
            <div class="textarea-container">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let control of assignmentForm.controls.sources.get('includeSources').controls; let i = index"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeUrl(i, 'include')"
                    class="trim-chip">
                    {{ control.value }}
                    <mat-icon matChipRemove>X</mat-icon>
                </mat-chip>
                <input placeholder="New URL..."
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addUrl($event, 'include')"
                    class="chip-input" />
            </mat-chip-list>
            </div>
            </div>

            <div>
            <h3 class="terniary_heading">
                Exclude URLs
                <button matTooltipClass="new_report_tooltip" matTooltipPosition="above"
                    matTooltip="Exclude specific sources from the originality checking process">
                    <img src="../../../../assets/report_v2/info.svg" alt="">
                </button>
            </h3>
            <div class="textarea-container">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let control of assignmentForm.controls.sources.get('excludeSources').controls; let i = index"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeUrl(i, 'exclude')"
                    class="trim-chip">
                    {{ control.value }}
                    <mat-icon matChipRemove>X</mat-icon>
                </mat-chip>
                <input placeholder="New URL..."
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addUrl($event, 'exclude')"
                    class="chip-input" />
            </mat-chip-list>
            </div>
            </div>
        </div>

       </form>
    </div>
  </div>
