<div class="information-modal">
  <div class="shadow">
    <h1 tabindex="0">
      {{ 'report.sentence_information' | translate }}
    </h1>
    <button mat-dialog-close tabindex="0" role="button" [attr.aria-label]="'aria-labels.close_modal' | translate">
      <img src="../../../../assets/images/close_x.svg" alt="">
    </button>
  </div>
  <div *ngIf="this.changeSourceView === false">
    <h2 tabindex="0">{{ 'report.matching_source' | translate }}:</h2>
    <!-- <h3> -->
      <a tabindex="0" [attr.aria-label]="'aria-labels.enter_source_link' | translate" style="cursor: pointer" *ngIf="currentSource.sourceUrl !== undefined && currentSource.sourceUrl !== null"
        (click)="openLink()">{{ currentSource.sourceUrl }}</a>
        <span tabindex="0" [attr.aria-label]="'aria-labels.open_source_origin' | translate" *ngIf="currentSource.sourceUrl == undefined || currentSource.sourceUrl == null">{{ 'report.this_sentence_was_found_in' | translate }} <ng-container *ngIf="!this.canEdit">{{ 'app.upload.student_submission' | translate }}</ng-container> <a *ngIf="this.canEdit" style="cursor: pointer" (click)="startDocumentPreview()">{{ 'app.upload.student_submission' | translate }}</a>.</span>

    <!-- </h3> -->
    <h3 tabindex="0">{{ 'report.sentence' | translate }}:</h3>
    <p tabindex="0" *ngIf="currentSentence !== null" [innerHTML]="currentSentence?.sourceSentence"></p>
    <div>
      <ng-container  *ngIf="this.data.sourceType && this.canEdit && !this.currentSentence?.isExcluded">
        <button aria-expanded="true" tabindex="0" [attr.aria-label]="'aria-labels.exclude_sentence' | translate" (click)="startExclude()" class="exclude-btn" [ngClass]="{
                 disabled: this.data.sourceType
                    ? this.currentSentence?.isExcluded
                    : this.currentSentence?.isTranslationExcluded
              }">{{ 'report.exclude' | translate }}
        </button>
      </ng-container>
      <ng-container  *ngIf="!this.data.sourceType && this.canEdit && !this.currentSentence?.isTranslationExcluded">
        <button aria-expanded="true" tabindex="0" [attr.aria-label]="'aria-labels.exclude_sentence' | translate" (click)="startExclude()" class="exclude-btn" [ngClass]="{
                 disabled: this.data.sourceType
                    ? this.currentSentence?.isExcluded
                    : this.currentSentence?.isTranslationExcluded
              }">{{ 'report.exclude' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="this.data.sourceType && this.canEdit && this.currentSentence?.isExcluded">
        <button tabindex="0" [attr.aria-label]="'aria-labels.press_to_include_sentence' | translate" (click)="includeSentence(currentSentence)" class="include-btn" [ngClass]="{
                  disabled: this.data.sourceType
                     ? this.currentSentence?.isExcluded === false
                     : this.currentSentence?.isTranslationExcluded === false
               }">{{ 'report.include' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="!this.data.sourceType && this.canEdit && this.currentSentence?.isTranslationExcluded">
        <button tabindex="0" [attr.aria-label]="'aria-labels.press_to_include_sentence' | translate" (click)="includeSentence(currentSentence)" class="include-btn" [ngClass]="{
                  disabled: this.currentSentence?.isTranslationExcluded == false
               }">{{ 'report.include' | translate }}
        </button>
      </ng-container>
      <button tabindex="0" [attr.aria-label]="'aria-labels.change_sentence_source' | translate" *ngIf="this.canEdit" class="changeSrc-btn" (click)="changeSource(currentSentence)" [ngClass]="{
        disabled: this.currentSentence.secondarySources?.length == 0 || !this.currentSentence.secondarySources
     }">
        {{ 'general.change' | translate }} {{ 'report.source' | translate }}
      </button>
    </div>

    <div class="edit_comment" *ngIf="this.currentSentence?.isExcluded && this.data.sourceType">
      <h3 tabindex="0">{{ 'report.reason_why_sentence_is_excluded' | translate }}:</h3>
      <div *ngIf="!this.editCommentStarted">
        <span tabindex="0" *ngIf="this.currentSentence.comment">{{ this.currentSentence.comment }}
        </span>
        <span tabindex="0" *ngIf="!this.currentSentence.comment">{{ 'app.no_comment' | translate }}</span>
        <button tabindex="0" [attr.aria-label]="'aria-labels.edit_comment' | translate" (click)="startEdit()" *ngIf="!this.editCommentStarted && this.canEdit">
          <i class="far fa-edit"></i> {{ 'general.edit' | translate }}
        </button>
      </div>
    </div>

    <div class="edit_comment" *ngIf="
            this.currentSentence?.isTranslationExcluded &&
            this.data.sourceType === false
         ">
      <h3 tabindex="0">{{ 'report.reason_why_sentence_is_excluded' | translate }}:</h3>
      <div *ngIf="!this.editCommentStarted">
        <span tabindex="0" *ngIf="this.currentSentence.translateComment">{{ this.currentSentence.translateComment }}
        </span>
        <span tabindex="0" *ngIf="!this.currentSentence.translateComment">{{ 'app.no_comment' | translate }}</span>
        <button tabindex="0" [attr.aria-label]="'aria-labels.edit_comment' | translate" (click)="startEdit()" *ngIf="!this.editCommentStarted">
          <i class="far fa-edit"></i> {{ 'general.edit' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="this.excludeButtonClicked" class="comment">
      <div>
        <textarea tabindex="Press enter to write a comment on why do you want to exclude this sentence!" maxlength="300" name="comment" [(ngModel)]="comment" rows="5"
          [placeholder]="'report.reason' | translate"></textarea>
        <span> {{ this.comment.length || 0 }} / 300 </span>
      </div>
      <div>
        <button tabindex="0" [attr.aria-label]="'aria-labels.close_modal_comment' | translate" mat-dialog-close>{{ 'general.cancel' | translate }}</button>
        <button tabindex="0" (click)="excludeSentence(currentSentence)">{{ 'report.finish' | translate }}</button>
      </div>
    </div>

    <div *ngIf="this.editCommentStarted" class="comment">
      <div>
        <textarea tabindex="0" [attr.aria-label]="'aria-labels.your_comment_here' | translate" maxlength="300" name="comment" [(ngModel)]="comment" [placeholder]="'placeholders.your_comment_here' | translate"
          rows="5"></textarea>
        <span> {{ this.comment.length || 0 }} / 300 </span>
      </div>
      <div>
        <button tabindex="0" [attr.aria-label]="'aria-labels.delete_comment' | translate" (click)="deleteComment()">{{ 'app.delete' | translate }}</button>
        <button tabindex="0" (click)="startEdit()">{{ 'general.cancel' | translate }}</button>
        <button tabindex="0" [attr.aria-label]="'aria-labels.update_comment' | translate" (click)="updateComment()">{{ 'report.update' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="sources" *ngIf="this.changeSourceView === true">
    <h2>{{ 'report.change' | translate }}</h2>
    <div class="change_src">
      <div class="source" *ngFor="let source of this.SelectedSentenceSources" tabindex="0" [attr.aria-label]="'aria-labels.secondary_source' | translate">
        <button name="secondarySources" [id]="source.id" (click)="clickedSource(source.id)"><img src="../../../../assets/images/icons/" alt="">
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="-32 0 512 512">
            <path fill="#004080"
              d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0" />
            <path fill="#004080"
              d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0" />
          </svg>
        </button>
        <label (click)="clickedSource(source.id)" tabindex="0" [attr.aria-label]="'aria-labels.click_change_source' | translate" *ngIf="!source.url" [for]="source.id">{{ 'report.private_document_source' | translate }}!</label>
        <label (click)="clickedSource(source.id)" tabindex="0" [attr.aria-label]="'aria-labels.click_change_source' | translate" *ngIf="source.url" [for]="source.id">{{ source.url }}</label>
      </div>
    </div>
    <!-- <div>
      <div *ngFor="let source of this.SelectedSentenceSources" tabindex="0" [attr.aria-label]="('aria-labels.source' | translate) + ' ' + source.no">
        <span>
          <svg tabindex="0" [attr.aria-label]="'aria-labels.click_change_source' | translate" (click)="clickedSource(source.id)" xmlns="http://www.w3.org/2000/svg" height="20px"
            viewBox="-32 0 512 512">
            <path fill="#004080"
              d="m235.605469 203.519531v25.742188c0 8.175781 1.632812 15.035156 4.855469 20.378906 4.445312 7.375 11.742187 11.601563 20.027343 11.601563 8.117188 0 16.183594-3.953126 23.972657-11.742188l85.023437-85.023438c18.667969-18.667968 18.667969-49.042968 0-67.710937l-85.023437-85.023437c-7.789063-7.792969-15.855469-11.742188-23.972657-11.742188-12.386719 0-24.882812 9.890625-24.882812 31.980469v23.378906c-120.710938 7.128906-220.640625 99.765625-235.492188 221.050781-.832031 6.773438 3.019531 13.257813 9.367188 15.769532 1.796875.710937 3.667969 1.054687 5.515625 1.054687 4.679687 0 9.21875-2.191406 12.109375-6.140625 39.835937-54.429688 103.796875-86.925781 171.09375-86.925781 12.46875 0 25.007812 1.125 37.40625 3.351562zm0 0" />
            <path fill="#004080"
              d="m438.253906 219.820312c-6.347656-2.515624-13.589844-.421874-17.625 5.089844-39.832031 54.425782-103.792968 86.921875-171.089844 86.921875-12.46875 0-25.007812-1.125-37.40625-3.351562v-25.742188c0-22.089843-12.5-31.980469-24.882812-31.980469-8.117188 0-16.183594 3.953126-23.976562 11.746094l-85.023438 85.019532c-18.664062 18.667968-18.664062 49.042968.003906 67.714843l85.019532 85.019531c7.792968 7.792969 15.859374 11.742188 23.976562 11.742188 12.382812 0 24.882812-9.890625 24.882812-31.980469v-23.378906c120.714844-7.128906 220.640626-99.765625 235.492188-221.046875.832031-6.777344-3.019531-13.261719-9.371094-15.773438zm0 0" />
          </svg>
        </span>
        <p tabindex="0" [attr.aria-label]="'aria-labels.click_change_source' | translate" (click)="clickedSource(source.id)">{{ source.url }}</p>
      </div>
    </div> -->
    <div class="src_btns">
      <button tabindex="0" [attr.aria-label]="'aria-labels.go_back' | translate" (click)="changeSource()">
        <i class="fas fa-arrow-left"></i> {{ 'app.back' | translate }}
      </button>
      <button tabindex="0" mat-dialog-close [attr.aria-label]="'aria-labels.close_the_modal' | translate">{{ 'general.cancel' | translate }}</button>
    </div>
  </div>
</div>
