<div *ngIf="!this.hasRoute('/manage/')">
   <div>
      <span (click)="this.router.navigateByUrl('administrator/store/offers')"
         >{{ 'app.offers' | translate }}</span
      >
   </div>
   <div>
      <div><span> {{ 'general.manage' | translate }} </span></div>
      <div>
         <div routerLink="commitment"><span>{{ 'app.addons.commitment' | translate }}</span></div>
         <div routerLink="users"><span>{{ 'app.users.users' | translate }}</span></div>
         <div routerLink="addons"><span>{{ 'app.addons.addons' | translate }}</span></div>
         <div routerLink="repositories"><span>{{ 'app.repositories' | translate }}</span></div>
      </div>
   </div>
</div>

<ng-container *ngIf="this.hasRoute('/manage/')">
   <router-outlet></router-outlet>
</ng-container>
