import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
   ApexAxisChartSeries,
   ApexChart,
   ApexTitleSubtitle,
   ApexXAxis,
} from 'ng-apexcharts';

import * as individualAdministratorActions from '../state/action/individualAdministrator.actions';
import { getIndividualAdministratorStatisticsStateDetails } from '../state/selector/individualAdministrator.selectors';
import { MatDialog } from '@angular/material/dialog';
import { DraftMessageRequestComponent } from '../modals/draft-message-request/draft-message-request.component';

export type ChartOptions = {
   series: ApexAxisChartSeries;
   chart: ApexChart;
   xaxis: ApexXAxis;
   title: ApexTitleSubtitle;
   labels: string[];
};

@Component({
   selector: 'app-individual-administrator-statistics',
   templateUrl: './individual-administrator-statistics.component.html',
   styleUrls: ['./individual-administrator-statistics.component.scss'],
})
export class IndividualAdministratorStatisticsComponent
   implements OnInit, OnDestroy
{
   chartOptions = {
      series: [
         {
            name: 'No of Words',
            type: 'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
         {
            name: 'No of Thesis',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
         {
            name: 'No of Drafts',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
      ],
      chart: {
         height: 350,
         type: 'line',
      },
      stroke: {
         width: [0, 4],
      },
      title: {
         text: 'Utilization graph ',
      },
      dataLabels: {
         enabled: true,
         enabledOnSeries: [1],
      },

      xaxis: {
         categories: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
         ],
      },
      yaxis: [
         {
            title: {
               text: 'Words',
            },
         },
         {
            opposite: true,
            title: {
               text: 'Thesis',
            },
         },
      ],
   };

   /**
    * Submission details selected from the store without subscribing used only in html.
    */
   statisticsDetails$;
   /**
    * Variable used to store state store
    */
   submissionsDetails;
   /**
    * Variable used to store submissions Statistics state, more specific: graph data
    */
   submissionsGraphData;
   /**
    * Current year
    */
   year = 2021;

   /**
    * List of years to show on dropdown
    */
   years = [
      { name: '2020', value: 2020 },
      { name: '2021', value: 2021 },
   ];
   /**
    * Submission details Subscriber
    */
   submissionsDetailsSub;
   /**
    * Submission Data Graph Subscriber
    */
   submissionsGraphSub;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
      * Symbolizing the page of transactions pagination
      
      */
   pageTransaction: number = 1;

   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 5;

   /**
    * Representing tile model
    */
   title: string;
   /**
    * Filter for the submissions percentage
    */
   filter: number = 0;

   /**
    * Used to show zero state and search result state if filter are active
    */
   isFilterActive: boolean = false;

   /**
    * Current used data.
    */
   currentUser: any;

   statistics;

   /**
    * Timer used for search delay
    */
   timer: any;

   reSubmission: boolean = false;

   /**
    * Component constructor
    * @param store
    * @param toastr
    * @param statisticsService
    * @param authService
    * @param excelService
    * @param router
    * @param spinner
    */
   constructor(private store: Store, public dialog: MatDialog) {}
   ngOnDestroy(): void {
      this.statisticsDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(
         individualAdministratorActions.SubmissionsStatisticsDetails({
            page: 1,
         })
      );

      this.store.dispatch(
         individualAdministratorActions.GeneralStatisticsDetails()
      );

      this.statisticsDetails$ = this.store
         .select(getIndividualAdministratorStatisticsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.submissionsDetails = data.Submissions;
               this.statistics = data;
               this.chartOptions = {
                  series: [
                     {
                        name: 'No of Words',
                        type: 'column',
                        data: this.statistics.graph.wordsPerMonth,
                     },
                     {
                        name: 'No of Thesis',
                        type: 'line',
                        data: this.statistics.graph.documentsPerMonth,
                     },
                     {
                        name: 'No of Drafts',
                        type: 'line',
                        data: this.statistics.graph.chartDataReSubmissions,
                     },
                  ],
                  chart: {
                     height: 350,
                     type: 'line',
                  },
                  stroke: {
                     width: [0, 4],
                  },
                  title: {
                     text: 'Utilization graph ',
                  },
                  dataLabels: {
                     enabled: true,
                     enabledOnSeries: [1],
                  },

                  xaxis: {
                     categories: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                     ],
                  },
                  yaxis: [
                     {
                        title: {
                           text: 'Words',
                        },
                     },
                     {
                        title: {
                           text: 'Thesis ',
                        },
                     },
                     {
                        opposite: true,
                        title: {
                           text: 'Drats',
                        },
                     },
                  ],
               };
            }
         });
   }

   /**
    * Method used to open report in a new tab.
    * @param url
    */
   goToLink(id: string): void {
      let url = `${environment.currentUrl}/vleresues/submission/${id}/report/v2`;
      window.open(url, '_blank');
   }

   /**
    * Method is used to toggle a submission access that was submitted by a student.
    * @param id
    */
   toggleDraft(id) {
      this.dialog.open(DraftMessageRequestComponent, {
         data: {
            submissionId: id,
            page: this.page,
            title: this.title,
         },
         width: '50%',
      });
   }

   /**
    * Method is used to toggle a submission access that was submitted by a student.
    * @param id
    */
   toggleAccess(id) {
      this.store.dispatch(
         individualAdministratorActions.toggleSubmissionAccess({
            id,
            page: this.page,
            filter: this.filter,
            title: this.title,
         })
      );
   }

   /**
    * Method used to get submissions by specific filter (submission plagiarism percentage )
    * @param filter
    */
   changeValue(filter) {
      this.isFilterActive = true;
      this.filter = filter;
      this.page = 1;

      this.store.dispatch(
         individualAdministratorActions.SubmissionsStatisticsDetails({
            page: this.page,
            filter: this.filter,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page and title as params.
    * @param page
    */
   changePage(page): void {
      this.page = page;
      this.store.dispatch(
         individualAdministratorActions.SubmissionsStatisticsDetails({
            page,
            filter: this.filter,
         })
      );
   }

   /**
    * Method used filter submissions by title
    */
   titleFilter(): void {
      let time;
      time = 800;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            individualAdministratorActions.administratorIndividualDashboardDetails(
               { page: this.page, filter: this.filter, title: this.title }
            )
         );
      }, time);
   }
}
