<div class="modalContainer" role="dialog" [attr.aria-label]="'aria-labels.settings' | translate">
    <div class="modalHeader" tabindex="0" [attr.aria-label]="'aria-labels.permissions' | translate">
        <h1>{{ 'app.privacy_settings' | translate }}</h1>
        <i class="far fa-edit"></i>
    </div>

    <div class="btnContainer">
        <div *ngIf="!(data.isDocumentInArchive == undefined)">
            <div>
                <label [ngClass]="{ disabled: data.isDocumentInArchive == false }" for="" tabindex="0" [attr.aria-label]="'aria-labels.give_student_permission_document_archives' | translate">{{ 'report.give_student_permission_to_delete_document' | translate }}</label>
                <input name="deleteInArchive" id="deleteInArchive" [disabled]="data.isDocumentInArchive == false" [(ngModel)]="permissions.deleteInArchive" type="checkbox" tabindex="0">
                <label for="deleteInArchive" type="checkbox" tabindex="0" [attr.aria-label]="'aria-labels.checkbox' | translate"></label>
            </div>
            <span *ngIf="data.isDocumentInArchive == false" [attr.aria-label]="'aria-labels.student_deleted_document' | translate" tabindex="0">
                Student has deleted this document from our archives
            </span>
        </div>

        <!-- <div>
            <div>
                <label [ngClass]="{ disabled: data.sourcesAreDeleted }" for="" tabindex="0" [attr.aria-label]="'aria-labels.give_student_permission_document_sources' | translate">{{ 'report.give_student_permission_to_delete_document_sources' | translate }}</label>

                <input  name="deleteSources" id="deleteSources" [disabled]="data.sourcesAreDeleted" [(ngModel)]="permissions.deleteSources"  type="checkbox" tabindex="0">
                <label for="deleteSources" tabindex="0" [attr.aria-label]="'aria-labels.checkbox' | translate"></label>
            </div>
            <span *ngIf="data.sourcesAreDeleted" [attr.aria-label]="'aria-labels.student_deleted_sources' | translate" tabindex="0">
                {{ 'report.student_has_deleted_sources' | translate }} {{ 'report.for_this_document' | translate }}
            </span>
        </div> -->
        <div>
            <div>
                <label for="" tabindex="0" [attr.aria-label]="'aria-labels.give_student_permission_everything' | translate">{{ 'report.give_student_permission_to_delete_everything' | translate }}</label>
                <input name="deleteEverything" id="deleteEverything" [(ngModel)]="permissions.deleteEverything" (change)="deleteEverything()" type="checkbox" tabindex="0">
                <label for="deleteEverything" tabindex="0" [attr.aria-label]="'aria-labels.checkbox' | translate"></label>
            </div>
        </div>
    </div>

    <div class="submitnBtn">
        <button class="cancel-btn" (click)="close()" mat-dialog-close>{{ 'general.cancel' | translate }}</button>
        <button class="submit-btn" (click)="submit()">{{ 'general.submit' | translate }}</button>
    </div>
</div>
