// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Actions
import * as insProfessorsActions from '../state/action/institutional-professors-statistics.actions';
import * as facultyActions from '../../../../../state/faculty/action/faculty.actions';
import * as filtersActions from '../../../../../../administrator-components/state/filters/action/filters.actions';

// *** Selectors
import { getInstitutionalProfessorsStatisticsDetails } from '../state/selector/institutional-professors-statistics.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../../administrator-components/state/filters/selector/filters.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';

@Component({
   selector: 'app-institutional-professors-statistics',
   templateUrl: './institutional-professors-statistics.component.html',
   styleUrls: ['./institutional-professors-statistics.component.scss'],
})
export class InstitutionalProfessorsStatisticsComponent
   implements OnInit, OnDestroy
{
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter submissions of assignments within specific department.
    */
   departmentId = '-1';
   /**
    * Variable used to filter professors by name
    */
   professorName: string;
   /**
    * Variable used to filter professors buy subject
    */
   professorSubject: any;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variabel used to store Institutional professors
    */
   professors;
   /**
    * Variable used to store professor id that used to expand specific row on HTML
    */
   professorId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   callFiltersState = true;

   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store professors total number without pagination
    */
   professorsNumber;
   /**
    * Variable used to call store for statistics of professors
    */
   statistics$;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   getFiltersStateDetails$: any;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router
   ) {}
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.faculties$.unsubscribe();
      this.statistics$.unsubscribe();
   }

   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.professorsFilters.faculty != null) {
               this.facultyId = data.professorsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.departments = data.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.professorsFilters.subject != null) {
               this.professorSubject = data.professorsFilters.subject;
            }
            if (data.professorsFilters.department != null) {
               this.departmentId = data.professorsFilters.department;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         insProfessorsActions.loadInstitutionalProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.statistics$ = this.store
         .select(getInstitutionalProfessorsStatisticsDetails)
         .subscribe((data) => {
            if (data.insProfessorsStatistics !== null || data.professorsNumber !== null) {
               this.professors = data.insProfessorsStatistics;
               this.professorsNumber = data.professorsNumber;
            }
         });
   }
   /**
    * Function is used to filter by Title
    */
   filterWithTimeout() {
      
      this.store.dispatch(
         filtersActions.professorsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.professorSubject,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         // this.store.dispatch(
         //    insProfessorsActions.loadInstitutionalProfessorsStatistics({
         //       page: this.page,
         //       faculty: this.facultyId == '-1' ? undefined : this.facultyId,
         //       department:
         //          this.departmentId == '-1' ? undefined : this.departmentId,
         //       name: this.professorName,
         //       subject: this.professorSubject,
         //    })
         // );
         this.changePage(1)
      }, time);
   }
   /**
    * Function is used to filter by Faculty
    */
   selectedFaculty() {
      this.departmentId = '-1';
      this.store.dispatch(
         filtersActions.professorsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.professorSubject,
         })
      );
      this.page = 1;
      this.store.dispatch(
         insProfessorsActions.loadInstitutionalProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter by Department
    */
   selectedDepartment() {
      this.callFiltersState = false;
      this.store.dispatch(
         filtersActions.professorsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.professorSubject,
         })
      );
      this.store.dispatch(
         insProfessorsActions.loadInstitutionalProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         insProfessorsActions.loadInstitutionalProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );
   }
   /**
    * Function is used to exapnd information
    * @param professorId
    */
   expand(professorId) {
      this.professorId = professorId;
      if (this.expandInfo !== professorId) {
         this.expandInfo = professorId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used when clicked on number of thesis of professor to redirect to thesis with filter activated to clicked professor.
    * @param professorId
    */
   thesisFilter(professorId) {
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            professor: professorId,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/submissions/thesis');
   }

      /**
    * Function is used when clicked on number of submissions of professor to redirect to submissions page with filter activated to clicked professor.
    * @param professorId
    */
       submissionsFilter(professorId) {
         this.store.dispatch(
            filtersActions.professorSubmissionsFilters({
               professor: professorId,
            })
         );
         this.router.navigateByUrl('/administrator/statistics/submissions/professors-submissions');
      }

   /**
    * Function is used when clicked on number of subjects of professor to redirect to subjects with filter activated to clicked professor.
    * @param professorId
    */
   subjectsFilter(professorId) {
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: professorId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/subjects'
      );
   }
   /**
    * Function is used when clicked on number of assignments of professor to redirect to assigments with filter activated to clicked professor.
    * @param professorId
    */
   assignmentFilter(professorId) {
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: professorId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/assignments'
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.professorSubject = undefined;
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professorName = undefined;
      this.page = 1;
      this.store.dispatch(
         filtersActions.professorsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.professorSubject,
         })
      );
      this.store.dispatch(
         insProfessorsActions.loadInstitutionalProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );
   }
}
