<div class="d-flex justify-flex-end">
   <div class="ml-2">
      <div class="dropdown">
         <button class="dropbtn">
            <i class="fa fa-download"></i> {{ 'general.export_data' | translate }}
         </button>
         <div class="dropdown-content">
            <a (click)="exportToExcel()">{{ 'general.export_as_excel' | translate }} (.xlsx)</a>
            <a (click)="exportToPDF()">{{ 'general.export_as_pdf' | translate }}</a>
            <a (click)="exportToCSV()">{{ 'general.export_as_csv' | translate }}</a>
         </div>
      </div>
   </div>

   <div class="download_reports">
    <div *ngIf="!anyAssignmentsSelected()" class="tooltipAccess">
      <p>Please select an assignment first!</p>
    </div>
    <div class="ml-2"  [ngClass]="{ disabled: !anyAssignmentsSelected() }">
       <div class="dropdown">
         <button class="dropbtn">
           <i class="fa fa-download"></i> {{ 'general.download_reports' | translate }}
         </button>
         <div class="dropdown-content">
           <div *ngFor="let report of reports" class="dropdown-item" (click)="toggleSelection(report)">
             <input type="checkbox" [checked]="report.selected" />
             <label>{{ report.name }}</label>
           </div>
           <button class="download-all-btn" (click)="downloadAll()" [disabled]="!anyReportsSelected()" [ngClass]="{ disabled: !anyReportsSelected() }">{{ 'general.download' | translate }}</button>
         </div>
       </div>
    </div>
   </div>
</div>
<br>
<div class="documents-2 shadow white-bg">
   <div *ngIf="
         !(
            this.totalAssignments == 0 &&
            assignmentName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            subjectName == undefined &&
            professor == '-1'
         )
      " class="filtersContainer">
      <h4>{{ 'general.overview' | translate }}</h4>
      <div class="selectors">
         <!-- <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_institution' | translate }}
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">{{ faculty.name }}</option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">{{ 'general.select_institution_first' | translate }}</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_department' }}
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div> -->
         <div [ngClass]="{
            'selectedOptionBorder': professor != '-1'}">
            <select [(ngModel)]="professor" (change)="filterByProfessor()">
               <option value="-1" disabled>{{ 'app.filter_by_educator' | translate }}</option>
               <ng-container *ngFor="let professor of this.professors">
                  <option value="{{ professor.id }}">
                     {{ professor.name }}
                  </option>
               </ng-container>

               <option [disabled]="professor == '-1'">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div [ngClass]="{
            'inputBorder': subjectName != ''}">
            <input [placeholder]="'placeholders.search_by_subject' | translate" [(ngModel)]="subjectName"
               (ngModelChange)="filterWithTimeout()" type="text" name="searchByName" />
         </div>
         <div [ngClass]="{
            'inputBorder': assignmentName != ''}">
            <input [placeholder]="'placeholders.search_by_name' | translate" [(ngModel)]="assignmentName"
               (ngModelChange)="filterWithTimeout()" type="text" name="searchByName" />
         </div>
         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </div>
   <section class="select_all_checkbox">
     <input type="checkbox" name="select_all" id="select_all" [checked]="allSelected" (change)="toggleSelectAll($event)">
     <label for="select_all">{{ 'new_entries.select_all' | translate }}</label>
   </section>
   <div *ngIf="this.totalAssignments != 0" class="tableWithExpandedInfoAssignments tablewithRatios" #contentToCapture>
      <ng-container *ngFor="
            let assignment of this.assignments
               | paginate
                  : {
                       itemsPerPage: 10,
                       currentPage: this.page,
                       totalItems: this.totalAssignments
                    };
            let i = index
         ">
         <div>
            <div>
               <section class="d-flex">
                  <div (click)="toggleAssignmentSelection(assignment.assignment.id)">
                     <input type="checkbox" class="custom-checkbox" [checked]="assignment.assignment.selected" />
                  </div>
                  <h4 class="blueText bold">{{ assignment.assignment.name}}</h4>
               </section>
               <div class="container_data">
                  <div>
                     <span>{{ 'general.subject' | translate }}</span>
                     <span>{{ 'general.educator' | translate }}</span>
                     <!-- <span>{{ 'app.institution' | translate }}</span>
                     <span>{{ 'general.department' | translate }}</span> -->
                     <span>{{ 'general.start_date' | translate }}</span>
                     <span>{{ 'general.end_date' | translate }}</span>
                     <span>{{ 'general.submissions' | translate }}</span>
                     <span>{{ 'general.avg_of_document_language' | translate }}</span>
                     <span>{{ 'general.avg_of_translated_language' | translate }}</span>
                  </div>
                  <div>
                     <span class="bold">{{
                        assignment.assignment.Course.title
                        }}</span>
                     <span class="bold">{{
                        assignment.assignment.user.name
                        }}</span>
                     <!-- <span class="bold">{{
                        assignment.assignment.user.Department.Faculty.name
                     }}</span>
                     <span class="bold">{{
                        assignment.assignment.user.Department.name
                     }}</span> -->
                     <span class="bold">{{
                        assignment.assignment.startDate.split('T')[0]
                        }}</span>
                     <span class="bold">{{
                        assignment.assignment.endDate
                        .split('T')[0]
                        .includes('2700-01-01') ||
                        assignment.assignment.endDate
                        .split('T')[0]
                        .includes('2699')
                        ? ('placeholders.never' | translate)
                        : assignment.assignment.endDate.split('T')[0]
                        }}</span>
                     <span (click)="filterAssignments(assignment.assignment.name)" class="bold clickable">{{
                        assignment.submissions._count }}</span>
                     <span class="bold" [ngClass]="{
                      'red-border': assignment.submissions._avg.originalPercentage > 20,
                      'green-border':
                      assignment.submissions._avg.originalPercentage > 0 &&
                      assignment.submissions._avg.originalPercentage < 5,
                      'orange-border':
                      assignment.submissions._avg.originalPercentage >= 5 &&
                      assignment.submissions._avg.originalPercentage <= 20,
                      'gray-border': assignment.submissions._avg.originalPercentage === null || assignment.submissions._avg.originalPercentage === 0
                    }">
                        {{
                        assignment.submissions._avg.originalPercentage === null
                        ? '-'
                        : assignment.submissions._avg.originalPercentage === 0
                        ? 0
                        : assignment.submissions._avg.originalPercentage?.toFixed(2) + '%'
                        }}
                     </span>
                     <span class="bold" [ngClass]="{
                      'red-border': assignment.submissions._avg.translatedPercentage > 20,
                      'green-border':
                      assignment.submissions._avg.translatedPercentage > 0 &&
                      assignment.submissions._avg.translatedPercentage < 5,
                      'orange-border':
                      assignment.submissions._avg.translatedPercentage >= 5 &&
                      assignment.submissions._avg.translatedPercentage <= 20,
                      'gray-border': assignment.submissions._avg.translatedPercentage === null || assignment.submissions._avg.translatedPercentage === 0
                    }">
                        {{
                        assignment.submissions._avg.translatedPercentage === null
                        ? '-'
                        : assignment.submissions._avg.translatedPercentage === 0
                        ? 0
                        : assignment.submissions._avg.translatedPercentage?.toFixed(2) + '%'
                        }}
                     </span>
                  </div>
               </div>
               <a *ngIf="expandInfo !== i" class="blueText underline vold" (click)="expand(i)">{{ 'general.expand' |
                  translate }}</a>
               <a *ngIf="expandInfo === i" class="blueText underline vold" (click)="expand(i)">{{ 'general.collapse' |
                  translate }}</a>
            </div>
            <div [ngClass]="{ expandedInfo: expandInfo == i }" class="moreInfo" style="grid-template: none !important">
               <div class="details">
                  <span>
                     {{ 'app.most_used_language' | translate }}:
                     <b>{{
                        setLanguageName(
                        assignment.language ? assignment.language[0] : '-'
                        )
                        }}</b>
                     <!-- <b>{{ assignment.language === undefined
                     ? '-'
                     : assignment.language[0] }}</b> -->
                  </span>
                  <span>
                     {{ 'general.students' | translate }}:
                     <b>{{ assignment.students }}</b>
                  </span>
                  <span>
                     {{ 'report.high_similarity_submissions' | translate }}:
                     <b>{{ assignment.highSimilaritySubmissions }}</b></span>
                  <span>
                     {{ 'report.medium_similarity_submissions' | translate }}:
                     <b>{{ assignment.mediumSimilaritySubmissions }}</b></span>
                  <span>
                     {{ 'report.low_similarity_submissions' | translate }}:
                     <b>{{ assignment.lowSimilaritySubmissions }}</b></span>
               </div>
               <div class="ratios">{{ 'app.ratios' | translate }}</div>
               <div class="details">
                  <span>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' |
                     translate }}:
                     <b>
                        {{ assignment.students }} :
                        {{ assignment.submissions._count }}</b></span>
               </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div class="zeroState" *ngIf="
         this.totalAssignments == 0 &&
         (assignmentName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            subjectName != undefined ||
            professor != '-1')
      ">
      {{ 'new_entries.no_assignments_found' | translate }}
   </div>
   <pagination-controls *ngIf="this.totalAssignments != 0" class="num" (pageChange)="changePage($event)"
      responsive="true" previousLabel="" nextLabel=""></pagination-controls>
   <div class="zero-state" *ngIf="
         this.totalAssignments == 0 &&
         assignmentName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         subjectName == undefined &&
         professor == '-1'
      ">
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold">
         {{ 'new_entries.there_are_no_assignments_creared_by_your_educators' | translate }}
      </h5>
   </div>
</div>
