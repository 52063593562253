import { AuthService } from './../../services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as authActions from '../../authModule/state/actions/authentication.actions';
/**
 * Routing effect used to route user in specific routes based on triggered actions.
 */
@Injectable()
export class RouteEffects {
   /**
    * Effect Constructor.
    * @param actions$
    * @param router
    * @param authService
    */
   constructor(
      private actions$: Actions,
      private router: Router,
      private authService: AuthService
   ) {}
   /**
    * Effect used to redirect user on dashboard based on user role.
    */
   goDashboard$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(
               authActions.loginSuccess,
               authActions.googleLoginSuccess,
               authActions.linkedInLoginSuccess,
               authActions.signUpSuccess,
               authActions.LTILoginSuccess
            ),
            tap((data) => {
               if (data.user.roleId == 1) {
                  this.router.navigate(['admin/dashboard']);
               } else if (data.user.roleId == 2) {
                  if(data.user.lastLogin){
                     this.router.navigate(['individual/dashboard']);
                  } else {
                     this.router.navigate(['individual/detector']);
                  }

               } else if (data.user.roleId == 3 || data.user.roleId == 11) {
                  this.router.navigate(['administrator']);
               } else if (data.user.roleId == 4 || data.user.roleId == 10) {
                  this.router.navigate(['professor']);
               } else if (data.user.roleId == 6) {
                  this.router.navigate(['vleresues']);
               } else if (data.user.roleId == 5 || data.user.roleId == 9) {
                  if(data.user.lastLogin) {
                     this.router.navigate(['student']);
                  } else {
                     this.router.navigate(['student/register']);
                  }

               }
            })
         ),
      { dispatch: false }
   );
}
