<!-- <div class="text-input">
   <main>
      <h4>{{ 'individual.upload.text_input' | translate }}</h4>
      <h5>{{ 'individual.upload.document_title' | translate }}</h5>
      <input type="text" />
      <h5>{{ 'individual.upload.insert_text' | translate }}</h5>
      <textarea name="" id="" cols="30" rows="10"></textarea>
      <div class="center">
         <a class="submit" href="">{{ 'general.submit' | translate }}</a>
      </div>
      <h5>{{ 'other.coming_soon' | translate }}</h5>
   </main>
</div> -->

<ng-container *ngIf="this.uploaded == 0">
  <div class="upload">
    <main>
      <!-- <p>{{ 'app.upload.drafts_uploaded' | translate }}.</p> -->
      <!-- <p>{{ 'app.upload.click_on_the_select_file_button_and_choose_document' | translate }}.</p> -->
      <div>

        <ngx-file-drop class="dropzone" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div (click)="openFileSelector()" class="contentOnFileDrop">
              <img src="../../../../assets/report_v2/upload_v2.svg" alt="" />
              <h6><a class="clickable blue underline">{{ 'new_entries.click_to_upload' | translate }}</a> {{ 'login.or' | translate }} {{ 'app.upload.drag_files' | translate }}</h6>
            </div>
          </ng-template>
        </ngx-file-drop>

      </div>
      <!-- <button class="submit" type="button" (click)="openFileSelector()">{{ 'app.upload.select_files' | translate }}</button> -->
    </main>
  </div>

</ng-container>
<ng-container *ngIf="this.uploaded == 1">
  <div class="file-uploads">
    <main>
      <div>
        <form [formGroup]="uploadForm">

          <a class="more_info" href="https://support.inspera.com/hc/en-us/articles/20903232283421-Educator-Upload-Settings-Navigation" target="_blank">{{ 'new_entries.get_more_info' | translate }}</a>

          <h5 class="main_heading">{{ 'new_entries.submission_overview' | translate }}</h5>

          <h5 class="terniary_heading">{{ 'general.name_and_surname' | translate }}</h5>
          <input type="text" formControlName="fullName" />
          <div *ngIf="form.fullName.invalid && form.fullName.touched" class="invalid">
            <div *ngIf="form.fullName.errors.required">
              {{ 'general.full_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
          </div>
          <h5 class="terniary_heading">{{ 'individual.upload.date_of_upload' | translate }}</h5>
          <input formControlName="dateOfUpload" disabled type="text" />

          <hr>

          <h5 class="main_heading">{{ 'new_entries.analysis_settings' | translate }}</h5>
          <h5 class="secondary_heading">{{ 'new_entries.customize_language_translation_ai_detection' | translate }}.</h5>

          <!-- Select Original Language -->
          <!-- <h5 class="terniary_heading"
          *ngIf="
                this.user?.Institution?.settings?.source_percentage_threshold || (this.user?.roleId !== 5 && this.user?.roleId !== 9)">
          {{ 'individual.upload.document_language' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.original_language_similarity_check' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h5>

        <div
          *ngIf="this.user?.Institution?.settings?.source_percentage_threshold || (this.user?.roleId !== 5 && this.user?.roleId !== 9)"
          style="position: relative">
          <ng-container *ngIf="this.disableField === true">
            <select (change)="selectLanguage($event.target.value)" formControlName="documentLanguage" disabled
              class="border" name="" id="">
              <option value="-1" selected>{{ 'general.select_language' | translate }}</option>
              <option *ngFor="let language of languages" value="{{ language.value }}">
                {{ language.text }}
              </option>
              <span><img src="../../assets/images/Path 26.png" alt="" /></span>
            </select>
          </ng-container>
          <ng-container *ngIf="this.disableField === false">
            <select (change)="selectLanguage($event.target.value)" formControlName="documentLanguage" class="border"
              name="" id="" aria-placeholder="Select Language">
              <option value="-1" selected>{{ 'general.select_language' | translate }}</option>
              <option *ngFor="let language of languages" value="{{ language.value }}">
                {{ language.text }}
              </option>
              <span><img src="../../assets/images/Path 26.png" alt="" /></span>
            </select>
          </ng-container>
          <span><img src="../../assets/images/Path 26.png" alt="" /></span>
        </div> -->

        <div *ngIf="form.documentLanguage.invalid && languageSelected" class="invalid">
          <div *ngIf="form.documentLanguage.errors.required || form.documentLanguage.value == 'undefined'">
            {{ 'report.document_language' | translate }} {{ 'aria-labels.is_required' | translate }}*
          </div>
        </div>
        <div
          *ngIf="translatedLanguageEnabled.value && (!selectedTranslatedLanguages.value || selectedTranslatedLanguages.value.length === 0)"
          class="invalid">
          <div>
            {{ 'new_entries.translation_error_message' | translate }}
          </div>
        </div>
        <!-- Activate Translations -->

        <!-- *ngIf="this.user?.Institution?.settings?.translatedLanguage.length > 0 || ( this.user.roleId !== 5 && this.user.roleId !== 9)" -->
        <div
        *ngIf="((this.user.roleId === 10 || (this.user.roleId === 9 && this.precheck)) && this.user?.Institution?.settings?.translatedLanguage !== null)"
        style="position: relative">
        <!-- <ng-container *ngIf="this.disableField === true">
                  <select
                     (change)="selectTranslatedLanguage($event.target.value)"
                     formControlName="documentTranslatedLanguage"
                     class="border"
                     disabled
                     name=""
                     id=""
                  >
                     <option value="-1" selected>{{ 'general.none' | translate }}</option>
                     <option
                        *ngFor="let language of languages"
                        value="{{ language.value }}"
                     >
                        {{ language.text }}
                     </option>
                     <span
                        ><img src="../../assets/images/Path 26.png" alt=""
                     /></span>
                  </select>
               </ng-container> -->

        <!-- <ng-container *ngIf="this.disableField === false">
                  <select
                     (change)="selectTranslatedLanguage($event.target.value)"
                     formControlName="documentTranslatedLanguage"
                     class="border"
                     name=""
                     id=""
                  >
                     <option value="-1" selected>{{ 'general.none' | translate }}</option>
                     <option
                        *ngFor="let language of languages"
                        value="{{ language.value }}"
                     >
                        {{ language.text }}
                     </option>
                     <span
                        ><img src="../../assets/images/Path 26.png" alt=""
                     /></span>
                  </select>
               </ng-container> -->

        <!-- <span
                  ><img src="../../assets/images/Path 26.png" alt=""
               /></span> -->

        <div class="expandable_section" [formGroup]="uploadForm" style="padding-bottom: 2%;">
          <div class="header_section">
            <h5 class="terniary_heading inside_heading">
              {{ 'new_entries.translation_similarity' | translate }}:
              <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.translation_similarity_analysis' | translate">
                <img src="../../../../assets/report_v2/info.svg" alt="">
              </button>
            </h5>
            <label class="switch">
              <input type="checkbox" [formControl]="translatedLanguageEnabled" />
              <span class="slider round"></span>
            </label>
          </div>

          <div class="body_section" style="width: 100%;" *ngIf="translatedLanguageEnabled.value">
            <p class="sm_title">{{ 'new_entries.select_languages' | translate }}</p>
            <div class="language-selection">
              <mat-form-field>
                <mat-select [formControl]="selectedTranslatedLanguages" multiple>
                  <mat-option *ngFor="let language of languages" [value]="language.value"
                    [disabled]="selectedTranslatedLanguages.value.length >= 3 && !isSelected(language.value)">
                    {{ language.text }}
                  </mat-option>
                </mat-select>
                <mat-hint *ngIf="selectedTranslatedLanguages.value.length > 0">
                  {{ selectedTranslatedLanguages.value.length }}/3 selected
                </mat-hint>
              </mat-form-field>
            </div>
            <div *ngIf="selectedTranslatedLanguages.errors?.maxlength" class="invalid">
              <div>You can select up to 3 languages only.</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Contextual Similarity -->
      <div class="expandable_section" *ngIf="this.user?.Institution?.settings?.contextualSimilarity !== 0" >
        <div class="header_section">
          <h3 class="terniary_heading inside_heading">
            {{ 'new_entries.contextual_similarity' | translate }}
            <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.contextual_similarity_analysis' | translate ">
              <img src="../../../../assets/report_v2/info.svg" alt="">
            </button>
          </h3>
          <div>
            <label class="switch">
              <input formControlName="contextualSimilarity" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="inputs thresholdUI" *ngIf="uploadForm.controls.enableThresholds.value">
         <div>
          <p>Minimum threshhold:</p>
          <input formControlName="contextualSimilaritiesThreshold" type="number" min="50" max="100" placeholder="80" />
         </div>
        </div>
      </div>

      <!-- Activate AI -->
        <div class="expandable_section" *ngIf="this.user?.Institution?.settings?.ai !== 0">
          <div class="header_section">
            <h5 class="terniary_heading inside_heading"
            *ngIf="this.user?.Institution?.settings?.ai || (this.user.roleId !== 5 && this.user.roleId !== 9)">
            {{ 'general.enable_ai_prediction' | translate }}:
            <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.ai_generated_content_analysis' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
          </h5>
          <div
            *ngIf="this.user?.Institution?.settings?.ai || (this.user.roleId !== 5 && this.user.roleId !== 9)">
            <label class="switch">
              <input formControlName="aiDetection" type="checkbox" />

              <span class="slider round"></span>
            </label>
          </div>
          </div>
        </div>


        <h5 class="main_heading pointer" [ngClass]="{'rotate': advancedOptionsIndex}" (click)="advancedOptions()">{{ 'new_entries.additional_settings' | translate }}</h5>

        <div class="advanced_options_1" [ngClass]="{'open': advancedOptionsIndex}" *ngIf="this.user.institutionId === null || user.roleId == 4 || user.roleId == 10 || (user.roleId == 9 && this.precheck)">

          <h5 class="main_heading">{{ 'new_entries.detailed_analysis_settings' | translate }}</h5>
          <h5 class="secondary_heading">{{ 'new_entries.fine_tune_analysis' | translate }}</h5>

        <!-- Include URLs -->
        <h3 class="terniary_heading inside_heading">
          {{ 'new_entries.include_urls' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="Include additional sources in the originality checking process.">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <div class="expandable_section">
          <div class="textarea-container">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let url of includeUrlsArray; let i = index" [selectable]="true" [removable]="true"
                (removed)="removeUrl(i, 'include')" class="trim-chip">
                {{url}}
                <mat-icon matChipRemove (click)="removeUrl(i, 'include')">X</mat-icon>
              </mat-chip>
              <input (paste)="onPaste($event, 'include')" [placeholder]="('new_entries.new_url' | translate) + '...'" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                (keydown.enter)="addUrlOnEnter($event, 'include'); $event.preventDefault();" class="chip-input" />
            </mat-chip-list>
          </div>
        </div>

        <!-- Exclude URLs -->
        <h3 class="terniary_heading inside_heading">
          {{ 'new_entries.exclude_urls' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.exclude_specific_sources' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <div class="expandable_section">
          <div class="textarea-container">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let url of excludeUrlsArray; let i = index" [selectable]="true" [removable]="true"
                (removed)="removeUrl(i, 'exclude')" class="trim-chip">
                {{url}}
                <mat-icon matChipRemove (click)="removeUrl(i, 'exclude')">X</mat-icon>
              </mat-chip>
              <input (paste)="onPaste($event, 'exclude')" [placeholder]="('new_entries.new_url' | translate) + '...'" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                (keydown.enter)="addUrlOnEnter($event, 'exclude'); $event.preventDefault();" class="chip-input" />
            </mat-chip-list>
          </div>
        </div>

          <!-- Add to archive -->
          <div class="expandable_section" *ngIf="!this.precheck && enableArchive">
            <div class="header_section">
              <h5 class="terniary_heading inside_heading">
                {{ 'new_entries.add_document_to_archive' | translate }}:
              <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="{{ 'new_entries.adding_document_to_archive_warning' | translate }}">
              <img src="../../../../assets/report_v2/info.svg" alt="">
            </button>
            </h5>
            <div>
              <label class="switch">
                <input type="checkbox" id="add_to_archive" value="{{ archive }}" name="add_to_archive"
                [(ngModel)]="archive" (click)="archiveDocument()" [ngModelOptions]="{ standalone: true }" />
                <span class="slider round"></span>
              </label>
            </div>
            </div>
          </div>


          <div>
            <h4 class="terniary_heading">
              {{ 'general.choose_third_party_libraries' | translate }}
              <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.store_submissions_for_collusion_detection' | translate">
                <img src="../../../../assets/report_v2/info.svg" alt="">
              </button>
            </h4>
            <div class="box_section">
              <div *ngFor="let library of thirdPartyLib; let i = index">
                <section>
                  <input type="checkbox" id="library{{ i }}" [value]="library.description" [checked]="library.isChecked" name="library{{ i }}" (change)="onCheckboxChange($event)" />
                  <label for="library{{ i }}">{{ library.description }}</label>
                </section>
              </div>
            </div>
          </div>

          <div>
            <h4 class="terniary_heading">
              {{ 'general.choose_citation' | translate }}
              <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" matTooltip="{{ 'new_entries.adding_document_to_archive_warning' | translate }}">
                <img src="../../../../assets/report_v2/info.svg" alt="">
              </button>
            </h4>
            <div style="position: relative">
              <select (change)="selectCitationStyle($event)" [(ngModel)]="citationStyle" class="border" name=""
                id="" [ngModelOptions]="{ standalone: true }">
                <option value="" selected>
                  {{ 'new_entries.select_citation_style' | translate }}
                </option>
                <optgroup [label]="'new_entries.popular_formats' | translate">
                  <option value="AMA">{{ 'general.ama' | translate }}</option>
                  <option value="APA(6th edition)">
                    {{ 'other.apa_6th_edition' | translate }}
                  </option>
                  <option value="APA(7th edition)">
                    {{ 'other.apa_7th_edition' | translate }}
                  </option>
                  <option value="Harvard">{{ 'general.harvard' | translate }}</option>
                  <option value="IEEE">{{ 'general.ieee' | translate }}</option>
                  <option value="MLA 9th">MLA 9th</option>
                </optgroup>
                <optgroup [label]="'new_entries.others' | translate">
                  <option value="OSCOLA">{{ 'general.oscola' | translate }}</option>
                  <option value="MHRA(3rd edition)">
                    {{ 'other.mhra_third_edition' | translate }}
                  </option>
                  <option value="Vancouver">{{ 'general.vancouver' | translate }}</option>
                </optgroup>
              </select>
              <span class="chevron-icon"><img src="../../assets/images/Path 26.png" alt="" /></span>
            </div>
          </div>
        </div>


                  <!-- <h5 class="bold">
            {{ 'individual.upload.upload_form' | translate }}
          </h5> -->
          <div *ngIf="
                      form.documentTitle.invalid && form.documentTitle.touched
                   " class="invalid">
            <div *ngIf="form.documentTitle.errors.required">
              {{ 'app.upload.document_title' | translate }} {{ 'general.end_date' | translate }}*
            </div>
            <div *ngIf="form.documentTitle.errors.minlength">
              <div>{{ 'individual.upload.document_title_must_be_at_least_5' | translate }}*</div>
            </div>
            <div *ngIf="form.documentTitle.errors.maxlength">
              <div>
                Document title can't be more than 190 characters*
              </div>
            </div>
          </div>

          <h5 *ngIf="user.roleId == 2">{{ 'general.credits_to_be_charged' | translate }}:</h5>
          <input *ngIf="user.roleId == 2" formControlName="documentCredits" disabled type="text" />
          <h5 *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && thesisId                  ">
            {{ 'general.thesis' | translate }}
          </h5>
          <h5 *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && assignmentId">
            {{ 'general.assignment' | translate }}
          </h5>
          <!-- <h5
                   *ngIf="this.user.Institution.settings.source_percentage_threshold || (user.roleId !== 5 && user.roleId !== 9)"
                >
                   {{ 'individual.upload.translate_document' | translate }}
                </h5> -->
          <input *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && assignmentId" [value]="assignmentName"
            disabled type="text">
          <input *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && thesisId" [value]="thesisName" disabled
            type="text">

          <div class="center">
            <button
              *ngIf="!uploadForm.invalid && filesPreviewReady && !(translatedLanguageEnabled.value && (!selectedTranslatedLanguages.value || selectedTranslatedLanguages.value.length === 0))"
              [ngClass]="{ disable: uploadForm.invalid }" class="submit" (click)="createSubmission()">
              {{ 'general.submit' | translate }}
            </button>
          
            <button style="pointer-events: unset;"
              *ngIf="uploadForm.invalid || !filesPreviewReady || (translatedLanguageEnabled.value && (!selectedTranslatedLanguages.value || selectedTranslatedLanguages.value.length === 0))"
              [ngClass]="{ disable: uploadForm.invalid }" (click)="showFormMessages()" class="submit">
              {{ 'general.submit' | translate }}
            </button>
          </div>
        </form>
      </div>

      <div class="file files-preview">
        <ng-container *ngIf="filesPreviewReady">
          <div class="files" *ngFor="let file of filesProperties ; let i = index">
            <i class="far fa-file"></i>
            <div>
              <h6>{{ file.title }}</h6>
              <p>{{ file.fileSize }}</p>
            </div>
            <button>
              <i (click)="previewFile(file.previewSrc)" class="fas fa-eye"></i>
              <i class="far fa-times-circle" (click)="deleteFile(i)"></i>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="!filesPreviewReady">
          <div class="loadingFiles">
            <div class="processing">
              Loading files preview

              <mat-spinner></mat-spinner>
            </div>
          </div>
        </ng-container>
      </div>


    </main>
  </div>
</ng-container>
