<div class="cards">
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/6.Thesis_Color.png" alt=""
      />
      <div>
         Total Theses
         <span>
            {{
               thesisInformation?.bachelorThesis +
                  thesisInformation?.masterThesis +
                  thesisInformation?.phdThesis
            }}
         </span>
      </div>
   </div>
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/7.Thesis_Bachelor_Color.png" alt=""/>
      <div>
         Bachelor theses
         <span>{{ thesisInformation?.bachelorThesis || 0 }}</span>
      </div>
   </div>
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/8.Thesis_Master_Color.png" alt=""/>
      <div>
         Master theses
         <span>{{ thesisInformation?.masterThesis || 0 }}</span>
      </div>
   </div>
   <div class="shadowBorder">
      <img src="../../../assets/images/newIcons/9.Thesis_PHD_Color.png" alt=""/>
      <div>
         {{ 'app.phd_thesis' | translate }}
         <span> {{ thesisInformation?.phdThesis || 0 }}</span>
      </div>
   </div>
</div>

<h3
   *ngIf="
      !(
         thesisInformation?.totalCreatedThesis == 0 &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         name == undefined &&
         level == '-1'
      )
   "
   class="filtersContainer"
>
   <h4>{{ 'general.theses' | translate }}</h4>
   <div class="selectors">
      <div>
         <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
            <option value="-1" selected disabled>
               {{ 'app.filter_by_institution' | translate }}
            </option>
            <ng-container *ngFor="let faculty of this.faculties">
               <option value="{{ faculty.id }}">
                  {{ faculty.name }}
               </option>
            </ng-container>
            <option value="">{{ 'general.all' | translate }}</option>
         </select>
      </div>
      <div>
         <select
            disabled
            *ngIf="facultyId == '-1' || facultyId == ''"
            name=""
            id=""
         >
            <option value="">{{ 'general.select_institution_first' | translate }}</option>
         </select>
         <select
            *ngIf="facultyId !== '-1' && facultyId !== ''"
            [(ngModel)]="departmentId"
            (change)="filterThesis()"
         >
            <option value="-1" selected disabled>
               {{ 'app.filter_by_department' | translate }}
            </option>
            <ng-container *ngFor="let department of this.departments">
               <option value="{{ department.id }}">
                  {{ department.name }}
               </option>
            </ng-container>
            <option value="">{{ 'general.all' | translate }}</option>
         </select>
         <select [(ngModel)]="level" (change)="filterThesis()">
            <option value="-1" selected disabled>{{ 'app.filter_by_level' | translate }}</option>
            <option value="bachelor">{{ 'general.bachelor' | translate }}</option>
            <option value="master">{{ 'general.master' | translate }}</option>
            <option value="phd">{{ 'general.phd' | translate }}</option>
            <option value="">{{ 'general.all' | translate }}</option>
         </select>
      </div>
      <div>
         <input
            [placeholder]="'placeholders.search_by_name' | translate"
            [(ngModel)]="name"
            (ngModelChange)="titleFilter()"
            type="text"
            name="searchByName"
         />
      </div>
      <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
   </div>
</h3>

<main>
   <div
      *ngIf="thesisInformation?.totalCreatedThesis != 0"
      class="scroll white-bg"
   >
      <table>
         <tr>
            <th class="bold">{{ 'general.table.name' | translate }}</th>
            <th class="bold">{{ 'app.institution' | translate }}</th>
            <th class="bold">{{ 'general.department' | translate }}</th>
            <th class="bold">{{ 'general.student' | translate }}</th>
            <th class="bold">{{ 'general.educator' | translate }}</th>
            <th class="bold">{{ 'general.level' | translate }}</th>
         </tr>
         <ng-container
            *ngFor="
               let thesis of thesisInformation?.createdThesis
                  | paginate
                     : {
                          itemsPerPage: 5,
                          currentPage: page,
                          totalItems: thesisInformation?.totalCreatedThesis
                       }
            "
         >
            <tr class="shadow">
               <td>
                  {{ thesis.name }}
               </td>

               <td>
                  {{ thesis.professor.Faculty.name }}
               </td>

               <td>
                  {{ thesis.professor.Department.name }}
               </td>

               <td>
                     {{ thesis.student.name }}
               </td>

               <td>
                     {{ thesis.student.name }}
               </td>
               <td>
                     {{ thesis.level }}
               </td>
            </tr>
         </ng-container>
      </table>
      <pagination-controls
         class="num"
         (pageChange)="changePage($event)"
         responsive="true"
         previousLabel=""
         nextLabel=""
      ></pagination-controls>
   </div>
   <div
      class="zero-state"
      *ngIf="
         thesisInformation?.totalCreatedThesis == 0 &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         name == undefined &&
         level == '-1'
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold">There are no thesis submitted yet.</h5>
   </div>
   <div
      class="zeroState"
      *ngIf="
         thesisInformation?.totalCreatedThesis == 0 &&
         (name != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            level != '-1')
      "
   >
      No thesis found
   </div>
</main>
