<div *ngIf="this.userRole == '1'" class="flex register-monitoruesi">
   <!-- <h4 class="bold">{{ 'general.profile' | translate }}</h4> -->
   <a routerLink="/admin/register" class="plus active upload-mobile"
      ><svg
         is="plus"
         xmlns="http://www.w3.org/2000/svg"
         width="22"
         height="66"
         viewBox="0 0 33 66"
      >
         <text
            id="_"
            data-name="+"
            transform="translate(0 52)"
            fill="#fff"
            font-size="54"

            font-weight="700"
         >
            <tspan x="0" y="0">+</tspan>
         </text>
      </svg>
      <h5>{{ 'general.register' | translate }}</h5>
   </a>
</div>
<section>
   <!-- <h4 class="bold w-87">
      {{ 'general.profile' | translate }}
   </h4> -->
   <div>
      <div class="links">
         <a routerLink="personal-information" routerLinkActive="active">{{
            'profile.account_details' | translate
         }}</a>
         <a *ngIf="isLoggedInFromApp" routerLink="settings" routerLinkActive="active"
            >{{ 'profile.password_settings' | translate }}
         </a>
         <!-- <a routerLink="billing-information" routerLinkActive="active"
        >{{ 'add_credits.billing_information' | translate }}</a
      > -->
         <a
            *ngIf="this.userRole == 2"
            routerLink="transaction"
            routerLinkActive="active"
            >{{ 'profile.transaction' | translate }}</a
         >
      </div>
      <router-outlet></router-outlet>
   </div>
</section>
