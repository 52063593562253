import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from "rxjs";
import { Source } from 'src/app/models/source';

export interface Manipulation {
  includedCharacters: { character: string; count: number }[];
  excludedCharacters: any[];
  characters: any[];
  isExcluded: boolean;
}

export interface Translated {
  lang: string;
  similarity: number;
  percentage: string;
  source: Source;
  isExcluded: boolean;
  comment:string;
  sentence: string;
  secondarySources: any;
}

export interface Data {
  submissionId: string,
  text: string;
  comment:string;
  sentence: string;
  source: Source;
  id: number;
  isOriginal: boolean;
  isTranslated: boolean;
  isManipulated: boolean;
  isExcluded: boolean;
  isAi: boolean;
  similarity: number;
  page: number;
  manipulations: Manipulation[];
  translated: Translated[];
  secondarySources: any;
}

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {
  }

  public newS3JsonSubject = new BehaviorSubject<string>('');
  public newS3JsonObject = this.newS3JsonSubject as Observable<string>;

  public filterSourcesSubject = new BehaviorSubject<number>(0);
  public filterSourcesObject = this.filterSourcesSubject as Observable<number>;

  public filterManipulationsSubject = new BehaviorSubject<number>(0);
  public filterManipulationsObject = this.filterManipulationsSubject as Observable<number>;

  public sentenceInformationSubject = new BehaviorSubject<Data | null>(null);
  public sentenceInformationObject = this.sentenceInformationSubject.asObservable();

  public selectedTransltaionCodeSubject = new BehaviorSubject<string | null>(null);
  public selectedTransltaionCodeObject = this.selectedTransltaionCodeSubject.asObservable();

  public documentPercentagesSubject = new BehaviorSubject<{ originalPercentage: number, overallTransltedPercentage: number, originalPercentageWithoutQuotes: number, overallTransltedPercentageWithoutQuotes: number }>
  ({ originalPercentage: 0, overallTransltedPercentage: 0, originalPercentageWithoutQuotes: 0, overallTransltedPercentageWithoutQuotes: 0 });
  public documentPercentagesObject = this.documentPercentagesSubject as Observable<object>;

  sentenceInformation(data: Data, type: string) {
    this.sentenceInformationSubject.next(data);
  }

  public selectLanguageSubject = new BehaviorSubject<{
    language: string;
    type: string;
  }>({
    language: 'English',
    type: 'Original',
  });

  public selectLanguageObject = this.selectLanguageSubject as Observable<{
    language: string;
    type: string;
  }>;

  public selectAnalysisTypeSubject = new BehaviorSubject<string>('none');
  public selectAnalysisTypeObject = this.selectAnalysisTypeSubject as Observable<string>;

  public selectedSourcesSubject = new BehaviorSubject<number[]>([])
  public selectedSourcesObject = this.selectedSourcesSubject as Observable<number[]>

  public toggleQuotesSubject = new BehaviorSubject<boolean>(true);
  public toggleQuotesObject = this.toggleQuotesSubject as Observable<boolean>;

  public selectManipulationTypeSubject = new BehaviorSubject<string>('');
  public selectManipulationTypeObject = this.selectManipulationTypeSubject as Observable<string>;

  public currentTranslatedLanguageSubject = new BehaviorSubject<string>('');
  public currentTranslatedLanguageObject = this.currentTranslatedLanguageSubject as Observable<string>;

  submissionDetails(submissionId: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/submission/${submissionId}/report/users`
    );
  }

  documentStatus(submissionId: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/document/${submissionId}/checkStatus`
    );
  }

  getS3Json(url: string) {
    return this.http.get(url).pipe();
  }

  excludeSource(
    sourceId: string,
    submissionId: string,
    originalLanguage: boolean,
    exclude: boolean,
    excludeUrlId: string,
    excludeUrlPercentage: number,
    language?: string
  ) {
    return this.http
      .request(
        'delete',
        `${environment.apiUrl}/submission/${submissionId}/excludeSource/${sourceId}`,
        {
          body: {
            originalLanguage,
            exclude,
            excludeUrlId,
            excludeUrlPercentage: excludeUrlPercentage,
            language
          },
        }
      )
      .pipe();
  }

  includeSource(
    sourceId: string,
    submissionId: string,
    originalLanguage: boolean,
    includeUrlPercentage: number,
    language?: string
  ) {
    return this.http
      .request(
        'put',
        `${environment.apiUrl}/submission/${submissionId}/includeSource/${sourceId}`,
        {
          body: {
            originalLanguage,
            includeUrlPercentage,
            language
          },
        }
      )
      .pipe();
  }


  downloadOriginalDocument(submissionId: string) {
    return this.http
      .get(`${environment.apiUrl}/document/${submissionId}`)
      .pipe();
  }

  downloadReport(submissionId: string, reportType: string) {
    return this.http
      .get(
        `${environment.apiUrl}/submission/${submissionId}/offlineReport?reportType=${reportType}`)
      .pipe()
  }

  /**
  * Method used to download ai offline pdf report.
  * @param submissionId
  */
  downloadAiReport(submissionId: string) {
    return this.http
        .get(
          `${environment.apiUrl}/submission/${submissionId}/aiOfflineReport?reportType='test'`,
          {}
        )
        .pipe();
  }

  excludeSentence(
      submissionId: string,
      sentenceId: number,
      originalLanguage: boolean,
      comment?: string,
      language?: string
   ) {
      return this.http
         .request(
            'delete',
            `${environment.apiUrl}/submission/${submissionId}/sentences/${sentenceId}`,
            { body: { originalLanguage, comment, language } }
         )
         .pipe();
   }

  includeSentence(
    submissionId: string,
    sentenceId: number,
    originalLanguage: boolean,
    comment?: string,
    language?: string
  ) {
    return this.http
      .request(
        'put',
        `${environment.apiUrl}/submission/${submissionId}/sentences/${sentenceId}`,
        {body: {originalLanguage, language}}
      )
      .pipe();
  }

  changeSentenceSource(
    sourceId: string,
    sentenceId: number,
    submissionId: string,
    originalLanguage: boolean,
    language?: string
  ) {
    return this.http.post<any>(
      `${environment.apiUrl}/submission/${submissionId}/source/${sourceId}`,
      {originalLanguage, sentenceId, language}
    );
  }

  sentContentRequest(
    sourceId: string,
    submissionId: string,
    description: string,
    index
  ) {
    return this.http.post<any>(
      `${environment.apiUrl}/submission/${submissionId}/request/content`,
      {sourceId, description, index}
    );
  }

  getContentRequest() {
    return this.http.get<any>(
      `${environment.apiUrl}/requests/content`
    );
  }

  changeContentRequestAccess(requestId,status) {
    return this.http.post<any>(
      `${environment.apiUrl}/request/content/${requestId}`,{status}
    );
  }

  updateComment(
    comment: string,
    submissionId: string,
    sentenceId: number,
    originalLanguage: boolean,
    langCode?:string
  ) {
    return this.http
      .put<any>(
        `${environment.apiUrl}/submission/${submissionId}/sentences/${sentenceId}/updateComment`,
        {originalLanguage, comment, langCode}).pipe()
  }

  submissionPreview(sourceId: string, submissionId: string, index: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/submission/${submissionId}/preview/${sourceId}`,
      { index }
    );
  }

  toggleReplacedCharacters(submissionId: string, charsToToggle: any, type: number) {
    return this.http
       .request(
          'put',
          `${environment.apiUrl}/submission/${submissionId}/toggle/1`,
          { body: { charsToToggle, exclude: type == 1 ? true : false } }
       )
       .pipe();
 }

 toggleWhiteCharacterSentences(submissionId: string, sentenceId: number, excludeSentence: boolean) {
  console.log('in service', submissionId, sentenceId, excludeSentence);

  return this.http
     .request(
        'put',
        `${environment.apiUrl}/submission/${submissionId}/toggle/2`,
        { body: { sentenceId, excludeSentence } }
     )
     .pipe();
}

 toggleManipulatedImages(submissionId: string, imageId: number, exclude: boolean) {
  console.log(submissionId, imageId, exclude);

  return this.http
  .request(
     'put',
     `${environment.apiUrl}/submission/${submissionId}/toggle/3`,
     { body: { imageId, exclude } }
  )
  .pipe();
 }
}
