import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from '../../../services/submissions.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  constructor(
   private spinner: NgxSpinnerService,
   private SubmissionsService: SubmissionsService,
    public dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<any>,
    /**
     * Used to pass data in component through dialog
     */
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService) { }

  ngOnInit(): void {
  }

  deleteDocument(type){

   let title;
   let paragraph;

   let deleteText;
   if(type == 0 ){
      title = this.translate.instant('delete.all_the_sources_we_found_similarity_will_be_removed')

      deleteText = `<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.this_is_permanent')}</span>`,
      paragraph = this.translate.instant('delete.sources_deleted_successfully')
      if(this.data.sourcesAreDeleted){
         swal.fire(this.translate.instant('app.sources_of_this_document_are_already_deleted'), '', 'info');
         return true;
      }
   } else if (type == 1){
      title = this.translate.instant('delete.your_document_will_be_removed_from_our_archive');
      deleteText = `${this.translate.instant('delete.after_you_delete_this_document_in_our_archives_we_wont_be_able_to_detect_it')} <span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.this_is_permanent')}</span>`
      paragraph = this.translate.instant('delete.document_deleted_successfully_in_our_archive')
      if(this.data.isDocumentInArchive == false){
        let info: string = this.translate.instant('delete.this_document_is_already_deleted_from_our_archive').toString()
         swal.fire(info, '', 'info');
         return true;
      }

   } else if(type == 2) {
      title = this.translate.instant('delete.the_document_will_be_deleted_permanently');
      deleteText = `<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.this_is_permanent')}</span>`
      paragraph = this.translate.instant('delete.document_has_been_deleted')
   }
       swal.fire({
                     title: title,
                     showDenyButton: true,
                     confirmButtonText: this.translate.instant('general.yes'),
                     denyButtonText: this.translate.instant('general.no'),
                     icon: 'warning',
                     html: deleteText
                  })
                  .then((result) => {
                     if (result.isConfirmed) {
                        this.spinner.show(this.translate.instant('app.loading'));
                        this.SubmissionsService.deleteSubmissionInElastic(this.data.submissionId,type)
                        .pipe()
                        .subscribe(
                           (data: any) => {
                              this.spinner.hide(this.translate.instant('app.loading'));
                              swal.close()
                              swal.fire('', `${paragraph}`, 'info').then((result)=>{
                                 if(result.isConfirmed){
                                  if (this.data.role == 10 || this.data.role == 4) {
                                    window.location.replace(`${environment.currentUrl}professor/submissions`)
                                  } else {
                                    window.location.replace(`${environment.currentUrl}student/submissions`)
                                  }
                                 }
                              })
                           },
                           (error) => {
                              console.log('error', error);
                              this.spinner.hide(this.translate.instant('app.loading'));
                           }
                        );
                     }
                  });

 }

 close() {
   this.dialogRef.close();
 }
}
