import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from "src/app/super-admin/super-admin.service";
import { LanguageService } from "../report-v2/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss']
})

export class AdminSettingsComponent {
    initialFormValues: any;
    languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
      {
         text: 'Danish',
         value: 'da'
      },
      {
         text: 'Estonian',
         value: 'et'
      }
   ];
   translatedLanguageEnabled = new FormControl(false); // Using FormControl for the toggle
   selectedTranslatedLanguages = new FormControl([], [Validators.maxLength(3)]);
   archive: boolean = false;
   isFormChanged = false;
   selectedContentInfo: { text: string } | null = null;
   selectedContentValue: string = '';
   admin_settings = this.fb.group({
    documentTranslatedLanguage: this.selectedTranslatedLanguages,
    aiDetection: [false],
    submissionPreCheck: [false],
    contextualSimilarity: [false],
    enableThresholds: [false],
    exactMatchThreshold: [0, [Validators.max(100)]],
    alteredTextThreshold: [0, [Validators.max(100)]],
    contextualSimilaritiesThreshold: [80, [Validators.max(100), Validators.min(50)]],
    archive: [false],
    contentAccess: ["closed"],
    institutionsContentAccess: []
  });

    constructor(
      private fb: FormBuilder,
      private toastrService: ToastrService,
      private superAdminService: SuperAdminService,
      private languageService: LanguageService,
      private translate: TranslateService,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
      const storedLanguage = localStorage.getItem('websiteLanguage');
      const language = storedLanguage ? storedLanguage : 'en';

      this.translate.use(language);
      this.languageService.selectedLanguage$.subscribe((language: string) => {
        this.translate.use(language);
      });
      this.languages.sort((a, b) => a.text.localeCompare(b.text));
      this.admin_settings = this.fb.group({
        documentTranslatedLanguage: this.selectedTranslatedLanguages,
        aiDetection: [false],
        submissionPreCheck: [false],
        contextualSimilarity: [false],
        enableThresholds: [false],
        exactMatchThreshold: [0, [Validators.max(100)]],
        alteredTextThreshold: [0, [Validators.max(100)]],
        contextualSimilaritiesThreshold: [80, [Validators.max(100), Validators.min(50)]],
        archive: [false],
        contentAccess: ["closed"],
        institutionsContentAccess: []
      });

       // Retrieve data from local storage
       const storedData = localStorage.getItem('user');
       if (storedData) {
          const institutionData = JSON.parse(storedData);

          if (institutionData && institutionData.Institution && institutionData.Institution.settings) {
             const settings = institutionData.Institution.settings;

             // Prefill the form with retrieved data
             this.admin_settings.patchValue({
                documentTranslatedLanguage: settings.translatedLanguage || [],
                aiDetection: !!settings.ai,
                submissionPreCheck: !!settings.submission_pre_check,
                contextualSimilarity: !!settings.contextualSimilarity,
                enableThresholds: !!settings.thresholds,
                exactMatchThreshold: settings.thresholds?.exact_match || 0,
                alteredTextThreshold: settings.thresholds?.altered_text || 0,
                contextualSimilaritiesThreshold: settings.thresholds?.contextual_similarity || 80,
                archive: !!settings.archive,
                contentAccess: settings.contentAccess,
                institutionsContentAccess: settings.institutionsContentAccess
             });

             // Enable the translatedLanguage toggle if there are selected languages
             this.translatedLanguageEnabled.setValue(settings.translatedLanguage && settings.translatedLanguage.length > 0);
             this.selectedTranslatedLanguages.setValue(settings.translatedLanguage || []);
          }
       }

       // Store initial form values
       this.initialFormValues = this.admin_settings.getRawValue();

       this.admin_settings.valueChanges.subscribe(() => {
          this.checkIfFormChanged();
       });

       this.translatedLanguageEnabled.valueChanges.subscribe((enabled) => {
          if (!enabled) {
             this.selectedTranslatedLanguages.setValue([]);
          }
       });

       this.admin_settings.controls['contextualSimilarity'].valueChanges.subscribe((value) => {
        if (value) {
           this.admin_settings.controls['enableThresholds'].setValue(true);
        } else {
            this.admin_settings.controls['enableThresholds'].setValue(false);
        }
       })

       const initialContentAccess = this.admin_settings.controls['contentAccess'].value;
       this.selectedContentValue = initialContentAccess;

       this.admin_settings.controls['contentAccess'].valueChanges.subscribe((value) => {
        this.selectedContentValue = value;
      });
    }

    ngDoCheck() {
      if (localStorage.getItem('websiteLanguage') !== this.translate.currentLang) {
        this.translate.use(localStorage.getItem('websiteLanguage'));
        this.cdr.detectChanges();
      }
    }

    checkIfFormChanged() {
      const currentFormValues = this.admin_settings.getRawValue();

      // Compare current values with initial values to determine if the form has changed
      this.isFormChanged = JSON.stringify(currentFormValues) !== JSON.stringify(this.initialFormValues);
    }

    isSelected(language: string): boolean {
      return this.selectedTranslatedLanguages.value.includes(language);
   }

   get form() {
    return this.admin_settings.controls;
  }

  selectTranslatedLanguage(event) {
    this.selectedTranslatedLanguages = event.value;
    this.form.documentTranslatedLanguage.setValue(this.selectedTranslatedLanguages);
  }

  archiveDocument() {
    const currentValue = this.admin_settings.controls['archive'].value;
    this.admin_settings.controls['archive'].setValue(!currentValue);
 }

   async submitSettings() {

      await Swal.fire({
         title: 'Do you want to save the current settings?',
         icon: 'info',
         showCancelButton: true,
         confirmButtonText: 'Yes',
         cancelButtonText: 'No',
         confirmButtonColor: '#4dbd74',
         cancelButtonColor: '#f86c6b',
      }).then((result) => {
         if (result.isConfirmed) {

            if (this.admin_settings.valid) {
               const formValues = this.admin_settings.value;
               console.log(formValues.documentTranslatedLanguage, formValues?.documentTranslatedLanguage);

               const settings = {
                  ai: formValues.aiDetection ? 1 : 0,
                  archive: formValues.archive ? 1 : 0,
                  originalLanguage: formValues.documentTranslatedLanguage[0] || 'en',
                  translatedLanguage: formValues?.documentTranslatedLanguage.length ? formValues.documentTranslatedLanguage : null,
                  submission_pre_check: formValues.submissionPreCheck ? 1 : 0,
                  contextualSimilarity: formValues.contextualSimilarity || 0,
                  thresholds: {
                     exact_match: formValues.exactMatchThreshold || 0,
                     altered_text: formValues.alteredTextThreshold || 0,
                     contextual_similarity: formValues.contextualSimilaritiesThreshold || 0
                  },
                  contentAccess:formValues.contentAccess,
                  institutionsContentAccess: formValues.institutionsContentAccess
               };

               this.spinner.show();

               this.superAdminService.createInstitution(
                  settings,
                  undefined,
                  undefined,
                  undefined,
                  true
               ).subscribe(
                  response => {
                     this.toastrService.success('Institution created/updated successfully!');

                     // Update local storage with the new settings
                     const storedData = localStorage.getItem('user');
                     if (storedData) {
                        const institutionData = JSON.parse(storedData);

                        // Update the settings in the local storage
                        institutionData.Institution.settings = {
                           ...institutionData.Institution.settings,
                           ...settings,
                        };

                        console.log('institutionData', institutionData)

                        localStorage.setItem('user', JSON.stringify(institutionData));
                     }

                     // Reset the initial form values
                     this.initialFormValues = this.admin_settings.getRawValue();
                     this.isFormChanged = false;
                     this.spinner.hide();
                  },
                  error => {
                     this.spinner.hide();
                     this.toastrService.error('There was an error processing your request.');
                     console.error(error);
                  }
               );
            } else {
               console.log('Form is invalid, please correct the errors.');
            }
         } else if (result.isDenied) {
            this.toastrService.info('Settings not saved!', 'Info');
         }
      });
   }

 cancel() {
    this.admin_settings.reset();
    this.admin_settings.setValue(this.initialFormValues);
    this.isFormChanged = false;
 }

}
