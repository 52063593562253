<div *ngIf="showSubjects" class="cloneSubjects">
   <h4>{{ 'app.subject.clone_your_subject' | translate }}</h4>
   <div  class="subjectsFilters">
     <a (click)="changeFilters()">{{ 'app.subject.change_selections' | translate }}</a>
     <div class="choose-generation">
      <span>{{ 'app.subject.select_academic_year_to_renew_subjects' | translate }}</span>
      <select  [(ngModel)]="generationToChange"
      class="border white-bg select-adm"
   >
      <option selected disabled>{{ 'app.choose_generation' | translate }}!</option>
      <option value="g1819">2018/2019</option>
      <option value="g1920">2019/2020</option>
      <option value="g2021">2020/2021</option>
      <option value="g2122">2021/2022</option>
      <option value="g2223">2022/2023</option>
      <option value="g2324">2023/2024</option>
      <option value="g2425">2024/2025</option>
      <option value="g2526">2025/2026</option>
   </select>
     </div>
   </div>
   <div *ngIf="this.courses?.length == 0" class="subjectsZeroState">
      You have no subjects with these filters
   </div>
   <table *ngIf="this.courses?.length > 0">
      <tr>
         <th><h5 class="bold left">{{ 'general.subject_name' | translate }}</h5></th>
         <th><h5 class="bold">{{ 'general.academic_year' | translate }}</h5></th>
         <th><h5 class="bold">{{ 'app.institution' | translate }}</h5></th>
         <th><h5 class="bold">{{ 'general.department' | translate }}</h5></th>
         <th><h5 class="bold">{{ 'app.users.professord' | translate }}</h5></th>
      </tr>
      <ng-container *ngFor="let course of this.courses; let i = index">
         <tr
            class="shadow"
            [ngClass]="{ error: course.error === true }"
            *ngIf="course.hide === false"
         >
            <td>
               <h5 class="left">{{ course.title }}</h5>
            </td>
            <td>
               <h5>{{ '20'+ course.generation.substring(1, 3) }}/{{ '20'+ course.generation.substring(3, 5) }}</h5>
            </td>
            <td>
               <h5>{{ course.Department.Faculty.name }}</h5>
            </td>
            <td>
               <h5>{{ course.Department.name }}</h5>
            </td>
            <td>
               <ngx-select-dropdown
                  [(ngModel)]="course.professors"
                  [config]="this.config"
                  [options]="course.professorsToAdd"
                  [multiple]="true"
               ></ngx-select-dropdown>
            </td>
            <td>
               <h5 class="remove" (click)="this.removeIndex(i)">{{ 'app.delete' | translate }}</h5>
            </td>
         </tr>
      </ng-container>
   </table>
   <div class="buttons">
      <div>
         <span class="submit" (click)="save()">{{ 'general.save' | translate }}</span>
      </div>
      <button (click)="closeModal()">{{ 'general.cancel' | translate }}</button>
   </div>
</div>

<div class="cloneSubjectsFilters" *ngIf="!showSubjects">
   <h1>{{ 'app.subject.subject_wizard' | translate }}</h1>
   <p>{{ 'app.subject_wizard_1' | translate }}.
       {{ 'app.subject_wizard_2' | translate }}.
      <br> <br> {{ 'app.subject_wizard_steps' | translate }}: <br> <br>
            1. {{ 'app.subject_wizard_steps_1' | translate }}. <br>
            2. {{ 'app.subject_wizard_steps_5' | translate }}. <br>
            3. {{ 'app.subject_wizard_steps_3' | translate }}. <br>
            4. {{ 'app.subject_wizard_steps_4' | translate }}. <br> <br>
            {{ 'app.subject_wizard_help' | translate }} <a href="">{{ 'app.subject.subject_wizard_documentation' | translate }}</a> {{ 'app.subject.or_contact_us_at' | translate }} <a href="mailto:support@crossplag.com">support@crossplag.com</a>.
   </p>
   <div class="selectors">
      <div class="generationFilter">
         <span>{{ 'app.choose_generation' | translate }}</span>
         <select
            [(ngModel)]="selectedGeneration"
            name=""
            id=""
         >
            <option value="-1" selected disabled>
               {{ 'app.choose_generation' | translate }}!
            </option>
            <option value="g1819">2018/2019</option>
            <option value="g1920">2019/2020</option>
            <option value="g2021">2020/2021</option>
            <option value="g2122">2021/2022</option>
            <option value="g2223">2022/2023</option>
            <option value="g2324">2023/2024</option>
            <option value="g2425">2024/2025</option>
            <option value="g2526">2025/2026</option>
            <option value="0">{{ 'general.all' | translate }}</option>
         </select>
         <div class="invalid" *ngIf="showError == true && (selectedGeneration == '-1' || selectedGeneration == '')"
            >{{ 'app.this_field_is_required' | translate }}!
         </div>
      </div>
      <div>
         <span>{{ 'general.select_institution' | translate }}</span>
         <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
            <option value="-1" selected disabled>
               {{ 'app.filter_by_institution' | translate }}
            </option>
            <ng-container *ngFor="let faculty of this.faculties">
               <option value="{{ faculty.id }}">
                  {{ faculty.name }}
               </option>
            </ng-container>
            <option value="">{{ 'general.all' | translate }}</option>
         </select>
         <div class="invalid" *ngIf="showError == true && (facultyId == '' || facultyId == '-1')"
            >{{ 'app.this_field_is_required' | translate }}!
         </div>
      </div>
      <div>
         <span>{{ 'general.select_department' | translate }}</span>
         <select
            disabled
            *ngIf="facultyId == '' || facultyId == '-1'"
            name=""
            id=""
         >
            <option value="">{{ 'general.select_institution_first' | translate }}</option>
         </select>
         <select
            *ngIf="facultyId !== '' && facultyId !== '-1'"
            [(ngModel)]="departmentId"
         >
            <option value="-1" selected disabled>
               {{ 'app.filter_by_department' | translate }}
            </option>
            <ng-container *ngFor="let department of this.departments">
               <option value="{{ department.id }}">
                  {{ department.name }}
               </option>
            </ng-container>
            <option value="">{{ 'general.all' | translate }}</option>
         </select>
         <div class="invalid" *ngIf="showError == true && (departmentId == '-1' || departmentId == '')"
            >{{ 'app.this_field_is_required' | translate }}!
         </div>
      </div>
   </div>
   <button class="submit" (click)="submit()">{{ 'general.submit' | translate }}</button>
</div>
