<div class="contact-us">
    <h4>{{ 'help_center.contact_us.contact_io_team_for_offer' | translate }}</h4>

 <form action="" [formGroup]="contactUs">
    <div class="flex">
        <div>
            <h5>{{ 'general.full_name' | translate }}</h5>
            <input disabled [placeholder]="'placeholders.name' | translate" formControlName="name" type="text">
        </div>
        <div>
            <h5>{{ 'general.email' | translate }}</h5>
            <input disabled [placeholder]="'placeholders.email' | translate" formControlName="email" type="text">
        </div>
    </div>
    <h5>{{ 'general.subject' | translate }}</h5>
    <input [placeholder]="'placeholders.subject' | translate" formControlName="subject" type="text">
    <h5>{{ 'general.message' | translate }}</h5>
    <textarea formControlName="message" [placeholder]="'placeholders.message' | translate" name="" id="" cols="30" rows="10">
    </textarea>
    <div
    class="offersTable"
 >
    <div class="header">
       <div>
          <span>{{ 'general.plan_name' | translate }}</span>
          <h2>{{ data.offer.title }}</h2>
       </div>
       <a (click)="moreOfferDetails()">{{ 'help_center.contact_us.see_more' | translate }}</a>
    </div>
    <div
       class="offerExpandedDetails"
       *ngIf="showOfferDetails"
    >
       <div>
          <h5>{{ 'general.number_of_students' | translate }}:</h5>
          <b>{{ data.offer.students }}</b>
          <!-- <a (click)="changeEditInput('student')">{{ 'general.add_user' | translate }}</a> -->
       </div>
       <div>
          <h5>Number of Educator:</h5>
          <b>{{ data.offer.professors }}</b>
          <!-- <a>{{ 'general.add_user' | translate }}</a> -->
       </div>
       <div>
          <h5>{{ 'general.license_duration' | translate }}:</h5>
          <b>{{ data.offer.commitment }}</b>
          <!-- <a>{{ 'general.add_user' | translate }}</a> -->
       </div>
       <!-- <div>
          <h5>{{ 'general.teaching_assistants' | translate }}:</h5>
          <b>{{ data.offer.teachingAssistants }}</b>
          <a>{{ 'general.add_user' | translate }}</a>
       </div> -->
       <!-- <div>
          <h5>{{ 'general.academic_integrity_officer' | translate }}:</h5>
          <b>{{ data.offer.academicIntegrityOfficers }}</b>
          <a>{{ 'general.add_user' | translate }}</a>
       </div> -->
       <!-- <div>
          <h5>{{ 'general.subscriptions' | translate }}:</h5>
          <b>{{ data.offer.subscriptions == 1 ? true : false }}</b>
          <a>{{ 'general.add_user' | translate }}</a>
       </div> -->
       <!-- <div>
          <h5>{{ 'general.thesis' | translate }}:</h5>
          <div>
             <div>
                <b>{{ 'general.bachelor' | translate }}: </b>
                <span> {{ data.offer.bachelor }}</span>
             </div>
             <div>
                <b>{{ 'general.master' | translate }}: </b>
                <span> {{ data.offer.master }}</span>
             </div>
             <div>
                <b>{{ 'general.phd' | translate }}: </b>
                <span> {{ data.offer.phd }}</span>
             </div>
          </div>
          <a>{{ 'general.add_user' | translate }}</a>
       </div> -->
       <!-- <div>
          <h5>{{ 'general.document_upload' | translate }}:</h5>
          <b>{{ data.offer.professorIndividualUploads }}</b>
          <a>{{ 'general.add_user' | translate }}</a>
       </div> -->
       <!-- <div>
          <h5>{{ 'general.crosslingual' | translate }}:</h5>
          <div>
             <span
                ><input
                   [disabled]="true"
                   type="checkbox"
                   id="assignment"
                   [checked]="data.offer.crossLingual['assignments']"
                /><label for="assignment">{{ 'general.assignment_level' | translate }}</label></span
             >
             <span
                ><input
                   [disabled]="true"
                   type="checkbox"
                   id="thesis"
                   [checked]="data.offer.crossLingual['thesis']"
                /><label for="thesis">{{ 'general.thesis' | translate }}</label></span
             >
             <span
                ><input
                   [disabled]="true"
                   type="checkbox"
                   id="professorUpload"
                   [checked]="data.offer.crossLingual['professorUploads']"
                /><label for="professorUpload"
                   >{{ 'general.document_uploads' | translate }}</label
                ></span
             >
          </div>
          <a (click)="changeEditInput('crossLingual')">{{ 'general.change' | translate }}</a>
       </div> -->
       <div>
          <h5>{{ 'general.payment_preference' | translate }}:</h5>
          <div>
             <span
                ><input
                   name="billingPlan"
                   [disabled]="true"
                   type="radio"
                   id="monthly"
                   [checked]="data.offer.billingPlan == 'Monthly'"
                /><label for="monthly">{{ 'general.monthly' | translate }}</label></span
             >
             <span
                ><input
                   name="billingPlan"
                   [disabled]="true"
                   type="radio"
                   id="annual"
                   [checked]="data.offer.billingPlan == 'Annually'"
                /><label for="annual">{{ 'general.annual' | translate }}</label></span
             >
             <span
                ><input
                   name="billingPlan"
                   [disabled]="true"
                   type="radio"
                   id="full"
                   [checked]="data.offer.billingPlan == 'OnePayment'"
                /><label for="full">{{ 'help_center.contact_us.full' | translate }}</label></span
             >
          </div>
          <!-- <a (click)="changeEditInput('billingPlan')">{{ 'general.change' | translate }}</a> -->
       </div>
       <!-- <div>
          <h5>{{ 'add_credits.support' | translate }}:</h5>
          <div>
             <span
                ><input
                   name="support"
                   [disabled]="true"
                   type="radio"
                   id="basic"
                   [checked]="data.offer.support == 'Basic'"
                /><label for="basic">{{ 'general.basic' | translate }}</label></span
             >
             <span
                ><input
                   name="support"
                   [disabled]="true"
                   type="radio"
                   id="Standard"
                   [checked]="data.offer.support == 'Standard'"
                /><label for="Standard">{{ 'general.standard' | translate }}</label></span
             >
             <span
                ><input
                   name="support"
                   [disabled]="true"
                   type="radio"
                   id="Premium"
                   [checked]="data.offer.support == 'Premium'"
                /><label for="Premium">{{ 'general.premium' | translate }}</label></span
             >
          </div>
       </div> -->
    </div>
 </div>
 <div class="emailConsent">
    <input
    formControlName="sentEmail"
    name="emails"
    id="emails"
    type="checkbox"
 />
 <label for="emails"></label>
 Yes, I would like crossplag team to contact me for this offer
 </div>
    <button
    [disabled]="contactUs.invalid"
    [ngClass]="{
       disable: contactUs.invalid
    }"
    class="submit"
    type="submit"
    (click)="sent()"
 >
    sent
 </button>
 </form>

</div>
