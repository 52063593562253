import { Sentence } from './../../../models/sentence';
import { Source } from './../../../models/source';
import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../store/app.state';
import { Submissions } from '../../../models/submissions';
import * as reportActions from '../actions/report.actions';
/**
 * Report reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add report state to the main state.
 */
export interface State extends AppState.State {
   currentSubmissionDetails: ReportState;
}
/**
 * An Report State interface, which presents the blueprint of the Report State.
 */
export interface ReportState {
   excludedSourcesMlPlag: any;
   excludedSourcesCrossPlag: any;
   submission: Submissions;
   currentSentenceId: number;
   sources: Source[];
   mlSources: Source[];
   originalNavigationArray: any;
   translatedNavigationArray: any;
   sentenceDetails: any;
   imagesDetection: any;
   manipulations: any;
}
/**
 * A constant that represent the initial state of Report State
 */
export const initialState: ReportState = {
   submission: null,
   currentSentenceId: 0,
   sources: [],
   mlSources: [],
   originalNavigationArray: [],
   translatedNavigationArray: [],
   excludedSourcesMlPlag: [],
   excludedSourcesCrossPlag: [],
   sentenceDetails: [],
   imagesDetection: [],
   manipulations: {},
};

/**
 * Report Reducer used to update the state upon report actions.
 */
export const reportReducer = createReducer<ReportState>(
   initialState,
   /**
    * On loadCurrentSubmissionSuccess action updated current state with the following properties.
    */
   on(reportActions.loadCurrentSubmissionSuccess, (state, action) => {
      return {
         ...state,
         submission: action.submission,
      };
   }),
   /**
    * On loadCurrentSubmissionFailure action updated current state with the following properties.
    */
   on(reportActions.loadCurrentSubmissionFailure, (state, action) => {
      return {
         ...state,
         error: action.error,
      };
   }),
   /**
    * On setCurrentSentenceIdSuccess action updated current state with the following properties.
    */ on(reportActions.setCurrentSentenceIdSuccess, (state, action) => {
      return {
         ...state,
         currentSentenceId: action.currentSentenceId,
      };
   }),
   /**
    * On setCurrentSentenceIdFailure action updated current state with the following properties.
    */ on(reportActions.setCurrentSentenceIdFailure, (state, action) => {
      return {
         ...state,
         error: action.error,
      };
   }),
   /**
    * On excludeSourceSuccess action updated current state with the following properties.
    */ on(reportActions.excludeSourceSuccess, (state, action) => {
      let updatedSubmission = { ...state.submission };
      let stateSources;
      let percentageToUpdate;
      let excludedSources;
      if (action.sourceType == true) {
         percentageToUpdate = updatedSubmission.originalPercentage;
         stateSources = [...state.sources];
         excludedSources = [...state.excludedSourcesMlPlag];
      } else {
         percentageToUpdate = updatedSubmission.translatedPercentage;
         stateSources = [...state.mlSources];
         excludedSources = [...state.excludedSourcesCrossPlag];
      }

      for (let source of stateSources) {
         if (source.sourceId === action.excludeUrlId) {
            excludedSources.push(source);
         }
      }
      if (action.sourceType == true) {
         updatedSubmission.originalPercentage =
            percentageToUpdate - action.source[0];
      } else {
         updatedSubmission.translatedPercentage =
            percentageToUpdate - action.source[0];
      }
      if (action.sourceType == true) {
         return {
            ...state,
            submission: updatedSubmission,
            sources: action.source[1],
            excludedSourcesMlPlag: excludedSources,
            currentSourceId: action.source.sourceId,
         };
      } else {
         return {
            ...state,
            submission: updatedSubmission,
            mlSources: action.source[1],
            excludedSourcesCrossPlag: excludedSources,
            currentSourceId: action.source.sourceId,
         };
      }
   }),
   /**
    * On excludeSourceFailure action updated current state with the following properties.
    */
   on(reportActions.excludeSourceFailure, (state, action) => {
      return {
         ...state,
         error: action.error,
      };
   }),
   /**
    * On includeSourceFailure action updated current state with the following properties.
    */
   on(reportActions.includeSourceFailure, (state, action) => {
      return {
         ...state,
         error: action.error,
      };
   }),
   /**
    * On excludeSentenceSuccess action updated current state with the following properties.
    */
   on(reportActions.excludeSentenceSuccess, (state, action) => {
      let activeSource;
      let updatedSubmission = { ...state.submission };
      if (action.sourceType == true) {
         activeSource = [...state.sources];
      } else {
         activeSource = [...state.mlSources];
      }

      let index = 0;
      let sentenceCopy;
      let text;
      let textToSearch;
      for (let source of activeSource) {
         let sourceCopy = { ...source };
         let sourceSentencesCopy = [...source.sentences];
         if (source.sourceId === action.source.sourceId) {
            for (let x in sourceSentencesCopy) {
               sentenceCopy = { ...sourceSentencesCopy[x] };
               if (action.sourceType) {
                  text = sentenceCopy.text;
                  textToSearch = action.updatedSentence.text;
                  if (text === textToSearch) {
                     sentenceCopy.isExcluded = true;
                     sentenceCopy.comment = action.comment;
                     sourceSentencesCopy[x] = sentenceCopy;
                     sourceCopy.sentences = sourceSentencesCopy;
                     sourceCopy.percentage -= sentenceCopy.percentage;
                     activeSource[index] = sourceCopy;
                  }
               } else {
                  text = sentenceCopy.translatedText;
                  textToSearch = action.updatedSentence.translatedText;
                  if (text === textToSearch) {
                     sentenceCopy.isTranslationExcluded = true;
                     sentenceCopy.comment = action.comment;
                     sourceSentencesCopy[x] = sentenceCopy;
                     sourceCopy.sentences = sourceSentencesCopy;
                     sourceCopy.percentage -= sentenceCopy.percentage;
                     activeSource[index] = sourceCopy;
                  }
               }
            }
         }
         index++;
      }

      if (action.sourceType == true) {
         updatedSubmission.originalPercentage =
            updatedSubmission.originalPercentage -
            action.updatedSentence.percentage;
         return {
            ...state,
            submission: updatedSubmission,
            sources: activeSource,
            currentSentenceId: action.updatedSentence.id,
            currentSourceId: action.source.sourceId,
         };
      } else {
         updatedSubmission.translatedPercentage =
            updatedSubmission.translatedPercentage -
            action.updatedSentence.percentage;
         return {
            ...state,
            submission: updatedSubmission,
            mlSources: activeSource,
            currentSentenceId: action.updatedSentence.id,
            currentSourceId: action.source.sourceId,
         };
      }
   }),
   on(reportActions.changeSourceSuccess, (state, action) => {
      let activeSource;
      if (action.originalLanguage == true) {
         activeSource = [...state.sources];
      } else {
         activeSource = [...state.mlSources];
      }

      let sourceFound;
      let existingSource;
      let sentenceCopy;
      let oldSource;
      let index = 0;
      let oldSourceUpdate;
      let NewSource;
      for (let source of activeSource) {
         if (source.sourceId === action.sourceId) {
            sourceFound = true;
            existingSource = { ...source };
            break;
         } else {
            for (let source of activeSource) {
               for (let sentence of source.sentences) {
                  if (sentence.id === action.sentenceId) {
                     oldSource = { ...source };
                  }
               }
            }
            sourceFound = false;
         }
      }

      if (sourceFound) {
         for (let source of activeSource) {
            for (let sentence of source.sentences) {
               oldSource = { ...source };
               if (sentence.id === action.sentenceId) {
                  sentenceCopy = { ...sentence };
                  let extSentences = [...existingSource.sentences];
                  extSentences.push(sentenceCopy);
                  let sentenceSecondaries;
                  if (action.originalLanguage == true) {
                     sentenceSecondaries = [...sentenceCopy.secondarySources];
                  } else {
                     sentenceSecondaries = [
                        ...sentenceCopy.secondaryTranslatedSources,
                     ];
                  }
                  for (let x in sentenceSecondaries) {
                     if (sentenceSecondaries[x].id === action.sourceId) {
                        sentenceSecondaries.splice(parseInt(x), 1);
                     }
                  }
                  if (action.originalLanguage == true) {
                     sentenceCopy.secondarySources = sentenceSecondaries;
                  } else {
                     sentenceCopy.secondaryTranslatedSources =
                        sentenceSecondaries;
                  }
                  existingSource.sentences = extSentences;
                  existingSource.percentage =
                     existingSource.percentage + sentenceCopy.percentage;
                  if (oldSource.sentences.length > 1) {
                     let extOldSentences = [...oldSource.sentences];
                     extOldSentences.splice(index, 1);
                     oldSource.sentences = extOldSentences;
                     oldSourceUpdate = true;
                  } else {
                     oldSourceUpdate = false;
                  }
                  for (let x in activeSource) {
                     // *** update source with newly added sentence
                     if (activeSource[x].sourceId === existingSource.sourceId) {
                        activeSource[x] = existingSource;
                     }
                  }
                  // *** remove entire source if there is 0 sentences left
                  for (let x in activeSource) {
                     if (oldSourceUpdate) {
                        if (activeSource[x].sourceId === oldSource.sourceId) {
                           activeSource[x] = oldSource;
                        }
                     } else {
                        if (activeSource[x].sourceId === oldSource.sourceId) {
                           activeSource.splice(x, 1);
                        }
                     }
                  }
               }
               index++;
            }
         }
      } else {
         for (let source of activeSource) {
            for (let sentence of source.sentences) {
               if (sentence.id === action.sentenceId) {
                  sentenceCopy = { ...sentence };
               }
            }
         }
         let secondaries;
         if (action.originalLanguage == true) {
            secondaries = [...sentenceCopy.secondarySources];
         } else {
            secondaries = [...sentenceCopy.secondaryTranslatedSources];
         }
         for (let secondary of secondaries) {
            if (secondary.id === action.sourceId) {
               let primaryToBe = { ...secondary };
               NewSource = {
                  isChecked: false,
                  isExcluded: false,
                  sentences: [sentenceCopy],
                  percentage: sentenceCopy.percentage,
                  sourceId: primaryToBe.id,
                  sourceUrl: primaryToBe.url,
                  type: 2,
               };
               activeSource.push(NewSource);
            }
         }
         // let oldSourceWithoutSentences = { ...oldSource };
         // delete oldSourceWithoutSentences.sentences;
         let sentenceSecondaries = [...secondaries];
         for (let x in sentenceSecondaries) {
            if (sentenceSecondaries[x].id === action.sourceId) {
               sentenceSecondaries.splice(parseInt(x), 1);
            }
         }

         secondaries = sentenceSecondaries;
         if (oldSource.sentences.length > 1) {
            let extOldSentences = [...oldSource.sentences];
            for (let x in extOldSentences) {
               let position = parseInt(x);
               if (extOldSentences[x].id === action.sentenceId) {
                  extOldSentences.splice(position, 1);
               }
            }
            oldSource.sentences = extOldSentences;
            oldSource.percentage =
               oldSource.percentage - sentenceCopy.percentage;
            oldSourceUpdate = true;
         } else {
            oldSourceUpdate = false;
         }
         for (let x in activeSource) {
            if (oldSourceUpdate) {
               if (activeSource[x].sourceId === oldSource.sourceId) {
                  activeSource[x] = oldSource;
                  break;
               }
            } else {
               if (activeSource[x].sourceId === oldSource.sourceId) {
                  activeSource.splice(x, 1);
                  break;
               }
            }
         }
      }

      if (action.originalLanguage == true) {
         return {
            ...state,
            sources: activeSource,
         };
      } else {
         return {
            ...state,
            mlSources: activeSource,
         };
      }
   }),
   /**
    * On includeSentenceSuccess action updated current state with the following properties.
    */ on(reportActions.includeSentenceSuccess, (state, action) => {
      let activeSource;
      let updatedSubmission = { ...state.submission };
      if (action.sourceType == true) {
         activeSource = [...state.sources];
      } else {
         activeSource = [...state.mlSources];
      }

      let index = 0;
      let sentenceCopy;
      let text;
      let textToSearch;
      for (let source of activeSource) {
         let sourceCopy = { ...source };
         let sourceSentencesCopy = [...source.sentences];
         if (source.sourceId === action.source.sourceId) {
            for (let x in sourceSentencesCopy) {
               sentenceCopy = { ...sourceSentencesCopy[x] };
               if (action.sourceType) {
                  text = sentenceCopy.text;
                  textToSearch = action.updatedSentence.text;
                  if (text === textToSearch) {
                     sentenceCopy.isExcluded = false;
                     sourceSentencesCopy[x] = sentenceCopy;
                     sourceCopy.sentences = sourceSentencesCopy;
                     sourceCopy.percentage += sentenceCopy.percentage;
                     activeSource[index] = sourceCopy;
                  }
               } else {
                  text = sentenceCopy.translatedText;
                  textToSearch = action.updatedSentence.translatedText;
                  if (text === textToSearch) {
                     sentenceCopy.isTranslationExcluded = false;
                     sourceSentencesCopy[x] = sentenceCopy;
                     sourceCopy.sentences = sourceSentencesCopy;
                     sourceCopy.percentage += sentenceCopy.percentage;
                     activeSource[index] = sourceCopy;
                  }
               }
            }
         }
         index++;
      }
      if (action.sourceType == true) {
         updatedSubmission.originalPercentage =
            updatedSubmission.originalPercentage +
            action.updatedSentence.percentage;
         return {
            ...state,
            submission: updatedSubmission,
            sources: activeSource,
            currentSentenceId: action.updatedSentence.id,
            currentSourceId: action.source.sourceId,
         };
      } else {
         updatedSubmission.translatedPercentage =
            updatedSubmission.translatedPercentage +
            action.updatedSentence.percentage;
         return {
            ...state,
            submission: updatedSubmission,
            mlSources: activeSource,
            currentSentenceId: action.updatedSentence.id,
            currentSourceId: action.source.sourceId,
         };
      }
   }),
   /**
    * On checkCheckbox action updated current state with the following properties.
    */ on(reportActions.checkCheckbox, (state, action) => {
      let activeSource;
      if (action.sourceType == true) {
         activeSource = state.sources;
      } else {
         activeSource = state.mlSources;
      }
      let updatedSources: any = activeSource.map((source) => {
         let sourceCopy = { ...source };
         if (source.sourceId === action.source.sourceId) {
            sourceCopy.isChecked = true;
         }
         return sourceCopy;
      });

      if (action.sourceType == true) {
         return {
            ...state,
            sources: updatedSources,
         };
      } else {
         return {
            ...state,
            mlSources: updatedSources,
         };
      }
   }),
   /**
    * On checkCheckbox action updated current state with the following properties.
    */ on(reportActions.checkCheckboxExclude, (state, action) => {
      let activeSource;
      if (action.sourceType == true) {
         activeSource = state.excludedSourcesMlPlag;
      } else {
         activeSource = state.excludedSourcesCrossPlag;
      }
      let updatedSources: any = activeSource.map((source) => {
         let sourceCopy = { ...source };
         if (source.sourceId === action.source.sourceId) {
            sourceCopy.isChecked = true;
         }
         return sourceCopy;
      });

      if (action.sourceType == true) {
         return {
            ...state,
            excludedSourcesMlPlag: updatedSources,
         };
      } else {
         return {
            ...state,
            excludedSourcesCrossPlag: updatedSources,
         };
      }
   }),
   /**
    * On unCheckCheckbox action updated current state with the following properties.
    */ on(reportActions.unCheckCheckbox, (state, action) => {
      let activeSource;
      if (action.sourceType == true) {
         activeSource = state.sources;
      } else {
         activeSource = state.mlSources;
      }
      let updatedSources: any = activeSource.map((source) => {
         let sourceCopy = { ...source };
         if (source.sourceId === action.source.sourceId) {
            sourceCopy.isChecked = false;
         }
         return sourceCopy;
      });
      if (action.sourceType == true) {
         return {
            ...state,
            sources: updatedSources,
         };
      } else {
         return {
            ...state,
            mlSources: updatedSources,
         };
      }
   }),
   /**
    * On unCheckCheckbox action updated current state with the following properties.
    */ on(reportActions.unCheckCheckboxExclude, (state, action) => {
      let activeSource;
      if (action.sourceType == true) {
         activeSource = state.excludedSourcesMlPlag;
      } else {
         activeSource = state.excludedSourcesCrossPlag;
      }
      let updatedSources: any = activeSource.map((source) => {
         let sourceCopy = { ...source };
         if (source.sourceId === action.source.sourceId) {
            sourceCopy.isChecked = false;
         }
         return sourceCopy;
      });
      if (action.sourceType == true) {
         return {
            ...state,
            excludedSourcesMlPlag: updatedSources,
         };
      } else {
         return {
            ...state,
            excludedSourcesCrossPlag: updatedSources,
         };
      }
   }),

   /**
    * On getSubmissionPlagSuccess action updated current state with the following properties.
    */ on(reportActions.getSubmissionPlagSuccess, (state, action) => {
      return {
         ...state,
         excludedSourcesMlPlag: action.excludedSourcesMlPlag,
         excludedSourcesCrossPlag: action.excludedSourcesCrossPlag,
         sources: action.sources,
         mlSources: action.mlSources,
         submission: action.submission,
         originalNavigationArray: action.originalNavigationArray,
         translatedNavigationArray: action.translatedNavigationArray,
         sentenceDetails: action.sentenceDetails,
         imagesDetection: action.imagesDetection,
         manipulations: action.manipulations,
      };
   }),
   /**
    * On addIsCheckedProp action updated current state with the following properties.
    */ on(reportActions.addIsCheckedProp, (state) => {
      let updatedSources: any = state.sources.map((source) => ({
         ...source,
         isChecked: false,
      }));
      let updatedMLSources: any = state.mlSources.map((source) => ({
         ...source,
         isChecked: false,
      }));
      return {
         ...state,
         sources: updatedSources,
         mlSources: updatedMLSources,
      };
   }),
   /**
    * On checkCheckboxFailure action updated current state with the following properties.
    */ on(reportActions.checkCheckboxFailure, (state, action) => {
      return {
         ...state,
         error: action.error,
      };
   }),
   /**
    * On unCheckCheckboxFailure action updated current state with the following properties.
    */ on(reportActions.unCheckCheckboxFailure, (state, action) => {
      return {
         ...state,
         error: action.error,
      };
   }),
   /**
    * On resetFilterSuccess action updated current state with the following properties.
    */ on(reportActions.resetFilterSuccess, (state, action) => {
      let updatedSources: any = state.sources.map((source) => {
         let sourceCopy = { ...source };
         sourceCopy.isChecked = false;
         return sourceCopy;
      });
      let updatedMlSources: any = state.mlSources.map((source) => {
         let sourceCopy = { ...source };
         sourceCopy.isChecked = false;
         return sourceCopy;
      });
      return {
         ...state,
         sources: updatedSources,
         mlSources: updatedMlSources,
      };
   }),
   /**
    * On updateCommentSuccess action updated current state with the following properties.
    */ on(reportActions.updateCommentSuccess, (state, action) => {
      let activeSource;
      if (action.sourceType == true) {
         activeSource = state.sources;
      } else {
         activeSource = state.mlSources;
      }
      let updatedSources: any = activeSource.map((source) => {
         let sourceCopy = { ...source };
         if (source.sourceId === action.source.sourceId) {
            sourceCopy.sentences = source.sentences.map((sentence) => {
               let newSentence = { ...sentence };
               if (action.sourceType) {
                  newSentence.comment =
                     action.sentence.id === sentence.id
                        ? action.comment
                        : sentence.comment;
               } else {
                  newSentence.translateComment =
                     action.sentence.id === sentence.id
                        ? action.comment
                        : sentence.translateComment;
               }
               return newSentence;
            });
         }
         return sourceCopy;
      });
      if (action.sourceType == true) {
         return {
            ...state,
            sources: updatedSources,
            currentSentenceId: action.sentence.id,
            currentSourceId: action.source.sourceId,
         };
      } else {
         return {
            ...state,
            mlSources: updatedSources,
            currentSentenceId: action.sentence.id,
            currentSourceId: action.source.sourceId,
         };
      }
   }),
   /**
    * On deleteCommentSuccess action updated current state with the following properties.
    */ on(reportActions.deleteCommentSuccess, (state, action) => {
      let activeSource;
      if (action.sourceType == true) {
         activeSource = state.sources;
      } else {
         activeSource = state.mlSources;
      }
      let updatedSources: any = activeSource.map((source) => {
         let sourceCopy = { ...source };
         if (source.sourceId === action.source.sourceId) {
            sourceCopy.sentences = source.sentences.map((sentence) => {
               let newSentence = { ...sentence };
               newSentence.comment = '';
               return newSentence;
            });
         }
         return sourceCopy;
      });
      if (action.sourceType == true) {
         return {
            ...state,
            sources: updatedSources,
            currentSentenceId: action.sentence.id,
            currentSourceId: action.source.sourceId,
         };
      } else {
         return {
            ...state,
            mlSources: updatedSources,
            currentSentenceId: action.sentence.id,
            currentSourceId: action.source.sourceId,
         };
      }
   })
);
