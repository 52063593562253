<div class="submissions">
   <!-- <div routerLink="assignment-submissions" routerLinkActive="active">
      <span>{{ 'general.assignment' | translate }}</span>
   </div>
   <div routerLink="professors-submissions" routerLinkActive="active">
      <span>{{ 'general.educators' | translate }}</span>
   </div>
   <div routerLink="thesis" routerLinkActive="active"><span>{{ 'general.theses' | translate }}</span></div> -->
</div>
<router-outlet></router-outlet>
