import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

import { AuthService } from '../../services/auth.service';
import * as authActions from './actions/authentication.actions';
/**
 * Effects listening for actions related to Authentication
 */
@Injectable()
export class AuthenticationEffects {
   /**
    * Effect Constructor
    * @param actions$
    * @param authService
    * @param userService
    */
   constructor(
      private actions$: Actions,
      private authService: AuthService,
      private userService: UserService
   ) {}
   // Login effect listening for login action
   login$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(authActions.login),
         concatMap((action) =>
            this.authService.login(action.email, action.password).pipe(
               map((user) => authActions.loginSuccess({ user: user }),
               localStorage.setItem('login-app', 'true')
               ),
               catchError((error) => of(authActions.loginFailure({ error })))
            )
         )
      );
   });

   // Login effect listening for login action
   currentUser$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(authActions.currentUser),
         concatMap((action) =>
            this.authService.getCurrentUserObservable().pipe(
               map((data) => {
                  return authActions.currentUserSuccess({
                     user: data.user,
                  });
               }),
               catchError((error) =>
                  of(authActions.currentUserFailure({ error }))
               )
            )
         )
      );
   });
   /**
    * linkedInLogin listening for linkedIn Login action
    */
   linkedInLogin$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(authActions.linkedInLogin),
         concatMap((action) =>
            this.authService.linkedInLogin(action.authCode).pipe(
               map((user) => authActions.linkedInLoginSuccess({ user: user })),
               catchError((error) =>
                  of(authActions.linkedInLoginFailure({ error }))
               )
            )
         )
      );
   });
   /**
    * googleLogin Effect listening for google login action, then calling googleAuth() from authentication Service
    */
   googleLogin$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(authActions.googleLogin),
         concatMap((action) =>
            this.authService.googleAuth(action.name, action.email).pipe(
               map((user) => authActions.googleLoginSuccess({ user: user })),
               catchError((error) =>
                  of(authActions.googleLoginFailure({ error }))
               )
            )
         )
      );
   });

   LTIlogin$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(authActions.LTILogin),
         concatMap((action) =>
            this.authService
               .LTIlogin({
                  token: action.token,
                  user: action.user,
                  assignment: action.assignment,
                  assignmentId: action.assignmentId,
                  courseId: action.courseId,
               })
               .pipe(
                  map((user) => authActions.LTILoginSuccess({ user })),
                  catchError((error) =>
                     of(authActions.LTILoginFailure({ error }))
                  )
               )
         )
      );
   });
   /**
    * Sign up effect listening to sign up action to call sign up api
    */
   signUp$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(authActions.signUp),
         concatMap((action) =>
            this.userService
               .signUp(
                  action.firstName,
                  action.lastName,
                  action.email,
                  action.institution,
                  action.marketing,
                  action.role,
                  action.country,
                  action.currentSystem,
                  action.company,
                  action.message
               )
               .pipe(
                  map((user) => authActions.signUpSuccess({ user: user })),
                  catchError((error) =>
                     of(authActions.signUpFailure({ error }))
                  )
               )
         )
      );
   });
   /**
    * Effect listening for updated user info action to call updatedUserInfo api
    */
   updateUserInfo$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(authActions.updateUserInfo),
         concatMap((action) =>
            this.userService
               .updateUserInfo(action.firstName, action.lastName)
               .pipe(
                  map((user) =>
                     authActions.updateUserInfoSuccess({
                        firstName: action.firstName,
                        lastName: action.lastName,
                     })
                  ),
                  catchError((error) =>
                     of(authActions.updateUserInfoFailure({ error }))
                  )
               )
         )
      );
   });

   /**
    * Logout effect listening for logout action, then calls authService logout() method.
    */
   logout$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(authActions.logout),
            tap((action) => {
               this.authService.logout();
            })
         ),
      { dispatch: false }
   );
}
