<div class="edit-professor-modal">
   <div class="edit-professor-title">
      <h4>{{ 'app.edit_educator' | translate }}</h4>
   </div>
   <form [formGroup]="professorEdit">
      <div class="editimi-lendes-inputs">
         <div class="fieldContainer">
            <label for="professorName">{{ 'general.full_name' | translate }}</label>
            <div>
               <input
                  id="professorName"
                  type="text"
                  formControlName="professorName"
               />
               <div
                  *ngIf="
                     professorEdit.controls.professorName.touched &&
                     professorEdit.controls.professorName.invalid
                  "
                  class="invalid"
               >
                  {{ 'general.full_name' | translate }} {{ 'aria-labels.is_required' | translate }}
               </div>
               <div
                  *ngIf="professorEdit.controls.professorName.errors?.minlength"
                  class="invalid"
               >
               {{ 'general.educator' | translate }} {{ 'general.name_and_surname' | translate }} should contain at least 3
                  characters!
               </div>
            </div>
            <label for="Email">{{ 'general.email' | translate }}</label>
            <div>
               <input
                  id="Email"
                  type="email"
                  formControlName="professorEmail"
               />
               <!-- <div *ngIf="this.emailExists" class="invalid">
                  Email already exists, please try another one.*
               </div> -->
               <div
                  *ngIf="
                     professorEdit.controls.professorEmail.touched &&
                     professorEdit.controls.professorEmail.invalid
                  "
                  class="invalid"
               >
               {{ 'general.educator' | translate }} {{ 'login.email_required' | translate }}*
               </div>

               <div
                  *ngIf="professorEdit.controls.professorEmail.errors?.email"
                  class="invalid"
               >
               {{ 'app.educator_email_is_not_valid' | translate }}*
               </div>
            </div>

            <!-- <label for="PhoneNumber">{{ 'general.phone' | translate }}</label>
            <div>
               <input
                  id="PhoneNumber"
                  type="text"
                  formControlName="professorPhoneNumber"
               />
               <div
                  *ngIf="
                     professorEdit.controls.professorPhoneNumber.touched &&
                     professorEdit.controls.professorPhoneNumber.invalid
                  "
                  class="invalid"
               >
                  {{ 'general.educator' | translate }} {{ 'app.contact_number' | translate }} {{ 'general.end_date' | translate }}*
               </div>
               <div
                  *ngIf="
                     professorEdit.controls.professorPhoneNumber.errors
                        ?.minlength
                  "
                  class="invalid"
               >
                  {{ 'general.educator' | translate }} {{ 'app.contact_number' | translate }} should contain at least 6 numbers!
               </div>
            </div> -->

            <label for="title">{{ 'app.institution' | translate }}</label>
            <div>
               <select
                  formControlName="professorFaculty"
                  (change)="facultyChanged()"
               >
                  <ng-container *ngFor="let faculty of this.faculties">
                     <option value="{{ faculty.id }}" selected>
                        {{ faculty.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  *ngIf="
                     professorEdit.controls.professorFaculty.touched &&
                     professorEdit.controls.professorFaculty.invalid
                  "
                  class="invalid"
               >
               {{ 'general.educator' | translate }} {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
               </div>
            </div>

            <label for="title">{{ 'general.department' | translate }}</label>
            <div>
               <select formControlName="professorDepartment">
                  <ng-container *ngFor="let department of this.departments">
                     <option value="{{ department.id }}" selected>
                        {{ department.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  *ngIf="
                     professorEdit.controls.professorDepartment.touched &&
                     professorEdit.controls.professorDepartment.invalid
                  "
                  class="invalid"
               >
               {{ 'general.educator' | translate }} {{ 'general.department' | translate }} {{ 'aria-labels.is_required' | translate }}*
               </div>
            </div>

            <label for="prof">{{ 'general.subjects' | translate }}</label>
            <div>
               <ngx-select-dropdown
                  [config]="this.config"
                  [options]="this.subjects"
                  [multiple]="true"
                  [formControl]="form['professorSubjects']"
               ></ngx-select-dropdown>
               <div
                  *ngIf="
                     professorEdit.controls.professorSubjects.touched &&
                     professorEdit.controls.professorSubjects.invalid
                  "
                  class="invalid"
               >
               {{ 'general.educator' | translate }} {{ 'general.subjects' | translate }} {{ 'general.end_date' | translate }}*
               </div>
            </div>
            <label for="title">{{ 'general.status' | translate }}</label>
            <div>
               <select formControlName="professorStatus">
                  <option value="1">{{ 'general.active' | translate }}</option>
                  <option value="0">{{ 'general.inactive' | translate }}</option>
               </select>
            </div>
         </div>
         <div class="buttons">
            <a class="delete" (click)="delete()">{{ 'app.delete' | translate }}</a>
            <button
               class="save"
               [disabled]="professorEdit.invalid || this.emailExists"
               [ngClass]="{
                  disable: professorEdit.invalid || this.emailExists
               }"
               type="submit"
               (click)="update()"
            >
               {{ 'app.confirm' | translate }}
            </button>
         </div>
      </div>
   </form>
</div>
<div id="overlay"></div>
