import { Component, Inject, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from 'src/app/sharedModule/report-v2/services/report.service';

@Component({
   selector: 'app-source-preview',
   templateUrl: './source-preview.component.html',
   styleUrls: ['./source-preview.component.scss'],
})
export class SourcePreviewComponent implements OnInit {
   pdfSrc;
   requestPurpose;
   constructor(
      private reportService: ReportService,
      public dialogRef: MatDialogRef<SourcePreviewComponent>,
      public dialog: MatDialog,
      private spinnerService: NgxSpinnerService,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnInit(): void {
      this.pdfSrc = this.data.presignedS3UrlPreview;
   }

   callBackFn(pdf: PDFDocumentProxy) {
   }

   submit() {
      this.spinnerService.show()
      this.reportService
        .sentContentRequest(
          this.data.sourceId,
          this.data.sourceSubmissionId,
          this.requestPurpose,
          this.data.index
        )
        .pipe()
        .subscribe(
          (data) => {

            this.data.hasAccess = true;
            this.data.submission = data.submission
            this.pdfSrc = data.presignedS3UrlPreview,
            this.spinnerService.hide();

          },
          (error) => {
            this.spinnerService.hide();
          }
        );
   }
}
