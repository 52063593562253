import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
   providedIn: 'root',
})
export class AssignmentService {
   constructor(private http: HttpClient) {}

   createAssignment(data) {
      let subjectId = data.subject;
      let name = data.name;
      let startDate = new Date(data.startDate);
      let endDate = new Date(data.endDate);
      let lateSubmissions = data.lateSubmissionsDate;
      let numberOfReSubmisions = data.nrReSubmissions;
      let descriptions = data.description;
      let type = parseInt(data.type);
      let id = data.id;
      let originalLanguage = data.originalLanguage;
      let translatedLanguage = data.translatedLanguage;

      let source_percentage_threshold = data.source_percentage_threshold;
      let submission_word_limit = data.submission_word_limit;
      let exclude_citations = data.exclude_citations;
      let anonymous_submissions = data.anonymous_submissions;

      let sources = {
         excludeSources: data.sources.excludeSources,
         includeSources: data.sources.includeSources
      };

      let sentence_thresholds = {
         exactMatchThreshold: data.exactMatchThreshold,
         alteredTextThreshold: data.alteredTextThreshold,
         contextualSimilaritiesThreshold: data.contextualSimilaritiesThreshold
      };

      let context_similarity = data.contextualSimilarity;

      if (!numberOfReSubmisions) {
         numberOfReSubmisions = 1;
      }

      if (!lateSubmissions) {
         lateSubmissions = null;
      } else {
         lateSubmissions = new Date(data.lateSubmissionsDate);
      }

      return this.http
         .post<any>(`${environment.apiUrl}/assignment`, {
            subjectId,
            name,
            startDate,
            endDate,
            lateSubmissions,
            numberOfReSubmisions,
            descriptions,
            type,
            assignmentId: id,
            originalLanguage,
            translatedLanguage,
            aiDetectionEnabled: data.aiDetection,
            archive: data.archive,
            source_percentage_threshold,
            submission_word_limit,
            exclude_citations,
            anonymous_submissions,
            sources,  
            sentence_thresholds,  
            context_similarity  
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getAssignments(userId, name, subjectId, type, page) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/assignments/?page=${page}` +
               (subjectId ? `&subjectId=${subjectId}` : '') +
               (type ? `&type=${type}` : '') +
               (name ? `&name=${name}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   editAssignment(data, id) {
      let subjectId = data.subject;
      let name = data.name;
      let startDate = new Date(data.startDate);
      let endDate = new Date(data.endDate);
      let lateSubmissions;
      let numberOfReSubmisions = data.nrReSubmissions;
      let descriptions = data.description;
      let type = parseInt(data.type);
      let originalLanguage = data.originalLanguage;
      let translatedLanguage = data.translatedLanguage;
      let submission_word_limit = data.submission_word_limit
      let source_percentage_threshold = data.source_percentage_threshold
      let exclude_citations = data.exclude_citations
      let anonymous_submissions = data.anonymous_submissions
      let sources = {
         excludeSources: data.sources.excludeSources,
         includeSources: data.sources.includeSources
      };

      let sentence_thresholds = {
         exactMatchThreshold: data.exactMatchThreshold,
         alteredTextThreshold: data.alteredTextThreshold,
         contextualSimilaritiesThreshold: data.contextualSimilaritiesThreshold
      };

      let context_similarity = data.contextualSimilarity;
      

      if (numberOfReSubmisions == '') {
         numberOfReSubmisions = 1;
      }

      if (data.lateSubmissionsDate == null) {
         lateSubmissions = null;
      } else {
         lateSubmissions = new Date(data.lateSubmissionsDate);
      }

      return this.http
         .put<any>(`${environment.apiUrl}/assignment/${id}`, {
            subjectId,
            name,
            startDate,
            endDate,
            lateSubmissions,
            numberOfReSubmisions,
            descriptions,
            type,
            originalLanguage,
            translatedLanguage,
            aiDetection:data.aiDetection,
            archive: data.archive,
            submission_word_limit,
            source_percentage_threshold,
            exclude_citations,
            anonymous_submissions,
            sources,
            sentence_thresholds,
            context_similarity  
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   deleteAssignment(id) {
      return this.http
         .delete<any>(`${environment.apiUrl}/assignment/${id}`, {})
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getAssignment(id) {
      return this.http
         .get<any>(`${environment.apiUrl}/assignment/${id}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
}
