<section class="modify_manipulation">
  <h1>Hidden Text</h1>
  <section class="manipulation_details ">
    <section>
      <p tabindex="0" class="text_type" aria-label="Submitted text">Submitted text</p>
      <p tabindex="0" class="text_result">{{ result.text }}</p>
      <p tabindex="0" class="text_type" aria-label="Manipulations">Manipulations</p>
      <p tabindex="0" class="text_result" [innerHTML]="formattedText"></p>
    </section>
  </section>
  <section class="manipulation_btns">
    <button tabindex="0" class="cancel_btn" (click)="cancel()">Close</button>
    <button tabindex="0" class="toggle_btn" (click)="toggleSentence(result.id)">{{ result.whitecharactersExcluded ? 'Include' : 'Exclude' }}</button>
  </section>
</section>
