<ng-container *ngIf="isOnAnalysis; else notOnAnalysis">
  <!-- Section for original languages -->
  <section *ngFor="let lang of document_languages | slice:0:1" tabindex="0" class="language_bar onAnalysis" [ariaLabel]="[(lang.type) + ' language - ' + (lang.language) + ' - Similarity ' + (percentageWithQuotes ? originalPercentage : originalPercentageWithoutQuotes) + '%']">
    <section class="original_section" *ngIf="lang.type == 'Original'">
      <header>
        <img [src]="['../../../../../../assets/report_v2/' + lang.type.toLowerCase() + '_lang.svg']" [alt]="lang.type + ' language logo/image'">
        <h4>{{ 'app.original_language' | translate }}</h4>
      </header>
      <section (click)="toggleSources(lang, $event)">
        <button class="toggle_sources_btn" [ngClass]="{ 'sources_closed': lang.sourcesClosed }"><img src="../../../../../../assets/report_v2/up_down_arrow.svg" alt="Arrow to indicate if matching sources are visible"></button>
        <p class="paragraphStyle">{{ lang.language }}</p>
        <div>
          <mat-progress-bar id="similarity" mode="determinate" [value]="percentageWithQuotes ? originalPercentage : originalPercentageWithoutQuotes"></mat-progress-bar>
          <p class="paragraphStyle">{{ percentageWithQuotes ? originalPercentage : originalPercentageWithoutQuotes }}%</p>
        </div>
      </section>
      <section tabindex="0" class="sources" [ngClass]="{ 'closed': lang.sourcesClosed, 'excluded': selectedFilterText === 'Removed sources' }" aria-label="Matching Sources">
        <header class="sources_header">
          <p>{{('new_entries.' + selectedFilterText.toLowerCase().split(' ').join('_').replace('%', '_percent')) | translate}}</p>
          <button class="sources_menu_btn" role="button" tabindex="0" (click)="toggleMenu()" focusable aria-label="Button to filter sources. Press to filter">
            <img src="../../../../../../assets/report_v2/three-dots.svg" alt="Options of Matching Sources">
            <section *ngIf="isMenuOpen" class="sources_menu" cdkTrapFocus>
              <button class="sources_menu_opt" aria-label="Click to show matching sources" (click)="filterSources(0)">{{ 'new_entries.show_matching_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show only sources from document" (click)="filterSources(1)">{{ 'new_entries.show_only_document_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show only sources from internet" (click)="filterSources(2)">{{ 'new_entries.show_only_internet_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show removed sources" (click)="filterSources(3)">{{ 'new_entries.show_removed_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show sources below 1%" (click)="filterSources(4)">{{ 'new_entries.sources_below_1_percent' | translate }}</button>
            </section>
          </button>
        </header>
        <a class="disclaimer" href="https://support.inspera.com/hc/en-us/articles/15852859897501-Matching-sources" target="_blank">{{ 'new_entries.learn_more_about_matching_sources' | translate }}</a>
        <section class="sources_body" >
          <ng-container *ngFor="let source of similarityAnalysis.originalSources.slice(0, lang.visibleSourcesCount)">
            <div *ngIf="this.selectedFilterText !== 'Removed sources' ? !source.hide && source.percentage > 0 && !source.isSentenceExcluded : true" class="source" [ngClass]="source.isExcluded ? 'excluded' : ''" tabindex="1" focusable [ariaLabel]="'Source number ' + source.no + ', similarity ' + (source.percentage == 0 ? 0 : source.percentage?.toFixed(2)) + '%. Press the X button to remove the source.'">
              <button tabindex="0" [ariaLabel]="'Source Number' + source.no" class="source_no" (click)="selectSourceToHighlight(source.no)" [ngClass]="{'selectedSource' : selectedSources.includes(source.no)}">{{ source.no }}</button>
              <span tabindex="-1" class="source_percentage">{{ source.percentage == 0 ? 0 : source.percentage?.toFixed(2) }}</span>
              <a tabindex="0" *ngIf="source.sourceUrl" class="source_url" aria-label="Source URL. Click to open the source." [href]="source.sourceUrl" target="_blank">{{ source.sourceUrl }}</a>
              <li tabindex="0" aria-label="This source is from a private document" *ngIf="!source.sourceUrl"  class="source_url" (click)="startDocumentPreview(source.sourceId,lang)">{{ 'new_entries.private_document_source' | translate }}</li>
              <button *ngIf="canEdit" aria-label="Remove Source" class="remove_source" [ngClass]="{'rotate':source.isExcluded}" (click)="sourceAction(source.no,source.sourceUrl,source.sourceId, !source.isExcluded, source.percentage)"><img src="../../../../../../assets/report_v2/delete-circled.svg" alt=""></button>
            </div>
          </ng-container>
          <span *ngIf="similarityAnalysis.originalSources.length == 0">{{ 'new_entries.no_sources_for_filter' | translate }}</span>
            <button aria-label="View More Sources" class="more_sources" [ngClass]="{'hidden': lang.hideLoadMoreButton}" (click)="loadMoreSources(lang)" *ngIf="shouldShowLoadMoreButton(lang)">{{ 'new_entries.view_more_sources' | translate }}</button>
        </section>
      </section>
    </section>
  </section>

  <!-- Section for translated languages -->
  <section class="translated_section" *ngIf="isDocumentTranslated">
    <header>
      <img [src]="['../../../../../../assets/report_v2/translated_lang.svg']" [alt]="'translated language logo/image'">
      <div class="border-right">
        <h4>{{ 'general.translated_language' | translate }}</h4>
        <p>{{ 'new_entries.overall_similarity_across_languages' | translate }}</p>
      </div>
      <p class="translations_total_percentage">{{ percentageWithQuotes ? overallTransltedPercentage : overallTransltedPercentageWithoutQuotes }}%</p>
    </header>
    <section *ngFor="let lang of document_languages | slice:1" class="language_section">
      <section (click)="toggleSources(lang, $event)">
        <button class="toggle_sources_btn" [ngClass]="{ 'sources_closed': lang.sourcesClosed }"><img src="../../../../../../assets/report_v2/up_down_arrow.svg" [alt]="'Arrow to indicate if matching sources are visible for ' + lang.language"></button>
        <p class="paragraphStyle">{{ lang.language }}</p>
        <div>
          <mat-progress-bar id="similarity" mode="determinate" [value]="percentageWithQuotes ? lang.percentage : lang.percentageWithoutQuotes"></mat-progress-bar>
          <p class="paragraphStyle">{{ percentageWithQuotes ? (lang.percentage == 0 ? '0' : lang.percentage?.toFixed(2)) : (lang.percentageWithoutQuotes == 0 ? '0' : lang.percentageWithoutQuotes?.toFixed(2)) }}%</p>
        </div>
      </section>
      <section tabindex="0" class="sources" [ngClass]="{ 'closed': lang.sourcesClosed, 'excluded': selectedFilterText === 'Removed sources' }" aria-label="Matching Sources">
        <header class="sources_header">
          <p>{{('new_entries.' + selectedFilterText.toLowerCase().split(' ').join('_').replace('%', '_percent')) | translate}}</p>
          <button class="sources_menu_btn" role="button" tabindex="0" (click)="toggleMenu()" focusable aria-label="Button to filter sources. Press to filter">
            <img src="../../../../../../assets/report_v2/three-dots.svg" alt="Options of Matching Sources">
            <section *ngIf="isMenuOpen" class="sources_menu" cdkTrapFocus>
              <button class="sources_menu_opt" aria-label="Click to show matching sources" (click)="filterSources(0)">{{ 'new_entries.show_matching_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show only sources from document" (click)="filterSources(1)">{{ 'new_entries.show_only_document_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show only sources from internet" (click)="filterSources(2)">{{ 'new_entries.show_only_internet_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show removed sources" (click)="filterSources(3)">{{ 'new_entries.show_removed_sources' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show sources below 1%" (click)="filterSources(4)">{{ 'new_entries.sources_below_1_percent' | translate }}</button>
            </section>
          </button>
        </header>
        <a class="disclaimer" href="https://support.inspera.com/hc/en-us/articles/15852859897501-Matching-sources" target="_blank">{{ 'new_entries.learn_more_about_matching_sources' | translate }}</a>
        <section class="sources_body">
          <ng-container *ngFor="let langSources of similarityAnalysis.translatedSources">
            <ng-container *ngIf="document_languages.slice(1).length == 1 && this.similarityAnalysis.submission.translatedPercentages == null; else otherTranslated">
              <div class="source" tabindex="1" focusable [ariaLabel]="'Source number ' + langSources.no + ', similarity ' + langSources?.percentage?.toFixed(2) + '%. Press the X button to remove the source.'">
                <button tabindex="0" [ariaLabel]="'Source Number' + langSources.no" class="source_no" (click)="selectSourceToHighlight(langSources.no)" [ngClass]="{'selectedSource' : selectedSources.includes(langSources.no)}">{{ langSources.no }}</button>
                <span tabindex="-1" class="source_percentage">{{ langSources.percentage == 0 ? 0 : langSources.percentage?.toFixed(2) }}</span>
                <a tabindex="0" *ngIf="langSources.sourceUrl" class="source_url" aria-label="Source URL. Click to open the source." [href]="langSources.sourceUrl" target="_blank">{{ langSources.sourceUrl }}</a>
                <li tabindex="0" *ngIf="!langSources.sourceUrl" (click)="startDocumentPreview(langSources.sourceId,lang)" class="source_url">{{ 'new_entries.private_document_source' | translate }}</li>
                <button *ngIf="canEdit" aria-label="Remove Source" class="remove_source" [ngClass]="{'rotate':langSources.isExcluded}" (click)="sourceAction(langSources.no,langSources.sourceUrl,langSources.sourceId, !langSources.isExcluded, langSources.percentage, currentTranslateLanguage)"><img src="../../../../../../assets/report_v2/delete-circled.svg" alt=""></button>
              </div>
            </ng-container>
            <ng-template #otherTranslated>
              <div *ngIf="this.selectedFilterText !== 'Removed sources' ? !langSources.hide && langSources.percentage > 0 && !langSources.isSentenceExcluded : true"
                class="source" tabindex="1" focusable
                [ariaLabel]="'Source number ' + langSources.no + ', similarity ' + (langSources.percentage != null ? (langSources.percentage == 0 ? '0' : langSources.percentage?.toFixed(2)) : 'N/A') + '%. Press the X button to remove the langSources.'">
                    <button tabindex="0" [ariaLabel]="'Source Number' + langSources.no" class="source_no" (click)="selectSourceToHighlight(langSources.no)" [ngClass]="{'selectedSource' : selectedSources.includes(langSources.no)}">{{ langSources.no }}</button>
                    <span tabindex="-1" class="source_percentage">{{ (langSources?.percentage == 0 ? 0 : langSources?.percentage?.toFixed(2)) }}</span>
                    <a tabindex="0" *ngIf="langSources.sourceUrl" class="source_url" aria-label="Source URL. Click to open the source." [href]="langSources.sourceUrl" target="_blank">{{ langSources.sourceUrl }}</a>
                    <li tabindex="0" *ngIf="!langSources.sourceUrl" (click)="startDocumentPreview(langSources.sourceId,lang)" class="source_url">{{ 'new_entries.private_document_source' | translate }}</li>
                    <button *ngIf="canEdit" aria-label="Remove Source" class="remove_source" [ngClass]="{'rotate':langSources.isExcluded}" (click)="sourceAction(langSources.no,langSources.sourceUrl,langSources.sourceId, !langSources.isExcluded, langSources.percentage, currentTranslateLanguage)"><img src="../../../../../../assets/report_v2/delete-circled.svg" alt=""></button>
                  </div>
                <span *ngIf="langSources?.length == 0">{{ 'new_entries.no_sources_for_filter' | translate }}</span>
            </ng-template>
          </ng-container>
          <span *ngIf="similarityAnalysis.translatedSources.length == 0">{{ 'new_entries.no_sources_for_filter' | translate }}</span>
          <button aria-label="View More Sources" class="more_sources" (click)="loadMoreSources(lang)" *ngIf="shouldShowLoadMoreButton(lang)">{{ 'new_entries.view_more_sources' | translate }}</button>
        </section>
      </section>
    </section>
  </section>

  <app-overview-disclaimer tabindex="0" [title]="'general.overview' | translate" [disclaimerText]="'new_entries.similarity_analysis_info' | translate" [disclaimerLink]="'https://support.inspera.com/hc/en-us/articles/20930474319901-Similarity-Analysis'" [linkText]="'new_entries.learn_more' | translate"></app-overview-disclaimer>
</ng-container>

<ng-template #notOnAnalysis>
    <section *ngFor="let lang of document_languages | slice:0:2; let i = index" tabindex="0" class="language_bar" [ariaLabel]="[(lang.type) + ' language - ' + (lang.type == 'Original' ? lang.language : transLangs) + ' - Similarity ' + (lang.type == 'Original' ? (percentageWithQuotes ? originalPercentage || 0 : originalPercentageWithoutQuotes || 0) : (percentageWithQuotes ? overallTransltedPercentage || 0 : overallTransltedPercentageWithoutQuotes || 0)) + '%']">
      <section class="similarity_overview_parent">
        <section class="similarity_overview">
          <div class="overview-head">
            <img [src]="lang.type == 'Original' ? '../../../../../../assets/report_v2/original_lang_nav.svg' : '../../../../../../assets/report_v2/translated_lang_nav.svg'" alt="Borderless Flag">
            <section class="overview">
              <div><span>{{ (lang.type == 'Original' ? 'app.original_language' : 'app.upload.translated_language') | translate }}:</span><span class="answ">{{ lang.type == 'Original' ? lang.language : transLangs.join(', ') }}</span></div>
              <div><span>{{ 'new_entries.matching_sources' | translate }}:</span><span class="answ">{{ lang.type == 'Original' ? topOriginalSources.length : topTranslatedSources.length }}</span></div>
            </section>
            <div class="overview-tag"><p class="tag" [ngClass]="{'low': (lang.percentage >= 0 && lang.percentage <= 5), 'medium': lang.percentage > 5 && lang.percentage <= 20, 'high': lang.percentage > 20 && lang.percentage <= 100}"><span class="round"></span>{{ lang.type == 'Original' ? (lang.percentage == 0 ? '0' : lang.percentage?.toFixed(2)) : (similarityAnalysis.submission.translatedPercentage == 0 ? '0' : similarityAnalysis.submission.translatedPercentage?.toFixed(2)) }}%</p></div>
          </div>
          <section class="show_more">
            <button [ngClass]="showTopSources === i ? 'rotateImg' : ''" (click)="toggleTopSources(i, lang)"><img src="../../../../../../assets/report_v2/arrow_down.svg" alt="Arrow Down">{{ (showTopSources === i ? 'new_entries.hide_top_sources' : 'new_entries.show_top_sources') | translate }}</button>
          </section>
        </section>
        <section class="top_sources" [ngClass]="showTopSources === i ? 'open' : ''">
          <section class="top_sources_header">
            <h5 tabindex="0">{{ 'new_entries.top_sources' | translate }} ({{('new_entries.' + selectedFilterText.toLowerCase().split(' ').join('_').replace('%', '_percent')) | translate}})</h5>
            <button (click)="toggleSummaryMenu()" class="sources_menu_btn">
              <img src="../../../../../../assets/report_v2/three-dots.svg" alt="Three Dots">
              <section *ngIf="isSummaryMenuOpen" class="sources_menu" cdkTrapFocus>
                <button class="sources_menu_opt" aria-label="Click to show matching sources" (click)="filterSources(0)">{{ 'new_entries.show_matching_sources' | translate }}</button>
                <button class="sources_menu_opt" aria-label="Click to show only sources from document" (click)="filterSources(1)">{{ 'new_entries.show_only_document_sources' | translate }}</button>
                <button class="sources_menu_opt" aria-label="Click to show only sources from internet" (click)="filterSources(2)">{{ 'new_entries.show_only_internet_sources' | translate }}</button>
                <button class="sources_menu_opt" aria-label="Click to show removed sources" (click)="filterSources(3)">{{ 'new_entries.show_removed_sources' | translate }}</button>
                <button class="sources_menu_opt" aria-label="Click to show sources below 1%" (click)="filterSources(4)">{{ 'new_entries.sources_below_1_percent' | translate }}</button>
              </section>
            </button>
          </section>
          <section class="top_analyses">
            <ng-container *ngIf="lang.type == 'Original'">
              <section class="content" *ngFor="let source of filteredSources" [ngClass]="source.isExcluded ? 'excluded' : ''">
                <section class="head" tabindex="0" [ariaLabel]="'This source is a ' + (source.type == 1 ? 'Submission Source' : (source.type == 2 ? 'Internet Source' : 'Premium Source')) + ' with ' + (source.percentage == 0 ? 0 : source.percentage?.toFixed(2)) + '% similarity.'">
                   <ng-container *ngIf="source.type == 1">
                    <img src="../../../../../../assets/report_v2/file_source.svg" alt="Submission Source">
                    <p>{{ 'general.submissions' | translate }}</p>
                  </ng-container>
                  <ng-container *ngIf="source.type == 2">
                    <img src="../../../../../../assets/report_v2/internet_source.svg" alt="Internet Source">
                    <p>{{ 'report.internet_sources' | translate }}</p>
                  </ng-container>
                  <ng-container *ngIf="source.type == 3">
                    <img src="../../../../../../assets/report_v2/premium_source.svg" alt="Premium Source">
                    <p>{{ 'new_entries.premium_content' | translate }}</p>
                  </ng-container>
                  <button [ariaLabel]="source.isExcluded ? 'Include Source' : 'Exclude Source'" *ngIf="canEdit" (click)="sourceAction(source.no,source.sourceUrl,source.sourceId, !source.isExcluded, source.percentage, source.language)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt="Remove Source"></button>
                </section>
                <section class="body">
                  <p class="percentage">{{ source.percentage == 0 ? 0 : source.percentage?.toFixed(2)  }}%</p>
                  <ng-container *ngIf="source.type == 1">
                    <p class="clickable" (click)="startDocumentPreview(source.sourceId,lang)">{{ 'new_entries.previous_submission' | translate }}</p>
                  </ng-container>
                  <ng-container *ngIf="source.type == 2">
                    <a [href]="source.sourceUrl">{{ source.sourceUrl }}</a>
                  </ng-container>
                  <ng-container *ngIf="source.type == 3">
                    <p>{{ 'new_entries.premium_content' | translate }}</p>p
                  </ng-container>
                </section>
              </section>
              <ng-container *ngIf="filteredSources.length == 0">
                <span class="no_sources">{{ 'new_entries.no_sources_for_filter' | translate }}!</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="lang.type == 'Translated'">
              <section class="content" *ngFor="let source of filteredSources" [ngClass]="source.isExcluded ? 'excluded' : ''">
                <section class="head">
                   <ng-container *ngIf="source.type == 1">
                    <img src="../../../../../../assets/report_v2/file_source.svg" alt="Submission Source">
                    <p>{{ 'general.submissions' | translate }} (<span>{{ getLanguageName(source.languageCode) }}</span>)</p>
                  </ng-container>
                  <ng-container *ngIf="source.type == 2">
                    <img src="../../../../../../assets/report_v2/internet_source.svg" alt="Internet Source">
                    <p>{{ 'report.internet_sources' | translate }} (<span>{{ getLanguageName(source.languageCode) }}</span>)</p>
                  </ng-container>
                  <ng-container *ngIf="source.type == 3">
                    <img src="../../../../../../assets/report_v2/premium_source.svg" alt="Premium Source">
                    <p>{{ 'new_entries.premium_content' | translate }} (<span>{{ getLanguageName(source.languageCode) }}</span>)</p>
                  </ng-container>
                  <button *ngIf="canEdit" (click)="sourceAction(source.no,source.sourceUrl,source.sourceId, !source.isExcluded, source.percentage, source.languageCode)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt="Remove Source"></button>
                </section>
                <section class="body">
                  <p class="percentage">{{ source.percentage == 0 ? 0 : source.percentage?.toFixed(2) }}%</p>
                  <ng-container *ngIf="source.type == 1">
                    <p class="clickable" (click)="startDocumentPreview(source.sourceId,lang)">{{ 'new_entries.previous_submission' | translate }}</p>
                  </ng-container>
                  <ng-container *ngIf="source.type == 2">
                    <a [href]="source.sourceUrl">{{ source.sourceUrl }}</a>
                  </ng-container>
                  <ng-container *ngIf="source.type == 3">
                    <p>{{ 'new_entries.premium_content' | translate }}</p>
                  </ng-container>
                </section>
              </section>
              <ng-container *ngIf="filteredSources.length == 0">
                <span class="no_sources">{{ 'new_entries.no_sources_for_filter' | translate }}!</span>
              </ng-container>
            </ng-container>
          </section>
        </section>
      </section>
    </section>
</ng-template>
