import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './../../../services/auth.service';
import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/authModule/state/authentication.reducer';
import { first } from 'rxjs/operators';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { User } from 'src/app/models/user';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
   selector: 'app-settings',
   templateUrl: './settings.component.html',
   styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
   /**
    * Variable used to hide/show password
    */
   showPassword;
   /**
    * Variable used to hide/show password
    */
   showConfirmPassword;
   /**
    * Variable used to hide/show password
    */
   showNewPassword;
   /**
    * Password Form Group
    */
   passwordForm: FormGroup;
   /**
    * User from the store, used to get user id and send it to api
    */
   user: User;
   /**
    * Component Constructor
    * @param fb
    * @param router
    * @param userService
    * @param authService
    * @param spinner
    * @param toastrService
    */
   constructor(
      private fb: FormBuilder,
      private router: Router,
      private userService: UserService,
      private authService: AuthService,
      private spinner: NgxSpinnerService,
      private toastrService: ToastrService,
      private store: Store<UserState>,
      public translate: TranslateService
   ) { }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.passwordForm = this.fb.group({
         currentPassword: ['', [Validators.required]],
         newPassword: [
            '',
            [
               Validators.required,
               Validators.pattern(
                  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
               ),
            ],
         ],
         confirmNewPassword: [
            '',
            [
               Validators.required,
               Validators.pattern(
                  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
               ),
            ],
         ],
      });
      this.store
         .select(getCurrentUser)
         .pipe(first())
         .subscribe((currentUser) => (this.user = currentUser));
   }

   activateAccount() {
      this.userService.ltiUserActivate().subscribe((data) => {
         console.log(data);

      })
   }

   /**
    * Method used to form group controls
    */
   get form() {
      return this.passwordForm.controls;
   }
   /**
    * Method used to update User password.
    */
   async updatePassword(): Promise<void> {
      const result = await Swal.fire({
         title: this.translate.instant('notifications.are_you_sure'),
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('notifications.yes_change_the_password'),
         cancelButtonText: this.translate.instant('report.cancel'),
      });
      if (result.isConfirmed) {
         this.spinner.show();
         this.userService
            .resetPassword(
               this.passwordForm.value.currentPassword,
               this.passwordForm.value.newPassword,
               this.passwordForm.value.confirmNewPassword
            )
            .subscribe(
               (data) => {
                  this.spinner.hide();
                  this.toastrService.success(this.translate.instant('report.password_updated_successfully'));
                  this.authService.logout();
                  this.router.navigateByUrl('/login');
               },
               (error) => {
                  this.spinner.hide();
                  console.log('Error:', error);
                  this.toastrService.error(error.error.message);
               }
            );
      }
   }
   /**
    * Method used to show/hide password
    */
   password() {
      this.showPassword = !this.showPassword;
   }
   /**
    * Method used to show/hide password
    */
   confirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
   }
   newPassword() {
      this.showNewPassword = !this.showNewPassword;
   }
}
