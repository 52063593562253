// *** Angular
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

// *** Packages
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

// *** Actions
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';

//*** Services
import { ExcelService } from '../../services/excel.service';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadfileService } from 'src/app/individual-components/individual-uploads/upload-file/Service/uploadfile.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-faculty-register',
   templateUrl: './faculty-register.component.html',
   styleUrls: ['./faculty-register.component.scss'],
})
export class FacultyRegisterComponent implements OnInit, OnDestroy {
   /**
    * Variable used to know if user is using excel form or manual form
    */
   excel = false;
   /**
    * Form group used to get user input
    */
   facultyRegister: FormGroup;
   getInstitutionFacultiesStateDetails$: any;
   fileToUpload: File;
   currentLanguage: string;
   constructor(
      private fb: FormBuilder,
      private store: Store,
      private excelService: ExcelService,
      private spinner: NgxSpinnerService,
      private uploadfileService: UploadfileService,
      private languageService: LanguageService,
      public translate: TranslateService,
      @Optional() public dialogRef: MatDialogRef<any>,
      /**
       * Used to pass data in component through dialog
       */
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
   ) {}
   ngOnDestroy(): void {
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });
      this.facultyRegister = this.fb.group({
         faculties: this.fb.array([]),
      });
      this.addFaculty();
      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.facultyRegistered !== null) {
               if (this.dialogRef) {
                  this.dialogRef.close();
               } else {
                  this.facultyRegister = this.fb.group({
                     faculties: this.fb.array([]),
                  });
                  this.addFaculty();
                  this.store.dispatch(
                     facultyActions.setRegisterFacultyToNull()
                  );
               }
            }
         });
   }
   /**
    * Method is used to toggle between excel and manual form.
    */
   toggleForm(formTodisplay) {
      if(formTodisplay=='manual') {
         this.excel = false;
      } else {
         this.excel = true;
      }

   }
   /**
    * Method is used to submit faculty information to service.
    */
   async submit() {
      const result = await Swal.fire({
         title: 'You are about to add faculties, please confirm your action by clicking “Confirm”.',
         // icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            facultyActions.facultiesRegister({
               data: this.facultyRegister.value.faculties,
            })
         );
      }
   }

   /**
    * Method is used to get faculties from @facultyRegister
    */
   get facultiesForm() {
      return this.facultyRegister.get('faculties') as FormArray;
   }

   /**
    * Method is used to add new faculties on formGroup  @facultyRegister
    */
   addFaculty() {
      const faculties = this.fb.group({
         facultyName: ['', [Validators.required, Validators.minLength(3)]],
         facultyDescription: [
            '',
            [Validators.required, Validators.minLength(3)],
         ],
      });
      this.facultiesForm.push(faculties);
   }

   /**
    * Method is used to remove element from formGroup @facultyRegister
    * @param i
    */
   removeFaculty(i) {
      this.facultiesForm.removeAt(i);
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.facultyRegister.controls;
   }

   closeModal() {
      this.dialogRef.close();
   }
}
