import { Source } from './../../models/source';
import {
  getActiveSource,
  getActiveSourceNav,
  getAllManipulations,
  getAllSentences,
  getImagesDetectionNav
} from './../state/selectors/report.selectors';
import { getCurrentUser } from './../../authModule/state/authentication.selectors';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  ElementRef,
  Inject,
  HostListener,
  QueryList,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as reportActions from '../state/actions/report.actions';
import { getCurrentSubmission } from '../state/selectors/report.selectors';
import { Submissions } from 'src/app/models/submissions';
import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material/dialog';
import { SentenceInformationModalComponent } from './sentence-information-modal/sentence-information-modal.component';
import { SourcePreviewComponent } from './sourcePreview/source-preview/source-preview.component';
import { Observable, Subscription } from 'rxjs';
import * as Mark from 'mark.js';
import { SubmissionsService } from '../../services/submissions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ProfessorDeleteModalComponent } from './professor-delete-modal/professor-delete-modal.component';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { ManipulationActions } from './manipulations-modal/manipulations.component';
import { data } from 'jquery';
import { CharReplacementModal } from './char-replacement-modal/char-replacement.component';

/**
 * Report component used for analyzing submission plagiarism.
 */

export interface CustomSourceEvent extends CustomEvent {
  source: any;
  pageNumber: number;
}
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
   s3Json: any;
   deleteModal: boolean;
   /**
    * Component Constructor
    * @param document
    * @param route
    * @param store
    * @param dialog
    * @param submissionService
    * @param fileService
    * @param spinner
    */
   constructor(
      @Inject(DOCUMENT) private document: Document,
      private route: ActivatedRoute,
      private store: Store,
      public dialog: MatDialog,
      private submissionService: SubmissionsService,
      private spinner: NgxSpinnerService,
      private titleService: Title,
      private http: HttpClient,
      private userService: UserService,
      private router: Router,
      private translate: TranslateService,
      private languageService: LanguageService,
      private renderer: Renderer2,
      private el: ElementRef,
   ) {
   }

   aiNavigation = [];
   manipulationNavigation = [];
   revealDiv = false;
   excludedSourcesShow: boolean = false;
   sentenceId: any;
   selectedPage;
   expandedPdf: boolean = false;
   selected;
   showText: boolean = false;
   documentLanguage: string;
   highlightQuotes;
   /**
    * Boolean variable used to show/hide scroll on top button on mobile view.
    */
   windowScrolled: boolean;
   MLPlagIndex: boolean = false;
   /**
    * Boolean variable use to check if download button is checked
    */
   plagiarismLabels;
   downloadClicked: boolean = false;
   expandedIndex: number = -1;
   selectedSources: boolean = false;
   MLplagSources;
   activePlagSources: any;
   crossPlag: number = 0;
   allSources: boolean = true;
   topSources: boolean = false;
   internetSources: boolean = false;
   documentSources: boolean = false;
   largest: number = 0;
   topThreeSources: Array<Source> = [];
   filteredSources: Array<any>;
   crossPlagIndex: boolean = true;
   filterSources: boolean = false;
   defaultSourceSelect: boolean = true;
   @ViewChildren('checkboxes') checkBoxes: QueryList<ElementRef>;
   pdfSrc;
   tempPdfSrc;
   pdfImageSrc;
   count = 0;
   @ViewChild('search', { static: false }) searchElemRef: ElementRef;
   sourceId;

   sources: Array<Source> = [];

   excludedSource = [];

   studentDeletionPermission;
   isDocumentInArchive;
   sourcesAreDeleted;
   translatedLanguage;
   tooltipVisible = false;

   languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
      {
        text: 'Danish',
        value: 'da'
     },
     {
        text: 'Estonian',
        value: 'et'
     }
   ];

   /**
    * Id of the current submission that report is being generated. We used to store current submission id & get current submission details.
    */
   currentSubmissionId: string;
   /**
    * Current Submission Details Subscriber. Used to store state & to get state changes in case of changes.
    */
   currentSubmissionDetails$: Observable<Submissions>;
   /**
    * Current User Observable. Used to store user state & to get state changes in chase of changes.
    */
   currentUser$: Observable<User>;
   currentUserDetailsSubscriber$: Subscription;
   currentUserDetails: User;
   /**
    * A part of DOM that is used on @function doHighlight() to add highlight on selected DOM
    */
   markInstance;
   /**
    * Variable used to store all not found text within first try to highlight then it will be used on algorithm that gets text from each page and compare
    *  for each character till its same length of successful characters and it will be send to highlight again.
    */
   notFound = [];
   /**
    * Array used to store loaded pages.
    */
   loadedPages = [];
   scrolling = false;
   numberOfDifference = 2;
   pagesToDraw = [];
   pagesToDestroy = [];
   loadedPagesId = [];
   oldPage = 1;
   /**
    * Subscriber used to subscribe to crossplag sources from store.
    */
   crossSourcesSub$;
   /**
    * Subscriber used to subscribe to mlplag sources from store.
    */
   MLPlagSourcesSub$;
   /**
    * Subscriber used to subscribe to navigation data from store.
    */
   navigationSourceSub$;
   /**
    * Fist page of pdf.
    */
   page: any;
   pages: any;
   pagesDrawn = [];
   documentPages;
   totalPages;
   dataClone;
   pdfLoadedPages = [];
   currentSubmissionDetailsSubscribe$;
   currentSubmissionDetails;
   canEdit: boolean;
   excludeSources;
   excludedSourceChecked = 0;
   displayAiText = false;
   displayManipulations = false;
   aiSentences;
   totalSentencesWithAI = 0;
   ai_model = 'base';
   skipLinkVisible = false;
   isExpanded;
   currentLanguage: string = localStorage.getItem('websiteLanguage');
   isCharReplacement = false;
   isHiddenText = false;
   isImage = false;
   sentenceDetails: any;
   sentenceDetails$: any;
   allManipulations: any;
   allManipulations$: any;
   imagesManipulationNav: any;
   imagesManipulationNav$: any;
   totalCharReplacements = 0;
   totalWhiteCharacters = 0;
   totalManipulatedImages = 0;
   replacementCharacterSentences: any = [];
   whiteCharacterSentences: any = [];
   currentManipulationType: 'charReplacement' | 'hiddenText' | 'isImage' = 'charReplacement';
   currentPageBeforeSwitch: number = 1;
    author;
   currentPage: number = 1;
   currentStartPage: number = 0;
   pagesPerView: number = 10;
   visiblePages: { page: number, numberOfSentences: number }[] = [];
   replacedCharacterList;
   includedCharactersSentences: any = [];
   excludedCharactersSentences: any = [];
   private selectedElement: HTMLElement;
   redImages = [];
   greenImages = [];
   excludedHiddenTextSentences: any = [];
   includedHiddenTextSentences: any = [];

   ngOnInit(): void {

    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });

      this.route.queryParams.subscribe(params => {
         if (params['expanded']) {
           this.isExpanded = true;
         }
       });

      this.spinner.show();
      this.page = 1;
      this.selectedPage = 1;
      this.selected = false;
      this.currentSubmissionId = this.route.snapshot.paramMap.get('id');
      this.submissionService
         .getSubmissionPlagData(this.currentSubmissionId)
         .pipe(first())
         .subscribe(
            (data) => {
              this.author = data.submission.author
               this.pdfSrc = data.fileUrl;
               this.tempPdfSrc = data.fileUrl;
               this.pdfImageSrc = data.imagesDetectionUrl;
              //  this.totalManipulatedImages = this.extractManipulationNumber(this.pdfImageSrc);
               this.studentDeletionPermission = data.studentDeletionPermission;
               this.isDocumentInArchive = data.isDocumentInArchive;
               this.sourcesAreDeleted = data.sourcesAreDeleted;
               this.aiSentences = data.sentencesWithAI;

               this.store.dispatch(
                  reportActions.getSubmissionPlag({
                     presignedUrl: data.presignedUrlJson,
                  })
               );
            },
            (error) => {
               console.log('error', error);
            }
         );
      this.currentSubmissionDetails$ = this.store.select(getCurrentSubmission);

    this.currentUser$ = this.store.select(getCurrentUser);
    this.currentUserDetailsSubscriber$ = this.store
      .select(getCurrentUser)
      .subscribe((data: User) => {
        this.currentUserDetails = data;
        this.spinner.hide();
        this.canEdit = true;
            if(this.currentSubmissionId !== "clmpm7jud00090wjq5pjs5545" && this.currentSubmissionId !== "clnvm3cjk0009eod5m7iq0w1p") {
               if ( this.currentUserDetails?.roleId === 2) {
                  this.canEdit = !!(
                     this.currentUserDetails?.institutionId === null
                  );
               }
               if (this.currentUserDetails?.roleId === 5 || this.currentUserDetails?.roleId === 3 ||  this.currentUserDetails?.roleId === 9) {
                  this.canEdit = false;
               }
            }


      });

      this.currentSubmissionDetailsSubscribe$ = this.store
         .select(getCurrentSubmission)
         .subscribe((data) => {
            this.currentSubmissionDetails = data;
            this.deleteModal = false;
            if(this.currentSubmissionId !== "clmpm7jud00090wjq5pjs5545" && this.currentSubmissionId !== "clnvm3cjk0009eod5m7iq0w1p") {
            if(((this.currentUserDetails?.roleId == 4 || this.currentUserDetails?.roleId == 10) && !this.currentSubmissionDetails?.assignmentsId && !this.currentSubmissionDetails?.thesisId) || (this.currentUserDetails?.roleId === 2 || this.currentUserDetails?.institutionId === null) || (this.currentUserDetails?.roleId === 5 || this.currentUserDetails?.roleId === 9)) {
             this.deleteModal = true;
            }
            if (this.currentSubmissionDetails && this.currentSubmissionDetails?.title) {
              let lastDotIndex = this.currentSubmissionDetails?.title.lastIndexOf('.');
              if (lastDotIndex !== -1) {
                let documentTitle = this.currentSubmissionDetails?.title.substring(0, lastDotIndex);
                this.titleService.setTitle(`Similarity Report for '${documentTitle}' | Inspera Originality'`);
              } else {
                this.titleService.setTitle(`Similarity Report for '${this.currentSubmissionDetails?.title}' | Inspera Originality'`);
              }
            }

    this.navigationSourceSub$ = this.store
      .select(getActiveSourceNav(this.crossPlagIndex))
      .pipe()
      .subscribe((data) => {
        if (this.plagiarismLabels == undefined) {
          this.plagiarismLabels = data;
        }
        if (data !== this.plagiarismLabels) {
          this.plagiarismLabels = data;
        }


        if (this.plagiarismLabels.length < this.totalPages) {
          this.plagiarismLabels.push({page: this.totalPages, numberOfSentences: 0});
        }
      });
         }
            if (data !== null) {
               this.highlightQuotes = this.currentSubmissionDetails?.quotesToggle;
               this.documentLanguage = this.getDocumentLanguage(
                  this.currentSubmissionDetails?.originalLanguage
               );
               this.translatedLanguage = this.getDocumentLanguage(
                  this.currentSubmissionDetails?.translatedLanguage
               );
            }
         });

      this.navigationSourceSub$ = this.store
         .select(getActiveSourceNav(this.crossPlagIndex))
         .pipe()
         .subscribe((data) => {
            if (this.plagiarismLabels == undefined) {
               this.plagiarismLabels = data;
            }
            if (data !== this.plagiarismLabels) {
               this.plagiarismLabels = data;
            }

            if (this.plagiarismLabels.length < this.totalPages) {
              this.plagiarismLabels.push({page: this.totalPages, numberOfSentences: 0});
            }
         });

      // subscribing to sources in store
      this.crossSourcesSub$ = this.store
         .select(getActiveSource(this.crossPlagIndex))
         .pipe()
         .subscribe((data) => {
            if (this.activePlagSources == undefined) {
               this.activePlagSources = data[0];
               this.excludeSources = data[1];
            }
            this.notFound = [];
            if (data !== this.activePlagSources) {
               this.activePlagSources = data[0];
               this.excludeSources = data[1];
            }
            this.reCallHighlight();
            this.getFilteredSources(this.documentSources ? 1 : this.internetSources ? 2 : this.topSources ? 'top3' : 'all');
         });

        this.sentenceDetails$ = this.store
      .select(getAllSentences())
      .subscribe((data) => {
        console.log(data, 'sentenceDetails');

        this.sentenceDetails = data;
        this.checkAndOpenFlags();

        this.excludedHiddenTextSentences = [];
        this.includedHiddenTextSentences = [];
        this.sentenceDetails?.forEach((sentence: any) => {
          if (sentence.characters && sentence.characters.length > 0) {
            if (sentence.whitecharactersExcluded) {
              this.excludedHiddenTextSentences.push(sentence);
            } else {
              this.includedHiddenTextSentences.push(sentence);
            }
          }
        });

        if (this.sentenceDetails?.some((sentence: any) => sentence?.characters && sentence?.characters.some((char: any) => char.isWhite && !sentence.whitecharactersExcluded))) {
          this.totalWhiteCharacters = this.sentenceDetails.reduce((total: number, sentence: any) => {
            if (!sentence.whitecharactersExcluded && sentence.characters) {
              return total + sentence.characters.filter((char: any) => char.isWhite).length;
            }
            return total;
          }, 0);
        } else {
          this.totalWhiteCharacters = 0;
        }
      });

      this.allManipulations$ = this.store.
      select(getAllManipulations())
      .subscribe((data) => {
        this.allManipulations = data;
        if (this.allManipulations?.replacedCharacters) {
          this.totalCharReplacements = this.allManipulations.replacedCharacters.reduce((total, obj) => total + obj.count, 0);
          this.replacedCharacterList = this.allManipulations.replacedCharacters.map(char => char.character);

          this.includedCharactersSentences = [];
          this.excludedCharactersSentences = [];

          this.sentenceDetails?.forEach((sentence: any) => {
            if (sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {
              if (sentence.replacedCharacters.filter((char: any) => this.replacedCharacterList.includes(char)).length > 0) {
                this.includedCharactersSentences.push(sentence);
              } else {
                this.excludedCharactersSentences.push(sentence);
              }
            }
          });
          this.calculateNavManipulations();
        }
      });

        this.updateImageManipulations();
      // this.imagesManipulationNav$ = this.store.select(getImagesDetectionNav).pipe().subscribe((data) => {
      //   this.imagesManipulationNav = data;
      //   data?.forEach((imageNav: any) => {
      //     if (imageNav.label == 'red') {
      //       this.redImages.push(imageNav);
      //       this.totalManipulatedImages++
      //     } else if (imageNav.label == 'green') {
      //       this.greenImages.push(imageNav);
      //     }
      //   });
      // });
   }

   excludeManipulatedImage(image: any) {
    swal.fire({
      title: 'Are you sure you want to exclude this image from the manipulations?',
      showDenyButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      denyButtonText: this.translate.instant('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submissionService
        .toggleManipulatedImages(this.currentSubmissionId, image.index, true)
        .pipe()
        .subscribe((data: any) => {
          this.store.dispatch(
            reportActions.getSubmissionPlag({
              presignedUrl: data?.presignedUrlJson,
            })
          )
          this.updateImageManipulations();
          this.calculateNavManipulations();
          swal.fire({
            title: 'Image is excluded in manipulations!',
            icon: 'success',
            footer: `Please be aware that this  action might take  up to 3 minutes to reflect in the report, you can always refresh the  page on the  manipulations sidebar.`,
          }).then(() => {
            this.showTooltipTemporarily();
          })
        })
      } else if (result.isDenied) {
        swal.fire("Image is not excluded from manipulations!", '', 'info');
      }
    });

   }

   includeManipulatedImage(image: any) {
    swal.fire({
      title: 'Are you sure you want to include this image in the manipulations?',
      showDenyButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      denyButtonText: this.translate.instant('general.no'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submissionService
        .toggleManipulatedImages(this.currentSubmissionId, image.index, false)
        .pipe()
        .subscribe((data: any) => {
          this.store.dispatch(
            reportActions.getSubmissionPlag({
              presignedUrl: data?.presignedUrlJson,
            })
          )
          this.updateImageManipulations();
          this.calculateNavManipulations();
          swal.fire({
            title: 'Image is included in manipulations!',
            icon: 'success',
            footer: `Please be aware that this  action might take  up to 3 minutes to reflect in the report, you can always refresh the  page on the  manipulations sidebar!`,
          }).then(() => {
            this.showTooltipTemporarily();
          })
        })
      } else if (result.isDenied) {
        swal.fire("Image is not included in manipulations!", '', 'info');
      }
    });
   }

   async refreshPdf() {
    this.spinner.show();
    this.changeView('similarity');
    await this.delay(300)
    this.changeView('manipulations');
    this.spinner.hide();
   }

  delay(arg0: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, arg0);
    });
  }

   showTooltipTemporarily(): void {
    this.tooltipVisible = true;
    setTimeout(() => {
      this.tooltipVisible = false;
    }, 3000);
  }

   updateImageManipulations() {
    this.imagesManipulationNav$ = this.store.select(getImagesDetectionNav).pipe().subscribe((data) => {
        this.imagesManipulationNav = data;
        this.redImages = [];
        this.greenImages = [];
        if (data && data.length > 0) {
          data?.forEach((imageNav: any) => {
              if (imageNav.label == 'red') {
                  this.redImages.push(imageNav);
              } else if (imageNav.label == 'green') {
                  this.greenImages.push(imageNav);
              }
          });
          this.totalManipulatedImages = this.redImages?.length
        }
        if (this.isImage) {
          this.currentManipulationType = 'isImage';
      }

      this.calculateNavManipulations();
    });
}

   ensureAllPagesAreCorrect(totalPages: any): void {
    const existingPages = this.plagiarismLabels.map((label: any) => label.page);

    for (let page = 1; page <= totalPages; page++) {
      if (!existingPages.includes(page)) {
        this.plagiarismLabels.push({ page, numberOfSentences: 0 });
      }
    }
    this.plagiarismLabels.sort((a: any, b: any) => a.page - b.page);
    this.updateVisiblePages(this.plagiarismLabels, 'similarity');
   }

   extractManipulationNumber(url: string): number | null {
    if (url !== null && url !== undefined) {
      const regex = /_imagesdetected_(\d+)\.pdf/;
      const match = url?.match(regex);

      if (match && match[1]) {
          return parseInt(match[1], 10); // Convert the matched string to a number
      }
    }
    return 0; // Return null if no match is found
  }

  calculateNavManipulations() {
    let navManipulations: any = [];

    // Initialize navManipulations with all pages set to 0 manipulations
    const totalPages = this.totalPages; // Assuming this.totalPages contains the total number of pages
    for (let page = 1; page <= totalPages; page++) {
      navManipulations.push({ page: page, numberOfSentences: 0 });
    }

    // Iterate  through includedCharactersSentences and update navManipulations for charReplacement
    this.includedCharactersSentences?.forEach((sentence: any) => {
      const page = sentence.page;
      const existingPage = navManipulations.find((item: any) => item.page === page);

      let charCount = sentence.replacedCharacters ? sentence.replacedCharacters.length : 0;

      if ((this.currentManipulationType === 'charReplacement' && charCount > 0)) {
        if (existingPage) {
          existingPage.numberOfSentences += charCount;
        }
      }
    });

    // Iterate through sentenceDetails and update navManipulations for hiddenText
    this.sentenceDetails?.forEach((sentence: any) => {
      const page = sentence.page;
      const existingPage = navManipulations.find((item: any) => item.page === page);

      let whiteCharCount = sentence.characters ? sentence.characters.filter((char: any) => char.isWhite).length : 0;

      if ((this.currentManipulationType === 'hiddenText' && whiteCharCount > 0)) {
        if (existingPage) {
          existingPage.numberOfSentences += whiteCharCount;
        }
      }
    });

  // Integrate imagesManipulationNav
  if (this.currentManipulationType === 'isImage') {
    this.imagesManipulationNav?.forEach((imageNav: any) => {
      if (imageNav.label == 'red') {
        const page = imageNav.page + 1; // Increment page number by 1
        const existingPage = navManipulations.find((item: any) => item.page === page);
        if (existingPage) {
          existingPage.numberOfSentences += 1;
        } else {
          navManipulations.push({ page: page, numberOfSentences: 1 });
        }
      }
    });
  }

    this.manipulationNavigation = navManipulations;
    this.updateVisiblePages(this.manipulationNavigation, 'manipulations');
  }

  updateVisiblePages(nav: any, type: string) {
    if (nav && nav.length > 0) {
      const totalPages = Math.min(nav.length, this.pagesPerView)
      if (this.currentStartPage + totalPages > nav.length) {
        this.visiblePages = nav.slice(nav.length - totalPages, nav.length);
      } else {
        this.visiblePages = nav.slice(this.currentStartPage, this.currentStartPage + totalPages);
      }
    }
  }

  scrollToNextPages() {
    if (this.currentStartPage + this.pagesPerView < this.plagiarismLabels.length) {
      this.currentStartPage += this.pagesPerView;
    } else {
      this.scrollToEnd();
    }
    this.scrollSelectedNav();

  }

  scrollToPreviousPages() {
    if (this.currentStartPage - this.pagesPerView >= 0) {
      this.currentStartPage -= this.pagesPerView;
    } else {
      this.currentStartPage = 0;
    }
    this.scrollSelectedNav();
  }

  scrollToBeginning() {
    this.currentStartPage = 0;
    this.scrollSelectedNav();
  }

  scrollToEnd() {
    this.currentStartPage = this.plagiarismLabels.length - 1;
    this.scrollSelectedNav();
  }

  scrollSelectedNav() {
    if (!this.displayAiText && !this.displayManipulations) {
      this.updateVisiblePages(this.plagiarismLabels, 'similarity');
    } else if (this.displayAiText && !this.displayManipulations) {
      this.updateVisiblePages(this.aiNavigation, 'ai')
    } else if (this.displayManipulations && !this.displayAiText) {
      this.updateVisiblePages(this.manipulationNavigation, 'manipulations')
    }
    this.scrollPage(this.currentStartPage + 1);
  }

  selectSentence(event: Event) {
    const target = event.target as HTMLElement;
      if (this.selectedElement) {
        this.renderer.removeClass(this.selectedElement, 'selected');
      }

      this.selectedElement = target.closest('.content');
      if (this.selectedElement) {
        this.renderer.addClass(this.selectedElement, 'selected');
      }
   }


  callBackFn(pdf: PDFDocumentProxy) {
    if (pdf) {
      this.totalPages = pdf?.numPages;
      setTimeout(() => {
        if (!this.displayAiText && !this.displayManipulations) {
          this.ensureAllPagesAreCorrect(pdf?.numPages);
        }
        this.getCrossPlag();
      }, 300);
    }
  }

  ngOnDestroy(): void {
    this.crossSourcesSub$.unsubscribe();
    this.MLPlagSourcesSub$.unsubscribe();
    this.navigationSourceSub$.unsubscribe();
    this.currentSubmissionDetailsSubscribe$.unsubscribe();
  }

  @ViewChild('mainDocument') mainElement: ElementRef | undefined;
  @ViewChild('changeView_1') changeViewElement: ElementRef | undefined;

  @HostListener('document:keydown', ['$event'])
  handleGlobalKeyPress(event: KeyboardEvent): void {

    if ((event.key === 'Enter' || event.key === ' ') && document.activeElement === this.mainElement?.nativeElement) {
      event.preventDefault();
      if (this.changeViewElement) {
        this.changeViewElement.nativeElement.focus();
      }
    }
  }


  toggleFilteredSource() {
    this.revealDiv = !this.revealDiv;
  }

  toggleExcludedSource() {
    this.excludedSourcesShow = !this.excludedSourcesShow;
  }


  aiHighlights() {
    this.notFound = [];
    this.totalSentencesWithAI = 0;
    this.removeHighlight();
    if(this.aiNavigation.length === 0){

      this.plagiarismLabels.forEach(element => {
        this.aiNavigation.push({page:element.page, numberOfSentences: 0});
      });

      this.aiSentences.forEach(element => {
        if (element.aiText) {
          this.doHighlight(
            element.text,
            element,
            this.notFound,
            []
          );

          this.totalSentencesWithAI++;
        }


        this.aiNavigation.forEach(label => {

          if (label.page == element.page && element.aiText) {
            label.numberOfSentences++;
          }
        });
      });
      // this.plagiarismLabels = this.aiNavigation
    }else{
      this.aiSentences.forEach(element => {
        if (element.aiText) {
          this.doHighlight(
            element.text,
            element,
            this.notFound,
            []
          );
          this.totalSentencesWithAI++;
        }

      });
    }
    if (this.aiNavigation.length < this.totalPages) {
      this.aiNavigation.push({page: this.totalPages, numberOfSentences: 0});
    }
    this.updateVisiblePages(this.aiNavigation, 'ai');

  }

  changeView(toChange) {
    if (this.currentSubmissionDetails?.aiPercentage == -1 || this.currentSubmissionDetails?.checkAI == 0) {
      return
    }
    if (toChange == 'similarity') {
      this.displayAiText = false;
      this.displayManipulations = false;
      this.pdfSrc = this.tempPdfSrc;
      this.getCrossPlag();
    } else if (toChange == 'aiIndex') {
      this.displayAiText = true;
      this.displayManipulations = false;
      this.pdfSrc = this.tempPdfSrc;
      this.calculateNextPage(1);
    } else if (toChange == 'manipulations') {
      this.displayAiText = false;
      this.displayManipulations = true;
      this.getManipulations()
      this.calculateNavManipulations();
      this.openFlag(this.currentManipulationType);
    }
  }

  openFlag(flag: string) {
      // Close all flags
      this.isCharReplacement = false;
      this.isHiddenText = false;
      this.isImage = false;

      // Open the selected flag
      if (flag === 'charReplacement') {
        this.isCharReplacement = true;
        this.isHiddenText = false;
        this.isImage = false;
        this.currentManipulationType = 'charReplacement';
        this.pdfSrc = this.tempPdfSrc;
        this.getManipulations(true)

      } else if (flag === 'hiddenText') {
        this.isCharReplacement = false;
        this.isHiddenText = true;
        this.isImage = false;
        this.currentManipulationType = 'hiddenText';
        this.pdfSrc = this.tempPdfSrc;
        this.getManipulations(true)
      } else if (flag === 'isImage') {
        this.isCharReplacement = false;
        this.isHiddenText = false;
        this.isImage = true;
        this.currentManipulationType = 'isImage';
        if (this.displayManipulations) {
          this.pdfSrc = this.pdfImageSrc;
        }
      }
      this.calculateNavManipulations();
  }

  checkAndOpenFlags() {
    if (this.currentManipulationType == 'charReplacement' && this.sentenceDetails && this.sentenceDetails.some(sentence => sentence?.replacedCharacters && sentence?.replacedCharacters?.length > 0)) {
      this.openFlag('charReplacement');
    } else if (this.currentManipulationType == 'hiddenText' && this.sentenceDetails && this.sentenceDetails.some(sentence => sentence?.characters && sentence?.characters?.length > 0)) {
      this.openFlag('hiddenText');
    } else if (this.currentManipulationType == 'isImage' && (this.redImages || this.greenImages)) {
      this.openFlag('isImage');
    } else {
      this.openFlag('charReplacement');
    }
  }

  getManipulations(shouldHighlight: boolean = false) {
    this.removeHighlight();
    if(this.displayManipulations){
      this.MLPlagIndex = false;
      this.crossPlagIndex = false;
    }
    if (shouldHighlight) {
      this.scrollPage(this.page);
    }
  }

  containsReplacedCharacter(sentenceText: string): boolean {
    return this.replacedCharacterList.some((index, char) => sentenceText.includes(char));
  }



  doHighlight(stringToHighlight, objToHighlight, notFound, source) {
    let mark = 0;
    let viewer = document.getElementById(`viewer`);
    this.markInstance = new Mark(viewer);
    const replacedCharsToHighlight = this.allManipulations?.replacedCharacters?.map(char => char.character);
    this.markInstance.mark(stringToHighlight, {
      acrossElements: true,
      separateWordSearch: false,
      debug: true,
      limit: 1,
      exclude: ['.canvasWrapper', '[data-markjs="true"]'],
      each: (element) => {
        if (mark == 0) {
          var node = document.createElement('SPAN');
          node.classList.add('markQuote');

          if (this.displayManipulations) {
            if (this.isHiddenText && objToHighlight.characters.length > 0) {
              let height = element.offsetHeight;
              console.log(height, 'height');

              if (height < 5) {
                var textnode = document.createTextNode('Small Hidden Text');
                node.appendChild(textnode);
                node.classList.add('smallHiddenText');
                element.appendChild(node);
              }

            }
          } else {
            if (source?.isExcluded) {
              node.style.background = 'rgb(128, 128, 128, 0.9)';
            } else {
              if (
                (objToHighlight.isExcluded == true &&
                  this.crossPlagIndex) ||
                (objToHighlight.isTranslationExcluded == true &&
                  !this.crossPlagIndex)
              ) {
                node.style.background = 'rgb(128, 128, 128, 0.9)';
              } else {
                if (objToHighlight.isCitation && this.toggleQuotes) {
                  node.style.background = 'rgb(102, 255, 102, 0.9)';
                  node.style.color = 'black';
                } else {
                  if (objToHighlight.sourceSentencePercentage > 84.5) {
                    node.style.background = 'rgb(240, 78, 103, 0.9)';
                  } else {
                    node.style.background = 'rgb(0, 207, 255, 0.9)';
                  }
                }
              }
            }

            if (
              source?.isSentenceExcluded === false &&
              (!source.hide || source.hide === false)
            ) {
              var textnode = document.createTextNode(source.no);
              node.appendChild(textnode);
              element.appendChild(node);
            }
          }

        }
        mark = 1;


        element.style.color = 'transparent';
        element.style.position = 'relative';
        if (this.displayManipulations) {
          if (this.isCharReplacement && objToHighlight.replacedCharacters) {
            if (replacedCharsToHighlight.some(char => stringToHighlight.includes(char))) {
              element.style.background = 'rgba(255, 81, 81, 0.45)';
              element.style.cursor = 'default';
            } else {
              element.style.background = 'rgba(154, 154, 154, 0.45)';
              element.style.cursor = 'default';
            }
          } else if (this.isHiddenText && objToHighlight.characters.length > 0) {
            element.style.cursor = 'pointer';
            if (!objToHighlight.whitecharactersExcluded) {
              element.style.background = 'rgba(255, 208, 0, 0.45)';
            } else {
                element.style.background = 'rgba(154, 154, 154, 0.45)';
              }

            element.addEventListener('click', (e) => {
              this.openManipulationsModal(objToHighlight);
            });
          }

        } else {
          if (this.displayAiText) {
            if (objToHighlight.aiText) {
              element.style.background = 'rgba(163, 137, 228, 0.45)';
            }

          } else {
            if (source?.isExcluded) {
              element.style.background = 'rgba(154, 154, 154, 0.45)';
            } else {
              if (
                (objToHighlight.isExcluded == true && this.crossPlagIndex) ||
                (objToHighlight.isTranslationExcluded == true &&
                  !this.crossPlagIndex)
              ) {
                element.style.background = 'rgba(154, 154, 154, 0.45)';
              } else {
                if (objToHighlight.isCitation) {
                  element.style.background = 'rgba(108, 247, 189, 0.45)';
                } else {
                  if (objToHighlight.sourceSentencePercentage > 84.5) {
                    element.style.background = 'rgba(255, 118, 164, 0.45)';
                  } else {
                    element.style.background = 'rgba(93, 174, 255, 0.45)';
                  }
                }
              }
            }
          }

          $(element).css('cursor', 'pointer');
          $(element).attr('data-source-id', source?.sourceId);
          $(element).attr('data-page-number', objToHighlight?.page);
          $(element).attr('sentence-id', objToHighlight?.id);
          $(element).attr('tabindex', '0');
          $(element).attr('aria-label', 'This text is ' + (objToHighlight?.isCitation ? 'a quotation' : objToHighlight?.isExcluded ? 'excluded' : objToHighlight?.sourceSentencePercentage < 84.5 ? ' possibly altered' : 'an exact match') + '. Press enter to open the modal');
          if (!this.displayAiText) {
            $(element).on('click', (e) => {
              this.sourceId = e.target?.attributes['data-source-id']?.value;
              this.sentenceId = e.target?.attributes['sentence-id']?.value;
              let result;
              this.activePlagSources.forEach((element) => {
                element.sentences.forEach((sentence) => {
                  if (sentence.id == this.sentenceId) {
                    result = sentence;
                  }
                });
              });
              this.openModal(result);
            });
          }
        }
      },
      filter: function (txtNode, string, s, b) {
        return true;
      },
      noMatch: function (range) {
        notFound.push({ objToHighlight, source });
      },
    });
    // }
  }
  /**
   * Method used to highlight or remove highlight from quotes.
   */
  toggleQuotes() {
    this.highlightQuotes = !this.highlightQuotes;
    this.submissionService
      .toggleQuotes(this.currentSubmissionDetails?.id)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log('data', data);
        },
        (error) => {
          console.log('error', error);
        }
      );
    setTimeout(() => {
      this.reCallHighlight();
    }, 200);
  }

  /**
   * Method is used as second phase for of sentence highlights it is triggered only when a text is not found within a basic search via a Mark.Js
   */
  removeHighlight() {
    let viewer = document.getElementById('viewer');
    this.markInstance = new Mark(viewer);
    this.markInstance.unmark();
    document.querySelectorAll('.markQuote').forEach((item) => {
      item.remove();
    });
  }

  getTextFromPages() {
    let textForHighlight = [];
    let pageText = $($('[data-loaded="true"]')).text();
    let arrayOfTexts = pageText
      .match(/((?:[A-Z][a-z]\.|\w\.\w.|.)*?(?:[.!?]|$))(?:\s+|$)/g)
      .map((string) => string.trim());
    arrayOfTexts.pop();
    for (let x in this.notFound) {
      for (let j in arrayOfTexts) {
        let parsedSentences = arrayOfTexts[j];
        let notFoundWithRegEx = this.notFound[
          x
        ].objToHighlight.text.replace(/\s+/g, '');

        let successCounter = 0;
        let startSentence = 0;
        let endSentence = 0;
        let keepChecking = false;
        let found = false;
        let i = 0;
        let textToHighlight = '';

        while (i < parsedSentences.length && !found) {
          if (parsedSentences[i].indexOf(' ') != 0) {
            if (notFoundWithRegEx[successCounter] == parsedSentences[i]) {
              successCounter++;
              if (keepChecking == false) {
                startSentence = i;
                keepChecking = true;
              }
            } else {
              if (successCounter > 0) {
                i = i - 1;
              }
              successCounter = 0;
              keepChecking = false;
            }

            if (successCounter == notFoundWithRegEx.length) {
              endSentence = i;

              textToHighlight = parsedSentences.substring(
                startSentence,
                endSentence + 1
              );

              found = true;
              textForHighlight.push({
                textToHighlight: textToHighlight,
                obj: this.notFound[x].objToHighlight,
                source: this.notFound[x].source,
              });
            }
          }
          i++;
        }
      }
    }
    this.notFound = [];
    for (let x in textForHighlight) {
        this.doHighlight(
          textForHighlight[x].textToHighlight,
          textForHighlight[x].obj,
          this.notFound,
          textForHighlight[x].source
        );
    }
  }

  pageChanging(e: CustomSourceEvent) {
    this.calculateNextPage(e.pageNumber);
    this.page = e.pageNumber;
    this.currentStartPage = e.pageNumber - 1;

    if (this.currentStartPage < this.visiblePages[0].page || this.currentStartPage >= this.visiblePages[this.visiblePages.length - 1].page) {
      if (this.displayManipulations && !this.displayAiText) {
        this.updateVisiblePages(this.manipulationNavigation, 'manipulations')
      } else if (!this.displayAiText && !this.displayManipulations) {
        this.updateVisiblePages(this.plagiarismLabels, 'similarity')
      } else if (this.displayAiText && !this.displayManipulations) {
        this.updateVisiblePages(this.aiNavigation, 'ai')
      }
    }
  }

  pageRendered(e) {
    this.pdfLoadedPages = [];
    this.notFound = [];
    // let pages = $($('.page'));
    this.documentPages = e.pageNumber;
    let same = 0;

    if (this.loadedPages.length !== 0) {
      for (let i = 0; i < this.loadedPages.length; i++) {
        if (this.loadedPages[i].id === e.source.id) {
          same++;
        }
      }
      if (same === 0) {
        this.loadedPages.push(e.source);
        this.loadedPagesId.push(e.source.id);
      }
    } else {
      this.loadedPagesId.push(e.source.id);
      this.loadedPages.push(e.source);
    }

    if (this.displayAiText && !this.displayManipulations) {

      this.aiHighlights();
    }

    if (!this.displayAiText && !this.displayManipulations) {
      let arrayToHighlight = [];
      if (this.selectedSources === true) {
        arrayToHighlight = this.sources;
      } else {
        arrayToHighlight = this.activePlagSources;
      }

      for (let source of arrayToHighlight) {
        let j = this.plagiarismLabels.length;

        for (let sentence of source.sentences) {
          if (sentence.page < j) {
            j = sentence.page;
          }
          let sentenceCopy = { ...sentence };

          if (this.highlightQuotes === true) {
            if (this.loadedPagesId.includes(sentenceCopy.page)) {
              this.doHighlight(
                sentenceCopy.text,
                sentenceCopy,
                this.notFound,
                source
              );
            }
          } else {
            if (
              this.loadedPagesId.includes(sentenceCopy.page) &&
              sentenceCopy.isCitation === null
            ) {
              this.doHighlight(
                sentenceCopy.text,
                sentenceCopy,
                this.notFound,
                source
              );
            }
          }

        }
      }
    }

    if (this.displayManipulations) {
      let arrayToHighlight = this.activePlagSources;

      for (let source of arrayToHighlight) {
        for (let sentence of source.sentences) {
          if (this.currentManipulationType == 'charReplacement' && sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {
            sentence.replacedCharacters.forEach((char: any) => {
              this.doHighlight(char, sentence, this.notFound, null);
            });
          } else if (this.currentManipulationType == 'hiddenText' && sentence?.characters.length > 0) {
            this.doHighlight(sentence.text, sentence, this.notFound, null);
          }
        }
      }

    }




    if (this.notFound.length !== 0) {
      this.getTextFromPages();
    }
  }

  callHighlight(currentPage) {
    this.notFound = [];
    let arrayToHighlight = [];

    if (!this.displayAiText && !this.displayManipulations) {
      if (this.selectedSources === true) {
        arrayToHighlight = this.sources;
      } else {
        arrayToHighlight = this.activePlagSources;
      }
      for (let source of arrayToHighlight) {
        for (let sentence of source.sentences) {
          if (currentPage === sentence.page &&
            (this.highlightQuotes && sentence.isCitation || sentence.isCitation === null)) {
            this.doHighlight(sentence.text, sentence, this.notFound, source);
          }
        }
      }
    } else if (this.displayManipulations) {
      const arrayToHighlight = this.sentenceDetails;

      for (let sentence of arrayToHighlight) {
        const shouldHighlight = currentPage === sentence.page;

        if (this.currentManipulationType == 'isImage') {
          return;
        } else if (this.isCharReplacement) {
          if (shouldHighlight && this.currentManipulationType == 'charReplacement' && sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {

            sentence.replacedCharacters.forEach((char: any) => {
              this.doHighlight(char, sentence, this.notFound, null);
            });
          }
        } else if (this.isHiddenText) {
          if (shouldHighlight && this.currentManipulationType == 'hiddenText' && sentence?.characters.length > 0) {
            this.doHighlight(sentence.text, sentence, this.notFound, null);
          }
        }
      }
    }


    if (this.displayAiText) {
      this.aiHighlights();
    }

    if (this.notFound.length !== 0) {
      this.getTextFromPages();
    }
  }

  calculateNextPage(currentPage) {
    if (this.loadedPagesId.length !== 0) {
      this.pagesToDraw = [];
      this.pagesToDestroy = [];

      let lowest = currentPage - this.numberOfDifference;
      let highest = currentPage + this.numberOfDifference;

      for (let i = 0; i < this.loadedPagesId.length; i++) {
        if (
          this.loadedPagesId[i] <= highest &&
          this.loadedPagesId[i] >= lowest
        ) {
          this.pagesToDraw.push(this.loadedPagesId[i]);
        } else {
          this.pagesToDestroy.push(this.loadedPagesId[i]);
        }
      }

      for (let i = 0; i < this.pagesToDestroy.length; i++) {
        for (let x in this.loadedPages) {
          if (this.loadedPages[x].id === this.pagesToDestroy[i]) {
            let position = parseInt(x);
            this.loadedPages[position].destroy();
            this.loadedPages.splice(position, 1);
          }
        }
      }

      if (this.loadedPagesId.length >= 6) {
        for (let x in this.loadedPagesId) {
          if (this.loadedPagesId[x] === this.pagesToDestroy[0]) {
            let position = parseInt(x);
            this.loadedPagesId.splice(position, 1);
            this.pagesToDestroy.splice(0, 1);
          }
        }
      }

      setTimeout(() => {
        if (this.oldPage > currentPage) {
          if (currentPage - 1 <= 1) {
            this.callHighlight(1); //TODO: Check if there are any issues with this A.R. (If the change affects other parts of the code)
          }
        } else {
          this.callHighlight(currentPage);
        }
        this.oldPage = currentPage;
      }, 200);
    }
  }

  expandRow(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  /**
   * Method used to get multi language plagiarism sources of the current submission
   */
  getMLPlag() {
    this.store.dispatch(
      reportActions.resetFilter({ sources: this.activePlagSources })
    );
    this.MLPlagIndex = true;
    this.crossPlagIndex = false;
    this.MLPlagSourcesSub$ = this.store
      .select(getActiveSource(this.crossPlagIndex))
      .subscribe((data) => {
        this.activePlagSources = data[0];
        this.excludeSources = data[1];
        if (data !== this.activePlagSources) {
          this.activePlagSources = data[0];
          this.excludeSources = data[1];
        }
        this.reCallHighlight();
        this.getFilteredSources(this.documentSources ? 1 : this.internetSources ? 2 : this.topSources ? 'top3' : 'all');
      });
    this.navigationSourceSub$.unsubscribe();
    this.navigationSourceSub$ = this.store
      .select(getActiveSourceNav(this.crossPlagIndex))
      .subscribe((data) => {
        this.plagiarismLabels = data;
        if (this.plagiarismLabels.length != this.totalPages) {
          this.plagiarismLabels.push({ page: this.totalPages, numberOfSentences: 0 });
        }
      });
    this.allSources = true;
    this.topSources = false;
    this.internetSources = false;
    this.documentSources = false;
    this.filterSources = false;
    this.expandedIndex = -1;
    this.selectedSources = false;
    this.scrollPage(1);
  }

  /**
   * Method used to close source expanded details div.
   */
  close() {
    this.expandedIndex = -1;
  }

  /**
   * Method used to get cross plagiarism sources of the current submission
   */
  getCrossPlag() {
    this.store.dispatch(
      reportActions.resetFilter({ sources: this.activePlagSources })
    );

    this.MLPlagIndex = false;
    this.crossPlagIndex = true;
    this.documentLanguage = this.getDocumentLanguage(
      this.currentSubmissionDetails?.originalLanguage
    );
    this.MLPlagSourcesSub$?.unsubscribe();
    this.removeHighlight();
    this.navigationSourceSub$.unsubscribe();
    this.navigationSourceSub$ = this.store
      .select(getActiveSourceNav(this.crossPlagIndex))
      .subscribe((data) => {
        this.plagiarismLabels = data;
        if (this.plagiarismLabels.length != this.totalPages) {
          this.plagiarismLabels.push({ page: this.totalPages, numberOfSentences: 0 });
        }
      });
    this.crossSourcesSub$ = this.store
      .select(getActiveSource(this.crossPlagIndex))
      .subscribe((data) => {
        this.activePlagSources = data[0];
        this.excludeSources = data[1];
        if (data !== this.activePlagSources) {
          this.activePlagSources = data[0];
          this.excludeSources = data[1];
        }
        this.reCallHighlight();
      });
    this.allSources = true;
    this.topSources = false;
    this.internetSources = false;
    this.documentSources = false;
    this.filterSources = false;
    this.expandedIndex = -1;
    this.selectedSources = false;
  }

  @ViewChild('targetSpan') targetSpan!: ElementRef;

  /**
   * Method used to filter plagiarism sources based on four filters.
   * All Sources
   * Top 3 Sources
   * Internet Sources
   * Document Sources
   * @param type
   */
  getFilteredSources(type) {
    this.internetSources = false;
    this.documentSources = false;
    this.topSources = false;
    this.allSources = false;
    if (type == 2) {
      this.internetSources = true;
    } else if (type == 1) {
      this.documentSources = true;
    } else if (type == 'top3') {
      this.topSources = true;
    } else if (type == 'all') {
      this.allSources = true;
    }

    this.selectedSources = false;
    this.expandedIndex = -1;
    this.filteredSources = [];
    for (let source of this.activePlagSources) {
      if (source.type == type) {
        this.filteredSources.push(source);
      }
    }
    this.filteredSources = this.activePlagSources.filter(
      (source) => source.type == type
    );
    for (let source of this.filteredSources) {
      if (source.isChecked == true) {
        this.selectedSources = true;
      }
    }
    this.filterSources = true;
    if (type === 'all') {
      this.filterSources = false;
      for (let source of this.activePlagSources) {
        for (let sentence of source.sentences) {
          let sentenceCopy = { ...sentence };
          sentenceCopy.page = sentenceCopy.page.toString();
          if (this.highlightQuotes === true) {
            if (this.loadedPagesId.includes(sentenceCopy.page)) {
              this.doHighlight(
                sentenceCopy.text,
                sentenceCopy,
                this.notFound,
                source
              );
            }
          } else {
            if (
              this.loadedPagesId.includes(sentenceCopy.page) &&
              sentenceCopy.isCitation === null
            ) {
              this.doHighlight(
                sentenceCopy.text,
                sentenceCopy,
                this.notFound,
                source
              );
            }
          }
        }
        if (source.isChecked == true) {
          this.selectedSources = true;
        }
      }
    } else if (type === 'top3') {
      this.filterSources = false;
      this.topThreeSources = [];
      if (this.topThreeSources.length == 0) {
        for (let source of this.activePlagSources) {
          if (!source.isExcluded) {
            this.topThreeSources.push(source);
          }
        }
        this.topThreeSources = [...this.topThreeSources]
          .sort((a, b) => b.percentage - a.percentage)
          .slice(0, 3);

        for (let source of this.topThreeSources) {
          if (source.isChecked == true) {
            this.selectedSources = true;
          }
        }
      }
    }
    setTimeout(() => {
      this.targetSpan?.nativeElement.children[1].focus()
    }, 100)

    // this.targetSpan.nativeElement.focus();
  }

  @HostListener('window:scroll', [])
  /**
   * Method used to show/hide scroll button on mobile if the window is scrolled or not.
   */
  onWindowScroll() {
    if (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop > 100
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  /**
   * Method used to scroll at the top of the page in mobile view.
   */
  scrollPageToTop() {
    (function smoothScroll() {
      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothScroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  /**
   * Method used to select plagiarism sources.
   */
  checkedSources = [];

  startDocumentPreview(id) {
    this.spinner.show();
    this.submissionService
      .submissionPreview(
        id,
        this.currentSubmissionDetails?.id,
        this.crossPlagIndex
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.dialog.open(SourcePreviewComponent, {
            data,
            width: '60%',
            height: '80%',
          });
          this.spinner.hide();
        },
        (error) => {
          console.log('error', error);
          this.spinner.hide();
        }
      );
  }

  /**
   * Method used to select source from source list
   * @param id
   */
  selectSource(id) {
    if (this.excludedSourceChecked > 0) {
      this.excludedSourceChecked = 0;
      for (let source of this.excludeSources) {
        this.store.dispatch(
          reportActions.unCheckCheckboxExclude({
            source: source,
            sourceType: this.crossPlagIndex,
          })
        );
      }
    }
    this.defaultSourceSelect = false;
    this.allSources = false;
    let i = 0;
    this.checkBoxes.forEach((element) => {
      if (element.nativeElement.checked == true) {
        i++;
      }
    });
    // if there are any checkbox selected.
    if (i > 0) {
      this.selectedSources = true;
      this.removeHighlight();
    }

    this.notFound = [];
    for (let source of this.activePlagSources) {
      if (id == source.sourceId) {
        let sourceIndex = this.sources.findIndex(
          (src) => src.sourceId == source.sourceId
        );
        if (sourceIndex === -1) {
          this.store.dispatch(
            reportActions.checkCheckbox({
              source: source,
              sourceType: this.crossPlagIndex,
            })
          );

          this.sources.push(source);
        } else {
          this.sources.splice(sourceIndex, 1);

          this.store.dispatch(
            reportActions.unCheckCheckbox({
              source: source,
              sourceType: this.crossPlagIndex,
            })
          );

          if (this.sources.length == 0) {
            this.defaultSourceSelect = true;
          }
        }
        this.pages = [];
        for (let source of this.sources) {
          for (let sentence of source.sentences) {
            let pageIndex = this.pages.findIndex(
              (x) => x == sentence.page
            );
            if (pageIndex === -1) {
              this.pages.push(sentence.page);
            }
            let sentenceCopy = { ...sentence };
            sentenceCopy.page = sentenceCopy.page.toString();
            if (this.loadedPages.includes(sentenceCopy.page)) {
              this.doHighlight(
                sentenceCopy.text,
                sentenceCopy,
                this.notFound,
                source
              );
            }
          }
        }
      }
    }

    if (this.notFound.length !== 0) {
      this.getTextFromPages();
    }
    let sourcePage = this.plagiarismLabels.length;
    if (i == 0) {
      this.selectedSources = false;
      sourcePage = 1;
    } else {
      for (let sentence of this.sources[this.sources.length - 1].sentences) {
        if (sentence.page < sourcePage) {
          sourcePage = sentence.page;
        }
      }
    }
    if (this.page !== sourcePage) {
      this.scrollPage(sourcePage);
    }
  }

  highlightExcludedSource(id) {
    if (this.selectedSources == true) {
      this.selectedSources = false;
      for (let source of this.activePlagSources) {
        this.store.dispatch(
          reportActions.unCheckCheckbox({
            source: source,
            sourceType: this.crossPlagIndex,
          })
        );
      }
    }
    this.defaultSourceSelect = false;
    this.allSources = false;
    let i = 0;
    this.checkBoxes.forEach((element) => {
      if (element.nativeElement.checked == true) {
        i++;
      }
    });
    // if there are any checkbox selected.
    if (i > 0) {
      this.selectedSources = true;
      this.removeHighlight();
    }

    this.notFound = [];
    for (let source of this.excludeSources) {
      if (id == source.sourceId) {
        let sourceIndex = this.sources.findIndex(
          (src) => src.sourceId == source.sourceId
        );
        if (sourceIndex === -1) {
          this.excludedSourceChecked++;
          this.store.dispatch(
            reportActions.checkCheckboxExclude({
              source: source,
              sourceType: this.crossPlagIndex,
            })
          );

          this.sources.push(source);
        } else {
          this.sources.splice(sourceIndex, 1);
          this.excludedSourceChecked--;
          this.store.dispatch(
            reportActions.unCheckCheckboxExclude({
              source: source,
              sourceType: this.crossPlagIndex,
            })
          );

          if (this.sources.length == 0) {
            this.defaultSourceSelect = true;
          }
        }
        this.pages = [];
        for (let source of this.sources) {
          for (let sentence of source.sentences) {
            let pageIndex = this.pages.findIndex(
              (x) => x == sentence.page
            );
            if (pageIndex === -1) {
              this.pages.push(sentence.page);
            }
            let sentenceCopy = { ...sentence };
            sentenceCopy.page = sentenceCopy.page.toString();
            if (!this.MLPlagIndex) {
              sentenceCopy.isExcluded = true;
            } else {
              sentenceCopy.isTranslationExcluded = true;
            }
            for (let page of this.loadedPages) {
              if (page.id === sentence.page) {
                this.doHighlight(
                  sentenceCopy.text,
                  sentenceCopy,
                  this.notFound,
                  source
                );
              }
            }
          }
        }
      }
    }

    if (this.notFound.length !== 0) {
      this.getTextFromPages();
    }
    let sourcePage = this.plagiarismLabels.length;
    if (i == 0) {
      this.selectedSources = false;
      sourcePage = 1;
    } else {
      for (let sentence of this.sources[this.sources.length - 1].sentences) {
        if (sentence.page < sourcePage) {
          sourcePage = sentence.page;
        }
      }
    }
    if (this.page !== sourcePage) {
      this.scrollPage(sourcePage);
    }
  }
  /**
   * Method used to exclude source from calculation.
   */
  excludeSource(source) {
    swal.fire({
      title: this.translate.instant('report.are_you_sure_you_want_to_exclude_this_source') + '?',
      showDenyButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      denyButtonText: this.translate.instant('general.no'),
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          swal.fire(this.translate.instant('report.source_excluded') + '!', '', 'success');
          this.submissionService
            .excludeSource(
              source.sourceId,
              this.currentSubmissionId,
              this.crossPlagIndex,
              true,
              source.sourceId,
              source.percentage
            )
            .pipe(first())
            .subscribe(
              (data) => {
                this.store.dispatch(
                  reportActions.getSubmissionPlag({
                    presignedUrl: data[0],
                  })
                );
              },
              (error) => {
                this.spinner.hide();
                console.log('error', error);
              }
            );
        } else if (result.isDenied) {
          swal.fire(this.translate.instant('report.source_not_excluded'), '', 'info');
        }
      });

    this.excludedSourcesShow = true;
  }

  /**
   * Method used to include source into calculation.
   * @param source
   */
  includeSource(source) {
    swal.fire({
      title: this.translate.instant('report.are_you_sure_you_want_to_include_this_source') + '?',
      showDenyButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      denyButtonText: this.translate.instant('general.no'),
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          swal.fire(this.translate.instant('report.source_included') + '!', '', 'success');
          this.submissionService
            .includeSource(
              source.sourceId,
              this.currentSubmissionId,
              this.crossPlagIndex,
              source.percentage
            )
            .pipe(first())
            .subscribe(
              (data) => {
                this.store.dispatch(
                  reportActions.getSubmissionPlag({
                    presignedUrl: data[1],
                  })
                );
              },
              (error) => {
                this.spinner.hide();
                console.log('error', error);
              }
            );
        } else if (result.isDenied) {
          swal.fire(this.translate.instant('report.source_not_included'), '', 'info');
        }
      });
  }

  /**
   * Method used to download report. Your can download two kinds of report crossplag report and mlplag report
   */
  download() {
    if (this.downloadClicked) {
      this.downloadClicked = false;
    } else {
      this.downloadClicked = true;
    }
  }

  /**
   * Method used to open sentence information component as modal.
   * @param sentence
   */
  openModal(sentence) {
    this.store.dispatch(
      reportActions.setCurrentSentenceId({ currentSentenceId: sentence.id })
    );
    this.dialog.open(SentenceInformationModalComponent, {
      data: {
        sentenceId: sentence.id,
        sourceType: this.crossPlagIndex,
        submissionId: this.currentSubmissionId,
      },
      width: '50%',
    });
  }

  openManipulationsModal(sentence) {
    this.dialog.open(ManipulationActions, {
      data: { sentence, submissionId: this.currentSubmissionId},
      width: '50%',
    });
  }

  openCharReplacementModal(type) {
    if (type == 'include') {
      this.dialog.open(CharReplacementModal, {
        data: {manipulations: this.allManipulations.excludedReplacedCharacters, submissionId: this.currentSubmissionId, type: type}, width: '50%'
      });
    } else if (type == 'exclude') {
      this.dialog.open(CharReplacementModal, {
        data: {manipulations: this.allManipulations.replacedCharacters, submissionId: this.currentSubmissionId, type: type,}, width: '50%'
      });
    }
  }

  /**
   * Method used to scroll in specif pages in document.
   * @param page
   */
  scrollPage(page) {
    this.selectedPage = page;
    if (this.selectedPage == page && page !== this.page) {
      this.selectedPage = '';
      setTimeout(() => {
        this.selectedPage = page;
      }, 100);
    }
    setTimeout(() => {
      this.callHighlight(this.selectedPage);
    }, 500);
  }
  /**
   * Method used to reset filters.
   */
  resetFilter(): void {
    this.allSources = true;
    this.defaultSourceSelect = true;
    this.internetSources = false;
    this.documentSources = false;
    this.topSources = false;
    this.selectedSources = false;
    this.scrollPage(1);
    this.store.dispatch(
      reportActions.resetFilter({ sources: this.activePlagSources })
    );
    this.getFilteredSources('all');
  }
  /**
   * Method used to expand pdf on mobile.
   */
  expandPdf() {
    if (this.expandedPdf == true) {
      this.expandedPdf = false;
    } else {
      this.expandedPdf = true;
    }
  }
  /**
   * Method used to recall highlight, specialy it used when we change source that means when we click on Inspera AS report or mlPlag report.
   */
  reCallHighlight() {
    this.notFound = [];
    this.removeHighlight();
    let i = 0;
    this.sources = [];



    if (this.excludedSourceChecked > 0) {
      this.excludeSources.forEach((source) => {
        if (source.isChecked == true) {
          i++;
          this.sources.push(source);
        }
      });
    } else {
      this.activePlagSources.forEach((source) => {
        if (source.isChecked == true) {
          i++;
          this.sources.push(source);
        }
      });
    }

    if (i > 0) {
      this.selectedSources = true;
      this.defaultSourceSelect = false;
      this.activePlagSources.forEach((source) => {
        if (source.isChecked == true) {
          this.pages = [];
          for (let source of this.sources) {
            for (let sentence of source.sentences) {
              let pageIndex = this.pages.findIndex(
                (x) => x == sentence.page
              );
              if (pageIndex === -1) {
                this.pages.push(sentence.page);
              }
              let sentenceCopy = { ...sentence };
              if (this.highlightQuotes === true) {
                if (this.loadedPagesId.includes(sentenceCopy.page)) {
                  this.doHighlight(
                    sentenceCopy.text,
                    sentenceCopy,
                    this.notFound,
                    source
                  );
                }
              } else {
                if (
                  this.loadedPagesId.includes(sentenceCopy.page) &&
                  (sentenceCopy.isCitation === null ||
                    sentenceCopy.isCitation === false)
                ) {
                  this.doHighlight(
                    sentenceCopy.text,
                    sentenceCopy,
                    this.notFound,
                    source
                  );
                }
              }
            }
          }
        }
      });
    } else {
      this.selectedSources = false;
      this.defaultSourceSelect = true;
      if (this.displayManipulations) {
        for (let sentence of this.sentenceDetails) {
          if (this.loadedPagesId.includes(sentence.page)) {
            if (this.isCharReplacement && sentence.replacedCharacters) {
              sentence.replacedCharacters.forEach((char: any) => {
                this.doHighlight(char, sentence, this.notFound, null);
              });
            } else {
              this.doHighlight(
                sentence.text,
                sentence,
                this.notFound,
                null
              );
            }

          }
        }
      } else {
        for (let source of this.activePlagSources) {
          for (let sentence of source.sentences) {
            let sentenceCopy = { ...sentence };
            if (this.highlightQuotes === true) {
              if (this.loadedPagesId.includes(sentenceCopy.page)) {
                  this.doHighlight(
                    sentenceCopy.text,
                    sentenceCopy,
                    this.notFound,
                    source
                  );
              }
            } else {
              if (
                this.loadedPagesId.includes(sentenceCopy.page) &&
                (sentenceCopy.isCitation === null ||
                  sentenceCopy.isCitation === false)
              ) {
                this.doHighlight(
                  sentenceCopy.text,
                  sentenceCopy,
                  this.notFound,
                  source
                );
              }
            }
          }
        }
      }
    }
    if (this.notFound.length !== 0) {
      this.getTextFromPages();
    }
  }
  /**
   * Method used to filter low percentage sources.
   * @param filteredSources
   */
  lowPercentage(filteredSources): boolean {
    for (let source of filteredSources) {
      if (source.percentage < 1) {
        return true;
      }
    }
  }

  /**
   * Method used to download submission pdf report
   * @param reportType
   */
  downloadPdfReport(reportType: string): void {
    this.showText = true;
    this.spinner.show();
    this.submissionService
      .downloadReport(this.currentSubmissionId, reportType)
      .pipe()
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          this.showText = false;
          swal
            .fire({
              title: this.translate.instant('report.the_offline_report_is_ready'),
              showDenyButton: true,
              confirmButtonText: this.translate.instant('report.download'),
              denyButtonText: this.translate.instant('report.cancel'),
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                swal.fire(this.translate.instant('report.the_offline_report_download_will_begin_shortly'), '', 'success');
                let desiredName = '';
                if (data.reportType === 'mlplag') {
                  desiredName = `${this.documentLanguage} - Offline Report for ${this.currentSubmissionDetails?.title}.pdf`;
                } else if (data.reportType === 'crossplag') {
                  desiredName = `${this.translatedLanguage} - Offline Report for ${this.currentSubmissionDetails?.title}.pdf`;
                } else {
                  desiredName = `${this.currentSubmissionDetails?.title}.pdf`;
                }
                this.http.get(data.presignedS3UrlDownload, { responseType: 'arraybuffer' })
                  .subscribe((fileData: ArrayBuffer) => {
                    const blob = new Blob([fileData]);
                    saveAs(blob, desiredName);
                  });
                // saveAs(data.presignedS3UrlDownload, desiredName);
              } else if (result.isDenied) {
                // swal.fire(this.translate.instant('report.document_not_saved'), '', 'info');
              }
            });
        },
        (error) => {
          console.log('error', error);
          this.spinner.hide();
        }
      );
  }

  downloadAiReport() {
    this.spinner.show();
    this.submissionService
      .downloadAiReport(this.currentSubmissionId)
      .pipe()
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          swal
            .fire({
              title: this.translate.instant('report.the_ai_authorship_report_is_ready'),
              showDenyButton: true,
              confirmButtonText: this.translate.instant('report.download'),
              denyButtonText: this.translate.instant('report.cancel'),
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                swal.fire(this.translate.instant('report.the_ai_authorship_report_download_will_begin_shortly'), '', 'success');
                let desiredName = `AI Authorship - Offline Report for ${this.currentSubmissionDetails?.title}.pdf`;
                this.http.get(data.presignedS3UrlDownload, { responseType: 'arraybuffer' })
                  .subscribe((fileData: ArrayBuffer) => {
                    const blob = new Blob([fileData]);
                    saveAs(blob, desiredName);
                  });
                // saveAs(data.presignedS3UrlDownload, desiredName);
              } else if (result.isDenied) {
                swal.fire(this.translate.instant('report.document_not_saved'), '', 'info');
              }
            });
        },
        (error) => {
          console.log('error', error);
          this.spinner.hide();
        }
      );
  }
  /**
   * Method used to get document language.
   * @param documentLanguage
   */
  getDocumentLanguage(documentLanguage: string): string {
    for (const language of this.languages) {
      if (language.value == documentLanguage) {
        return language.text;
      }
    }
  }

  /**
   * Method used to download document certificate
   */
  downloadCertificate() {
    this.spinner.show();
    this.submissionService
      .downloadCertificate(this.currentSubmissionId)
      .pipe()
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          swal
            .fire({
              title: this.translate.instant('report.your_document_is_ready_download_it'),
              showDenyButton: true,
              confirmButtonText: this.translate.instant('general.yes'),
              denyButtonText: this.translate.instant('general.no'),
            })
            .then((result) => {
              if (result.isConfirmed) {
                swal.fire(this.translate.instant('app.document_saved'), '', 'success');
                saveAs(data.urlToDownload, 'certificate.pdf');
              } else if (result.isDenied) {
                swal.fire(this.translate.instant('report.document_not_saved'), '', 'info');
              }
            });
          // saveAs(data.urlToDownload, 'certificate.pdf');
        },
        (error) => {
          console.log('error', error);
          this.spinner.hide();
        }
      );
  }
  /**
   * Method is used to download original document that means the document that is uploaded by user.
   */
  downloadOriginalDocument() {
    this.spinner.show();
    this.submissionService
      .downloadOriginalDocument(this.currentSubmissionId)
      .pipe()
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          swal
            .fire({
              title: this.translate.instant('report.your_document_is_ready_download_it'),
              showDenyButton: true,
              confirmButtonText: this.translate.instant('general.yes'),
              denyButtonText: this.translate.instant('general.no'),
            })
            .then((result) => {
              if (result.isConfirmed) {
                let desiredName = `${this.currentSubmissionDetails?.title}.pdf`;
                this.http.get(data.urlToDownload, { responseType: 'arraybuffer' })
                  .subscribe((fileData: ArrayBuffer) => {
                    const blob = new Blob([fileData]);
                    saveAs(blob, desiredName);
                  })
              } else if (result.isDenied) {
                swal.fire(this.translate.instant('report.document_not_saved'), '', 'info');
              }
            });
        },
        (error) => {
          console.log('error', error);
          this.spinner.hide();
        }
      );
  }

  openDeleteModal() {
    this.dialog.open(DeleteModalComponent, {
      data: {
        submissionId: this.currentSubmissionId,
        permissions: this.studentDeletionPermission,
        isDocumentInArchive: this.isDocumentInArchive,
        sourcesAreDeleted: this.sourcesAreDeleted,
        role: this.currentUserDetails.roleId
      },
      width: '50%',
    });
  }

  openPermissionsModal() {
    this.dialog.open(ProfessorDeleteModalComponent, {
      data: {
        submissionId: this.currentSubmissionId,
        permissions: this.studentDeletionPermission,
        isDocumentInArchive: this.isDocumentInArchive,
        sourcesAreDeleted: this.sourcesAreDeleted
      },
      width: '50%',
    });
  }

  changeModel() {
    if (this.ai_model == 'base') {
      this.ai_model = 'performance'
    } else {
      this.ai_model = 'base'
    }
   }

   openCurrentUrlInNewWindow(): void {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = JSON.parse(localStorage.getItem('ACCESS_TOKEN'))
    const profile = user.roleId === 9 ? 'student' : user.roleId === 10 ? 'professor' : 'administrator'
    let url = `${environment.currentUrl}${profile}/submission/${this.currentSubmissionId}/report/${user.id}/${token}`;
    window.open(`${url}?expanded=true`, '_blank');
   }

  goHome() {
    const user = JSON.parse(localStorage.getItem('user'))
    const profile = user.roleId === 9 ? 'student' : user.roleId === 10 ? 'professor' : 'administrator'
    if (!this.isExpanded){
     window.location.replace(`${environment.currentUrl}${profile}`)
   }
  }


}
