// *** Angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';

// *** Services
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { PdfExportService } from 'src/app/services/pdf-export.service';

@Component({
   selector: 'app-administrator-statistics',
   templateUrl: './administrator-statistics.component.html',
   styleUrls: ['./administrator-statistics.component.scss'],
})
export class AdministratorStatisticsComponent implements OnInit {
   /**
    * Variabel used to store institutiton statistics
    */
   institutionData: any;
   /**
    * Variable used to store number that changes by function and has a part on showing a specific chart a time
    */
   graphToDisplay: number = 1;
   /**
    * Variable used to store number of assigments of professors within institution
    */
   assignmentsStatistics;
   /**
    *Variable used to store number of assigments of submissions within institution
    */
   submissionsStatistics;
   /**
    *Variable used to store number of assigments of thesis within institution
    */
   thesisStatistics;

   @ViewChild('contentToCapture') contentToCapture: ElementRef;

   constructor(
      private statistics: AdministratorStatisticsService,
      private csvExportService: CsvExportService,
      private excelExportService: ExcelExportService,
      private pdfExportService: PdfExportService,
      private spinner: NgxSpinnerService,
   ) { }

   ngOnInit(): void {
      this.statistics
         .institutionStatistics()
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionData = data.data;
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.statistics
         .institutionAssignments()
         .pipe(first())
         .subscribe(
            (data) => {
               this.assignmentsStatistics = data.professorAssignments;
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.statistics
         .institutionSubmissions(9)
         .pipe(first())
         .subscribe(
            (data) => {
               this.submissionsStatistics = data.submissions;
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.statistics
         .institutionThesis('bachelor')
         .pipe(first())
         .subscribe(
            (data) => {
               this.thesisStatistics = data.thesis;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to change the statistics of submissions by professors or students.
    * @param event
    */
   changeType(event) {
      this.statistics
         .institutionSubmissions(event)
         .pipe(first())
         .subscribe(
            (data) => {
               this.submissionsStatistics = data.submissions;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to change the statistics of thesis by types (Bachelor, Master or Ph.D).
    * @param event
    */
   changeThesisType(event) {
      this.statistics
         .institutionThesis(event)
         .pipe(first())
         .subscribe(
            (data) => {
               this.thesisStatistics = data.thesis;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to change which chart will be shown
    * @param value
    */
   filter(value) {
      value = parseInt(value);
      this.graphToDisplay = value;
   }

   prepareExportData() {
      return [
         ["Overview"],
         [""],
         ["Type", "Total"],
         ["Subjects", this.institutionData.nrOfSubjects],
         ["Assignments", this.institutionData.nrOfAssignments],
         ["Number of Students", this.institutionData.nrOfStudents],
         ["Number of Educators", this.institutionData.nrOfProfessors],
         ["Student submissions", this.institutionData.nrOfStudentSubmissions],
         ["High Similarity", this.institutionData.nrOfHighSimilaritySubmissions],
         ["Medium Similarity", this.institutionData.nrOfMediumSimilaritySubmissions],
         ["Low Similarity", this.institutionData.nrOfLowSimilaritySubmissions],
         [""],
         ["Institution Ratios"],
         [""],
         ["Student-to-Educator", `${this.institutionData.nrOfStudents} : ${this.institutionData?.nrOfProfessors}`],
         ["Student-to-Subjects", `${this.institutionData.nrOfStudents} : ${this.institutionData?.nrOfSubjects}`],
         ["Student-to-Submissions", `${this.institutionData.nrOfStudents} : ${this.institutionData?.nrOfStudentSubmissions}`],
         ["Educator-to-Subjects", `${this.institutionData.nrOfProfessors} : ${this.institutionData?.nrOfSubjects}`],
         ["Educator-to-Assignment", `${this.institutionData.nrOfProfessors} : ${this.institutionData?.nrOfAssignments}`],
         ["Educator-to-Submissions", `${this.institutionData.nrOfProfessors} : ${this.institutionData?.nrOfProfessorSubmissions}`],
         [""],
      ]
   }

   exportToCSV() {
      this.spinner.show();
      const rows = this.prepareExportData()
      let year = 0

      this.statistics.selectedYearObservable.subscribe((data) => {
         year = data
      })
      if (this.graphToDisplay === 1) {
         this.statistics.institutionSubmissionsStatistics(year).subscribe((data) => {
            rows.push(
               [""],
               ["", "Low Similarity Submissions", "Medium Similarity Submissions", "High Similarity Submissions"],
               ["Jan", data.lowSimilarity[0], data.mediumSimilarity[0], data.highSimilarity[0]],
               ["Feb", data.lowSimilarity[1], data.mediumSimilarity[1], data.highSimilarity[1],],
               ["Mar", data.lowSimilarity[2], data.mediumSimilarity[2], data.highSimilarity[2],],
               ["Apr", data.lowSimilarity[3], data.mediumSimilarity[3], data.highSimilarity[3],],
               ["May", data.lowSimilarity[4], data.mediumSimilarity[4], data.highSimilarity[4],],
               ["Jun", data.lowSimilarity[5], data.mediumSimilarity[5], data.highSimilarity[5],],
               ["Jul", data.lowSimilarity[6], data.mediumSimilarity[6], data.highSimilarity[6],],
               ["Aug", data.lowSimilarity[7], data.mediumSimilarity[7], data.highSimilarity[7],],
               ["Sep", data.lowSimilarity[8], data.mediumSimilarity[8], data.highSimilarity[8],],
               ["Oct", data.lowSimilarity[9], data.mediumSimilarity[9], data.highSimilarity[9],],
               ["Nov", data.lowSimilarity[10], data.mediumSimilarity[10], data.highSimilarity[10]],
               ["Dec", data.lowSimilarity[11], data.mediumSimilarity[11], data.highSimilarity[11]],
               [""],
            )
            this.csvExportService.exportToCSV(rows, 'Analytics - Dashboard Overview Report');
            this.spinner.hide()
         }, (err) => {
            this.spinner.hide()
         })
      } else if (this.graphToDisplay == 2) {
         this.statistics.institutionUsersStatistics(year).subscribe((data) => {
            rows.push(
               [""],
               ["", "Number of Students activated", "Number of Students registered", "Number of unactive students"],
               ["Jan", data.usersActivated[0], data.usersRegistered[0], data.usersUnActivated[0]],
               ["Feb", data.usersActivated[1], data.usersRegistered[1], data.usersUnActivated[1],],
               ["Mar", data.usersActivated[2], data.usersRegistered[2], data.usersUnActivated[2],],
               ["Apr", data.usersActivated[3], data.usersRegistered[3], data.usersUnActivated[3],],
               ["May", data.usersActivated[4], data.usersRegistered[4], data.usersUnActivated[4],],
               ["Jun", data.usersActivated[5], data.usersRegistered[5], data.usersUnActivated[5],],
               ["Jul", data.usersActivated[6], data.usersRegistered[6], data.usersUnActivated[6],],
               ["Aug", data.usersActivated[7], data.usersRegistered[7], data.usersUnActivated[7],],
               ["Sep", data.usersActivated[8], data.usersRegistered[8], data.usersUnActivated[8],],
               ["Oct", data.usersActivated[9], data.usersRegistered[9], data.usersUnActivated[9],],
               ["Nov", data.usersActivated[10], data.usersRegistered[10], data.usersUnActivated[10]],
               ["Dec", data.usersActivated[11], data.usersRegistered[11], data.usersUnActivated[11]],
               [""],
            )
            this.csvExportService.exportToCSV(rows, 'Analytics - Dashboard Overview Report');
            this.spinner.hide()
         }, (err) => {
            this.spinner.hide()
         })
      } else if (this.graphToDisplay === 5) {
         this.statistics.institutionUsersStatistics(year).subscribe((data) => {
            rows.push(
               [""],
               ["", "Number of Educators activated", "Number of Educators registered", "Number of unactive educators"],
               ["Jan", data.usersActivated[0], data.usersRegistered[0], data.usersUnActivated[0]],
               ["Feb", data.usersActivated[1], data.usersRegistered[1], data.usersUnActivated[1],],
               ["Mar", data.usersActivated[2], data.usersRegistered[2], data.usersUnActivated[2],],
               ["Apr", data.usersActivated[3], data.usersRegistered[3], data.usersUnActivated[3],],
               ["May", data.usersActivated[4], data.usersRegistered[4], data.usersUnActivated[4],],
               ["Jun", data.usersActivated[5], data.usersRegistered[5], data.usersUnActivated[5],],
               ["Jul", data.usersActivated[6], data.usersRegistered[6], data.usersUnActivated[6],],
               ["Aug", data.usersActivated[7], data.usersRegistered[7], data.usersUnActivated[7],],
               ["Sep", data.usersActivated[8], data.usersRegistered[8], data.usersUnActivated[8],],
               ["Oct", data.usersActivated[9], data.usersRegistered[9], data.usersUnActivated[9],],
               ["Nov", data.usersActivated[10], data.usersRegistered[10], data.usersUnActivated[10]],
               ["Dec", data.usersActivated[11], data.usersRegistered[11], data.usersUnActivated[11]],
               [""],
            )
            this.csvExportService.exportToCSV(rows, 'Analytics - Dashboard Overview Report');
            this.spinner.hide()
         }, (err) => {
            this.spinner.hide()
         })
      }

      if (this.assignmentsStatistics.length > 0) {
         rows.push(["Assignments", "Educator"]);
         this.assignmentsStatistics.forEach(assignment => {
            let assignmentName = assignment.name.includes(',') ? `"${assignment.name}"` : assignment.name;
            rows.push([assignment._count.Assignments, assignmentName]);
         });
      }
      if (this.submissionsStatistics.length > 0) {
         rows.push(["Submissions", "Student"]);
         this.submissionsStatistics.forEach(sub => {
            rows.push([sub._count.Submissions, sub.name]);
         });
      }


   }

   exportToExcel() {
      this.spinner.show()
      const rows = this.prepareExportData()
      let year = 0

      this.statistics.selectedYearObservable.subscribe((data) => {
         year = data
      })
      if (this.graphToDisplay === 1) {
         this.statistics.institutionSubmissionsStatistics(year).subscribe((data) => {
            rows.push(
               [""],
               ["", "Low Similarity Submissions", "Medium Similarity Submissions", "High Similarity Submissions"],
               ["Jan", data.lowSimilarity[0], data.mediumSimilarity[0], data.highSimilarity[0]],
               ["Feb", data.lowSimilarity[1], data.mediumSimilarity[1], data.highSimilarity[1],],
               ["Mar", data.lowSimilarity[2], data.mediumSimilarity[2], data.highSimilarity[2],],
               ["Apr", data.lowSimilarity[3], data.mediumSimilarity[3], data.highSimilarity[3],],
               ["May", data.lowSimilarity[4], data.mediumSimilarity[4], data.highSimilarity[4],],
               ["Jun", data.lowSimilarity[5], data.mediumSimilarity[5], data.highSimilarity[5],],
               ["Jul", data.lowSimilarity[6], data.mediumSimilarity[6], data.highSimilarity[6],],
               ["Aug", data.lowSimilarity[7], data.mediumSimilarity[7], data.highSimilarity[7],],
               ["Sep", data.lowSimilarity[8], data.mediumSimilarity[8], data.highSimilarity[8],],
               ["Oct", data.lowSimilarity[9], data.mediumSimilarity[9], data.highSimilarity[9],],
               ["Nov", data.lowSimilarity[10], data.mediumSimilarity[10], data.highSimilarity[10]],
               ["Dec", data.lowSimilarity[11], data.mediumSimilarity[11], data.highSimilarity[11]],
               [""],
            )
            this.excelExportService.exportToExcel(rows, 'Analytics - Dashboard Overview Report');
            this.spinner.hide()
         }, (err) => {
            this.spinner.hide()
         })
      } else if (this.graphToDisplay == 2) {
         this.statistics.institutionUsersStatistics(year).subscribe((data) => {
            rows.push(
               [""],
               ["", "Number of Students activated", "Number of Students registered", "Number of unactive students"],
               ["Jan", data.usersActivated[0], data.usersRegistered[0], data.usersUnActivated[0]],
               ["Feb", data.usersActivated[1], data.usersRegistered[1], data.usersUnActivated[1],],
               ["Mar", data.usersActivated[2], data.usersRegistered[2], data.usersUnActivated[2],],
               ["Apr", data.usersActivated[3], data.usersRegistered[3], data.usersUnActivated[3],],
               ["May", data.usersActivated[4], data.usersRegistered[4], data.usersUnActivated[4],],
               ["Jun", data.usersActivated[5], data.usersRegistered[5], data.usersUnActivated[5],],
               ["Jul", data.usersActivated[6], data.usersRegistered[6], data.usersUnActivated[6],],
               ["Aug", data.usersActivated[7], data.usersRegistered[7], data.usersUnActivated[7],],
               ["Sep", data.usersActivated[8], data.usersRegistered[8], data.usersUnActivated[8],],
               ["Oct", data.usersActivated[9], data.usersRegistered[9], data.usersUnActivated[9],],
               ["Nov", data.usersActivated[10], data.usersRegistered[10], data.usersUnActivated[10]],
               ["Dec", data.usersActivated[11], data.usersRegistered[11], data.usersUnActivated[11]],
               [""]
            )
            this.excelExportService.exportToExcel(rows, 'Analytics - Dashboard Overview Report');
            this.spinner.hide()
         }, (err) => {
            this.spinner.hide()
         })
      } else if (this.graphToDisplay === 5) {
         this.statistics.institutionUsersStatistics(year).subscribe((data) => {
            rows.push(
               [""],
               ["", "Number of Educators activated", "Number of Educators registered", "Number of unactive educators"],
               ["Jan", data.usersActivated[0], data.usersRegistered[0], data.usersUnActivated[0]],
               ["Feb", data.usersActivated[1], data.usersRegistered[1], data.usersUnActivated[1],],
               ["Mar", data.usersActivated[2], data.usersRegistered[2], data.usersUnActivated[2],],
               ["Apr", data.usersActivated[3], data.usersRegistered[3], data.usersUnActivated[3],],
               ["May", data.usersActivated[4], data.usersRegistered[4], data.usersUnActivated[4],],
               ["Jun", data.usersActivated[5], data.usersRegistered[5], data.usersUnActivated[5],],
               ["Jul", data.usersActivated[6], data.usersRegistered[6], data.usersUnActivated[6],],
               ["Aug", data.usersActivated[7], data.usersRegistered[7], data.usersUnActivated[7],],
               ["Sep", data.usersActivated[8], data.usersRegistered[8], data.usersUnActivated[8],],
               ["Oct", data.usersActivated[9], data.usersRegistered[9], data.usersUnActivated[9],],
               ["Nov", data.usersActivated[10], data.usersRegistered[10], data.usersUnActivated[10]],
               ["Dec", data.usersActivated[11], data.usersRegistered[11], data.usersUnActivated[11]],
               [""],
            )
            this.excelExportService.exportToExcel(rows, 'Analytics - Dashboard Overview Report');
            this.spinner.hide()
         }, (err) => {
            this.spinner.hide()
         })
      }

      if (this.assignmentsStatistics.length > 0) {
         rows.push(["Assignments", "Educator"]);
         this.assignmentsStatistics.forEach(assignment => {
            let assignmentName = assignment.name.includes(',') ? `"${assignment.name}"` : assignment.name;
            rows.push([assignment._count.Assignments, assignmentName]);
         });
      }
      if (this.submissionsStatistics.length > 0) {
         rows.push(["Submissions", "Student"]);
         this.submissionsStatistics.forEach(sub => {
            rows.push([sub._count.Submissions, sub.name]);
         });
      }

   }

   exportToPDF() {
      this.spinner.show()
      if (this.contentToCapture) {
         this.pdfExportService.captureAndExportPDF(this.contentToCapture.nativeElement, 'Analytics - Dashboard Overview Report.pdf');
         this.spinner.hide()
      } else {
         console.error('Content to capture is not available yet.');
         this.spinner.hide()
      }
   }
}
