<div class="d-flex justify-flex-end">
   <div class="ml-2">
      <div class="dropdown">
         <button class="dropbtn">
            <i class="fa fa-download"></i> {{ 'general.export_data' | translate }}
         </button>
         <div class="dropdown-content">
            <a (click)="exportToExcel()">{{ 'general.export_as_excel' | translate }} (.xlsx)</a>
            <a (click)="exportToPDF()">{{ 'general.export_as_pdf' | translate }}</a>
            <a (click)="exportToCSV()">{{ 'general.export_as_csv' | translate }}</a>
         </div>
      </div>
   </div>

   <div class="ml-2">
      <div class="dropdown">
        <button class="dropbtn">
          <i class="fa fa-download"></i> {{ 'general.download_reports' | translate }}
        </button>
        <div class="dropdown-content">
          <div *ngFor="let report of reports" class="dropdown-item" (click)="toggleSelection(report)">
            <input type="checkbox" [checked]="report.selected" />
            <label>{{ report.name }}</label>
          </div>
          <button class="download-all-btn" (click)="downloadAll()" [disabled]="!anyReportsSelected()" [ngClass]="{ disabled: !anyReportsSelected() }">{{ 'general.download' | translate }}</button>
        </div>
      </div>
    </div>
</div>
<br>
<div class="documents-2 shadow white-bg">
   <h3 *ngIf="
         !(
            this.submissionsNumber == 0 &&
            submissionName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            assignmentType == '-1' &&
            studentName == undefined &&
            assignmentName == undefined &&
            subjectName == undefined
         )
      " class="filtersContainer">
      <h4>{{ 'general.submissions' | translate }}</h4>
      <div class="selectors">
         <div [ngClass]="{
            'selectedOptionBorder': subject != '-1'}">
            <select (change)="filterWithTimeout()" [(ngModel)]="subject" name="" id="">
               <option disabled selected value="-1">{{ 'general.select_subject' | translate }}</option>
               <option value="{{ subject.id }}" *ngFor="let subject of courses">
                  {{ subject.title }}
               </option>
               <option>{{ 'general.all' | translate }}</option>
            </select>
         </div>

         <div [ngClass]="{
            'selectedOptionBorder': assignment != '-1'}">
            <select (change)="filterWithTimeout()" [(ngModel)]="assignment" name="" id="">
               <option disabled selected value="-1">
                  {{ 'app.select_assignment' | translate }}
               </option>
               <option value="{{ assignment.id }}" *ngFor="let assignment of assignments">
                  {{ assignment.name }}
               </option>
               <option>{{ 'general.all' | translate }}</option>
            </select>
         </div>

         <div [ngClass]="{
            'inputBorder': submissionName != ''}">
            <input [placeholder]="'placeholders.search_by_title' | translate" [(ngModel)]="submissionName"
               (ngModelChange)="filterWithTimeout()" type="text" name="searchByName" />
         </div>

         <div [ngClass]="{
            'inputBorder': studentName != ''}">
            <input [placeholder]="'placeholders.search_by_student' | translate" [(ngModel)]="studentName"
               (ngModelChange)="filterWithTimeout()" type="text" name="searchByStudentName" />
         </div>

         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </h3>

   <div class="table_container" *ngIf="this.submissionsNumber != 0" #contentToCapture>
      <table>
         <tr>
            <th>
               <h5 class="bold left">{{ 'general.table.name' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.full_name' | translate }}</h5>
            </th>
            <!-- <th>
               <h5 class="bold">{{ 'app.institution' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'general.department' | translate }}</h5>
            </th> -->
            <!-- <th><h5 class="bold">{{ 'general.end_date' | translate }}</h5></th> -->
            <th>
               <h5 class="bold">{{ 'general.submission_date' | translate }}</h5>
            </th>
            <!-- <th>
               <h5 class="bold">{{ 'general.type' | translate }}</h5>
            </th> -->
            <!-- <th>
               <h5 class="bold">{{ 'general.table.number_of_words' | translate }}</h5>
            </th> -->
            <th>
               <h5 class="bold">{{ 'general.document_language_similarity' | translate }}</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'app.upload.translated_language' | translate }} similarity</h5>
            </th>
            <th>
               <h5 class="bold">{{ 'report.ai_index' | translate }}</h5>
            </th>
            <!-- <th>
            <h5 class="bold">{{ 'app.grade' | translate }}</h5>
         </th> -->
            <th>
               <h5 class="bold">{{ 'general.report' | translate }}</h5>
            </th>
         </tr>
         <tr *ngFor="
               let submission of this.submissions
                  | paginate
                     : {
                          itemsPerPage: 5,
                          currentPage: page,
                          totalItems: this.submissionsNumber
                       }
            ">
            <ng-container *ngIf="submission.showSubmission == true">
               <td>
                  <h5 class="left">
                     <!-- <span *ngIf="submission.draftId !== null" style="font-weight: 700">{{ 'general.table.draft' |translate }}: </span> -->
                     {{ submission.title }}
                  </h5>
               </td>
               <td>
                  <h5>
                     {{ submission.user.name }}
                  </h5>
               </td>
               <!-- <td>
                  <h5>{{ submission.user.Faculty.name }}</h5>
               </td>
               <td>
                  <h5>{{ submission.user.Department.name }}</h5>
               </td> -->
               <!-- <td>
                  <h5>
                     {{ submission.assignments.endDate | date: 'dd/MM/yyyy' }}
                  </h5>
               </td> -->

               <td>
                  <h5>{{ submission.createdAt | date: 'dd/MM/yyyy' }}</h5>
               </td>

               <!-- <td>
                  <h5 *ngIf="submission.assignments.type === 1">{{ 'general.essay' | translate }}</h5>
                  <h5 *ngIf="submission.assignments.type === 2">{{ 'general.research' | translate }}</h5>
                  <h5 *ngIf="submission.assignments.type === 3">{{ 'general.report' | translate }}</h5>
                  <h5 *ngIf="submission.assignments.type === 4">{{ 'general.other' | translate }}</h5>
               </td> -->

               <!-- <td>
                  <h5>{{ submission.wordCount }}</h5>
               </td> -->
               <td>
                  <h5 *ngIf="submission.processed == 1" [ngClass]="{
                        'red-border':
                           submission.originalPercentage.toFixed(2) > 20,
                        'green-border':
                           submission.originalPercentage.toFixed(2) >= 0 &&
                           submission.originalPercentage.toFixed(2) < 5,
                        'orange-border':
                           submission.originalPercentage.toFixed(2) >= 5 &&
                           submission.originalPercentage.toFixed(2) <= 20
                     }">
                     <span *ngIf="submission.originalLanguage !== null">{{ submission.originalLanguage | uppercase }}
                     </span>
                     <span *ngIf="submission.originalLanguage == null">
                        --
                     </span>

                     {{ submission.originalPercentage.toFixed(2) }}%
                  </h5>

                  <h5 *ngIf="submission.processed == 2" class="gray-border">
                     {{ 'app.suspended' | translate }}
                  </h5>
                  <h5 *ngIf="
                        submission.processed == 0 || submission.processed == -1
                     " class="gray-border">
                     {{ 'report.processing' | translate }}
                  </h5>
               </td>
               <td>
                  <h5 *ngIf="submission.processed == 1" [ngClass]="{
                        'red-border':
                           submission.translatedPercentage.toFixed(2) > 20,

                        'green-border':
                           submission.translatedPercentage.toFixed(2) >= 0 &&
                           submission.translatedPercentage.toFixed(2) < 5,

                        'orange-border':
                           submission.translatedPercentage.toFixed(2) >= 5 &&
                           submission.translatedPercentage.toFixed(2) <= 20,

                        'gray-border': submission.translatedLanguage == null
                     }">
                     <!-- <span *ngIf="submission.translatedLanguage !== null">{{ submission.translatedLanguage | uppercase
                        }}
                     </span> -->
                     <span *ngIf="submission.translatedLanguage == null">
                        --
                     </span>
                     <ng-container *ngIf="submission.translatedLanguage !== null">
                        {{ submission.translatedPercentage.toFixed(2) }}%
                     </ng-container>

                     <ng-container *ngIf="submission.translatedLanguage == null">
                        ---
                     </ng-container>
                  </h5>
               </td>
               <td>
                  <span *ngIf="submission.processed == 1 && submission.aiDetection" [ngClass]="{
                        'red-border': submission.aiPercentage > 20,
                        'green-border':
                           submission.aiPercentage >= 0 &&
                           submission.aiPercentage < 5,
                        'orange-border':
                           submission.aiPercentage >= 5 &&
                           submission.aiPercentage <= 20
                     }">
                     <!-- <span>{{ 'general.ai' | translate }}</span> -->
                     {{ submission.aiPercentage }}%</span>
                  <h5 *ngIf="
                        (submission.processed == 0 ||
                        submission.processed == -1) && submission.aiDetection
                     " class="gray-border"> -
                  </h5>
                  <h5 *ngIf="submission.processed == 2 && submission.aiDetection" class="gray-border">
                     {{ 'app.suspended' | translate }}
                  </h5>
                  <h5 *ngIf="!submission.aiDetection" class="gray-border">
                     N/A
                  </h5>
               </td>
               <!-- <td>6</td> -->
               <td>
                  <a (click)="goToLink(submission.id)" class="reportBtn">{{ 'general.report' | translate }}</a>
               </td>
            </ng-container>
         </tr>
      </table>
   </div>

   <div class="zeroState" *ngIf="
         this.submissionsNumber == 0 &&
         (submissionName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            assignmentType != '-1' ||
            studentName != undefined ||
            assignmentName != undefined ||
            subjectName != undefined)
      ">
      {{ 'app.there_are_no_submissions' | translate }}
   </div>
   <div>
      <pagination-controls *ngIf="this.submissionsNumber != 0" class="num" (pageChange)="changePage($event)"
         responsive="true" previousLabel="" nextLabel="">
      </pagination-controls>
   </div>
   <div class="zero-state" *ngIf="
         this.submissionsNumber == 0 &&
         submissionName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         assignmentType == '-1' &&
         studentName == undefined &&
         subjectName == undefined &&
         assignmentName == undefined
      ">
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">{{ 'app.your_students_dont_have_any_submissions' | translate }}</h5>
   </div>
</div>
