<div
   *ngIf="!this.hasRoute('store/')"
   style="display: flex; width: 100%; padding-top: 2%"
>
   <!-- <ng-container *ngFor="let license of this.licenses">
      <div style="width: 50%">
         <div style="display: flex; justify-content: center">
            <div style="width: 85%">
               <div style="display: flex; justify-content: space-between">
                  <span> Validity </span>
                  <span> {{ license.validity }} days left </span>
               </div>

               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'app.educators_accounts' | translate }} </span>
                  <span>
                     {{ license.professors }}/{{
                        license.Institution.professors
                     }}</span
                  >
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'profile.teaching_assistants_accounts' | translate }} </span>
                  <span>
                     {{ license.teachingAssistants || 0 }}/{{
                        license.Institution.teachingAssistants
                     }}</span
                  >
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'general.academic_integrity_officer_accounts' | translate }} </span>
                  <span>
                     {{ license.academicIntegrityOfficers }}/{{
                        license.Institution.academicIntegrityOfficers
                     }}</span
                  >
               </div>

               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'profile.student_accounts' | translate }} </span>
                  <span>
                     {{ license.students }}/{{
                        license.Institution.students
                     }}</span
                  >
               </div>

               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'app.institutions' | translate }} </span>
                  <span> {{ 'general.unlimited' | translate }} </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'general.departments' | translate }} </span>
                  <span> {{ 'general.unlimited' | translate }} </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'general.subjects' | translate }} </span>
                  <span> {{ 'general.unlimited' | translate }} </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'general.assignments' | translate }} </span>
                  <span> {{ 'general.unlimited' | translate }} </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'general.thesis' | translate }} </span>
                  <span>
                     {{ license.thesis }}/{{
                        license.Institution.bachelor +
                           license.Institution.master +
                           license.Institution.phd
                     }}
                  </span>
               </div>
               <div style="display: flex; justify-content: space-between">
                  <span> {{ 'app.repositories' | translate }} </span>
                  <span> {{ 'app.see_list' | translate }} </span>
               </div>
            </div>
         </div>
         <div style="display: flex; justify-content: space-around">
            <span routerLink="manage"> {{ 'general.manage' | translate }} </span>
            <span routerLink="offers"> {{ 'app.see_offers' | translate }} </span>
         </div>
      </div>
   </ng-container> -->

   <ng-container>
      <section>
         <section>
            <div>
               <h3>{{ 'add_credits.your_plan' | translate }}</h3>
            </div>
         </section>
         <section>
            <div>
               <div>
                  <div style="display: flex; justify-content: space-between">
                     <span> {{ 'app.users.users' | translate }} </span>
                     <span routerLink="manage/users"> {{ 'general.manage' | translate }} </span>
                  </div>
                  <!-- <section>
                     <div style="display: grid; padding-left: 5%; width: 100%">
                        <span>
                           {{ 'app.administrators' | translate }}
                           <b>
                              {{ this.licenses[0]?.administrators }}
                           </b>
                        </span>
                        <span>
                           {{ 'general.academic_integrity_officers' | translate }}
                           <b>{{
                              this.licenses[0]?.academicIntegrityOfficers
                           }}</b>
                        </span>
                        <span>
                           {{ 'general.educators' | translate }}
                           <b>
                              {{ this.licenses[0]?.professors }}
                           </b>
                        </span>
                        <span>
                           {{ 'general.teaching_assistants' | translate }}
                           <b>
                              {{ this.licenses[0]?.teachingAssistants }}
                           </b>
                        </span>
                        <span>
                           {{ 'general.students' | translate }}
                           <b>
                              {{ this.licenses[0]?.students }}
                           </b>
                        </span>
                     </div>
                  </section> -->
                  <span>
                     {{
                        this.licenses[0]?.administrators +
                           this.licenses[0]?.professors +
                           this.licenses[0]?.teachingAssistants +
                           this.licenses[0]?.academicIntegrityOfficers +
                           this.licenses[0]?.students
                     }}
                  </span>
               </div>
            </div>
         </section>
         <hr />
         <section>
            <div>
               <div style="display: flex; justify-content: space-between">
                  <span>
                     <b>{{ 'app.addons.addons' | translate }}</b>
                  </span>
                  <span routerLink="manage/addons"> {{ 'general.manage' | translate }} </span>
               </div>
               <div style="display: grid; padding-left: 5%; width: 100%">
                  <span>
                     Bachelor theses {{ this.licenses[0]?.bachelor }}
                  </span>
                  <span> Master theses {{ this.licenses[0]?.master }} </span>
                  <span> {{ 'app.phd_thesis' | translate }} {{ this.licenses[0]?.phd }} </span>
                  <section>
                     <span>{{ 'app.crosslingual_feature' | translate }}</span>
                     <div style="display: grid; padding-left: 5%; width: 100%">
                        <span>
                           {{ 'general.assignments' | translate }}
                           <b>
                              {{
                                 this.licenses[0]?.crossLingual.assignments ===
                                 true
                                    ? ('general.yes' | translate)
                                    : ('general.no' | translate)
                              }}
                           </b>
                        </span>
                        <span>
                           professorUploads
                           <b>
                              {{
                                 this.licenses[0]?.crossLingual
                                    .professorUploads === true
                                    ? ('general.yes' | translate)
                                    : ('general.no' | translate)
                              }}
                           </b>
                        </span>
                        <span>
                           thesis
                           <b>
                              {{
                                 this.licenses[0]?.crossLingual.thesis === true
                                 ? ('general.yes' | translate)
                                 : ('general.no' | translate)
                              }}
                           </b>
                        </span>
                     </div>
                  </section>
                  <section>
                     <span
                        >{{ 'app.educator_individual_uploads' | translate }}
                        <b>{{
                           this.licenses[0]?.professorIndividualUploads
                        }}</b></span
                     >
                  </section>
               </div>
            </div>
         </section>
         <hr />
         <section>
            <div style="display: flex; justify-content: space-between">
               <span>
                  {{ 'add_credits.support' | translate }} <b>{{ this.licenses[0]?.support }}</b>
               </span>
               <span> {{ 'general.manage' | translate }} </span>
            </div>
         </section>
         <hr />
         <section>
            <div style="display: flex; justify-content: space-between">
               <span>
                  Commitment <b>{{ this.licenses[0]?.commitment }}</b>
               </span>
               <span routerLink="manage/commitment"> {{ 'general.manage' | translate }} </span>
            </div>
         </section>
         <hr />
         <section>
            <div style="display: flex; justify-content: space-between">
               <span>
                  Billing Plan <b>{{ this.licenses[0]?.billingPlan }}</b>
               </span>
               <span> {{ 'general.manage' | translate }} </span>
            </div>
         </section>
      </section>
   </ng-container>
</div>

<router-outlet></router-outlet>
