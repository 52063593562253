import { SubmissionsEffects } from './state/effects/submissions.effects';
import { EffectsModule } from '@ngrx/effects';
import { NavbarComponent } from './../sharedModule/navbar/navbar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IndividualDashboardComponent } from './individual-dashboard/individual-dashboard.component';
import { IndividualStatisticsComponent } from './individual-statistics/individual-statistics.component';
import { IndividualUploadsComponent } from './individual-uploads/individual-uploads.component';
import { InputTextComponent } from './individual-uploads/input-text/input-text.component';
import { UploadFileComponent } from './individual-uploads/upload-file/upload-file.component';
import { WebImportComponent } from './individual-uploads/web-import/web-import.component';
import { Routes, RouterModule } from '@angular/router';
import { UploadFormComponent } from './individual-uploads/upload-form/upload-form.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { submissionsReducer } from './state/reducer/submissions.reducer';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadDraftFileComponent } from './individual-uploads/draft-upload/upload-draft-file/upload-draft-file.component';
import { TextDetectorComponent } from './text-detector/text-detector.component';
import { TextDetectorModalComponent } from './text-detector-modal/text-detector-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
const individualRoutes: Routes = [
   {
      path: '',
      component: NavbarComponent,
      data: { roles: ['2'] },
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
         {
            path: '',
            loadChildren: () =>
               import('../sharedModule/shared.module').then(
                  (m) => m.SharedModule
               ),
         },
         {
            path: 'dashboard',
            component: IndividualDashboardComponent,
            data: { roles: ['2'] },
         },
         {
            path: 'detector',
            component: TextDetectorComponent,
            data: { roles: ['2'] },
         },
         {
            path: 'statistics',
            component: IndividualStatisticsComponent,
            data: { roles: ['2'] },
         },
         {
            path: 'upload-files',
            component: IndividualUploadsComponent,
            children: [
               {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'upload',
                  data: { roles: ['2'] },
               },
               {
                  path: 'upload',
                  component: UploadFileComponent,
                  data: { roles: ['2'] },
               },
               {
                  path: 'web-import',
                  component: WebImportComponent,
                  data: { roles: ['2'] },
               },
               {
                  path: 'input-text',
                  component: InputTextComponent,
                  data: { roles: ['2'] },
               },
            ],
         },
         {
            path: 'upload-draft',
            component: IndividualUploadsComponent,
            children: [
               {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'upload',
                  data: { roles: ['2'] },
               },
               {
                  path: 'upload/:draftId',
                  component: UploadDraftFileComponent,
                  data: { roles: ['2'] },
               },
            ],
         },
         {
            path: 'upload-files',
            component: IndividualUploadsComponent,
            children: [
               {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'upload',
                  data: { roles: ['2'] },
               },
               {
                  path: 'upload',
                  component: UploadFileComponent,
                  data: { roles: ['2'] },
               },
               {
                  path: 'web-import',
                  component: WebImportComponent,
                  data: { roles: ['2'] },
               },
               {
                  path: 'input-text',
                  component: InputTextComponent,
                  data: { roles: ['2'] },
               },
               {
                  path: 'upload-form',
                  component: UploadFormComponent,
                  data: { roles: ['2'] },
               },
            ],
         },
      ],
   },
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
   declarations: [
      IndividualDashboardComponent,
      IndividualStatisticsComponent,
      IndividualUploadsComponent,
      InputTextComponent,
      UploadFileComponent,
      WebImportComponent,
      UploadFormComponent,
      UploadDraftFileComponent,
      TextDetectorComponent,
      TextDetectorModalComponent
   ],
   imports: [
      MatProgressSpinnerModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        }
      }),
      NgxPaginationModule,
      NgApexchartsModule,
      RouterModule.forChild(individualRoutes),
      StoreModule.forFeature('submissions', submissionsReducer),
      EffectsModule.forFeature([SubmissionsEffects]),
      CommonModule,
      PdfViewerModule,
      MatTooltipModule,
   ],
   exports: [TranslateModule],
})
export class IndividualComponentsModule {}
