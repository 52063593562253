<section class="view_logs">
  <section class="header">
    <h1>{{ 'new_entries.audit_log' | translate }}</h1>
    <button mat-dialog-close>
      <img src="../../../assets/report_v2/close_big.svg" alt="Close" />
    </button>
  </section>
  <section class="body">
    <section class="info">
      <p class="key">{{ 'new_entries.institution_name' | translate }}:</p>
      <p class="value">{{ data.request.user.Institution.name  }}</p>
    </section>
    <section class="info">
      <p class="key">Department:</p>
      <p class="value">{{ data.request.user.Department.name  }}</p>
    </section>
    <section class="info">
      <p class="key">{{ 'new_entries.document_author' | translate }}:</p>
      <p class="value">{{ data.request.user.name }}</p>
    </section>
    <section class="info">
      <p class="key">{{ 'new_entries.date_and_time' | translate }}:</p>
      <p class="value">{{ data.request.createdAt | date: 'dd/MM/yyyy'  }}</p>
    </section>
    <section class="info">
      <p class="key">{{ 'new_entries.request_purpose' | translate }}:</p>
      <p class="value">
        {{ data.request.description }}
      </p>
    </section>
    <section class="info">
      <p class="key">{{ 'new_entries.reports_linked_to_this_document' | translate }}:</p>
      <section class="value">
        {{data.request.linkedReports.length}}
        <button (click)="toggleShowList()" *ngIf="expandable">{{ 'new_entries.show_list' | translate }}</button>
      </section>
    </section>
    <section *ngIf="showList" class="expandable">
      <div class="document-list">
        <div class="document-row header">
          <div class="document-number">#</div>
          <div class="document-name">{{ 'new_entries.document_name' | translate }}</div>
        </div>

        <div class="document-row" *ngFor="let document of data.request.linkedReports">
          <div class="document-number">1</div>
          <div class="document-name"><a (click)="goToLink(document.id)">{{document.title}}</a></div>
        </div>

      </div>
    </section>
  </section>
  <section class="footer">
    <button mat-button mat-dialog-close>Close</button>
  </section>
</section>
