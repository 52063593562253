import {
  Component, Input, SimpleChanges, OnInit, OnDestroy, ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import {LanguageService} from '../../../services/language.service';
import {Language} from '../../../models/language';
import {ReportService} from "../../../services/report.service";
import {ActivatedRoute} from "@angular/router";
import {saveAs} from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { PrivacySettingsModalComponent } from '../../shared/privacy-settings-modal/privacy-settings-modal.component';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnDestroy {
  title = 'Banner';
  languages: Language[] = [];
  activeMenuBar: { [key: string]: boolean } = {};
  @Input() bannerDetails: any;
  @Input() submissionDetails: any;
  @Input() studentDeletionPermissions: any;
  @Input() isDocumentInArchive: any;
  @Input() sourcesAreDeleted: any;
  submissionId: string = ''
  newTab!: string;
  userInfo: any;
  showExpandView= false;
  homeLink: string

  constructor(
    private languageService: LanguageService,
    private reportService: ReportService,
    private acRouter: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private http: HttpClient,
       private spinnerService: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    this.languageService.getLanguages().subscribe((languages) => {
      this.languages = languages;
    });

    this.acRouter.params.subscribe((data: any) => {
      this.submissionId = data.id
    })
    // this.newTab = environment.new_tab_url;
    this.newTab = window.location.href;


    document.addEventListener('click', (event) => this.handleGlobalClick(event));

    this.acRouter.queryParams.subscribe(params => {
      const expanded = params['expanded'];
      if (expanded !== undefined) {
        this.showExpandView = true
      } else {
        this.showExpandView = false
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['submissionDetails'] && changes['submissionDetails'].currentValue !== changes['submissionDetails'].previousValue) {
      this.userService.userInfo(this.submissionDetails.submission.userId, localStorage.getItem('ACCESS_TOKEN')).subscribe((data: any) => {
        this.userInfo = data.user;
      });
    }
  }

  getLanguageName(languageCode: string | string[]): string {
    let languageCodes: string[];
    if (typeof languageCode === 'string' && languageCode.startsWith('["')) {
      try {
        languageCodes = JSON.parse(languageCode);
      } catch (error) {
        console.error('Invalid JSON string:', languageCode);
        return 'Invalid Language Code';
      }
    } else if (typeof languageCode === 'string') {
      languageCodes = [languageCode];
    } else {
      languageCodes = languageCode;
    }
  
    const languageNames = languageCodes.map(code => {
      const language = this.languages.find(lang => lang.value === code);
      return language ? language.text : 'Unknown Language';
    });
  
    return languageNames.join(', ');
  }

  openLink(url: string): void {
    if(url.includes('support.inspera.com')){
      window.open(url, '_blank');
    }else{
      const userToken = localStorage.getItem('ACCESS_TOKEN') as string
      const userId = JSON.parse(localStorage.getItem('user')).id as string
      url += `/${userId}/${userToken.slice(1, -1)}`
      window.open(url, '_blank');
    }
  }

  toggleMenuBar(menuBar: string): void {
    if (this.activeMenuBar[menuBar]) {
      this.activeMenuBar[menuBar] = false;
    } else {
      Object.keys(this.activeMenuBar).forEach((key) => {
        this.activeMenuBar[key] = false;
      });

      this.activeMenuBar[menuBar] = true;
    }
  }

  handleGlobalClick(event: Event): void {
    // Check if the clicked element is inside the menubar or the button that opens the menubar
    const isMenubarElement = (event.target as HTMLElement).closest('.menubar') !== null;
    const isMenuBarButton = (event.target as HTMLElement).closest('[data-menu-bar-key]') !== null;

    // If the clicked element is outside the menubar, close the menu bar
    if (!isMenubarElement && !isMenuBarButton) {
      this.closeMenuBar('submissionDetails', event);
      this.closeMenuBar('download', event);
      this.closeMenuBar('settings', event)
      // Add more menu bars if needed
    }
  }

  closeMenuBar(menubar: string, event: Event): void {
    event.stopPropagation();
    this.activeMenuBar[menubar] = false;
  }

  downloadOriginalDocument() {
    // this.spinnerService.show()
    this.reportService
      .downloadOriginalDocument(this.submissionId)
      .pipe()
      .subscribe(
        (data: any) => {
          saveAs(data.urlToDownload, 'document.pdf');
          // this.spinnerService.hide()
        },
        (error: any) => {
          console.log('error', error);
          // this.spinnerService.hide()
        }
      );
  }

  downloadPdfReport(reportType: string): void {
    this.spinnerService.show()
    this.reportService
      .downloadReport(this.submissionId, reportType)
      .pipe()
      .subscribe(
        (data: any) => {
          let desiredName = `Offline Report - ${this.bannerDetails.title}.pdf`;
          this.http.get(data.presignedS3UrlDownload, { responseType: 'arraybuffer' }).subscribe((fileData: ArrayBuffer) => {
            const blob = new Blob([fileData]);
            saveAs(blob, desiredName);
          })
          // saveAs(data.presignedS3UrlDownload, 'report.pdf');
          this.spinnerService.hide()
        },
        (error) => {
          console.log('error', error);
          this.spinnerService.hide()
        }
      );
  }

  downloadAiReport(): void {
    // this.spinnerService.show()
    this.reportService
      .downloadAiReport(this.submissionId)
      .pipe()
      .subscribe(
        (data: any) => {
          saveAs(data.presignedS3UrlDownload, 'ai-report.pdf');
          // this.spinnerService.hide()
        },
        (error) => {
          console.log('error', error);
          // this.spinnerService.hide()
        }
      );
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.handleGlobalClick);
  }

  openSettingsModal(): void {
    this.dialog.open(PrivacySettingsModalComponent, {
      width: '800px',
      height: '600px',
      data: {
        userInfo: this.userInfo,
        studentDeletionPermissions: this.studentDeletionPermissions,
        isDocumentInArchive: this.isDocumentInArchive,
        sourcesAreDeleted: this.sourcesAreDeleted,
        submission: this.submissionDetails.submission
      }
    });
  }

  goToHome(): void {
    const protocol = window.location.protocol
    const host = window.location.host
    const url = `${protocol}//${host}/`;
    window.open(url, '_self');
  }
}
