import {Component, Output, EventEmitter, Input} from '@angular/core';
import { ReportService } from '../../../services/report.service';
import { DocumentData } from '../../../services/document-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-matching-sources',
  templateUrl: './matching-sources.component.html',
  styleUrls: ['./matching-sources.component.scss']
})
export class MatchingSources {
  title = this.translate.instant('new_entries.matching_source');

  @Input() isOnAnalysis = false;
  @Output() isOnAnalysisChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() similarityAnalysis: any
  @Input() canEdit: boolean

  constructor(private reportService: ReportService, private documentDataService: DocumentData, private translate: TranslateService) {}

  toggleIsOnAnalysis(): void {
    this.isOnAnalysis = !this.isOnAnalysis;
    this.isOnAnalysisChange.emit(this.isOnAnalysis);
    this.documentDataService.setCurrentPage(1)
    if (this.isOnAnalysis) {
      this.reportService.selectAnalysisTypeSubject.next('analysis');
    } else {
      this.reportService.selectAnalysisTypeSubject.next('none');
    }
  }
}
