<section *ngIf="!isOnAnalysis && !isOnAi && !isOnManipulation" class="originality_score" aria-label="Originality Score">
  <section class="title">
    <h1 tabindex="0" [ariaLabel]="['Originality index is: ' + (docStatus ? docStatus : 'unknown')]">
      {{ 'new_entries.originality_index' | translate }}
      <span class="status_tag" [ngClass]="[docStatus ? docStatus.split(' ').join('_').toLowerCase() : '']" [ngStyle]="{ background: setBackgroundColor() }">
        <span
          class="status_tag_circle"
        ></span>
        <span class="status_tag_label">
          {{ (docStatus == 'High risk' ? 'new_entries.high_risk' : (docStatus == 'Medium risk' ? 'new_entries.medium_risk' : 'new_entries.low_risk')) | translate}}
        </span>
      </span>
    </h1>
    <button (click)="openLink('https://support.inspera.com/hc/en-us/articles/15954011237021-What-is-the-Originality-Index')">{{ 'new_entries.learn_more' | translate }}</button>
  </section>
  <section
    class="index_score"
    [ngStyle]="{ background: setBackgroundColor() }"
    focusable
    [ariaLabel]="docStatus + warningText"
  >
    <aside class="alert_img">
      <img [src]="[indexImage]" alt="" />
    </aside>
    <section class="originality_label" role="contentinfo">
      <p>
        <span class="score-bold bold">{{ (docStatus == 'High risk' ? 'new_entries.high_risk' : (docStatus == 'Medium risk' ? 'new_entries.medium_risk' : 'new_entries.low_risk')) | translate}}</span>
        {{ warningText }}
      </p>
    </section>
  </section>
</section>
