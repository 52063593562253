<div class="edit-faculty">
   <div class="edit-faculty-title">
      <h4>{{ 'general.edit' | translate }} {{ 'app.institution' | translate }}</h4>
   </div>
   <form [formGroup]="facultyEdit">
      <div class="editimi-fakultetit-inputs">
         <div class="inside-inputs">
            <label for="facultyName">{{ 'general.name' | translate }}:</label>
            <input formControlName="facultyName" type="text" />
            <div
               *ngIf="
                  facultyEdit.controls.facultyName.touched &&
                  facultyEdit.controls.facultyName.invalid
               "
               class="invalid"
            >
               {{ 'app.info.institution_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <div
               *ngIf="facultyEdit.controls.facultyName.errors?.minlength"
               class="invalid"
            >
               {{ 'app.info.institution_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
            </div>
         </div>
         <div class="inside-inputs">
            <label for="Description">{{ 'general.description' | translate }}:</label>
            <input formControlName="facultyDescription" type="text" />
            <div
               *ngIf="
                  facultyEdit.controls.facultyDescription.touched &&
                  facultyEdit.controls.facultyDescription.invalid
               "
               class="invalid"
            >
               {{ 'general.description' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>

            <div
               *ngIf="facultyEdit.controls.facultyDescription.errors?.minlength"
               class="invalid"
            >
               {{ 'app.institution' | translate }} {{ 'general.description' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
            </div>
         </div>
      </div>
      <div class="buttons">
         <a class="fshij-link" (click)="delete()">{{ 'app.delete' | translate }}</a>
         <button
            [disabled]="facultyEdit.invalid"
            [ngClass]="{
               disable: facultyEdit.invalid
            }"
            type="submit"
            class="editButton"
            (click)="update()"
         >
            SAVE
         </button>
      </div>
   </form>
</div>
