<section class="parent">
  <h1>{{ modalType == 'exclude' ? 'Exclude Characters' : 'Include Characters' }}</h1>
  <p>{{ modalType == 'exclude' ? 'Select the characters you want to exclude from the  document' : 'Select the characters you want to include on the  document' }}</p>
  <section class="modal-body">
    <div>
      <label>
        <input type="checkbox" (change)="toggleSelectAll($event)" />
        <span>Select All</span>
      </label>
      <label>Appearances</label>
    </div>
    <section class="scrollable">
      <div *ngFor="let char of result; let i = index">
        <label>
          <input type="checkbox" [id]="i" [(ngModel)]="selectionState[i]"/>
          <span>{{ char?.character }}</span>
        </label>
        <label>{{ char?.count }}</label>
      </div>
    </section>
  </section>
  <section class="modal-btns">
    <button (click)="cancel()">Cancel</button>
    <button (click)="onSubmit(modalType == 'exclude' ? 'exclude' : 'include')">{{ modalType == 'exclude' ? 'Exclude' : 'Include' }}</button>
  </section>
</section>
