  import swal from 'sweetalert2';
  import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
  import { ReportService } from "../../../services/report.service";
  import { DocumentData } from "../../../services/document-data.service";
  // import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
  // import { CharReplacementActions } from "../../shared/char_replacement_actions/char_replacement_actions.component";
  import { ReportComponent } from "../../../report.component";
  import { DocumentComponent } from "../../panel-components/document/document.component";
  // import { SpinnerService } from '../../../services/spinner.service';
  import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

  @Component({
    selector: 'app-manipulation',
    templateUrl: './manipulation.component.html',
    styleUrls: ['./manipulation.component.scss'],
    providers: [DocumentComponent]
  })
  export class Manipulation implements OnChanges, OnInit {
    @Input() isOnManipulation = false;
    isCharReplacement = false;
    isHiddenText = false;
    isImage = false;
    @Output() isOnManipulationChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() manipulationsCalculated: EventEmitter<any> = new EventEmitter<any>();
    @Input() sentenceDetails: any;
    @Input() allManipulations: any;
    @Input() imagesDetectionData: any;
    @Input() pdfImagesSrc: any;
    @Input() tempPdfSrc: any;
    @Input() pdfSrc: any;
    @Input() submissionId: any;
    summaryDetails: any;
    // ref: DynamicDialogRef | undefined;

    totalCharReplacements = 0;
    totalWhiteCharacters = 0;
    totalManipulatedImages = 0;

    redImages: any[] = [];
    greenImages: any[] = [];

    replacementCharacterSentences: any = [];
    whiteCharacterSentences: any = [];

    replacedCharacterList: any = []
    includedCharactersSentences: any = [];
    excludedCharactersSentences: any = [];

    private selectedElement!: HTMLElement;

    totalPdfPages: number = 0;

    private currentFlag!: string;

    includedHiddenTextSentences: any = [];
    excludedHiddenTextSentences: any = [];

    // @Input() documentComponent!: DocumentComponent;
    tooltipVisible = false;

    showTopFlags = false;
    topManipulations: any = {};

    selectedFilterText: string = 'All'
    isSummaryMenuOpen: boolean = false;

    includedCharacters: any[] = [];
    excludedCharacters: any[] = [];

    // @ViewChild('documentComponent') documentComponent!: DocumentComponent;

    constructor(
      private reportService: ReportService,
      private documentDataService: DocumentData,
      private renderer: Renderer2,
      private spinnerService: NgxSpinnerService,
      private reportComponent: ReportComponent,
      private documentComponent: DocumentComponent,
      private cdr: ChangeDetectorRef,
      private toastr: ToastrService,
    ) {
    }

    ngOnInit(): void {
      this.calculateTotalSentences();

      this.documentDataService.getTotalPages().subscribe((totalPages: number) => {
        this.totalPdfPages = totalPages;
      });
      document.addEventListener('click', (event) => this.handleGlobalClick(event));
    }

    ngAfterViewInit() {
      this.calculateTotalSentences();
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes && changes['sentenceDetails'] && changes['sentenceDetails'].currentValue !== changes['sentenceDetails'].previousValue) {
        this.setDefaultOpenFlag();

        if (this.sentenceDetails?.some((sentence: any) => sentence?.characters && sentence?.characters.some((char: any) => char.isWhite && !sentence.whitecharactersExcluded))) {
          this.totalWhiteCharacters = this.sentenceDetails.reduce((total: number, sentence: any) => {
            if (!sentence.whitecharactersExcluded && sentence.characters) {
              return total + sentence.characters.filter((char: any) => char.isWhite).length;
            }
            return total;
          }, 0);
        } else {
          this.totalWhiteCharacters = 0;
        }

        this.includedHiddenTextSentences = [];
        this.excludedHiddenTextSentences = [];
        this.sentenceDetails?.forEach((sentence: any) => {
          if (sentence.characters && sentence.characters.length > 0) {
            if (sentence.whitecharactersExcluded) {
              this.excludedHiddenTextSentences.push(sentence);
            } else {
              this.includedHiddenTextSentences.push(sentence);
            }
          }
        });
      }
      if (changes && changes['imagesDetectionData'] && changes['imagesDetectionData'].currentValue !== changes['imagesDetectionData'].previousValue) {
        this.redImages = [];
        this.greenImages = [];
        this.imagesDetectionData.forEach((image: any) => {
          if (image.label == 'red') {
            this.redImages.push(image);
          } else if (image.label == 'green') {
            this.greenImages.push(image);
          }
        });
        this.totalManipulatedImages = this.redImages.length;
      }

      if (changes && changes['allManipulations'] && changes['allManipulations'].currentValue !== changes['allManipulations'].previousValue) {
        this.includedCharactersSentences = [];
        this.excludedCharactersSentences = [];
        if (this.allManipulations && this.sentenceDetails) {
          this.replacedCharacterList = this.allManipulations?.replacedCharacters?.map((char: any) => char.character);

          this.sentenceDetails?.forEach((sentence: any) => {
            if (sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {
              if (sentence.replacedCharacters.filter((char: any) => this.replacedCharacterList.includes(char)).length > 0) {
                this.includedCharactersSentences.push(sentence);
              } else {
                this.excludedCharactersSentences.push(sentence);
              }
            }
          });

          this.totalCharReplacements = this.allManipulations.replacedCharacters.reduce((total: any, obj: any) => total + obj.count, 0);
        }
      }


      this.calculateManipulations();
      this.emitManipulations();
      this.cdr.detectChanges();

      this.topManipulations = this.calculateTopManipulations();
    }

    toggleIsOnManipulation() {
      this.isOnManipulation = !this.isOnManipulation;
      this.isOnManipulationChange.emit(this.isOnManipulation);
      this.documentDataService.setCurrentPage(1)
      this.emitManipulations();
      if (this.isOnManipulation) {
        this.reportService.selectAnalysisTypeSubject.next("manipulation")
      } else {
        this.reportService.selectAnalysisTypeSubject.next("none")
      }
    }

    handleGlobalClick(event: Event): void {
      // Check if the clicked element is inside the menubar or the button that opens the menubar
      const isMenubarElement = (event.target as HTMLElement).closest('.sources_menu') !== null;
      const isMenuBarButton = (event.target as HTMLElement).closest('.sources_menu_btn') !== null;

      // If the clicked element is outside the menubar, close the menu bar
      if (!isMenubarElement && !isMenuBarButton) {
        this.closeMenu();
      }
    }

    closeMenu() {
      this.isSummaryMenuOpen = false;
    }


    openFlag(flag: string) {
      // Close all flags
      this.isCharReplacement = false;
      this.isHiddenText = false;
      this.isImage = false;
      // Open the selected flag
      if (flag === 'charReplacement') {
        this.isCharReplacement = true;
        this.reportService.selectManipulationTypeSubject.next('charReplacement');
        if (this.isOnManipulation) {
          this.emitManipulations();
        }
      } else if (flag === 'hiddenText') {
        this.isHiddenText = true;
        this.reportService.selectManipulationTypeSubject.next('hiddenText');
        if (this.isOnManipulation) {
          this.emitManipulations();
        }
      } else if (flag === 'isImage') {
        this.isImage = true;
        this.reportService.selectManipulationTypeSubject.next('isImage');
        if (this.isOnManipulation) {
          this.emitManipulations();
        }
      }

      this.currentFlag = flag;
      if (this.isOnManipulation) {
        this.emitManipulations();
      }

    }

    setDefaultOpenFlag() {
      let type = '';
      this.reportService.selectManipulationTypeObject.subscribe((data: string) => {
        type = data;
      });

      if (type) {
        this.openFlag(type);
      } else {
        if (this.allManipulations?.replacedCharacters || this.allManipulations?.excludedReplacedCharacters) {
          this.openFlag('charReplacement')
        } else if (this.hasHiddenText()) {
          this.openFlag('hiddenText')
        } else if (this.hasImageManipulation() && this.totalManipulatedImages > 0) {
          this.openFlag('isImage')
        } else {
          this.openFlag('charReplacement');
        }
      }
    }

    hasCharReplacement(): boolean {
      return this.sentenceDetails?.some((sentence: any) => sentence.replacedCharacters && sentence.replacedCharacters.length > 0);
    }

    hasHiddenText(): boolean {
      return this.sentenceDetails?.some((sentence: any) =>
        sentence.characters && sentence.characters.some((char: any) => char.isWhite)
      );
    }

    hasImageManipulation(): boolean {
      return true ? this.pdfImagesSrc != undefined : false;
    }

    calculateTotalSentences() {
      this.totalCharReplacements = 0;
      this.sentenceDetails?.forEach((sentence: any) => {
        if (sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {
          this.totalCharReplacements += sentence.replacedCharacters.length;
        }
      });
    }

    calculateManipulations() {
      let manipulations: any = [];

      if (this.isCharReplacement) {
        this.includedCharactersSentences.forEach((sentence: any) => {
          const page = sentence.page;
          const existingPage = manipulations.find((item: any) => item.page === page);

          let charCount = sentence.replacedCharacters ? sentence.replacedCharacters.length : 0;

          if (charCount > 0) {
            if (existingPage) {
              existingPage.numberOfSentences += charCount;
            } else {
              manipulations.push({ page: page, numberOfSentences: charCount });
            }
          }
        });
      } else if (this.isHiddenText) {
        this.sentenceDetails?.forEach((sentence: any) => {
          const page = sentence.page;
          const existingPage = manipulations.find((item: any) => item.page === page);

          let whiteCharCount = sentence.characters ? sentence.characters.filter((char: any) => char.isWhite).length : 0;

          if (whiteCharCount > 0 && !sentence.whitecharactersExcluded) {
            if (existingPage) {
              existingPage.numberOfSentences += whiteCharCount;
            } else {
              manipulations.push({ page: page, numberOfSentences: whiteCharCount });
            }
          }
        });
      } else if (this.isImage) {
        this.redImages.forEach((image: any) => {
          const page = image.page + 1;
          const existingPage = manipulations.find((item: any) => item.page === page);

          if (existingPage) {
            existingPage.numberOfSentences += 1;
          } else {
            manipulations.push({ page: page, numberOfSentences: 1 });
          }
        });
      }
      manipulations = this.ensureAllPagesAreCorrect(this.totalPdfPages, manipulations);
      return manipulations;
    }

    ensureAllPagesAreCorrect(totalPages: number, manipulations: any) {
      const existingPages = manipulations.map((item: any) => item.page);

      for (let i = 1; i <= totalPages; i++) {
        if (!existingPages.includes(i)) {
          manipulations.push({ page: i, numberOfSentences: 0 });
        }
      }

      // Sort manipulations by page number
      manipulations.sort((a: any, b: any) => a.page - b.page);
      return manipulations;
    }

    emitManipulations() {
      const manipulations = this.calculateManipulations();
      this.manipulationsCalculated.emit(manipulations);
      this.cdr.detectChanges();
    }

    openCharReplacementModal(allManipulations: any, sentence: any) {
      this.spinnerService.show();
      this.reportService.selectAnalysisTypeSubject.next('manipulation')
      function getManipulations(sentence: any) {
        let temp_manipulations = [];

        // For char replacements
        if (sentence?.replacedCharacters && sentence?.replacedCharacters.length > 0) {
          let includedCharacters: { character: string; count: number }[] = [];
          let excludedCharacters: { character: string; count: number }[] = [];
          const allReplacedCharacters = new Map<string, number>();
          const allExcludedReplacedCharacters = new Map<string, number>();

          console.log('allManipulations here', allManipulations);

          // Populate the map with characters and their counts from allManipulations
          allManipulations.replacedCharacters.forEach(item => {
            allReplacedCharacters.set(item.character, item.count);
          });

          allManipulations.excludedReplacedCharacters.forEach(item => {
            allExcludedReplacedCharacters.set(item.character, item.count);
          });

          const processedChars = new Set<string>(); // Use a Set to track processed characters

          sentence.replacedCharacters.forEach(char => {
            if (!processedChars.has(char)) { // Check if character has already been processed
              if (allReplacedCharacters.has(char)) {
                includedCharacters.push({ character: char, count: allReplacedCharacters.get(char)! });
              } else {
                excludedCharacters.push({ character: char, count:  allExcludedReplacedCharacters.get(char)!});
              }
              processedChars.add(char); // Add character to processed set
            }
          });

          temp_manipulations.push({
            includedCharacters: includedCharacters,
            excludedReplacedCharacters: excludedCharacters
          });
        }
         else {
          temp_manipulations.push({
            includedCharacters: [],
            excludedReplacedCharacters: []
          });
        }

        // For hidden text (white characters)
        if (sentence?.characters && sentence?.characters.length > 0) {
        //   temp_manipulations.push({
        //     characters: sentence?.characters,
        //     isExcluded: sentence?.whitecharactersExcluded
        //   });
        } else {
          temp_manipulations.push({
            characters: [],
            isExcluded: false
          });
        }

        return temp_manipulations;
      }

      this.summaryDetails = {
        submissionId: this.submissionId,
        text: sentence?.text,
        sentence: sentence?.sourceSentence,
        source: sentence?.primarySource?.url,
        comment: sentence?.comment,
        id: sentence?.id,
        isOriginal: sentence?.sourceSentence || sentence?.primarySource ? true : false,
        isTranslated: sentence?.translatedText ? true : false,
        isManipulated: {
          isManipulated: allManipulations?.replacedCharacters && allManipulations?.replacedCharacters.length > 0 || sentence?.characters.length > 0,
          isCharReplacement: allManipulations?.replacedCharacters && allManipulations?.replacedCharacters.length > 0,
          isHiddenText: sentence?.characters?.length > 0
        },
        isExcluded: sentence?.isExcluded,
        isAi: sentence?.aiText?.aiText,
        similarity: sentence?.sourceSentencePercentage,
        page: sentence?.page,
        secondarySources: sentence?.secondarySources,
        manipulations: getManipulations(sentence),
        translated: sentence?.translatedText ? [
          {
            lang: sentence?.primaryTranslatedSource?.lang || 'en', // Change 'en' to the default language you want
            percentage: sentence?.primaryTranslatedSource?.percentage,
            sentence: sentence?.primaryTranslatedSource?.sourceSentence,
            source: sentence?.primaryTranslatedSource?.url,
            isExcluded: sentence?.primaryTranslatedSource?.isExcluded,
          }
        ] : []
      };

      this.reportService.sentenceInformation(this.summaryDetails, 'charReplacement');
      this.spinnerService.hide();
    }

    toggleOneReplacedChar(manipulation, type: number) {
      const characterToToggle = [
          {
            character: manipulation.character,
            count: manipulation.count
          }
      ]

      swal.fire({
        title: type == 1 ? 'Are you sure you want to exclude the selected character' : 'Are you sure you want to include the selected character',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          // this.spinnerService.show();
          this.reportService.toggleReplacedCharacters(this.submissionId, characterToToggle, type)
          .pipe()
          .subscribe((data: any) => {
            this.reportService.newS3JsonSubject.next(data.presignedUrlJson);
            // this.spinnerService.hide();
            this.toastr.success(type == 1 ? 'Character excluded successfully' : 'Character included successfully');
          })
        } else if (result.isDenied) {
          swal.fire(type == 1 ? "Character is not excluded!" : "Character is not included!", '', 'info');
        }
      });
    }

    toggleCharReplacements(manipulations: any, type: number) {
      console.log('manipulations', manipulations);

      this.summaryDetails = {
        submissionId: this.submissionId,
        isOriginal: false,
        isTranslated: false,
        isManipulated: {
          isManipulated: (manipulations.replacedCharacters || manipulations.excludedReplacedCharacters) && (manipulations.replacedCharacters.length > 0 || manipulations.excludedReplacedCharacters.length > 0),
          isCharReplacement: (manipulations.replacedCharacters || manipulations.excludedReplacedCharacters) && (manipulations.replacedCharacters.length > 0 || manipulations.excludedReplacedCharacters.length > 0),
          isHiddenText: false
        },
        isAi: false,
        manipulations: [
          {
            includedCharacters: manipulations.replacedCharacters,
            excludedReplacedCharacters: manipulations.excludedReplacedCharacters
          }
        ],
        translated: []
      };

      this.reportService.sentenceInformation(this.summaryDetails, 'charReplacement');

    }

    toggleWhiteCharSentence(manipulation: any) {
      swal.fire({
        title: 'Are you sure?',
        text: manipulation.whitecharactersExcluded ? 'You are about to include this sentence' : 'You are about to exclude this sentence',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.spinnerService.show();
          this.reportService.toggleWhiteCharacterSentences(this.submissionId, manipulation.id, !manipulation.whitecharactersExcluded)
          .pipe()
          .subscribe((res: any) => {
            if (res) {
              this.reportService.newS3JsonSubject.next(res.presignedUrlJson);
            }
            this.spinnerService.hide();
            this.toastr.success(manipulation.whitecharactersExcluded ? 'Sentence included successfully' : 'Sentence excluded successfully');
          });
        } else if (result.isDismissed) {
          swal.fire(manipulation.whitecharactersExcluded ? 'Sentence is not included' : 'Sentence is not excluded', '', 'info');
        }
      });
    }

    scrollPage(page: number) {
      const pdfViewerContainer = document.querySelector('.ng2-pdf-viewer-container');
      if (pdfViewerContainer) {
        const pageElement = pdfViewerContainer.querySelector(`[data-page-number="${page}"]`);
        console.log('pageElement', pageElement);
        if (pageElement) {
          pageElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          });
        }
      }
    }

    selectSentence(event: Event) {
      const target = event.target as HTMLElement;
      if (this.selectedElement) {
        this.renderer.removeClass(this.selectedElement, 'selected');
      }

      this.selectedElement = target?.closest('.content')! as HTMLElement;
      if (this.selectedElement) {
        this.renderer.addClass(this.selectedElement, 'selected');
      }
    }

    includeManipulatedImage(image: any) {
      swal.fire({
        title: 'Are you sure you want to include this image in the manipulations?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          // this.spinnerService.show();
          this.reportService
            .toggleManipulatedImages(this.submissionId, image.index, false)
            .pipe()
            .subscribe((data: any) => {
              this.reportComponent.getS3Json(data.presignedUrlJson);
              // this.spinnerService.hide();
              this.refreshPdf();
              this.toastr.success('Image included successfully');
            })
        } else if (result.isDenied) {
          swal.fire("Image is not included in manipulations!", '', 'info');
        }
      });
    }

    excludeManipulatedImage(image: any) {
      swal.fire({
        title: 'Are you sure you want to exclude this image in the manipulations?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          // this.spinnerService.show();
          this.reportService
            .toggleManipulatedImages(this.submissionId, image.index, true)
            .pipe()
            .subscribe((data: any) => {
              this.reportComponent.getS3Json(data.presignedUrlJson);
              this.refreshPdf();
              // this.spinnerService.hide();
              this.toastr.success('Image excluded successfully');
            })
        } else if (result.isDenied) {
          swal.fire("Image is not excluded in manipulations!", '', 'info');
        }
      });
    }

    refreshPdf() {
      // this.spinnerService.show();
      this.toggleIsOnManipulation();
      setTimeout(() => {
        this.toggleIsOnManipulation();
        this.openFlag('isImage');
      }, 300)

      setTimeout(() => {
        // this.spinnerService.hide();
      }, 1000);

    }

    showTooltipTemporarily(): void {
      this.tooltipVisible = true;
      setTimeout(() => {
        this.tooltipVisible = false;
      }, 4000);
    }

    toggleTopFlags() {
      this.showTopFlags = !this.showTopFlags;
    }

    calculateTopManipulations() {
      const topManipulations: any = {
        charReplacements: [],
        excludedCharReplacements: [],
        whiteCharacters: [],
        excludedWhiteCharacters: [],
        images: [],
        excludedImages: []
      };
      // Get top 5 character replacements
      if (this.allManipulations?.replacedCharacters) {
        const sortedCharReplacements = this.allManipulations.replacedCharacters
          .sort((a: any, b: any) => b.count - a.count)
          .slice(0, 5);
        topManipulations.charReplacements = sortedCharReplacements;
      }

      // Get top 5 excluded character replacements
      if (this.allManipulations?.excludedReplacedCharacters) {
        const sortedExcludedCharReplacements = this.allManipulations.excludedReplacedCharacters
          .sort((a: any, b: any) => b.count - a.count)
          .slice(0, 5);
        topManipulations.excludedCharReplacements = sortedExcludedCharReplacements;
      }


      // Get top 5 sentences with the highest number of white characters
      if (this.sentenceDetails) {
        const sortedWhiteCharacters = this.sentenceDetails
          .filter((sentence: any) => !sentence.whitecharactersExcluded && sentence.characters.length < 0)
          .sort((a: any, b: any) => {
            const aWhiteCount = a.characters ? a.characters.filter((char: any) => char.isWhite).length : 0;
            const bWhiteCount = b.characters ? b.characters.filter((char: any) => char.isWhite).length : 0;
            return bWhiteCount - aWhiteCount;
          })
          .slice(0, 5);
        topManipulations.whiteCharacters = sortedWhiteCharacters;
      }

      // Get top 5 sentences with the highest number of excluded white characters
      if (this.sentenceDetails) {
        const sortedExcludedWhiteCharacters = this.sentenceDetails
          .filter((sentence: any) => sentence.whitecharactersExcluded)
          .sort((a: any, b: any) => {
            const aWhiteCount = a.characters ? a.characters.filter((char: any) => char.isWhite).length : 0;
            const bWhiteCount = b.characters ? b.characters.filter((char: any) => char.isWhite).length : 0;
            return bWhiteCount - aWhiteCount;
          })
          .slice(0, 5);
        topManipulations.excludedWhiteCharacters = sortedExcludedWhiteCharacters;
      }

      // Get top 5 red images
      const topRedImages = this.redImages.slice(0, 5);
      topManipulations.images = topRedImages;

      // Get top 5 green images
      const topGreenImages = this.greenImages.slice(0, 5);
      topManipulations.excludedImages = topGreenImages;

      return topManipulations;
    }

    toggleSummaryMenu() {
      this.isSummaryMenuOpen = !this.isSummaryMenuOpen;
    }

    filterManipulations(param: number) {
      this.selectedFilterText = param === 0 ? 'All' : param === 1 ? 'Character Replacements ' : param === 2 ? 'Hidden Text' : param === 3 ? 'Image in place of text' : param === 4 ? 'Excluded "Characters"' : param === 5 ? 'Excluded "Hidden Text"' : param === 6 ? 'Green Flags of "Image in place of text"' : 'Red Flags of "Image in place of text"'
      this.reportService.filterManipulationsSubject.next(param)
      let filteredManipulations: any = {};

      switch (param) {
        case 0:
          filteredManipulations = this.calculateTopManipulations();
          break;
        case 1:
          filteredManipulations.charReplacements = this.allManipulations.replacedCharacters;
          break;
        case 2:
          filteredManipulations.whiteCharacters = this.sentenceDetails.filter((sentence: any) =>
            sentence.characters && sentence.characters.some((char: any) => char.isWhite && !sentence.whitecharactersExcluded));
          break;
        case 3:
          filteredManipulations.images = this.redImages;
          break;
        case 4:
          filteredManipulations.charReplacements = this.allManipulations.excludedReplacedCharacters;
          break;
        case 5:
          filteredManipulations.whiteCharacters = this.sentenceDetails.filter((sentence: any) =>
            sentence.characters && sentence.characters.some((char: any) => char.isWhite && sentence.whitecharactersExcluded));
          break;
        case 6:
          filteredManipulations.images = this.greenImages;
          break;
        case 7:
          filteredManipulations.images = this.redImages;
          break;
      }
      this.topManipulations = filteredManipulations;


      this.cdr.detectChanges();
    }

    isCharReplacementExcluded(char: any) {
      if (this.allManipulations?.excludedReplacedCharacters) {
        return this.allManipulations?.excludedReplacedCharacters.some((excludedChar: any) => excludedChar?.character === char?.character);
      }
    }

    ngOnDestroy(): void {
      document.removeEventListener('click', this.handleGlobalClick);
    }

  }
