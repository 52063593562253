import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
   selector: 'app-subject-id',
   templateUrl: './subject-id.component.html',
   styleUrls: ['./subject-id.component.scss'],
})
export class SubjectIdComponent implements OnInit {
   constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

   ngOnInit(): void {}
}
