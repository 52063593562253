import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NavbarIndividualAdministratorComponent } from '../sharedModule/navbar-individual-administrator/navbar-individual-administrator.component';
import { IndividualAdministratorDashboardComponent } from './individual-administrator-dashboard/individual-administrator-dashboard.component';
import { StudentsListComponent } from './students-list/students-list.component';
import { InviteStudentComponent } from './modals/invite-student/invite-student.component';
import { EditUserComponent } from './modals/edit-user/edit-user.component';
import { IndividualAdministratorStatisticsComponent } from './individual-administrator-statistics/individual-administrator-statistics.component';
import { StudentsListEffects } from './state/effect/individualAdministrator.effects';
import { IndividualAdministratorReducer } from './state/reducer/individualAdministrator.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DraftMessageRequestComponent } from './modals/draft-message-request/draft-message-request.component';

const individualAdministratorRoutes: Routes = [
   {
      path: '',
      component: NavbarIndividualAdministratorComponent,
      data: { roles: ['6'] },
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
         {
            path: '',
            loadChildren: () =>
               import('../sharedModule/shared.module').then(
                  (m) => m.SharedModule
               ),
         },
         {
            path: 'dashboard',
            component: IndividualAdministratorDashboardComponent,
            data: { roles: ['6'] },
         },
         {
            path: 'students-list',
            component: StudentsListComponent,
            data: { roles: ['6'] },
         },
         {
            path: 'statistics',
            component: IndividualAdministratorStatisticsComponent,
            data: { roles: ['6'] },
         },
      ],
   },
];

@NgModule({
   declarations: [
      IndividualAdministratorDashboardComponent,
      StudentsListComponent,
      InviteStudentComponent,
      EditUserComponent,
      IndividualAdministratorStatisticsComponent,
      DraftMessageRequestComponent,
   ],
   imports: [
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      NgxPaginationModule,
      NgApexchartsModule,
      RouterModule.forChild(individualAdministratorRoutes),
      CommonModule,
      EffectsModule.forFeature([StudentsListEffects]),
      StoreModule.forFeature(
         'IndividualAdministrator',
         IndividualAdministratorReducer
      ),
   ],
   exports: [TranslateModule],
})
export class IndividualAdministratorComponentsModule {}
