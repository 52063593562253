<div class="costumeOffer">
   <h4>{{ 'app.get_quote' | translate }}</h4>
   <form [formGroup]="createOffer">
      <div class="inputContainer">
         <div>
            <h5>{{ 'app.offer.offer_name' | translate }}:</h5>
            <p>
               Give this estimation a name so you can find it easier.
            </p>
         </div>
         <input class="textInput" formControlName="title" type="text" />
      </div>
      <div class="inputContainer">
         <div>
            <h5>{{ 'general.license_duration' | translate }}</h5>
            <p></p>
         </div>
         <div class="validity">
            <input
               min="0"
               oninput="this.value = Math.abs(this.value)"
               class="numberInputs"
               formControlName="commitment"
               type="number"
            />
            <span>{{ 'app.years' | translate }}</span>
         </div>
      </div>
      <div class="inputContainer">
         <div>
            <h5>{{ 'general.students' | translate }}</h5>
            <p>
               Let us know what is the estimated number of students
               you want to provide access to Crossplag.
               Our pricing is based on number of students. For more
               pricing information please check our <a href="https://crossplag.com/pricing/">{{ 'app.offer.pricing_page' | translate }}.</a>
            </p>
         </div>
         <input
            min="0"
            oninput="this.value = Math.abs(this.value)"
            class="numberInputs"
            formControlName="studentAccounts"
            type="number"
         />
      </div>

      <div class="inputContainer">
         <div>
            <h5>{{ 'general.educators' | translate }}</h5>
            <p>
               Let us know what is the estimated number of
               educators you want to provide access to Crossplag.
            </p>
         </div>
         <input
            min="0"
            oninput="this.value = Math.abs(this.value)"
            class="numberInputs"
            formControlName="professorAccounts"
            type="number"
         />
      </div>
      <!-- <div class="inputContainer">
         <div>
            <h5>{{ 'app.users.teaching_assistants_accounts' | translate }}</h5>
            <p></p>
         </div>
         <input
            min="0"
            oninput="this.value = Math.abs(this.value)"
            class="numberInputs"
            formControlName="teachingAssistantAccounts"
            type="number"
         />
      </div> -->

      <!-- <div class="inputContainer">
         <div>
            <h5>{{ 'general.academic_integrity_officer_accounts' | translate }}</h5>
            <p></p>
         </div>
         <input
            min="0"
            oninput="this.value = Math.abs(this.value)"
            class="numberInputs"
            formControlName="academicIntegrityOfficerAccounts"
            type="number"
         />
      </div> -->
      <!-- <div
         class="inputContainer"
         [ngClass]="{ removeBorder: this.form.crossLingual.value }"
      >
         <div>
            <h5>{{ 'general.crosslingual' | translate }}</h5>
            <p>
               You can enable crosslingual as your features and add thesis
               number, educators upload and assignments.
            </p>
         </div>
         <label class="switch">
            <input
               name="crossLingual"
               id="crossLingual"
               formControlName="crossLingual"
               type="checkbox"
            />

            <span class="slider round"></span>
         </label>
      </div>

      <div class="crosslingualOption" *ngIf="this.form.crossLingual.value">
         <div>
            <input
               (click)="this.selectModule('professorUploads')"
               name="professorUploads"
               id="professorUploads"
               type="checkbox"
               [checked]="this.modulesSelected.professorUploads"
            />
            <label for="professorUploads"></label>
            <h5>{{ 'app.upload.educator_uploads' | translate }}</h5>
         </div>

         <div>
            <input
               (click)="this.selectModule('assignments')"
               name="assignments"
               id="assignments"
               type="checkbox"
               [checked]="this.modulesSelected.assignments"
            />
            <label for="assignments"></label>
            <h5>{{ 'app.offer.assignments_module' | translate }}</h5>
         </div>

         <div>
            <input
               (click)="this.selectModule('thesis')"
               name="thesisModule"
               id="thesisModule"
               type="checkbox"
               [checked]="this.modulesSelected.thesis"
            />
            <label for="thesisModule"></label>
            <h5>{{ 'app.offer.thesis_module' | translate }}</h5>
         </div>
         <ng-container *ngIf="this.showAlert">
            <span class="invalid"> Please choose one of options above. </span>
         </ng-container>
      </div> -->

      <!-- <div class="inputContainer direction-column">
         <div>
            <h5>{{ 'general.thesis_estimations' | translate }}</h5>
            <p>
               You added thesis to your crosslingual features, please add
               maximum number of Bachelor, Master and PHD Thesis that you want
               to assign to user.
            </p>
         </div>
         <div>
            <div>
               <label for="">{{ 'general.bachelor' | translate }}</label>
               <input formControlName="bachelor" type="number" />
            </div>
            <div>
               <label for="">{{ 'general.master' | translate }}</label>
               <input formControlName="master" type="number" />
            </div>
            <div>
               <label for="">{{ 'general.phd' | translate }}</label>
               <input formControlName="phd" type="number" />
            </div>
         </div>
      </div> -->

      <!-- <div class="inputContainer direction-column">
         <div>
            <h5>{{ 'general.document_uploads' | translate }}</h5>
            <p>
               You added document uploads to your crosslingual features, which
               means that all your users ({{ 'general.educators' | translate }} and Students) can upload
               their documents. You need to choose whether you want to set a
               total limit.
            </p>
         </div>
         <div>
            <div>-->
               <!-- <div>
                  <input id="documentUploads" type="checkbox" />
                  <label for="documentUploads"></label>
               </div> -->
               <!-- <label for="">{{ 'app.offer.total_limit' | translate }}</label>
               <input formControlName="professorUploads" type="number" />
            </div>
         </div>
      </div>  -->

      <!-- <div class="inputContainer">
         <div>
            <h5>{{ 'general.subscriptions' | translate }}</h5>
            <p></p>
         </div>
         <input
            name="subscriptions"
            id="subscriptions"
            formControlName="subscriptions"
            type="checkbox"
         />
         <label for="subscriptions"></label>
      </div> -->
      <div class="inputContainer">
         <div>
            <h5>{{ 'general.payment_preference' | translate }}</h5>
            <p>
               You can choose how you want to pay using Crossplag,
               be that monthly, annually, or pre-pay the contract in advance.
               Based on your payment preference a discount may be applied.
            </p>
         </div>

         <div class="selectContainer">
            <div (click)="openSelectOptions('billing plans')">
               Choose you billing plan<i
                  [ngClass]="{ rotate: displayBillingPlans }"
                  class="fas fa-chevron-left"
               ></i>
            </div>
            <div [ngClass]="{ displayOptions: displayBillingPlans }">
               <div>
                  <input
                     formControlName="billingPlan"
                     type="radio"
                     id="monthly"
                     name="billingPlan"
                     value="Monthly"
                  />
                  <label for="monthly">{{ 'general.monthly' | translate }}</label>
               </div>
               <div>
                  <input
                     formControlName="billingPlan"
                     type="radio"
                     id="annual"
                     name="billingPlan"
                     value="Annually"
                  />
                  <label for="annual">{{ 'general.annual' | translate }} <span>5% - Discount</span></label>
               </div>
               <div>
                  <input
                     formControlName="billingPlan"
                     type="radio"
                     id="full"
                     name="billingPlan"
                     value="OnePayment"
                  />
                  <label for="full">{{ 'help_center.contact_us.full' | translate }} <span>10% - Discount</span></label>
               </div>
            </div>
         </div>
      </div>
      <!-- <div class="inputContainer">
         <div>
            <h5>{{ 'add_credits.support' | translate }}</h5>
            <p>
               Find the right level of support to meet the needs of your
               institutions.
            </p>
         </div>
         <div class="selectContainer">
            <div (click)="openSelectOptions('support')">
               Choose you {{ 'add_credits.support' | translate }}
               <i
                  [ngClass]="{ rotate: displaySupportOptions }"
                  class="fas fa-chevron-left"
               ></i>
            </div>
            <div [ngClass]="{ displayOptions: displaySupportOptions }">
               <div>
                  <input
                     formControlName="support"
                     type="radio"
                     id="basic"
                     name="support"
                     value="Basic"
                  />
                  <label for="basic">{{ 'general.basic' | translate }}</label>
               </div>
               <div>
                  <input
                     formControlName="support"
                     type="radio"
                     id="standard"
                     name="support"
                     value="Standard"
                  />
                  <label for="standard"
                     >{{ 'general.standard' | translate }} <span>5% - Discount</span></label
                  >
               </div>
               <div>
                  <input
                     formControlName="support"
                     type="radio"
                     id="premium"
                     name="support"
                     value="Premium"
                  />
                  <label for="premium"
                     >{{ 'general.premium' | translate }} <span>10% - Discount</span></label
                  >
               </div>
            </div>
         </div>
      </div> -->
      <div class="emailConsent">
         <input
         formControlName="sentEmail"
         name="emails"
         id="emails"
         type="checkbox"
      />
      <label for="emails"></label>
         Yes, I would like Crossplag team to contact me for this offer.
      </div>
      <div class="center">
         <button
            *ngIf="data"
            [disabled]="createOffer.invalid"
            [ngClass]="{
               disable: createOffer.invalid
            }"
            class="submit"
            type="submit"
            (click)="edit()"
         >
            {{ 'general.edit' | translate }}
         </button>
         <button
            *ngIf="!data"
            [disabled]="createOffer.invalid"
            [ngClass]="{
               disable: createOffer.invalid
            }"
            class="submit"
            type="submit"
            (click)="submit()"
         >
            {{ 'app.offer.create_your_plan' | translate }}
         </button>
      </div>
   </form>
</div>
