import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

/**
 * Service used only for super-admin entity.
 */
@Injectable({
   providedIn: 'root',
})
export class SuperAdminService {
   constructor(private http: HttpClient) {}

   /**
    * Method used to create institution
    * @param name
    * @param description
    * @param students
    * @param ltiSecret
    * @param academicIntegrityOfficers
    * @param professors
    * @param teachingAssistants
    * @param bachelor
    * @param master
    * @param phd
    * @param administrators
    * @returns
    */
   createInstitution(
      settings?: any,
      name?: string,
      description?: string,
      ltiSecret?: string,
      update: boolean = false
   ) {
      const payload: any = {}

      if (update) {
        // Update mode, send only settings
        payload.settings = settings;
        payload.update = true;
      } else {
          // Create mode, send all data
          payload.name = name;
          payload.description = description;
          payload.ltiSecret = ltiSecret;
      }
      return this.http
         .post<any>(`${environment.apiUrl}/institution`, payload)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getInstitutions() {
      return this.http.get<any>(`${environment.apiUrl}/institutions`).pipe(
         map((data) => {
            return data;
         })
      );
   }

   createInstitutionAdministrator(firstName, lastName, email, institutionId) {
      return this.http
         .post<any>(`${environment.apiUrl}/register/administrator`, {
            firstName,
            lastName,
            email,
            institutionId,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   activateFreeOffer(institutionId,email) {
      return this.http
         .post<any>(`${environment.apiUrl}/activateFreeOffer`, {
            institutionId,
            email
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
}
