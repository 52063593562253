import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges } from "@angular/core";
import { ReportService } from "../../../services/report.service";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { DocumentData } from "../../../services/document-data.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-ai-bar',
  templateUrl: './ai-bar.component.html',
  styleUrls: ['./ai-bar.component.scss'],
  providers: [MatProgressBarModule]
})
export class AiBar implements OnChanges {
  title = 'AI Bar'
  // isOnAi = false;
  @Input() isOnAi: any;
  @Input() aiAnalysis: any;
  @Output() isOnAiChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  totalSentences!: number;
  aiSentences!: number;
  humanSentences!: number;
  @Input() totalAIStenteces!: [];

  ai_percentage: any = {
    high: this.translate.instant('report.this_text_has_a_high_probability_of_being_ai_generated'),
    medium: this.translate.instant('report.this_text_has_a_moderate_probability_of_being_ai_generated'),
    low: this.translate.instant('report.this_text_has_a_low_probability_of_being_ai_generated')
  }

  constructor(private reportService: ReportService, private documentDataService: DocumentData, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.countSentences();
  }


  toggleIsOnAi():void {
    this.isOnAi = !this.isOnAi;
    this.isOnAiChange.emit(this.isOnAi);
    this.documentDataService.setCurrentPage(1)
    if (this.isOnAi) {
      this.reportService.selectAnalysisTypeSubject.next('ai');
      this.countSentences();
    } else {
      this.reportService.selectAnalysisTypeSubject.next('none');
    }
  }

  countSentences() {
    this.totalSentences = 0;
    this.aiSentences = 0;
    this.humanSentences = 0;

    this.totalAIStenteces.forEach((sentence: any) => {
      this.totalSentences++;
      if (sentence.aiText) {
        this.aiSentences++;
      } else {
        this.humanSentences++;
      }
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes["isOnAi"]) {
      this.countSentences();
    }
  }
}
