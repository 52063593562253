import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from "src/app/super-admin/super-admin.service";
import { MatDialog } from '@angular/material/dialog';
import { ViewLogs } from "src/app/sharedModule/view_logs/view_logs.component";
import { ReportService } from "src/app/sharedModule/report-v2/services/report.service";
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/sharedModule/report-v2/services/language.service";

interface RequestFilters {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-access-content-admin',
    templateUrl: './access-content.component.html',
    styleUrls: ['./access-content.component.scss']
})



export class AccessContentAdmin {
  request_filters: RequestFilters[] = [
    {value: 'show_all', viewValue: this.translate.instant('new_entries.show_all')},
    {value: 'your_requests', viewValue: this.translate.instant('new_entries.your_institution_requests')},
    {value: 'other_requests', viewValue: this.translate.instant('new_entries.other_institution_requests')},
  ];
  selectedRequestFilter = this.request_filters[0].value;
  user: any;

  constructor(private dialog: MatDialog,private reportService: ReportService,private store: Store, private translate: TranslateService, private languageService: LanguageService) {

  }

  requests;

  ngOnInit(): void {

    const storedLanguage = localStorage.getItem('websiteLanguage');
    const language = storedLanguage ? storedLanguage : 'en';

    this.translate.use(language);
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.translate.use(language);
    });

    this.translate.onLangChange.subscribe((event) => {
      this.request_filters = [
        {value: 'show_all', viewValue: this.translate.instant('new_entries.show_all')},
        {value: 'your_requests', viewValue: this.translate.instant('new_entries.your_institution_requests')},
        {value: 'other_requests', viewValue: this.translate.instant('new_entries.other_institution_requests')},
      ];

    });

    this.reportService
    .getContentRequest()
    .pipe()
    .subscribe(
      (data) => {
       this.requests = data;
      },
      (error) => {
      }
    );

    this.store.select(getCurrentUser).subscribe((data) => {
      if (data !== null) {
         this.user = data;
      }
   });

  }

  ngDoCheck() {
    if (localStorage.getItem('websiteLanguage') !== this.translate.currentLang) {
      this.translate.use(localStorage.getItem('websiteLanguage'));
    }
  }

  openViewLogs(request,expandable) {

    this.dialog.open(ViewLogs, {
      data: {
        requestType: this.selectedRequestFilter,
        expandable: expandable,
        request
      },
      height: '80%',
      width: '50%'
    })
  }

  changeAccess(requestId,access){


    this.reportService
    .changeContentRequestAccess(requestId,!access)
    .pipe()
    .subscribe(
      (data) => {
        this.requests.forEach(element => {
          if(element.id == requestId ) {
            element.status = !access
          }
        });
      },
      (error) => {
      }
    );
  }

}
