<div>
    <header>
        <section class="header-section">
            <section class="header-label" tabindex="0" aria-label="Cohort Analysis">
                <img src="../../../../../../assets/report_v2/cohort.svg" alt="Cohort analysis logo/image">
                <section>
                    <h3>{{ 'new_entries.cohort_analysis' | translate }}</h3>
                    <span tabindex="0" aria-label="Average metrics for similarity, translation, manipulations, AI, and word count">{{ 'new_entries.cohort_analysis_metrics' | translate }}</span>
                </section>
            </section>
            <button (click)="toggleIsOnAnalysis()" tabindex="0">
                <img src="../../../../../../assets/report_v2/arrow-right.svg"
                    alt="Arrow right. Click to go to the Summary.">
            </button>
        </section>
    </header>
    <div class="chart-container">
        <div class="chart-wrapper">
            <apx-chart #chart [series]="chartOptions?.series" [chart]="chartOptions?.chart" [xaxis]="chartOptions?.xaxis"
                [stroke]="chartOptions?.stroke" [fill]="chartOptions?.fill" [markers]="chartOptions?.markers"
                [tooltip]="chartOptions?.tooltip" [colors]="chartOptions?.colors" [legend]="chartOptions?.legend"
                [grid]="chartOptions?.grid"></apx-chart>
        </div>
    </div>

    <div class="table-container">
        <table class="analysis-table">
            <thead>
                <tr>
                    <th tabindex="0">{{ 'new_entries.analysis_metrics' | translate }}</th>
                    <th tabindex="0">{{ 'new_entries.avg_cohort_insights' | translate  }}</th>
                    <th tabindex="0">{{ 'new_entries.submissions_insights' | translate  }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td tabindex="0">{{ 'report.similarity' | translate }}</td>
                    <td tabindex="0" class="bold">{{ normalizedCohortValues[0] | number:'1.2-2' }}%</td>
                    <td tabindex="0">{{ normalizedSubmissionValues[0] | number:'1.2-2' }}%</td>
                </tr>
                <tr>
                    <td tabindex="0">{{ 'new_entries.translation' | translate }}</td>
                    <td tabindex="0" class="bold">{{ normalizedCohortValues[1] | number:'1.2-2' }}%</td>
                    <td tabindex="0">{{ normalizedSubmissionValues[1] | number:'1.2-2' }}%</td>
                </tr>
                <tr>
                    <td tabindex="0">{{ 'general.ai_authorship' | translate }}</td>
                    <td tabindex="0" class="bold">{{ normalizedCohortValues[2] | number:'1.2-2' }}%</td>
                    <td tabindex="0">{{ normalizedSubmissionValues[2] | number:'1.2-2' }}%</td>
                </tr>
                <tr>
                    <td tabindex="0">{{ 'new_entries.manipulations' | translate }}</td>
                    <td tabindex="0" class="bold">{{ this.cohortValues[3] }}</td>
                    <td tabindex="0">{{ this.submissionValues[3] }}</td>
                </tr>
                <tr>
                    <td tabindex="0">{{ 'new_entries.word_count' | translate }}</td>
                    <td tabindex="0" class="bold">{{ this.cohortValues[4] }}</td>
                    <td tabindex="0">{{ this.submissionValues[4] }}</td>
                </tr>
            </tbody>
        </table>
        <app-overview-disclaimer tabindex="0" [title]="'general.overview' | translate" [disclaimerText]="'new_entries.cohort_analysis_description' | translate"
            [disclaimerLink]="'https://support.inspera.com/hc/en-us/articles/20899067788701-Cohort-Analysis'" [linkText]="'new_entries.learn_more' | translate"></app-overview-disclaimer>
    </div>

</div>

