import { excludeSentence } from './../sharedModule/state/actions/report.actions';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

/**
 * Service is used for submission entity.
 */
@Injectable({
   providedIn: 'root',
})
export class SubmissionsService {
   /**
    * Variable used to store user.
    */
   user;
   /**
    * Variable used to subscribe to user in the store state
    */
   userObservable;

   /**
    * Submissions Service Constructor
    * @param http
    * @param authService
    */
   constructor(private http: HttpClient) {}

   /**
    * Method used to call api to get user submissions details
    */
   getUserSubmissionsDetails() {
      return this.http
         .get<any>(`${environment.apiUrl}/users/submissions-details`, {})
         .pipe();
   }

   /**
    * Method used to get user submissions
    * @param page
    * @param title
    * @param filter
    * @param reSubmissions
    */
   getUserSubmissions(
      page: number,
      title?: string,
      filter?: number,
      limit?: number,
      reSubmissions?: number
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/users/submissions/?page=${page}` +
               (title ? `&search=${title}` : '') +
               (filter ? `&filter=${filter}` : '') +
               (limit ? `&limit=${limit}` : '') +
               (reSubmissions ? `&reSubmissions=${reSubmissions}` : '')
         )
         .pipe();
   }

   /**
    * Method used to get details of current submission in the report component.
    * @param currentSubmissionId
    */
   getUserCurrentSubmissionDetails(currentSubmissionId: string) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/submission/${currentSubmissionId}/users`
         )
         .pipe();
   }
   /**
    * Method used to get data(number of words & number of documents) for Document and Nr. Words graph at individual statistics
    * @param year
    */
   getSubmissionGraph(year: number) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/users/submissions-graph/?year=${year}`
         )
         .pipe();
   }

   /**
    * Method used to get data(number of words & number of documents) for Document and Nr. Words graph at individual statistics
    * @param year
    */
   getSubmissionGraphIndividual(year: number) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/users/individual/submissions-graph/?year=${year}`
         )
         .pipe();
   }

   /**
    * Method used to get submission data.
    * @param submissionId
    */
   getSubmissionPlagData(submissionId: string): any {
      return this.http
         .get<any>(
            `${environment.apiUrl}/submission/${submissionId}/report/users`
         )
         .pipe();
   }
   /**
    * Method used to  exclude sentence from plagiarism calculation
    * @param submissionId
    * @param sentenceId
    * @param originalLanguage
    * @param comment
    */
   excludeSentence(
      submissionId: string,
      sentenceId: string,
      originalLanguage: boolean,
      comment?: string
   ) {
      return this.http
         .request(
            'delete',
            `${environment.apiUrl}/submission/${submissionId}/sentences/${sentenceId}`,
            { body: { originalLanguage, comment } }
         )
         .pipe();
   }

   updateComment(comment, submissionId, sentenceId, originalLanguage: boolean) {
      return this.http
         .put<any>(
            `${environment.apiUrl}/submission/${submissionId}/sentences/${sentenceId}/updateComment`,
            { originalLanguage, comment }
         )
         .pipe();
   }
   /**
    * Method used to include sentence in plagiarism calculation
    * @param submissionId
    * @param sentenceId
    * @param originalLanguage
    */
   includeSentence(
      submissionId: string,
      sentenceId: number,
      originalLanguage: boolean
   ) {
      return this.http
         .request(
            'put',
            `${environment.apiUrl}/submission/${submissionId}/sentences/${sentenceId}`,
            { body: { originalLanguage } }
         )
         .pipe();
   }

   excludeSource(
      sourceId: string,
      submissionId: string,
      originalLanguage: boolean,
      exclude: boolean,
      excludeUrlId: string,
      excludeUrlPercentage: string
   ) {
      return this.http
         .request(
            'delete',
            `${environment.apiUrl}/submission/${submissionId}/excludeSource/${sourceId}`,
            {
               body: {
                  originalLanguage,
                  exclude,
                  excludeUrlId,
                  excludeUrlPercentage: excludeUrlPercentage,
               },
            }
         )
         .pipe();
   }

   includeSource(
      sourceId: string,
      submissionId: string,
      originalLanguage: boolean,
      includeUrlPercentage: string
   ) {
      return this.http
         .request(
            'put',
            `${environment.apiUrl}/submission/${submissionId}/includeSource/${sourceId}`,
            {
               body: {
                  originalLanguage,
                  includeUrlPercentage,
               },
            }
         )
         .pipe();
   }

   toggleReplacedCharacters(submissionId: string, charsToToggle: string, type: number) {
      return this.http
         .request(
            'put',
            `${environment.apiUrl}/submission/${submissionId}/toggle/1`,
            { body: { charsToToggle, exclude: type == 1 ? true : false } }
         )
         .pipe();
   }

   toggleWhiteCharacterSentences(submissionId: string, sentenceId: number, excludeSentence: boolean) {
    return this.http
       .request(
          'put',
          `${environment.apiUrl}/submission/${submissionId}/toggle/2`,
          { body: { sentenceId, excludeSentence } }
       )
       .pipe();
 }

   toggleManipulatedImages(submissionId: string, imageId: number, exclude: boolean) {
    return this.http
    .request(
       'put',
       `${environment.apiUrl}/submission/${submissionId}/toggle/3`,
       { body: { imageId, exclude } }
    )
    .pipe();
   }

   /**
    * Method used to download offline pdf report.
    * @param submissionId
    * @param reportType
    */
   downloadReport(submissionId: string, reportType: string) {
      return this.http
         .get(
            `${environment.apiUrl}/submission/${submissionId}/offlineReport?reportType=${reportType}`,
            {}
         )
         .pipe();
   }
      /**
    * Method used to download ai offline pdf report.
    * @param submissionId
    */
    downloadAiReport(submissionId: string) {
      return this.http
          .get(
            `${environment.apiUrl}/submission/${submissionId}/aiOfflineReport?reportType='test'`,
            {}
          )
          .pipe();
    }
   downloadInvoice(invoiceId) {
      return this.http.get(`${environment.apiUrl}/invoice/${invoiceId}`).pipe();
   }

   downloadCertificate(submissionId) {
      return this.http
         .get(`${environment.apiUrl}/certificate/${submissionId}`)
         .pipe();
   }

   downloadOriginalDocument(submissionId) {
      return this.http
         .get(`${environment.apiUrl}/document/${submissionId}`)
         .pipe();
   }

   createSubmission(
      documentTitle: string,
      fileName: string,
      author: string,
      dateOfUpload: string,
      documentSize: string,
      language: string,
      translatedLang: string,
      archive: boolean,
      citationStyle: string,
      thirdPartyLibraries: any,
      converted: boolean,
      withCredits: boolean,
      assignmentId: string,
      thesisId: string,
      aiDetection,
      sources?: [{}],
      contextualSimilarity?: boolean,   
      enableThresholds?: boolean,      
      exactMatchThreshold?: number,       
      alteredTextThreshold?: number,      
      contextualSimilaritiesThreshold?:number
   ) {

      let sentence_thresholds = {
         exactMatchThreshold: exactMatchThreshold,
         alteredTextThreshold: alteredTextThreshold,
         contextualSimilaritiesThreshold: contextualSimilaritiesThreshold
      };

      return this.http
         .post(`${environment.apiUrl}/submissions`, {
            documentTitle,
            fileName,
            author,
            dateOfUpload,
            documentSize,
            language,
            translatedLang,
            archive,
            citationStyle,
            thirdPartyLibraries,
            converted,
            withCredits,
            assignmentId,
            thesisId,
            aiDetection,
            sources,
            contextualSimilarity,
            enableThresholds,
            sentence_thresholds
         })
         .pipe();
   }

   createDraftSubmission(
      documentTitle: string,
      fileName: string,
      author: string,
      dateOfUpload: string,
      documentSize: string,
      language: string,
      translatedLang: string,
      archive: boolean,
      citationStyle: string,
      thirdPartyLibraries: any,
      converted: boolean,
      draftId: string
   ) {
      return this.http
         .post(`${environment.apiUrl}/drafts`, {
            documentTitle,
            fileName,
            author,
            dateOfUpload,
            documentSize,
            language,
            translatedLang,
            archive,
            citationStyle,
            thirdPartyLibraries,
            converted,
            draftId,
         })
         .pipe();
   }

   getS3Json(url: string) {
      return this.http.get(url).pipe();
   }
   /**
    * Method used to delete a submission.
    * @param id Submission Id
    */
   deleteSubmission(id: string, showSubmission: boolean) {
      return this.http.put<any>(`${environment.apiUrl}/submissions`, {
         id,
         showSubmission,
      });
   }

   changeSentenceSource(
      sourceId: string,
      sentenceId: number,
      submissionId: string,
      originalLanguage: boolean
   ) {
      return this.http.post<any>(
         `${environment.apiUrl}/submission/${submissionId}/source/${sourceId}`,
         { originalLanguage, sentenceId }
      );
   }

   toggleQuotes(submissionId: string) {
      return this.http.post<any>(
         `${environment.apiUrl}/submission/${submissionId}/toggleQuotes`,
         {}
      );
   }

   getProfessorSubmissions(userId, page, title,percentageFilter) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/submissions/?page=${page}` +
               (title ? `&title=${title}` : '') +
               (percentageFilter ? `&percentageFilter=${percentageFilter}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   individualAdministratorSubmissions(
      page: number,
      filter?: number,
      title?: string
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/individual/administrator/submissions/?page=${page}` +
               (title ? `&search=${title}` : '') +
               (filter ? `&filter=${filter}` : '')
         )
         .pipe();
   }

   toggleAccess(id: string) {
      return this.http
         .post<any>(
            `${environment.apiUrl}/individual/administrator/submission/toggle/access/?submissionId=${id}`,
            {}
         )
         .pipe();
   }

   toggleDraft(id: string, message: string) {
      return this.http
         .post<any>(
            `${environment.apiUrl}/individual/administrator/submission/toggle/draft/?submissionId=${id}`,
            { message }
         )
         .pipe();
   }

   getAllDocumentSubmissions(id, page) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/submission/${id}/getDocumentSubmissions/?page=${page}`,
            {}
         )
         .pipe();
   }

   submissionPreview(sourceId: string, submissionId: string, index) {
      return this.http.post<any>(
         `${environment.apiUrl}/submission/${submissionId}/preview/${sourceId}`,
         { index }
      );
   }

   getAssignmentStudentSubmissions(page: number, assignment: string, pre_check?: boolean) {
      let params = new HttpParams().set('page', page.toString());

      if (assignment) {
         params = params.set('assignment', assignment);
      }

      if (pre_check !== undefined) {
         params = params.set('pre_check', pre_check.toString());
      }

      return this.http.get<any>(
         `${environment.apiUrl}/student/submissions/`,
         { params }
      );
   }

   /**
    * Method used to sent student request for resubmission.
    * @param submissionId
    * @param reportType
    */

   studentRequest(assignmentId, type, message, requestFor) {
      return this.http.post<any>(
         `${environment.apiUrl}/assignment/${assignmentId}/student/requests/${requestFor}`,
         {
            type,
            message,
         }
      );
   }

   /**
    * Method used to get students requests for resubmission.
    */

   getStudentsRequests(page, type, checked, startDate, endDate) {
      return this.http.get<any>(
         `${environment.apiUrl}/professor/requests/?page=${page}` +
            (startDate ? `&startDate=${startDate}` : '') +
            (endDate ? `&endDate=${endDate}` : '') +
            (type ? `&type=${type}` : '') +
            (checked ? `&checked=${checked}` : '')
      );
   }

   /**
    * Method used to get student requests.
    */

   getStudentRequests(assignmentId, checked, page) {
      return this.http.get<any>(
         `${environment.apiUrl}/student/requests/?page=${page}` +
            (assignmentId ? `&assignmentId=${assignmentId}` : '') +
            (checked ? `&checked=${checked}` : '')
      );
   }

   /**
    * Method used to approve or disapprove student request.
    * @param requestId
    * @param isApproved
    */

   approveStudentRequest(requestId, isApproved) {
      return this.http
         .put<any>(
            `${environment.apiUrl}/professor/assignments/requests/${requestId}`,
            { isApproved }
         )
         .pipe();
   }

   /**
    * Method used to get types of requests that are allowed for that assignment.
    * @param requestId
    * @param isApproved
    */

   assignmentRequest(assignmentId, requestFor) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/assignment/${assignmentId}/student/requests/${requestFor}/getAllowedRequests`
         )
         .pipe();
   }

   deleteSubmissionInElastic(submissionId,type) {
      return this.http.delete<any>(
         `${environment.apiUrl}/submission/${submissionId}/delete/${type}`);
   }

   deletionPermissions(submissionId,permissions){
      return this.http.put<any>(
         `${environment.apiUrl}/submission/${submissionId}/allowStudentToDelete`,{
            permissions
         });
   }

   getLanguages(type: string) {
    return this.http.get<any>(`${environment.apiUrl}/getLanguages/${type}`).pipe();
   }

   setLanguage(language) {
      return this.http.post<any>(`${environment.apiUrl}/setLanguage`, {
         language,
      });
   }

   submissionDetailsForOfflineExport(assignmentIds = []) {
      console.log('assignmentIds', assignmentIds);
      return this.http.post<any>(`${environment.apiUrl}/submissionsDetails`,{assignmentIds});
   }
}
