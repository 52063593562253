 <ng-container *ngIf="isOnManipulation; else notOnManipulation">
   <section class="manipulation_bar isOnManipulation">
    <header>
      <section class="header-section">
        <section class="header-label" tabindex="0" aria-label="Manipulation">
          <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="Manipulation language logo/image">
          <section>
            <h3 class="inBeta">{{ 'new_entries.manipulation' | translate }}</h3>
            <span tabindex="0" aria-label="Numbers show detected/identified manipulations">{{ 'new_entries.detected_manipulations_count' | translate }}</span>
          </section>
        </section>
        <button (click)="toggleIsOnManipulation()" tabindex="0">
          <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow left. Click to go to the Summary.">
        </button>
      </section>
    </header>
     <section class="manipulation-body">
      <section class="found_flags">
        <p>{{ 'new_entries.flags_found' | translate }}</p>
        <span [ngClass]="(totalCharReplacements + totalManipulatedImages + totalWhiteCharacters) == 0 ? 'low' : 'high'">{{ totalCharReplacements + totalManipulatedImages + totalWhiteCharacters }}</span>
      </section>
      <section [ngClass]="(totalCharReplacements + totalManipulatedImages + totalWhiteCharacters) == 0 ? 'low' : 'high'" class="flag_score" tabindex="0">
        <aside class="alert_img">
          <img [src]="(totalCharReplacements + totalManipulatedImages + totalWhiteCharacters) == 0 ? '../../../../../../assets/report_v2/check-circled-filled.svg' : '../../../../../../assets/report_v2/warning-circled-filled.svg'" alt="" />
        </aside>
          <h5 [innerHTML]="'new_entries.found_manipulations' | translate:{ number: (totalCharReplacements + totalManipulatedImages + totalWhiteCharacters) }"></h5>
      </section>
       <section class="flags">
         <!-- <section class="flags-header">
           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15Z" stroke="#0059B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
             <path d="M4 22V15" stroke="#0059B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
           <span>{{ 'new_entries.flags' | translate }}</span>
         </section> -->
         <section class="flags-body">
           <section tabindex="0" class="flag charReplacement" [ngClass]="{'active': isCharReplacement}">
             <section (click)="openFlag('charReplacement')" class="flag-title">
               <section class="flag-count"><span class="counter">{{ totalCharReplacements }}</span></section>
               <section class="flag-body">
                 <h5><img src="../../../../../../assets/report_v2/flag_blue.svg" alt=""> {{ 'new_entries.character_replacement' | translate }}</h5>
                 <p>{{ 'new_entries.character_replacement_description' | translate }}</p>
               </section>
               <!-- <button class="flag-toggle" [ngClass]="isCharReplacement ? 'rotate' : ''" *ngIf="includedCharactersSentences.length > 0 || excludedCharactersSentences.length > 0">
                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                   <path d="M12 5V19" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M19 12L12 19L5 12" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
               </button> -->
             </section>
             <section class="found-flags" [ngClass]="!isCharReplacement ? 'closed' : ''" *ngIf="includedCharactersSentences.length > 0 || excludedCharactersSentences.length > 0">
               <section class="flagged-content">
                 <h5 class="bold" *ngIf="includedCharactersSentences.length > 0">{{ 'new_entries.content_flagged' | translate }}</h5>
                 <ng-container *ngFor="let sentence of includedCharactersSentences">
                   <section class="content" (click)="selectSentence($event); scrollPage(sentence.page)">
                     <!-- <img src="../../../../../../assets/report_v2/flag_grey.svg" alt=""> -->
                     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.05664 13.4095C4.05664 13.4095 4.94184 12.5243 7.59743 12.5243C10.253 12.5243 12.0234 14.2947 14.679 14.2947C17.3346 14.2947 18.2198 13.4095 18.2198 13.4095V2.78714C18.2198 2.78714 17.3346 3.67234 14.679 3.67234C12.0234 3.67234 10.253 1.90195 7.59743 1.90195C4.94184 1.90195 4.05664 2.78714 4.05664 2.78714V13.4095Z" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.05664 19.6059V13.4095" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                     <section class="content-body">
                       <h6>{{ 'new_entries.flag' | translate }}</h6>
                       <p>{{ sentence.text }} </p>
                     </section>
                     <!-- <button class="includeCharBtn" (click)="openCharReplacementModal(allManipulations, sentence)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt=""></button> -->
                   </section>
                 </ng-container>
                 <h5 class="bold" class="excluded-content-title" *ngIf="excludedCharactersSentences.length > 0">Excluded Content</h5>
                 <ng-container *ngFor="let sentence of excludedCharactersSentences">
                   <section class="content excluded" (click)="selectSentence($event); scrollPage(sentence.page)">
                     <!-- <img src="../../../../../../assets/report_v2/flag_grey.svg" alt=""> -->
                     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.05664 13.4095C4.05664 13.4095 4.94184 12.5243 7.59743 12.5243C10.253 12.5243 12.0234 14.2947 14.679 14.2947C17.3346 14.2947 18.2198 13.4095 18.2198 13.4095V2.78714C18.2198 2.78714 17.3346 3.67234 14.679 3.67234C12.0234 3.67234 10.253 1.90195 7.59743 1.90195C4.94184 1.90195 4.05664 2.78714 4.05664 2.78714V13.4095Z" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.05664 19.6059V13.4095" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                     <section class="content-body">
                       <h6>{{ 'new_entries.flag' | translate }}</h6>
                       <p>{{ sentence.text }} </p>
                     </section>
                     <!-- <button class="includeCharBtn" (click)="openCharReplacementModal(allManipulations, sentence)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt=""></button> -->
                   </section>
                 </ng-container>

               </section>
               <section class="actionBtns">
                 <button class="includeCharBtn" [ngClass]="(allManipulations.excludedReplacedCharacters.length == 0 && allManipulations.replacedCharacters.length == 0) ? 'disabled': ''" [disabled]="allManipulations.excludedReplacedCharacters.length == 0 && allManipulations.replacedCharacters.length == 0" (click)="toggleCharReplacements(allManipulations, 2)">Toggle Characters</button>
                 <!-- <button class="excludeCharBtn" [ngClass]="(allManipulations.excludedReplacedCharacters.length == 0 && allManipulations.replacedCharacters.length == 0) ? 'disabled': ''" [disabled]="allManipulations.excludedReplacedCharacters.length == 0 && allManipulations.replacedCharacters.length == 0" (click)="toggleCharReplacements(allManipulations.replacedCharacters, 1)">Exclude Characters</button> -->
               </section>
             </section>
           </section>

           <section tabindex="0" class="flag hiddenText" [ngClass]="{'active': isHiddenText}">
             <section (click)="openFlag('hiddenText')" class="flag-title">
               <section class="flag-count"><span class="counter">{{ totalWhiteCharacters }}</span></section>
               <section class="flag-body">
                 <h5><img src="../../../../../../assets/report_v2/flag_blue.svg" alt="">{{ 'new_entries.hidden_text' | translate }}</h5>
                 <p>{{ 'new_entries.hidden_text_description' | translate }}</p>
               </section>
               <button class="flag-toggle" [ngClass]="isHiddenText ? 'rotate' : ''" *ngIf="includedHiddenTextSentences.length > 0 || excludedHiddenTextSentences.length > 0">
                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                   <path d="M12 5V19" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M19 12L12 19L5 12" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
               </button>
             </section>
             <section class="found-flags" [ngClass]="!isHiddenText ? 'closed' : ''" *ngIf="includedHiddenTextSentences.length > 0 || excludedHiddenTextSentences.length > 0">
               <section class="flagged-content">
                 <h5 *ngIf="includedHiddenTextSentences.length > 0">{{ 'new_entries.content_flagged' | translate }}</h5>
                 <ng-container *ngFor="let sentence  of includedHiddenTextSentences">
                   <section class="content" (click)="selectSentence($event); scrollPage(sentence.page)" *ngIf="sentence.characters && sentence.characters.length > 0">
                     <!-- <img src="../../../../../../assets/report_v2/flag_grey.svg" alt=""> -->
                     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.05664 13.4095C4.05664 13.4095 4.94184 12.5243 7.59743 12.5243C10.253 12.5243 12.0234 14.2947 14.679 14.2947C17.3346 14.2947 18.2198 13.4095 18.2198 13.4095V2.78714C18.2198 2.78714 17.3346 3.67234 14.679 3.67234C12.0234 3.67234 10.253 1.90195 7.59743 1.90195C4.94184 1.90195 4.05664 2.78714 4.05664 2.78714V13.4095Z" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.05664 19.6059V13.4095" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                     <section class="content-body">
                       <h6>{{ 'new_entries.flag' | translate }}</h6>
                       <p>{{ sentence.text }}</p>
                     </section>
                     <button (click)="toggleWhiteCharSentence(sentence)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt=""></button>
                   </section>
                 </ng-container>
                 <h5 class="excluded-content-title" *ngIf="excludedHiddenTextSentences.length > 0">Excluded Content</h5>
                 <ng-container *ngFor="let sentence of excludedHiddenTextSentences">
                   <section class="content excluded" (click)="selectSentence($event); scrollPage(sentence.page)" *ngIf="sentence.characters && sentence.characters.length > 0">
                     <!-- <img src="../../../../../../assets/report_v2/flag_grey.svg" alt=""> -->
                     <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.05664 13.4095C4.05664 13.4095 4.94184 12.5243 7.59743 12.5243C10.253 12.5243 12.0234 14.2947 14.679 14.2947C17.3346 14.2947 18.2198 13.4095 18.2198 13.4095V2.78714C18.2198 2.78714 17.3346 3.67234 14.679 3.67234C12.0234 3.67234 10.253 1.90195 7.59743 1.90195C4.94184 1.90195 4.05664 2.78714 4.05664 2.78714V13.4095Z" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.05664 19.6059V13.4095" stroke="#272727" stroke-width="1.3278" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                     <section class="content-body">
                       <h6>{{ 'new_entries.flag' | translate }}</h6>
                       <p>{{ sentence.text }}</p>
                     </section>
                     <button (click)="toggleWhiteCharSentence(sentence)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt=""></button>
                   </section>
                 </ng-container>
               </section>
             </section>
           </section>

           <section tabindex="0" class="flag isImage" [ngClass]="{'active': isImage}">
             <section (click)="openFlag('isImage')" class="flag-title">
               <section class="flag-count"><span class="counter">{{ totalManipulatedImages }}</span></section>
               <section class="flag-body">
                 <h5><img src="../../../../../../assets/report_v2/flag_blue.svg" alt="">{{ 'new_entries.image_in_place_of_text' | translate }}</h5>
                 <p>{{ 'new_entries.image_in_place_of_text_description' | translate }}</p>
               </section>
               <button class="flag-toggle" [ngClass]="isImage ? 'rotate' : ''" *ngIf="redImages.length > 0 || greenImages.length > 0">
                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                   <path d="M12 5V19" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M19 12L12 19L5 12" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
               </button>
             </section>
             <section class="found-flags" [ngClass]="!isImage ? 'closed' : ''" *ngIf="redImages.length > 0 || greenImages.length > 0">
               <section class="flagged-content">
                 <h5 *ngIf="redImages.length > 0">Red Flags</h5>
                 <ng-container *ngIf="redImages.length > 0">
                   <div class="content red" *ngFor="let image of redImages" (click)="selectSentence($event); scrollPage(image.page + 1)">
                     <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="">
                     <section class="content-body">
                       <h6>{{ 'new_entries.flag' | translate }}</h6>
                       <p>Image {{ image.index }}</p>
                     </section>
                     <button><img src="../../../../../../assets/report_v2/plus-circle.svg" alt="Plus Button" (click)="includeManipulatedImage(image)"></button>
                   </div>
                 </ng-container>
                 <h5 class="excluded-content-title" *ngIf="greenImages.length > 0">Green Flags</h5>
                 <ng-container *ngIf="greenImages.length > 0">
                   <div class="content green" *ngFor="let image of greenImages" (click)="selectSentence($event); scrollPage(image.page + 1)">
                     <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="">
                     <section class="content-body">
                       <h6>{{ 'new_entries.flag' | translate }}</h6>
                       <p>Image {{ image.index }}</p>
                     </section>
                     <button><img src="../../../../../../assets/report_v2/x-circle.svg" alt="X Button" (click)="excludeManipulatedImage(image)"></button>
                   </div>
                 </ng-container>
               </section>
               <section class="actionBtns">
                 <button *ngIf="isImage" (click)="refreshPdf()" class="refresh_btn"><img src="../../../../../../assets/report_v2/reload.svg" alt="Reload"> Reload PDF <span class="tooltip" [ngClass]="tooltipVisible ? 'visible' : ''">Click here to refresh the PDF!</span></button>
               </section>
             </section>
           </section>
         </section>
       </section>
     </section>
     <app-overview-disclaimer [title]="'general.overview' | translate" tabindex="0" [disclaimerText]="'new_entries.manipulation_analysis' | translate"
       [disclaimerLink]="'https://support.inspera.com/hc/en-us/articles/19328760939549-What-is-Text-manipulation-detection'" [linkText]="'new_entries.learn_more' | translate"></app-overview-disclaimer>
    </section>

 </ng-container>

 <ng-template #notOnManipulation>
  <section class="manipulation_bar">
    <header>
      <section class="header-section">
        <section class="header-label" tabindex="0" aria-label="Manipulation">
          <img src="../../../../../../assets/report_v2/flag.svg" alt="Manipulation language logo/image">
          <section>
            <h3 class="inBeta">{{ 'new_entries.manipulation' | translate }}</h3>
            <span tabindex="0" aria-label="Numbers show detected/identified manipulations">{{ 'new_entries.detected_manipulations_count' | translate }}</span>
          </section>
        </section>
        <button (click)="toggleIsOnManipulation()" tabindex="0">
          <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow right. Click to go to the Manipulation.">
        </button>
      </section>
    </header>
    <section class="similarity_overview_parent" tabindex="0" [ariaLabel]="'Document has ' + (totalCharReplacements + totalWhiteCharacters + totalManipulatedImages) + ' Manipulations'">
      <section class="similarity_overview">
        <div class="overview-head">
          <img src="../../../../../../assets/report_v2/flag_borderless.svg" alt="Borderless Flag">
          <section class="overview">
            <div tabindex="0"><span>{{ 'new_entries.character_replacement' | translate }}:</span><span class="answ">{{ totalCharReplacements }}</span></div>
            <div tabindex="0"><span>{{ 'new_entries.hidden_text' | translate }}:</span><span class="answ">{{ totalWhiteCharacters }}</span></div>
            <div tabindex="0"><span>{{ 'new_entries.image_in_place_of_text' | translate }}:</span><span class="answ">{{ totalManipulatedImages }}</span></div>
          </section>
          <div class="overview-tag"><p class="tag" [ngClass]="(totalCharReplacements + totalWhiteCharacters + totalManipulatedImages) == 0 ? 'low' : 'high'"><span class="round"></span>{{ totalCharReplacements + totalWhiteCharacters + totalManipulatedImages }}</p></div>
        </div>
        <section class="show_more" (click)="toggleTopFlags()">
          <button [ngClass]="showTopFlags ? 'rotate' : ''"><img src="../../../../../../assets/report_v2/arrow_down.svg" alt="Arrow Down">{{ showTopFlags ? 'Show less' : 'Show more' }}</button>
        </section>
      </section>
      <section class="top_flags" [ngClass]="showTopFlags ? 'open' : ''">
        <section class="top_flags_header">
          <h5>{{ 'new_entries.top_flags' | translate }} ({{ selectedFilterText }})</h5>
          <button (click)="toggleSummaryMenu()" class="sources_menu_btn">
            <img src="../../../../../../assets/report_v2/three-dots.svg" alt="Three Dots">
            <section *ngIf="isSummaryMenuOpen" class="sources_menu" cdkTrapFocus>
              <button class="sources_menu_opt" aria-label="Click to Show matching manipulations" (click)="filterManipulations(0)">{{ 'new_entries.show_top_flags' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to Show only Character Replacements" (click)="filterManipulations(1)">{{ 'new_entries.show_only_character_replacements' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show only {{ 'new_entries.hidden_text' | translate }}" (click)="filterManipulations(2)">{{ 'new_entries.show_only_hidden_text' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show only Image in place  of text" (click)="filterManipulations(3)">{{ 'new_entries.show_only_images_in_place_of_text' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show excluded 'Characters'" (click)="filterManipulations(4)">{{ 'new_entries.show_excluded_characters' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show excluded '{{ 'new_entries.hidden_text' | translate }}'" (click)="filterManipulations(5)">{{ 'new_entries.show_excluded_hidden_text' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show red flags of 'Image in place of text'" (click)="filterManipulations(6)">{{ 'new_entries.show_green_flags_images' | translate }}</button>
              <button class="sources_menu_opt" aria-label="Click to show green flags of 'Image in place of text'" (click)="filterManipulations(7)">{{ 'new_entries.show_red_flags_images' | translate }}</button>
            </section>
          </button>
        </section>
        <section class="top_manipulations">
          <!-- {{ 'new_entries.character_replacement' | translate }} -->
          <ng-container *ngIf="topManipulations?.charReplacements && topManipulations?.charReplacements.length >= 0">
            <section class="content" *ngFor="let manipulation of topManipulations?.charReplacements" [ngClass]="isCharReplacementExcluded(manipulation) ? 'excluded' : ''">
              <section class="head">
                <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="">
                <p>{{ 'new_entries.character_replacement' | translate }}</p>
                <button (click)="toggleOneReplacedChar(manipulation, isCharReplacementExcluded(manipulation) ? 2 : 1)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt="Remove Manipulation"></button>
              </section>
              <section class="body">
                <p><span>{{ 'new_entries.character' | translate }}:</span>{{ manipulation.character }}</p>
                <p><span>{{ 'new_entries.appearance' | translate }}:</span>{{ manipulation.count }}</p>
              </section>
            </section>
            <span class="no_manipulations" *ngIf="topManipulations?.charReplacements.length == 0 && !topManipulations?.whiteCharacters && !topManipulations?.images">There are no manipulations that match this filter!</span>
          </ng-container>

          <!-- {{ 'new_entries.hidden_text' | translate }} -->
          <ng-container *ngIf="topManipulations?.whiteCharacters && topManipulations?.whiteCharacters.length >= 0">
            <section class="content" *ngFor="let manipulation of topManipulations?.whiteCharacters" [ngClass]="manipulation.whitecharactersExcluded ? 'excluded' : ''">
              <section class="head">
                <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="">
                <p>White Characters</p>
                <button (click)="toggleWhiteCharSentence(manipulation)"><img src="../../../../../../assets/report_v2/close-circle.svg" alt="Remove Manipulation"></button>
              </section>
              <section class="body">
                <p>{{ manipulation.text }}</p>
              </section>
            </section>
            <span class="no_manipulations" *ngIf="topManipulations?.whiteCharacters.length == 0 && !topManipulations?.charReplacements && !topManipulations?.images">There are no manipulations that match this filter!</span>
          </ng-container>

          <!-- {{ 'new_entries.image_in_place_of_text' | translate }} -->
          <ng-container *ngIf="topManipulations?.images && topManipulations?.images.length >= 0">
            <section class="content"
              [ngClass]="isCharReplacementExcluded(manipulation) ? 'excluded' : ''">
              <section class="imagesFilter">
                <div>
                  <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="">
                  <p>{{ 'new_entries.image_in_place_of_text' | translate }}</p>
                </div>
                <ng-container *ngIf="redImages.length > 0">
                  <div class="content red" *ngFor="let image of redImages" (click)="selectSentence($event); scrollPage(image.page + 1)">
                    <img src="../../../../../../assets/report_v2/flag_grey.svg" alt="">
                    <section class="content-body">
                      <h6>{{ 'new_entries.flag' | translate }}</h6>
                      <p>Image {{ image.index }}</p>
                    </section>
                  </div>
                </ng-container>
              </section>
            </section>

            <span class="no_manipulations" *ngIf="topManipulations?.images.length == 0 && !topManipulations?.charReplacements && !topManipulations?.whiteCharacters">There are no manipulations that match this filter!</span>

          </ng-container>
        </section>
      </section>
    </section>
  </section>
 </ng-template>
