<div class="editimi-fakultetit-modal">
   <div class="editimi-fakulltetit-title">
      <h4>{{ 'app.upload.draft_message' | translate }}</h4>
   </div>
   <form [formGroup]="draftMessage">
      <div class="editimi-fakultetit-inputs">
         <div class="inside-inputs">
            <label for="message">{{ 'general.message' | translate }}:</label>
            <textarea
               cols="30"
               rows="5"
               formControlName="message"
               type="textarea"
            ></textarea>
         </div>
      </div>
      <div class="editimi-fakultetit-fshij-perfundo">
         <button type="submit" class="submit" (click)="submit()">{{ 'report.finish' | translate }}</button>
      </div>
   </form>
</div>
