<!-- <h2 mat-dialog-title>{{ 'other.install_angular' | translate }}</h2>
<mat-dialog-content class="mat-typography">
</mat-dialog-content>
<mat-dialog-actions align="end">
   <button mat-button mat-dialog-close>{{ 'general.cancel' | translate }}</button> -->
<!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'other.install' | translate }}</button> -->
<!-- </mat-dialog-actions> -->
<div class="conditions">
   <div>
      <h3>{{ 'general.terms_and_conditions' | translate }}</h3>
      <i mat-dialog-close class="far fa-times-circle"></i>
   </div>
   <div>
      <h3>{{ 'general.terms_of_use' | translate }}</h3>
      <p>
         Crossplag L.L.C and the services (“Website” or “Services”) are
         maintained by Crossplag L.L.C and are offered to you, the users ("You"
         and/or "User"), subject to your acceptance of the terms of use,
         changes, and notices, which we otherwise call the "Terms of Use." You
         must read the usage agreement carefully before accepting it. If you as
         a user violate the Terms of Use in any form, your authorization will be
         terminated immediately.
      </p>

      <h3>{{ 'terms_and_conditions.acceptance_of_conditions' | translate }}</h3>
      <p>
         The User accepts this Agreement by clicking on the "accept" or "agree"
         button in the country where this option is made possible by the
         Crossplag L.L.C. These terms of use apply to all services provided by
         the Crossplag L.L.C. Users that Crossplag L.L.C 1) has informed that it
         may not make the services accessible to you, 2) you are an unauthorized
         person for use in breach of any agreement with Platform Academy, or 3)
         you are an unauthorized user.
      </p>

      <h3>{{ 'app.authorized_users_acceptability' | translate }}</h3>
      <p>
         Use of Crossplag L.L.C and Crossplag L.L.C's services are not
         accessible to users under 13 years of age. Use for persons under the
         legal age (16 years) must be authorized by the parent or guardian, a
         request which the Crossplag L.L.C may make to the User at any time and
         in any manner. If the User is under the permitted age of use, you
         should ask the parent or guardian to carefully read and click the
         "accept" or "agree" button. "Authorized Users" are: 1) administrators
         of institutions, 2) professors, 3) students who are registered in the
         Educational Institution that has a direct agreement with the Crossplag
         L.L.C.
      </p>

      <h3>{{ 'terms_and_conditions.scope_of_services' | translate }}</h3>
      <p>
         The Website offers several services, including, text similarity
         detection system, website data, articles, and various materials and
         information. Our services allow authorized institutions (Universities,
         Research Institutes, etc.) and users authorized (Professors,
         Administrators, etc.) who have access to documents submitted by the
         courses or units they attend to use the services provided by the
         Crossplag L.L.C to check and evaluate the submitted work and the
         similarity of the document with other documents which are located in
         the primary database of Crossplag L.L.C.
      </p>

      <p>
         The User accepts and agrees to the form, nature of the services, and
         the Website from which the Crossplag L.L.C provides the services may
         change from time to time, without prior notice to the User. The User
         also acknowledges that Crossplag L.L.C may discontinue (temporarily or
         permanently) the provision of services or additional
         features/capabilities of Crossplag L.L.C to the User without prior
         notice.
      </p>
      <p>
         Crossplag L.L.C's services, including those of licensors, vendors,
         suppliers, and service providers, can be considered evidence to prove
         the level of quality and integrity of the writings of students and
         users in general. When a report is used to make an important decision
         about student performance, professors should review and evaluate the
         information to ensure that the right disciplinary decision is being
         made regarding student performance.
      </p>
      <p>
         The User agrees and understands that Crossplag L.L.C's services may not
         be used for any other purpose or towards any other party than those
         written in the "user agreement." The User must ensure that the
         licensees, vendors, suppliers, and service providers of the Crossplag
         L.L.C will be held free from any charges raised by the User against the
         acceptance of the Services, use of the information, or any other
         purpose.
      </p>

      <h3>{{ 'app.license' | translate }}</h3>

      <p>
         Following the Terms of Use, Crossplag L.L.C licenses you with the
         non-transferable, non-exclusive right to use the services, Website, and
         services of Crossplag L.L.C without the right of personal or commercial
         benefit from the services and Website of Platforma Academy.
      </p>
      <p>
         You agree to use the Crossplag L.L.C website and services only as
         follows; 1) if you are an administrator associated with different units
         in Crossplag L.L.C through one Institution, 2) if you are a professor
         associated with different units of Crossplag L.L.C through one
         Institution, 3) if you are a student connected to different units
         through our software within an institution, 4) if You are an individual
         user associated with the Crossplag L.L.C, 5) if you are an independent
         individual user with limited rights of use.
      </p>
      <p>
         To avoid doubt, you agree or agree that any license granted to You does
         not allow you to 1) modify, copy, distribute, transmit, display,
         perform, reproduce, publish, license, transfer, damage, collect, or
         sell any information or service from the Crossplag L.L.C website
         without the permission of Crossplag L.L.C, 2) trade, rent or lease,
         re-license the services, or use the Crossplag L.L.C website or services
         for commercial gain and use, and 3) cause, allow, decompile, recompile,
         re-engineer, or share, any part of the website technology.
      </p>

      <h3>{{ 'terms_and_conditions.property' | translate }}</h3>

      <p>
         You or the person who authorizes you to submit a document to Crossplag
         L.L.C for any purpose involving Crossplag L.L.C's services will retain
         ownership of the submitted document. Under the terms of use agreement,
         Crossplag L.L.C reserves the right to store and use your document
         submitted within the database only to review the similarity of other
         documents with parts of your document, or your document in general for
         calculation and specification of the level of similarities and other
         services offered by the Crossplag L.L.C.
      </p>
      <p>
         All materials created by the services, including the format of the
         originality evaluation reports, the form of comments, feedback, and
         specifications, and everything that is part of the intellectual
         property of the Crossplag L.L.C are the property of the Crossplag
         L.L.C. The Website is protected by law on copyright protection and
         other applicable laws of the Republic of Kosovo. Unless you have any
         special permission to access the services and materials owned by
         Crossplag L.L.C, you have no right to the intellectual property and/or
         primary Information of Crossplag L.L.C. The Crossplag L.L.C reserves
         all rights not stated in the user agreement.
      </p>
      <h3>{{ 'app.license_from_you' | translate }}</h3>
      <p>
         If you submit a document or other content in connection with the
         Services of Crossplag L.L.C, You grant Crossplag L.L.C the right of
         non-exclusive, perpetual, non-refundable, non-compensatory use of the
         documents and content submitted to Crossplag L.L.C for; 1) providing
         services, and 2) improving the quality of services of the Platform
         Academy.
      </p>

      <p>
         You also agree that the Crossplag L.L.C retains these rights even after
         termination of the User Agreement. Any interruption of the use of the
         Crossplag L.L.C website or services by both the User and the Crossplag
         L.L.C will not be the basis for the prohibition of this right that
         Crossplag L.L.C holds to the content you submit to the program. All
         submitted documents will be kept at the discretion of each Institution
         through which the documents are received and with which the Crossplag
         L.L.C has an agreement. Unless otherwise stated, including the Privacy
         Policy any communication or material of any kind you email, post, or
         transmit through the Crossplag website ( excluding Student, Staff, and
         Student Registration lists and Information their personal, any
         documents uploaded to the Website, comments, and ratings ) included,
         questions, suggestions, and other information will be used as
         non-confidential information. You give the Crossplag L.L.C the
         non-exclusive, perpetual, irrevocable right to reproduce, transmit,
         display, publish, archive, and use your communications on our Website
         to do business. The Crossplag L.L.C is free to use all ideas, concepts,
         techniques, knowledge, and information in your communications,
         including developing and using products and services based on these
         communications. For example, if you would suggest a feature that
         Crossplag L.L.C would include on the Website or services it offers,
         Crossplag L.L.C would be free to proceed with that feature without
         notifying you or asking for any additional permissions, as well as
         without given any additional compensation to you.
      </p>
      <h3>{{ 'app.registration_and_passwords' | translate }}</h3>
      <p>
         If you are an administrator, you agree to 1) provide class or class
         identification numbers and passwords only to professors/instructors, 2)
         do not make statements that your Institution uses the Crossplag L.L.C
         Services concerning any unit if that unit does not is registered.
      </p>

      <p>
         If you are a professor/instructor, you agree that; 1) provide names or
         identification numbers to find the units to which students/users will
         submit documents, and 2) do not declare that students/users under your
         jurisdiction are using the services of the Crossplag L.L.C if users are
         not registered to use the services of Platform Academy.
      </p>

      <p>
         If you are a student/user, you guarantee that 1) You are registered in
         the Crossplag L.L.C and in all units required by the
         professor/instructor, and 2) the password with which you log in to the
         Crossplag L.L.C website is yours personal, given to you by the
         Crossplag L.L.C, Educator / Instructor, or Administrator.
      </p>

      <p>
         You are solely and personally responsible for any and all uses of your
         account. If you are notified of any privacy breach, unauthorized use of
         your account or password, please contact us
         at support@crossplag.com. However, you agree to maintain your account's
         confidentiality, password, or any other information that connects you
         to the Crossplag L.L.C. Under no circumstances should you transmit or
         make your password or any Platforma Academy password known to anyone or
         in any form, including but not limited to web pages, blogs, complaints,
         advertisements, or any posting on internet, public newsletter, or any
         file that is or can be accessed from any public network.
      </p>

      <p>
         You agree to use unit numbers and/or login names, passwords and that
         you will not attempt to log in with a password that has not been
         expressly provided to you by the administrator, instructor, or Platform
         Academy. You also agree to report any suspicions you may have about
         unauthorized access and use of your account by a third party.
      </p>
      <h3>{{ 'terms_and_conditions.conclusion' | translate }}</h3>
      <p>
         The Crossplag L.L.C, in its sole discretion, may suspend or terminate
         access to the Website for 1) prevent damage or degradation of the
         Services; 2) obey any law, rule, court order, or any other state
         request; 3) protect the Crossplag L.L.C from any potential for
         violation of laws in the Republic of Kosovo; 4) under the violation of
         the Agreement of Use.
      </p>
      <p>
         Crossplag L.L.C should try to a reasonable level to notify you in
         advance of any violations you are committing while using Crossplag
         before suspending access to the Website. Suspended users' materials may
         be deleted at the request of their instructor or administrator.
         Crossplag L.L.C may restart user access to the Website at its sole
         discretion, once the dispute has been resolved, under the agreements in
         force or to the satisfaction of Crossplag L.L.C.
      </p>
      <p>
         You agree and agree that if Crossplag L.L.C suspends or deactivates
         your account, You may be barred from accessing the Services, account
         details, or any other file or content relating to your account.
      </p>
      <h3>Originality Report</h3>
      <p>
         If you are an administrator or instructor, you agree to be bound by any
         Crossplag L.L.C notification (including legal notices regarding
         Crossplag L.L.C rights and primary information (e.g., copyright and
         trademark) and disclaimers within the Platform. Any disclosure of
         authenticity reports to any third party by You is at your own risk and
         may violate privacy rights or applicable laws. The value and/or
         feedback and comments received from the Crossplag L.L.C Services should
         be considered evidence. When a value is being used to make a
         significant decision to the User, Instructors and/or Administrators
         should reassess that the decision is made on the performance or
         similarity outcome is appropriate. Accurately and under the laws of the
         Republic of Kosovo and the relevant Regulatory Authority.
      </p>
      <p>
         You next agree to exercise your independent judgment and professional
         and hold full responsibility for the poor decision and measures taken
         to work in the primary level resembles j s hmërisë in the paper
         submitted by acknowledging and understanding that the authenticity of
         the reports is only tools for detecting the similarity of the text
         between different works and articles without finally deciding on the
         existence of plagiarism. This decision is based on the professional
         judgment of the professor/instructor and the Institution.
      </p>
      <h3>{{ 'other.compliance_of_use' | translate }}</h3>
      <p>
         You agree to use the services and Website of Crossplag L.L.C only for
         the purposes permitted by 1) this agreement of use and 2) any
         applicable law, rule, practice, or administrative instruction under
         applicable laws of the Republic of Kosovo or other states in which the
         Crossplag L.L.C operates. You agree not to access or request access to
         any Crossplag L.L.C services or websites other than the Crossplag L.L.C
         websites. You agree not to access or request access to any of the
         Crossplag L.L.C services or websites by any automatic method (including
         "eng; scripts, web crawlers"), and you must ensure that you adhere to
         all other instructions You are displayed on the Crossplag L.L.C
         website.
      </p>
      <p>
         You will not transmit or transfer or communicate any document on or
         through the Website that 1) prohibits the navigation and enjoyment of
         other users on the Website; 2) is illegal, threatening, abusive,
         defamatory, profane, or offensive; 3) constitute or encourage criminal
         activities or violate the law and the AgreementAgreement of use in any
         form; 4) infringes or violates the rights of any third party including
         privacy, primary information, or any other copyright; 5) contains any
         virus or other harmful components for the Website and services; 6)
         contains false information or misleading indications as to the origin
         of the facts. The Crossplag L.L.C reserves the right and has no
         obligation to remove any User's Communication to the Website. Crossplag
         L.L.C reserves the right to suspend the access of Users who do not
         comply with the user agreement at its sole discretion.
      </p>
      <p>
         As a condition of using this Website, you as a user of this Website and
         the services of Crossplag L.L.C agree that you will not use the Website
         or the services provided by Crossplag L.L.C for any illegal activity or
         purpose prohibited by this Agreement.
      </p>
      <p>
         Under the terms of use, you are not allowed to use the Website and
         services of Crossplag L.L.C for any purpose other than the conditions
         stated in the AgreementAgreement for use.
      </p>
      <h3>{{ 'help_center.privacy.privacy' | translate }}</h3>
      <p>
         By agreeing to the terms of use, you agree that you have read and fully
         understand and understand the privacy policies attached to this
         Contract and agree to the terms and conditions outlined in this policy.
      </p>
      <h3>{{ 'terms_and_conditions.warranty' | translate }}</h3>
      <p>
         From the Crossplag L.L.C website, all information and all services
         provided, including viewing resources, are presented as written by the
         original authors of the works, with all errors made during the work.
         The Crossplag L.L.C. Crossplag L.L.C does not improve the original
         authors' work; associates, suppliers, and all partners of Crossplag
         L.L.C do not guarantee that the services provided will meet all your
         expectations and all searches generated by the Website will be
         completely accurate. The Crossplag L.L.C does not warrant that the
         Website and the Services provided will be unobstructed or that
         potential barriers to use will be rectified immediately upon request.
      </p>
      <p>
         However, Crossplag L.L.C. guarantees that specific problems you may
         encounter will be resolved as soon as possible, and Crossplag L.L.C
         will maintain a rigorous attitude towards continuously improving your
         experience and interactivity with the Website and services offered.
      </p>
      <p>
         Since all services provided by Crossplag L.L.C are accessible from the
         internet, you agree that Crossplag L.L.C does not contain any control
         over the quality of the internet you accept, and 1) that you may be
         exposed to other malicious software and sites, 2) that outsiders may
         attempt and/or misuse or damage your data by unauthorized access that
         may be obtained in various ways. The Crossplag L.L.C will not be held
         responsible if your password is lost or made accessible to unauthorized
         persons. For this reason, Crossplag L.L.C recommends changing your
         account password regularly.
      </p>
      <p>
         As the Crossplag L.L.C contains a rigorous attitude towards continuous
         improvement, the Crossplag L.L.C website and services may be
         temporarily suspended from time to time due to technical maintenance
         and improvement of problems presented to the benefit of the User.
         Crossplag L.L.C assumes no responsibility for interruptions, deletions,
         defects, delays, technical malfunctions, computer systems, internet
         speed, operating equipment, or damage to any user equipment used while
         using the Platforma Academy website or services.
      </p>
      <h3>{{ 'terms_and_conditions.limitation_of_liability' | translate }}</h3>
      <p>
         Crossplag L.L.C. is not liable for any damages (including; direct,
         indirect damage to reputation, loss of credibility, loss of profits,
         and/or business interference) that may result from the use of the
         Crossplag L.L.C. website or services.
      </p>
      <p>
         If you are an Institution or instructor, You agree that in any
         allegation related to any irresponsible action discovered by the
         Crossplag L.L.C website and services, Crossplag L.L.C will not be held
         liable or involved in 1) decisions and measures taken to irresponsible
         actions; 2) for any disclosure of information shared with the public
         based on authenticity reports provided by the Crossplag.
      </p>
      <h3>{{ 'terms_and_conditions.own_remedies' | translate }}</h3>
      <p>
         If you are an institution or instructor, the only legal remedy you can
         use to express dissatisfaction with the use and dissatisfaction with
         the Crossplag L.L.C website and services is to prohibit the use of the
         Website and services.
      </p>
      <h3>INDEMNIFICATION</h3>
      <p>
         You agree to indemnify, protect, and retain the Crossplag L.L.C,
         associates, officers, directors, employees, agents, partners, suppliers
         from any charges, losses, damages, costs, and expenses that may arise
         from 1) use of the web site and services; 2) violation of the rights of
         any third party, including privacy, copyright, and intellectual
         property; 3) violation of the laws in force of the Republic of Kosovo,
         4) violation of the AgreementAgreement of use.
      </p>
      <p>
         This indemnity applies to the charges between you and Crossplag L.L.C
         and any charges by any third party. You agree to cooperate reasonably
         against any allegations against Crossplag L.L.C, and You will not
         settle these charges without the approval of Crossplag L.L.C. The
         User's obligation to protect and compensate the Crossplag L.L.C exceeds
         the user agreement's liability.
      </p>
      <p>
         The Crossplag L.L.C reserves the full right to change the terms and
         AgreementAgreement of use.
      </p>
      <h3>{{ 'terms_and_conditions.changing_the_terms' | translate }}</h3>
      <p>
         1) This user agreement contains the legal AgreementAgreement for the
         use of the Website between you and the Website and services of
         Crossplag L.L.C, excluding any other service specified in any other
         agreement/contract in writing.
      </p>
      <p>
         2) This AgreementAgreement may be alienated from the Crossplag L.L.C,
         even if the Crossplag L.L.C is liquidated, reorganized, or
         re-incorporated. You agree that there is no relationship between you
         and the Crossplag L.L.C, which may give rise to a conflict of interest
         in the use or conclusion of this AgreementAgreement.
      </p>
      <p>
         3) Allowed in full and under the legal framework, You agree that any
         charges or actions issued, issued, or directed based on the Crossplag
         L.L.C website and services must be resolved within 365 calendar days,
         or otherwise, the charges or the action will last forever.
      </p>
      <p>
         4) You agree that Crossplag L.L.C will notify you of the latest changes
         regarding Platform Academy, including new products and services to be
         developed, as well as improvements to current services.
      </p>
      <p>
         5) This Website is controlled and administered by the headquarters of
         Crossplag L.L.C in Prishtina, Republic of Kosovo, and all documents and
         personal information that you upload to Crossplag L.L.C. resides on the
         servers of Crossplag L.L.C.
      </p>
      <p>
         6) Crossplag L.L.C and all other names of products and/or services
         displayed on the Website are the property of Crossplag L.L.C and may
         not be used in connection with any activity that is not the activity of
         Crossplag L.L.C and may cause any ambiguity between customers. All
         trademarks and materials displayed on the Crossplag L.L.C website are
         the property of Crossplag L.L.C.
      </p>
      <p>
         7) Crossplag L.L.C website contains hyperlinks to other websites that
         do not agree or are supported by Crossplag L.L.C. As such, these
         websites may not represent the views and principles of Crossplag L.L.C
         and Crossplag L.L.C will not be held responsible for the terms of use
         of other websites. The Crossplag L.L.C will not be liable for any
         damages you may incur to third-party websites.
      </p>
      <p>
         8) If you are an authorized user as an instructor, agent, or
         administrator of any institution with which the Crossplag L.L.C has an
         agreement, and the user agreement conflicts with the AgreementAgreement
         between the Crossplag L.L.C and you as a user, then the above elements
         apply in the Contract. This only applies to items that conflict with
         the user agreement.
      </p>
      <div>
         <h2>{{ 'help_center.privacy.policy' | translate }}</h2>
         <p>
            Crossplag L.L.C respects all rights and privacy of personal
            information. We are committed to protecting your personal
            information at high-security levels using SSL encryption,
            sophisticated firewalls, data storage in several different
            locations, and many other security measures.
         </p>
         <p>
            Crossplag L.L.C is in line with the security standards of the
            Republic of Kosovo and the European Union.
         </p>
         <p>
            We ask that you read our privacy policies carefully and ask us for
            any ambiguities regarding your privacy policies or other concerns.
         </p>
         <h3>{{ 'terms_and_conditions.purpose_of_personal_data' | translate }}</h3>
         <p>
            To create accounts on Inspera AS L.L.C for academic and managerial
            staff, we ask you for personal information such as; name, surname,
            email address, Institution, and other specifics that help us manage
            the accounts as accurately as possible and so that we can provide
            you with the best possible services. All the information we seek is
            related to a purpose; access to the plagiarism detection system, the
            generation of statistics, and other services that you can see now
            and in the future on our website.
         </p>
         <p class="bold">
            " You can change to request a change or even deletion of personal
            data by contacting us at support@crossplag.com."
         </p>
         <h3>{{ 'terms_and_conditions.data_protection' | translate }} </h3>
         <p>
            We strive to keep your personal information safe on our servers at
            all times. Passwords will be required to access your account and
            personal information. Please keep your passwords in a safe place and
            treat them confidentially. When a user uploads a document to
            Crossplag L.L.C, the document is compared to a secure document
            database that contains millions of records, articles, writings, and
            scientific publications.
         </p>
         <p class="bold">
            “ Crossplag L.L.C does not reserve the intellectual property of any
            submitted works, and we will not use any of the information in your
            works other than to provide and improve the services provided. The
            plagiarism detection system and the services provided do not stand
            to judge any offense. Crossplag L.L.C aims to facilitate the work of
            the Institution and the academic staff to view and compare only the
            writings uploaded to show similarities and not to provide access to
            all student works from other institutions, including the work of
            your Institution to other Institutions . "
         </p>
         <h3>{{ 'help_center.privacy.policies' | translate }}</h3>
         <p>{{ 'app.updated' | translate }} 20/ 10 /2020</p>
         <p>
            Privacy policies apply to all websites and aspects of the services
            of "Crossplag L.L.C " Sh.pk. These policies explain how Crossplag
            L.L.C uses, views, and secures your personal information. The
            information obtained from Crossplag L.L.C will be used only for the
            purpose that your interaction with "Crossplag L.L.C " is as
            satisfactory as possible.
         </p>
         <h3>{{ 'terms_and_conditions.compatibility' | translate }}</h3>
         <p>
            Crossplag L.L.C, based in Prishtina, Republic of Kosovo, serves a
            broad community of students, professors, writers, and academics, for
            whom information on information security and privacy on the Internet
            can be extensive. Crossplag L.L.C not only respects all opinions but
            will also carefully obey the laws to protect personal data security.
         </p>
         <h2>Crossplag L.L.C</h2>
         <h3>{{ 'terms_and_conditions.development_of_legislation' | translate }}</h3>
         <p>
            Based on the nature of personal data protection law, we reserve the
            right to make changes based on needs and changes in the world of
            information security. Therefore, we urge you to continually review
            your {{ 'help_center.privacy.policy' | translate }} to see if any changes have occurred.
         </p>
         <h3>{{ 'terms_and_conditions.personal_data' | translate }}</h3>
         <p>
            As we provide specific educational services, we advocate for the
            protection of students and all users' data, and we want to make sure
            that all users and, in some cases, user caregivers know where we
            stand with the protection of personal data.
         </p>
         <h3>The {{ 'terms_and_conditions.information_we_recieve' | translate }}</h3>
         <p>
            When you create an account on Inspera AS L.L.C, we ask you for some
            personal information such as name, surname, telephone, job title or
            name of the Institution, and more. The information in question
            serves to 1) verify your correct identity and connection with any
            institution or company; 2) allowing the uploading and downloading of
            various documents through Crossplag L.L.C ; 3) to confirm the valid
            submission of multiple documents; 4) informed about important
            information about the services and Crossplag L.L.C; 5) to notify us
            when opening an account, resetting your password, and successfully
            uploading documents; 6) as well as to provide various privileged
            support to you as a user.
         </p>
         <p>
            When using the services and the Crossplag L.L.C website, we will
            automatically save the type of device you are accessing (e.g.,
            phone, tablet, computer).
         </p>
         <p>
            Since Crossplag L.L.C stores the personal data of their clients, it
            does not mean that it will directly connect with the individuals
            from whom it receives data or provides services.
         </p>
         <p>
            Users of Crossplag L.L.C who use the website or accept the services
            through an institution that has an agreement with Crossplag L.L.C
            should contact the Institution to which they belong, to deny access
            to the use of Crossplag L.L.C services.
         </p>
         <h3>{{ 'terms_and_conditions.control' | translate }}</h3>
         <p>
            Crossplag L.L.C always leaves all users who choose to be informed by
            Crossplag L.L.C about various essential communications.
         </p>
         <p class="bold">
            " Any individual seeking access to the modification, deletion, or
            retrieval of any data provided to Crossplag L.L.C, should contact
            the Institution through which Crossplag L.L.C serves you or directly
            to Crossplag L.L.C. If the institutional client wants us to delete,
            modify, or withdraw any information about any of the users, we will
            respond to the request within 24 hours and confirm the changes. ”
         </p>

         <h3>{{ 'terms_and_conditions.evidence_and_recommendations' | translate }}</h3>
         <p>
            If you choose to share any criticisms, suggestions, or
            recommendations for the provider (which we would very much
            appreciate), you in no way retain the right to compensation in the
            event of improvement by the provider. We will publish this evidence
            or recommendations only after we received in writing from you that
            we have the permission to publish this evidence and recommendations.
         </p>
         <h3>{{ 'terms_and_conditions.extraorinary_circumstances' | translate }}</h3>
         <p>
            We may disclose your personal information to comply with any court
            summons, court order, or any legal process that requires Crossplag
            L.L.C to cooperate and legislate within the Republic of Kosovo.
         </p>
         <p>
            Suppose Crossplag L.L.C is involved in any business restructuring or
            asset sales (liquidation). In that case, you will be informed about
            them via an email address, any announcement on the website, in your
            profile, and you will be able to choose between several
            opportunities you have towards your personal information, which is
            found on Inspera AS L.L.C.
         </p>

         <h3>{{ 'terms_and_conditions.technologies_used' | translate }}</h3>
         <p class="bold">1. Cookies</p>
         <p>
            Crossplag L.L.C and all partners use cookies or similar technologies
            to analyze various trends related to the website's administration,
            the movement of users within the website, and the detection of user
            demographics as a whole. However, you can check if cookies are
            allowed by you or not. If you deactivate the use of cookies, then
            you limit the use of several different functions.
         </p>
         <p class="bold">2. Advertising</p>
         <p>
            Crossplag L.L.C does not display any advertisements on our website
            and throughout the services we provide. However, Crossplag L.L.C
            cannot guarantee whether other websites will use their methods (such
            as cookies) to display ads based on your interests.
         </p>

         <h3>{{ 'terms_and_conditions.personal_choices' | translate }}</h3>
         <p>
            Crossplag L.L.C always offers the free choice of what you want to be
            informed by Crossplag L.L.C and whatnot, in addition to information
            about the system and changing passwords.
         </p>
         <p>
            You will also have the option of choosing communication information
            from Crossplag L.L.C at the start of opening your account. You will
            have the opportunity to change your personal information in the
            administration of your account.
         </p>

         <h3>{{ 'terms_and_conditions.transfer_to_third_parties' | translate }}</h3>
         <p class="bold">
            "Your personal information will not be made available by the
            provider to any third party. In no way will Crossplag L.L.C sell or
            share your information with any other organization, Institution, or
            business. If you suspect any breach of your personal information and
            privacy policies, please contact us at support@crosspalg.com."
         </p>
         <h3>{{ 'terms_and_conditions.safety' | translate }}</h3>
         <p>
            We strive to keep your personal information secure at all times and
            in all circumstances. Your information is stored on Inspera AS L.L.C
            servers and secured in various ways, including SSL encryption, data
            storage on several different servers, and other sophisticated
            methods of protecting personal data. Passwords will require you to
            log in to Crossplag L.L.C services, so please keep your password
            secure and treat it as confidential information. It should also be
            noted that even with the most sophisticated systems security
            methods, it cannot be guaranteed that unauthorized third parties may
            obtain your information.
         </p>
         <h3>{{ 'terms_and_conditions.data_integrity' | translate }}</h3>
         <p>
            We take every possible step to ensure that our systems' personal
            information is relevant to the correct and proper use. Crossplag
            L.L.C will ensure that data processed on behalf of institutional
            clients will not be misused in any way and that it will be kept
            secure while Crossplag L.L.C provides services. If for any reason
            you (institutional clients) wish to discontinue using Crossplag
            L.L.C, then you must contact us based on the information exchanged
            in the concluded agreement.
         </p>
         <p>
            Crossplag L.L.C database includes academic papers submitted by
            students, professors, and all users, including information from the
            Internet. When an instructor wants to check a plagiarism level
            document, the document is compared with millions of pieces of
            information to see the paper's level of similarity with the
            Crossplag L.L.C database information.
         </p>
         <p>
            Crossplag L.L.C serves as a tool for controlling various works based
            on software functions and does not judge any user's intellectual
            property. Instead, judging documents and intellectual property is
            the instructor or Institution's responsibility, based on their
            internal operating rules. To assure the similarity of the works,
            Crossplag L.L.C offers the opportunity to view the words, sentences,
            or paragraphs of a similar position with the compared work.
         </p>
         <p class="bold">
            " In some instances, like the works of others, there is no
            opportunity to look at the work as a whole. The opportunity to view
            results and articles in their entirety applies only to information
            from the Internet and products within your Institution. The products
            and documents submitted are your property, and we will not use your
            documents for any purpose other than to support and develop our
            services. ”
         </p>
         <h3>{{ 'terms_and_conditions.changes_in_privacy_policy' | translate }}</h3>
         <p>
            We reserve the right to make changes to the Privacy Policy at any
            time. Please review our privacy policies from time to time to stay
            informed of possible changes. Crossplag L.L.C will announce the day
            when the Privacy Policies have recently changed. If we decide to use
            your personal information for any reason other than as stated in the
            Privacy Policy, we will inform you via your email, which is linked
            to your Crossplag L.L.C account, and request your permission to use
            the information in the manner specified in the email. If for any
            reason, you decide that we may not use your personal information in
            the way stated in the email, then we will continue to use your
            information as before.
         </p>
         <h3>{{ 'terms_and_conditions.importance_of_privacy' | translate }}</h3>
         <p>
            Our goal is to become your partner in developing quality and
            integrity in education, building a modern and robust foundation for
            educating current and future generations. Crossplag L.L.C empowers
            educators, instructors, writers, and students to maintain academic
            integrity for the benefit of improving teaching methods, process,
            and level. Since your concerns are also our concerns, we promise
            that we will do our best to protect your personal information and
            respect our privacy policies.
         </p>

         <div class="signature">
            <p class="bold">Crossplag L.L.C</p>
            <p class="bold">
               Rr. Rexhep Mala Nr. 28A Prishtina, Republic of Kosovo
            </p>
            <p class="bold">support@crossplag.com</p>
            <p class="bold">+38344112596</p>
         </div>
      </div>
   </div>
</div>
