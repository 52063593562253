<div class="edit-subject-modal">
   <div class="edit-subject-title">
      <h4>{{ 'app.edit.edit_subjects' | translate }}</h4>
      <button mat-dialog-close (click)="closeModal()"><img src="../../../../assets/report_v2/close_big.svg" alt=""></button>
   </div>
   <form [formGroup]="subjectEdit">
      <div class="editimi-lendes-inputs">
         <div class="fieldContainer">
            <label for="title">{{ 'general.name' | translate }}</label>
            <input id="title" type="text" formControlName="subjectTitle" />
            <div
               *ngIf="
                  subjectEdit.controls.subjectTitle.touched &&
                  subjectEdit.controls.subjectTitle.invalid
               "
               class="invalid"
            >
               {{ 'general.subject_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <div
               *ngIf="subjectEdit.controls.subjectTitle.errors?.minlength"
               class="invalid"
            >
               {{ 'general.subject_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
            </div>
            <!-- <label for="prof">{{ 'general.educators' | translate }}</label>
            <ngx-select-dropdown
               [config]="this.config"
               [options]="this.professors"
               [multiple]="true"
               [formControl]="form['subjectProfessors']"
            ></ngx-select-dropdown>
            <div
               *ngIf="
                  subjectEdit.controls.subjectProfessors.touched &&
                  subjectEdit.controls.subjectProfessors.invalid
               "
               class="invalid"
            >
               {{ 'general.subjects' | translate }} {{ 'general.educators' | translate }} are required*
            </div> -->

            <!-- <label for="title">{{ 'app.institution' | translate }}</label>
            <select
               formControlName="subjectFaculty"
               (change)="facultyChanged()"
            >
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}" selected>
                     {{ faculty.name }}
                  </option>
               </ng-container>
            </select> -->
            <!-- <div
               *ngIf="
                  subjectEdit.controls.subjectFaculty.touched &&
                  subjectEdit.controls.subjectFaculty.invalid
               "
               class="invalid"
            >
               {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <label for="title">{{ 'general.department' | translate }}</label>
            <select formControlName="subjectDepartment">
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}" selected>
                     {{ department.name }}
                  </option>
               </ng-container>
            </select> -->
            <!-- <div
               *ngIf="
                  subjectEdit.controls.subjectDepartment.touched &&
                  subjectEdit.controls.subjectDepartment.invalid
               "
               class="invalid"
            >
               {{ 'general.department' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div> -->
            <!-- <label for="title">{{ 'general.type' | translate }}</label>
            <select formControlName="subjectType">
               <option value="1">{{ 'app.subject.obligatory' | translate }}</option>
               <option value="2">{{ 'general.elective' | translate }}</option>
            </select>
            <div
               *ngIf="
                  subjectEdit.controls.subjectType.touched &&
                  subjectEdit.controls.subjectType.invalid
               "
               class="invalid"
            >
               {{ 'general.type' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div> -->
            <label for="prof">{{ 'general.description' | translate }}</label>
            <input id="prof" type="text" formControlName="subjectDescription" />
            <div
               *ngIf="
                  subjectEdit.controls.subjectDescription.touched &&
                  subjectEdit.controls.subjectDescription.invalid
               "
               class="invalid"
            >
               {{ 'general.description' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <!-- <label for="title">{{ 'app.study_year' | translate }}</label>
            <select formControlName="subjectYear">
               <option value="-1" selected disabled>{{ 'app.choose_year' | translate }}!</option>
               <option value="1">1</option>
               <option value="2">2</option>
               <option value="3">3</option>
               <option value="4">4</option>
               <option value="5">5</option>
            </select> -->
            <div
               *ngIf="
                  subjectEdit.controls.subjectYear.touched &&
                  subjectEdit.controls.subjectYear.invalid
               "
               class="invalid"
            >
               {{ 'app.study_year' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <label for="title">{{ 'general.academic_year' | translate }}</label>
            <select
               class="border white-bg select-adm"
               formControlName="subjectGeneration"
               [ngClass]="{
                  changeGeneration: this.generationAlert === true
               }"
            >
               <option value="-1" selected disabled>{{ 'app.choose_generation' | translate }}!</option>
               <option *ngFor="let yearOption of years" [value]="'g' + (yearOption % 100) + (yearOption % 100 + 1)">{{ yearOption }}/{{ yearOption + 1 }}</option>
               <!-- <option value="g1819">2018/2019</option>
               <option value="g1920">2019/2020</option>
               <option value="g2021">2020/2021</option>
               <option value="g2122">2021/2022</option>
               <option value="g2223">2022/2023</option>
               <option value="g2324">2023/2024</option>
               <option value="g2425">2024/2025</option>
               <option value="g2526">2025/2026</option> -->
            </select>
            <ng-container *ngIf="this.generationAlert">
               <span style="color: red !important">
                  To duplicate the subject please change the generation.
               </span>
            </ng-container>
            <div
               *ngIf="
                  subjectEdit.controls.subjectGeneration.touched &&
                  subjectEdit.controls.subjectGeneration.invalid
               "
               class="invalid"
            >
               {{ 'general.academic_year' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>

            <label for="title">{{ 'general.status' | translate }}</label>
            <select
               class="border white-bg select-adm"
               formControlName="subjectStatus"
            >
               <option value="0">{{ 'general.deactive' | translate }}</option>
               <option value="1">{{ 'general.active' | translate }}</option>
            </select>
         </div>
         <div class="editimi-lendes-links">
            <button mat-dialog-close (click)="closeModal()" class="secondary-button">{{ 'report.cancel' | translate }}</button>
            <button
               class="button-primary"
               [disabled]="subjectEdit.invalid"
               [ngClass]="{
                  disable: subjectEdit.invalid
               }"
               type="submit"
               (click)="update()"
            >
               {{ 'general.save' | translate }}
            </button>
            <!-- <a class="dupliko-lendet" (click)="duplicate()">{{ 'app.duplicate' | translate }}</a> -->
         </div>
      </div>
   </form>
</div>
<div id="overlay"></div>
