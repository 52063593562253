import { element } from 'protractor';
import { Component, OnDestroy, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Source } from '../../../models/source';
import { DocumentData } from '../../../services/document-data.service';
import { Subscription, of } from 'rxjs';
import Mark from 'mark.js';
// import {HighlightActions} from '../../shared/highlight_actions/highlight_actions.component';
// import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { ReportService } from "../../../services/report.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportComponent } from '../../../report.component';
// import {SpinnerService} from "../../../services/spinner.service";
// import { ManipulationActions } from '../../shared/manipulation_actions/manipulation_actions.component';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  // providers: [DialogService]
})
export class DocumentComponent implements OnInit, OnDestroy, OnChanges {
  title = 'document'
  @Input() pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
  tempPdfSrc: any;
  @Input() summaryDetails: any;
  @Input() pdfImagesSrc: any;
  @Input() allSources: {
    sources?: [] | undefined,
    excludedSources?: [] | undefined
  } = {
      sources: [],
      excludedSources: [],
    }
  selectedSources: number[] = []
  @Input() sentencesWithAI!: [];
  @Input() isOriginalLanguage: any;
  selectedPage: number = 1;
  page = 1;
  loadedPagesId: any[] = [];
  loadedPages: any[] = [];
  pagesToDraw: any[] = [];
  pagesToDestroy: any[] = [];
  numberOfDifference = 2;
  oldPage = 1;
  notFound: any
  displayAiText = false;
  displayManipulations = false;
  sources: any;
  activePlagSources: any;
  pdfLoadedPages = [];
  documentPages: any;
  totalPages!: number;
  currentPageSubscription: Subscription[] = []
  markInstance: any
  crossPlagIndex: any
  sourceId: any;
  sentenceId: any;
  // ref: DynamicDialogRef | undefined;
  highlightQuotes = true
  analysisType: string = 'none';
  totalSentencesWithAI!: number;
  @Input() selectedManipulation: any;
  @Input() isOnManipulation: any;
  @Input() sentenceDetails: any;
  @Input() allManipulations: any;
  @Input() submissionId: any;
  @Input() context_similarity: any;
  @Input() submission: any;
  @Input() exactMatchThreshold: number;
  @Input() contextSimilarityThreshold:number;
  currentlyHighlightedElement = [];
  zoom = 1;
  selectedTransltaionCode

  // Make  it a  html collection

    @ViewChild('connectionLines', { static: true }) connectionLines!: ElementRef<SVGElement>;
    @ViewChild('viewer', { static: true }) viewer!: any;

  constructor(
    public documentDataService: DocumentData,
    //  private dialogService: DialogService,
    private reportService: ReportService,
     private spinnerService:NgxSpinnerService,
     private reportComponent: ReportComponent,
     private renderer: Renderer2
  ) {
    this.currentPageSubscription.push(this.documentDataService.getCurrentPage().subscribe(page => {
      this.scrollPage(page);
      // this.page = page;
    }))
  }

  ngOnInit(): void {

    this.reportService.selectedTransltaionCodeObject.subscribe((data)=>{
      if (data !== null && data.length !== 0){
        this.selectedTransltaionCode = data
      }
    })

    this.spinnerService.show()
    this.documentDataService.totalPagesSubject.next(this.totalPages)
    this.reportService.toggleQuotesObject.subscribe((data: boolean) => {
      this.highlightQuotes = data
      this.removeHighlight()
      this.callHighlight(this.page)
    })

    this.reportService.selectedSourcesObject.subscribe((data: number[]) => {
      this.selectedSources = data
      this.removeHighlight()
      this.callHighlight(this.page)
    })

    this.reportService.selectAnalysisTypeObject.subscribe((data: string) => {
      this.removeHighlight();
      this.analysisType = data;
      setTimeout(() => {
        if (this.analysisType == 'ai') {
          this.displayAiText = true;
          this.displayManipulations = false;
        } else if (this.analysisType == 'manipulation') {
          this.displayAiText = false;
          this.displayManipulations = true;
          this.handleManipulations();
        } else if (this.analysisType == 'analysis') {
          this.displayAiText = false;
          this.displayManipulations = false;
        }
        this.removeHighlight();
        this.callHighlight(this.page)
      }, 500);

      // if (this.analysisType == 'none') {
      // this.removeHighlight();
      // } else {
      // }
    })
    // this.documentDataService.setCurrentPage(1);
  }

  ngAfterViewInit() {
    const pdfContainer = this.viewer.element.nativeElement.querySelector('.ng2-pdf-viewer-container');

    if (pdfContainer) {
      this.renderer.setAttribute(pdfContainer, 'tabindex', '0');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['pdfSrc'] && changes['pdfSrc'].currentValue !== changes['pdfSrc'].previousValue) {
      this.pdfSrc = changes['pdfSrc'].currentValue
      this.tempPdfSrc = changes['pdfSrc'].currentValue
    }
    if (changes && changes['imageDetectionURL'] && changes['imageDetectionURL'].currentValue !== changes['imageDetectionURL'].previousValue) {
      this.pdfImagesSrc = changes['imageDetectionURL'].currentValue
    }
    if (changes && changes['allSources'] && changes['allSources'].currentValue !== changes['allSources'].previousValue) {
      this.activePlagSources = this.allSources.sources
      this.removeHighlight()
      this.callHighlight(this.page)

    }

    if (changes && changes['selectedManipulation'] && changes['selectedManipulation'].currentValue !== changes['selectedManipulation'].previousValue) {
      if(this.analysisType == 'manipulation'){
        this.removeHighlight()
        this.handleManipulations();
      }
    }
    if (changes && changes['isOnManipulation'] && changes['isOnManipulation'].currentValue !== changes['isOnManipulation'].previousValue) {
      this.removeHighlight()
      if (!this.isOnManipulation) {
        this.pdfSrc = this.tempPdfSrc;
      }
    }

    if (changes && changes['summaryDetails'] && changes['summaryDetails'].currentValue !== changes['summaryDetails'].previousValue) {
      this.removeHighlight()
      this.callHighlight(this.page)
    }
  }

  removeHighlight() {
    let viewer = document.getElementById('viewer') as HTMLElement
    this.markInstance = new Mark(viewer);
    this.markInstance.unmark();
    document.querySelectorAll('.markQuote').forEach((item) => {
      item.remove();
    });
  }

  onPdfLoad(pdf: PDFDocumentProxy): void {
    this.totalPages = pdf.numPages;
    this.documentDataService.setTotalPages(this.totalPages)
    this.spinnerService.hide()

  }


  pageChanging(e: any) {
    this.page = e.pageNumber;
    this.documentDataService.setCurrentScrollingPage(this.page);
    // this part is commented because sometimes page 1 and 2 didn't get highlighted.
    // this.calculateNextPage(this.page);

    // if (this.analysisType !== 'none') {
    this.callHighlight(this.page)
    // }
  }

  calculateNextPage(currentPage: any) {
    this.documentDataService.setCurrentScrollingPage(this.page);
    if (this.loadedPagesId.length === 0) {
      return;
    }

    this.pagesToDraw = [];
    this.pagesToDestroy = [];

    const { numberOfDifference, loadedPages, loadedPagesId } = this;
    const lowest = currentPage - numberOfDifference;
    const highest = currentPage + numberOfDifference;

    for (const pageId of loadedPagesId) {
      if (pageId <= highest && pageId >= lowest) {
        this.pagesToDraw.push(pageId);
      } else {
        this.pagesToDestroy.push(pageId);
      }
    }

    for (const pageToDestroy of this.pagesToDestroy) {
      const index = this.loadedPages.findIndex((page) => (page as any).id === pageToDestroy);

      if (index !== -1) {
        (this.loadedPages[index] as any).destroy();
        this.loadedPages.splice(index, 1);
      }
    }

    if (this.loadedPagesId.length >= 6) {
      const destroyIndex = this.loadedPagesId.indexOf(this.pagesToDestroy[0]);

      if (destroyIndex !== -1) {
        this.loadedPagesId.splice(destroyIndex, 1);
        this.pagesToDestroy.splice(0, 1);
      }
    }

    // if (this.analysisType != 'none') {
    setTimeout(() => {
      if (this.oldPage > currentPage && currentPage - 1 <= 1) {
        this.callHighlight(currentPage - 1);
      } else {
        this.callHighlight(currentPage);
      }

      this.oldPage = currentPage;

    }, 200);
    // }
  }

  // Add this method to handle the completion of page change initiated by scrolling
  // handleScrollingPageChangeComplete() {
  //   // Check if the page change is not initiated by a navigation button click
  //   if (!this.documentDataService.getNavigationClickedSubject()) {
  //     this.documentDataService.setCurrentPage(this.page);
  //   }
  // }

  callHighlight(currentPage: any) {
    this.notFound = [];
    if (this.analysisType != 'none') {
      if (!this.displayAiText && !this.isOnManipulation) {
        // console.log(this.activePlagSources, 'activePlagSources');

        const arrayToHighlight = this.activePlagSources;
        if (arrayToHighlight) {
          for (let source of arrayToHighlight) {
            for (let sentence of source.sentences) {
              const shouldHighlight = currentPage === sentence.page &&
                (this.highlightQuotes && sentence.isCitation || sentence.isCitation === null);
              const isSelectedOrNoSelection = this.selectedSources.length === 0 || this.selectedSources.includes(source.no);

              if (shouldHighlight && isSelectedOrNoSelection) {
                this.doHighlight(sentence.text, sentence, this.notFound, source);
              }
            }
          }
        }
      } else if (this.isOnManipulation) {
        const arrayToHighlight = this.sentenceDetails;

        for (let sentence of arrayToHighlight) {
          const shouldHighlight = currentPage === sentence.page;

          if (this.selectedManipulation == 'isImage') {
            return;
          } else if (this.selectedManipulation === 'charReplacement') {
            if (shouldHighlight && sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {
              sentence.replacedCharacters.forEach((char: any) => {
                this.doHighlight(char, sentence, this.notFound, null);
              });
            }
          } else if (this.selectedManipulation === 'hiddenText') {
            if (shouldHighlight && sentence.characters.length > 0) {
              this.doHighlight(sentence.text, sentence, this.notFound, []);
            }
          }
        }

      }
      else {
        this.aiHighlights();
      }
    } else {
      if (this.summaryDetails) {
        if (!Array.isArray(this.summaryDetails)) {
          this.reportComponent.getTransformData();
        }

        const arrayToHighlight = this.summaryDetails;
        if (arrayToHighlight && Array.isArray(arrayToHighlight)) {
          for (let sentence of arrayToHighlight) {
            const shouldHighlight = currentPage === sentence.page;

            if (shouldHighlight) {
              this.doHighlight(sentence.text, sentence, this.notFound, []);
            }
          }
        }
      }
    }

    if (this.notFound.length !== 0) {
      this.getTextFromPages();
    }
  }

  setNodeBackground(node, data) {
    let correctData = data;

    if(!this.isOriginalLanguage){
      // Check if translated findings exist and select the appropriate translation based on language
      if (correctData.translatedFindings && correctData.translatedFindings[this.selectedTransltaionCode]) {
        correctData = correctData.translatedFindings[this.selectedTransltaionCode];
      } else if (correctData.translatedFindings && Object.keys(correctData.translatedFindings).length > 0) {
        correctData = correctData.translatedFindings[Object.keys(correctData.translatedFindings)[0]];
      }
    }
    const similarity = this.isOriginalLanguage?  correctData.sourceSentencePercentage : correctData.primarySource.percentage;
    const proximity = correctData.primarySource?.averageDistance/100 || 0;
    const contextSimilarity = parseFloat(correctData.primarySource?.contextSimilarityPercentage) || 0;
    // Set background colors based on the conditions
    if (this.isOriginalLanguage ? ( data.isExcluded || data.primarySource.isExcluded ) : correctData.isTranslationExcluded) {
      node.style.background = 'rgb(128, 128, 128, 0.45)'; // Gray color for Excluded
    } else if (data.isCitation) {
      node.style.background = 'rgba(108, 247, 189, 0.45)'; // Green color for Citation
    } else if (similarity === 100 || (similarity >= 80 && proximity < 2.00)) {
      node.style.background = 'rgb(240, 78, 103, 0.45)'; // Red color for Exact Match
    } else if ((similarity >= 60 && similarity < 80 && proximity < 2.00) ||
               (similarity >= 60 && proximity >= 2.00 && proximity <= 3.00 && contextSimilarity > this.contextSimilarityThreshold)) {
      node.style.background = 'rgb(0, 207, 255, 0.45)'; // Blue color for Altered text
    } else if (similarity < 60 && contextSimilarity > this.contextSimilarityThreshold) {
      node.style.background = 'rgb(255, 199, 88, 0.45)'; // Yellow color for Context similarity
    }

    return node
  }



  doHighlight(stringToHighlight: any, objToHighlight: any, notFound: any, source: any, identicalSentence?: any) {
    let mark = 0;
    let viewer = document.getElementById(`viewer`);
    const replacedCharsToHighlight = this.allManipulations?.replacedCharacters?.map((char: any) => char.character);
    this.markInstance = new Mark(viewer as any);

    if (this.page === objToHighlight.page) {
      this.markInstance.mark(stringToHighlight, {
        acrossElements: true,
        separateWordSearch: false,
        limit: 1,
        exclude: ['.canvasWrapper', '[data-markjs="true"]'],
        each: (element: any) => {
          if (mark == 0) {
            let elementRect = element.getBoundingClientRect();
            let viewerRect = viewer.getBoundingClientRect();

            setTimeout(() => {
              let highlightedElements = document.querySelectorAll(`mark[sentence-id="${objToHighlight.id}"]`);
              if (highlightedElements.length > 0) {
                let topValues = Array.from(highlightedElements).map((el: any) => {
                  let spanElement = el.parentElement;
                  let styleNode = spanElement.attributes.style.nodeValue;
                  let topValueMatch = styleNode.match(/top:\s*([\d.]+)px/);
                  let topValue = topValueMatch ? parseFloat(topValueMatch[1]) : 0;
                  return topValue;
                });

                let minTopValue;

                if (topValues.length > 1) {
                  // Calculate the difference between each pair of values
                  let differences = topValues.map((value, index, array) => {
                    if (index > 0) {
                      return Math.abs(value - array[index - 1]);
                    } else {
                      return 0;
                    }
                  });

                  // Calculate the average of differences
                  let averageDifference = differences.reduce((a, b) => a + b, 0) / (differences.length - 1);

                  // Check if the average difference is greater than the threshold (300)
                  if (averageDifference > 300) {
                    minTopValue = Math.max(...topValues);
                  } else {
                    minTopValue = Math.min(...topValues);
                  }
                } else {
                  minTopValue = Math.min(...topValues);
                }
                var node = document.createElement('SPAN');
                node.classList.add('markQuote');

                if (this.analysisType === 'none'){
                let isLeftSide = elementRect.left + elementRect.width / 2 < viewerRect.left + viewerRect.width / 2;
                if (isLeftSide) {
                  node.classList.add('left');
                  node.style.left = '15px';
                } else {
                  node.classList.add('right');
                  node.style.right = '45px';
                }

                  node.style.top = `${minTopValue}px`;
                }
                node.style.position = 'absolute';

                let overlapping = false;
                document.querySelectorAll('.markQuote.on_summary').forEach((existingNode: any) => {
                    let existingRect = existingNode.getBoundingClientRect();
                    if (node.getBoundingClientRect().top < existingRect.bottom && node.getBoundingClientRect().bottom > existingRect.top) {
                        overlapping = true;
                    }
                });

                if (overlapping) {
                    node.style.top = `${minTopValue + 20}px`;
                }


            if (this.analysisType == 'none') {
              $(node).addClass('on_summary');
              element.style.position = 'relative';
              element.style.zIndex = '10';

              let section = document.createElement('section');
              section.classList.add('summaryHighlight-btns');
              $(section).attr('data-sentence-id', objToHighlight.id);

              section.addEventListener('click', () => {
                const elementWithId = document.querySelector(`[sentence-id="${objToHighlight.id}"]`) as HTMLElement;
                this.activateButton(elementWithId, objToHighlight)
              })

              // Create svg element
              let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
              svg.classList.add('connection-line');
              section.appendChild(svg);

              let  insideSection = document.createElement('div');
              insideSection.classList.add('summary-section');
              section.appendChild(insideSection);

              for (let i  = 0; i <= 3; i++) {
                let summary_ui = document.createElement('div');
                summary_ui.classList.add('summary-ui');

                insideSection.appendChild(summary_ui);
              }

              if (objToHighlight.isOriginal || objToHighlight.isTranslated || objToHighlight.isAi || objToHighlight.isManipulated.isManipulated) {
                if (objToHighlight.isOriginal && !objToHighlight.isExcluded && !objToHighlight.source.isExcluded) {
                      insideSection.children[0].classList.add('filled');
                    }

                    if (objToHighlight.isTranslated && objToHighlight.translated.every((element: any) => element.isExcluded === false)) {
                      insideSection.children[1].classList.add('filled');
                    }

                    if (objToHighlight.isAi) {
                      insideSection.children[2].classList.add('filled');
                    }

                    if (objToHighlight.isManipulated.isManipulated && (objToHighlight.manipulations[0]?.includedCharacters?.length > 0 || objToHighlight?.manipulations[0]?.excludedReplacedCharacters?.length > 0 || !objToHighlight?.manipulations[1]?.isExcluded)) {
                      insideSection.children[3].classList.add('filled');
                    }

                    if (objToHighlight.isManipulated?.isManipulated && (objToHighlight.manipulations[0]?.includedCharacters?.length > 0 || objToHighlight?.manipulations[0]?.excludedCharacters?.length > 0 || !objToHighlight?.manipulations[1]?.isExcluded)) {
                      insideSection.children[3].classList.add('filled');
                    }


                    var inside_btns = document.createElement('div');
                    inside_btns.classList.add('inside_btns');
                    section.appendChild(inside_btns);

                    node.appendChild(section);
                    element?.parentElement?.parentElement?.appendChild(node);

                    // Connect the SVG line from the span element to the last highlighted element
                    let firstHighlightedElement = highlightedElements[0];
                    let lastHighlightedElement = highlightedElements[highlightedElements.length - 1];
                    let line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                    let spanRect = node.getBoundingClientRect();
                    let highlightRect = lastHighlightedElement.getBoundingClientRect();

                    line.setAttribute('x1', `${spanRect.left + spanRect.width / 2}`);
                    line.setAttribute('y1', `${spanRect.top + spanRect.height / 2}`);
                    line.setAttribute('x2', `${highlightRect.left + highlightRect.width / 2}`);
                    line.setAttribute('y2', `${highlightRect.top + highlightRect.height / 2}`);

                    line.setAttribute('stroke', 'black');
                    line.setAttribute('stroke-width', '2');
                    svg.appendChild(line);
                  }
                } else {
                  if (this.isOnManipulation) {
                    if (this.selectedManipulation === 'hiddenText') {
                      let height = element.offsetHeight;
                      if (height < 10) {
                        var textnode = document.createTextNode('Small Hidden Text');
                        node.appendChild(textnode);
                        node.classList.add('smallHiddenText');
                        element.appendChild(node);
                      }
                    }
                  } else {
                    // if (source.isExcluded) {
                    //   node.style.background = 'rgb(128, 128, 128, 0.9)';
                    // } else {
                    //   if (objToHighlight.isExcluded == true) {
                    //     node.style.background = 'rgb(128, 128, 128, 0.9)';
                    //   } else {
                    //     if (objToHighlight.isCitation) {
                    //       node.style.background = 'rgb(102, 255, 102, 0.9)';
                    //       node.style.color = 'black';
                    //     } else {
                    //       if (objToHighlight.sourceSentencePercentage > this.exactMatchThreshold) {
                    //         node.style.background = 'rgb(240, 78, 103, 0.9)';
                    //       } else {
                    //         node.style.background = 'rgb(0, 207, 255, 0.9)';
                    //       }
                    //     }
                    //   }
                    // }
                    node = this.setNodeBackground(node,objToHighlight)

                    if (
                      // element.innerHTML.trim().length &&
                      !this.isOriginalLanguage || source.isSentenceExcluded === false &&
                      (!source.hide || source.hide === false)
                    ) {
                      var textnode = document.createTextNode(source.no);
                      node.appendChild(textnode);
                      element.appendChild(node);
                    }
                  }
                }
              }
            }, 0); // Immediate timeout to ensure DOM elements are present
          }
          mark = 1;
          element.style.color = 'transparent';
          element.style.position = 'relative';

          if (this.analysisType == 'none') {
            const shouldMarkGray = (() => {
              // Case 3: If AI is true, sentence should not be marked gray
              if (objToHighlight.isAi) return false;

              // Check if the original is excluded
              const isOriginalExcluded = objToHighlight.isOriginal && (objToHighlight.isExcluded || objToHighlight.source.isExcluded);

              // Check if there are any translated findings
              const hasTranslatedFindings = Array.isArray(objToHighlight.translated) && objToHighlight.translated.length > 0;

              // Check if all translated findings are excluded
              const areAllTranslationsExcluded = objToHighlight.translated?.every((trans: any) => (trans.isExcluded || trans.source.isExcluded));

              // Check if all manipulations are excluded
              const areAllManipulationsExcluded = Array.isArray(objToHighlight.manipulations) &&
                objToHighlight.manipulations.every((manip: any) => manip.isExcluded);

              // Case 1: Original is excluded, no translations, no manipulations
              if (isOriginalExcluded && !hasTranslatedFindings && !objToHighlight.isManipulated?.isManipulated) {
                return true;
              }

              // Case 2: Original is excluded, has translations, and all translations are excluded
              if (isOriginalExcluded && hasTranslatedFindings && areAllTranslationsExcluded && !objToHighlight.isManipulated?.isManipulated) {
                return true;
              }

              // Final Case: If original is excluded and all translations and manipulations are excluded, mark gray
              if (isOriginalExcluded && areAllManipulationsExcluded && (!hasTranslatedFindings || areAllTranslationsExcluded)) {
                return true;
              }
              // Default to false if none of the conditions are met
              return false;
            })();

            $(element).attr('sentence-id', objToHighlight.id);
            $(element).addClass('sentence-' + objToHighlight.id);
            if(shouldMarkGray){
              element.style.background = 'rgba(128, 128, 128, 0.45)';
              $(element).attr('excluded', 'true');
            } else{
              if (objToHighlight.isOriginal || objToHighlight.isTranslated || objToHighlight.isAi || objToHighlight.isManipulated.isManipulated) {
                element.style.background = 'rgba(255, 45, 45, 0.25)';
              }

              if (!objToHighlight.isOriginal && !objToHighlight.isTranslated && (!objToHighlight.isAi || objToHighlight.isAi == undefined) && !objToHighlight.isManipulated.isManipulated) {
                $(element).attr('excluded', 'true');
                element.style.background = 'rgba(128, 128, 128, 0.45)';
              }
            }


            $(element).on('click', (e) => {
              this.activateButton(element, objToHighlight)
            });
          } else {
            if (this.isOnManipulation) {
              if (this.selectedManipulation === 'charReplacement') {
                if (replacedCharsToHighlight.some((char: any) => stringToHighlight.includes(char))) {
                  element.style.background = 'rgba(255, 81, 81, 0.45)';
                } else {
                  element.style.background = 'rgba(128, 128, 128, 0.45)';
                }
                element.style.cursor = 'default';
              } else if (this.selectedManipulation === 'hiddenText') {
                if (objToHighlight.characters.length > 0) {
                  if (!objToHighlight.whitecharactersExcluded) {
                    element.style.background = 'rgba(255, 208, 0, 0.45)';
                  } else {
                    element.style.background = 'rgba(128, 128, 128, 0.45)';
                  }
                  element.style.cursor = 'pointer';
                  $(element).on('click', (e) => {
                    // this.openManipulationsModal(objToHighlight);
                    this.openSentenceInformation(objToHighlight, 'hiddenText');
                  });
                }
              }
            }
            else {
              if (this.displayAiText) {
                element.style.background = 'rgba(163, 137, 228, 0.45)';
                if (objToHighlight.model_1) {
                  element.style.background = 'rgba(163, 137, 228, 0.45)';
                }
              } else {
                element = this.setNodeBackground(element,objToHighlight)
              }
              $(element).css('cursor', 'pointer');
              $(element).attr('data-source-id', source.sourceId);
              $(element).attr('data-page-number', objToHighlight.page);
              $(element).attr('sentence-id', objToHighlight.id);
              $(element).attr('source-no', source.no);
              $(element).attr('tabindex', '0');
              $(element).attr('aria-label', 'This text is ' + (objToHighlight.isCitation ? 'a quotation' : objToHighlight.isExcluded ? 'excluded' : objToHighlight.sourceSentencePercentage < this.exactMatchThreshold ? ' possibly altered' : 'an exact match') + '. Press enter to open the modal');

              if (!this.displayAiText) {
                $(element).on('click', (e) => {
                  this.sourceId = e.target.attributes['data-source-id'].value;
                  this.sentenceId = e.target.attributes['sentence-id'].value;
                  let sourceNo = e.target.attributes['source-no'].value || 1;
                  let result;
                  this.activePlagSources.forEach((element: any) => {
                    element.sentences.forEach((sentence: any) => {
                      if (sentence.id == this.sentenceId) {
                        result = sentence;
                      }
                    });
                  });
                  this.openModal(result, sourceNo);
                });
              }
              if (!this.displayAiText){
                element.addEventListener('click', (e) => {
                  this.openSentenceInformation(objToHighlight, 'indeepth');
                });
              }
            }
          }
        },
        filter: function (txtNode: any, string: string, s: any, b: any) {
          return true;
        },
        noMatch: function (range: any) {
          notFound.push({ objToHighlight, source });
        },
      });
    }
  }

  activateButton(element: HTMLElement, objToHighlight: any) {
    const currentElement = $(element);
    const sameElements = document.getElementsByClassName('sentence-' + objToHighlight.id);

    const allBtns = document.querySelectorAll('.summaryHighlight-btns');

    allBtns.forEach((btn: any) => {
      btn.classList.remove('active');
    });

    const btn = document.querySelector(`[sentence-id="${objToHighlight.id}"]`);

    if (this.currentlyHighlightedElement.includes(element)) {
      Array.from(sameElements).forEach((el: any) => {
        let isExcluded = el.hasAttribute('excluded');
        if (isExcluded) {
          el.style.background = 'rgba(128, 128, 128, 0.45)';
        } else {
          el.style.background = 'rgba(255, 45, 45, 0.25)';
        }
      });

      this.reportService.sentenceInformation(null, '');
      this.currentlyHighlightedElement = [];
      btn?.classList.remove('active');
    } else {
      if (this.currentlyHighlightedElement.length > 0) {
        const previouslyHighlightedClass = this.currentlyHighlightedElement[0].className;
        const previouslyHighlightedElements = document.getElementsByClassName(previouslyHighlightedClass);
        Array.from(previouslyHighlightedElements).forEach((el: any) => {
        let isExcluded = el.hasAttribute('excluded');
        if (isExcluded) {
          el.style.background = 'rgba(128, 128, 128, 0.45)';
        } else {
          el.style.background = 'rgba(255, 45, 45, 0.25)';
        }
        });
      }

      Array.from(sameElements).forEach((el: HTMLElement) => {
        let isExcluded = el.hasAttribute('excluded');
        if (isExcluded) {
          el.style.background = 'rgba(128, 128, 128, 0.65)';
        } else {
          el.style.background = 'rgba(255, 45, 45, 0.50)';
        }
      });
      // btn?.classList.add('active');
      this.reportService.sentenceInformation(objToHighlight, '');
      this.currentlyHighlightedElement = [currentElement[0]];
      // this.drawConnectionLine(btn as HTMLElement, element as HTMLElement);
    }
  }

  drawConnectionLine(button: HTMLElement, sentence: HTMLElement) {
    const buttonRect = button.getBoundingClientRect();
    const sentenceRect = sentence.getBoundingClientRect();

    const highlightedSentence = document.querySelector('.sentence-' + button.getAttribute('data-sentence-id')) as HTMLElement;

    if (highlightedSentence) {
      const sentenceRect = highlightedSentence.getBoundingClientRect();
      let viewer = document.getElementById(`viewer`);
      const viewerRect = viewer?.getBoundingClientRect();

      const svg = button.querySelector('.connection-line') as HTMLElement;
      while (svg.firstChild) {
        svg.removeChild(svg.firstChild);
      }
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');

      // ${elementRect.top - viewerRect.top + 15}px`
      // if button is  on the left side of the sentence
      if (buttonRect.left + buttonRect.width / 2 < sentenceRect.left + sentenceRect.width / 2) {
        line.setAttribute('x1', '30px');
        line.setAttribute('y1', `15px`);
        line.setAttribute('x2', `${sentenceRect.left - buttonRect.left}`);
        line.setAttribute('y2', `${buttonRect.top - sentenceRect.top}`);
        // line.classList.add('line-connector');
        // svg.appendChild(line);
      } else {
        line.setAttribute('x1', '0px');
        line.setAttribute('y1', '0px');
        line.setAttribute('x2', `${buttonRect.left - (sentenceRect.left + sentenceRect.width)}`);
        line.setAttribute('y2', '0px');
      }

      line.classList.add('line-connector');
      svg.appendChild(line);
    }
  }

  openModal(result: any, sourceNo: number) {
    // this.ref = this.dialogService.open(HighlightActions, {
    //   maximizable: true,
    //   keepInViewport: true,
    //   draggable: true,
    //   header: result.isCitation ? 'This is a quotation' : result.isExcluded ? 'This is a removed highlight' : result.sourceSentencePercentage < 84.5 ? 'This is Altered text' : 'This is an exact match',
    //   styleClass: 'modal',
    //   dismissableMask: true,
    //   data: {
    //     result,
    //     isOriginalLanguage: this.isOriginalLanguage,
    //     highlight_type: result.isCitation ? 'quotation' : result.isExcluded ? 'removed' : result.sourceSentencePercentage < 84.5 ? 'possibly_altered' : 'exact_match',
    //     sourceNo
    //   }
    // });
  }

  openManipulationsModal(result: any) {
    // this.ref = this.dialogService.open(ManipulationActions, {
    //   maximizable: true,
    //   keepInViewport: true,
    //   draggable: true,
    //   header: 'Hidden Text',
    //   styleClass: 'modal',
    //   dismissableMask: true,
    //   data: {
    //     result,
    //     submissionId: this.submissionId
    //   }
    // });
  }

  aiHighlights() {
    this.notFound = [];
    this.totalSentencesWithAI = 0;

    this.sentencesWithAI.forEach((element: any) => {
      if (element.aiText) {
        this.totalSentencesWithAI++;
        this.doHighlight(element.text, element, this.notFound, [])
      }
    })
  }

  handleManipulations() {
    this.removeHighlight()
    if (this.selectedManipulation === 'charReplacement') {
      this.pdfSrc = this.tempPdfSrc;
      // this.spinnerService.show();
      this.highlightCharacterReplacements();
    } else if (this.selectedManipulation === 'hiddenText') {
      this.pdfSrc = this.tempPdfSrc;
      this.highlightWhiteCharacters();
    } else if (this.selectedManipulation === 'isImage') {
      this.highlightImages();
    }

  }

  openSentenceInformation(sentence: any, stage: string) {
    if (stage === 'summary') {
      this.reportService.sentenceInformation(sentence, '');
    } else {
      let isTranslated: boolean;
      let translated = [];


      if (sentence.translatedFindings) {
        translated = Object.keys(sentence.translatedFindings).map((lang: any) => {
          const finding = sentence?.translatedFindings[lang];
          const allTranslatedPercentages = this.submission.translatedPercentages;
          return {
            lang,
            percentage: allTranslatedPercentages !== undefined ? (allTranslatedPercentages[lang].percentageWithoutQuotes ? allTranslatedPercentages[lang].percentageWithoutQuotes : allTranslatedPercentages[lang].percentage) : finding?.percentage,
            sentence: finding?.primarySource?.sourceSentence,
            source: finding?.primarySource,
            isExcluded: finding?.isTranslationExcluded,
            comment: finding?.translateComment,
            secondarySources: finding?.secondarySources,
            text: sentence?.translatedFindings[lang].translatedText
          }
        });
        isTranslated = translated.some((item: any) => item.source);
      } else {
        const primaryTranslatedSource = sentence?.primaryTranslatedSource;
        translated = sentence?.translatedText
          ?
          [{
            lang: this.submission?.translatedLanguage,
            percentage: primaryTranslatedSource?.percentage,
            sentence: primaryTranslatedSource?.sourceSentence,
            source: primaryTranslatedSource,
            isExcluded: sentence?.isTranslationExcluded,
            comment: sentence?.translateComment,
            secondarySources: sentence?.secondarySources,
            text: sentence?.translatedText
          }]
          :
          [];
        isTranslated = !!sentence?.translatedText;
      }

      function getManipulations(sentence: any) {

        let temp_manipulations = [];

        // For char replacements
        if (sentence?.replacedCharacters && sentence?.replacedCharacters.length > 0) {
          temp_manipulations.push({
            includedCharacters: sentence?.replacedCharacters,
            excludedReplacedCharacters: []
          });
        } else {
          temp_manipulations.push({
            includedCharacters: [],
            excludedReplacedCharacters: []
          });
        }

        // For hidden text (white characters)
        if (sentence?.characters?.length > 0) {
          temp_manipulations.push({
            characters: sentence?.characters,
            isExcluded: sentence?.whitecharactersExcluded
          });
        } else {
          temp_manipulations.push({
            characters: [],
            isExcluded: false
          });
        }

        return temp_manipulations;
      }

      this.summaryDetails = {
        submissionId: this.submissionId,
        text: sentence?.text,
        sentence: sentence?.sourceSentence,
        source: sentence?.primarySource,
        comment: sentence?.comment,
        id: sentence?.id,
        isOriginal: sentence?.sourceSentence || sentence?.primarySource ? true : false,
        isTranslated: isTranslated,
        isManipulated: {
          isManipulated: this.allManipulations?.replacedCharacters && this.allManipulations?.replacedCharacters?.length > 0 || sentence?.characters?.length > 0,
          isCharReplacement: this.allManipulations?.replacedCharacters && this.allManipulations?.replacedCharacters?.length > 0,
          isHiddenText: sentence?.characters?.length > 0
        },
        isExcluded: sentence?.isExcluded,
        isAi: sentence?.aiText?.aiText,
        isCitation: sentence.isCitation,
        similarity: sentence?.sourceSentencePercentage,
        page: sentence?.page,
        secondarySources: sentence?.secondarySources,
        manipulations: getManipulations(sentence),
        translated
      };

      this.reportService.sentenceInformation(this.summaryDetails, stage);
    }
  }


  highlightCharacterReplacements() {
    this.calculateNextPage(1);
    // this.notFound = [];

    // this.sentenceDetails?.forEach((sentence: any) => {
    //     if (sentence.replacedCharacters && sentence.replacedCharacters.length > 0) {
    //       sentence.replacedCharacters.forEach((char: any) => {
    //         this.doHighlight(char, sentence, this.notFound, null);
    //       });
    //     }
    // this.spinnerService.hide();
    // });
  }
  highlightWhiteCharacters() {
    // this.notFound = [];
    this.calculateNextPage(1);
  }
  highlightImages() {
    this.pdfSrc = this.pdfImagesSrc;
    // console.log(this.pdfSrc, 'pdfSrc');

  }

  getTextFromPages() {
    let textForHighlight = [];
    let pageText = $($('[data-loaded="true"]')).text();
    // Use match to find all occurrences of the pattern, and check if the result is not null
    let matchResult = pageText.match(/((?:[A-Z][a-z]\.|\w\.\w.|.)*?(?:[.!?]|$))(?:\s+|$)/g);
    let arrayOfTexts = matchResult ? matchResult.map(string => string.trim()) : [];

    // Remove the last element if arrayOfTexts is not empty
    if (arrayOfTexts.length > 0) {
      arrayOfTexts.pop();
    }
    for (let x in this.notFound) {
      for (let j in arrayOfTexts) {
        let parsedSentences = arrayOfTexts[j];
        let notFoundWithRegEx = this.notFound[
          x
        ].objToHighlight.text.replace(/\s+/g, '');

        let successCounter = 0;
        let startSentence = 0;
        let endSentence = 0;
        let keepChecking = false;
        let found = false;
        let i = 0;
        let textToHighlight = '';

        while (i < parsedSentences.length && !found) {
          if (parsedSentences[i].indexOf(' ') != 0) {
            if (notFoundWithRegEx[successCounter] == parsedSentences[i]) {
              successCounter++;
              if (keepChecking == false) {
                startSentence = i;
                keepChecking = true;
              }
            } else {
              if (successCounter > 0) {
                i = i - 1;
              }
              successCounter = 0;
              keepChecking = false;
            }

            if (successCounter == notFoundWithRegEx.length) {
              endSentence = i;

              textToHighlight = parsedSentences.substring(
                startSentence,
                endSentence + 1
              );

              found = true;
              textForHighlight.push({
                textToHighlight: textToHighlight,
                obj: this.notFound[x].objToHighlight,
                source: this.notFound[x].source,
              });
            }
          }
          i++;
        }
      }
    }
    this.notFound = [];
    for (let x in textForHighlight) {
      this.doHighlight(
        textForHighlight[x].textToHighlight,
        textForHighlight[x].obj,
        this.notFound,
        textForHighlight[x].source
      );
    }
  }


  pageRendered(e: any): void {
    if(e.pageNumber === 1){
      setTimeout(() => {
        this.callHighlight(1)
      }, 1000);
    }
    this.pdfLoadedPages = [];
    this.notFound = [];

    this.documentPages = e.pageNumber;


    const sourceId: any = e.source.id;

    if (this.loadedPages.length === 0 || !this.loadedPages.some(page => (page as any).id === e.source.id)) {
      this.loadedPagesId.push(sourceId);
      this.loadedPages.push(e.source);
    }
    // this.callHighlight(1)

    if (this.notFound.length !== 0) {
      console.log('Not Found');
    }

  }


  scrollPage(page: any) {
    const pageNumber = typeof page === 'string' ? parseInt(page, 10) : page;
    this.selectedPage = pageNumber;
    if (this.selectedPage == pageNumber && pageNumber !== this.page) {
      this.selectedPage = pageNumber - 1;
      setTimeout(() => {
        this.selectedPage = pageNumber;
        this.page = pageNumber
      }, 100);
    }
    setTimeout(() => {
      this.callHighlight(this.selectedPage);
    }, 500);
  }

  // Add this function if needed
  // private highlightPages(): void {
  //   let arrayToHighlight = this.selectedSources ? this.sources : this.activePlagSources;

  //   for (let source of arrayToHighlight) {
  //     for (let sentence of source.sentences) {
  //       this.handleSentenceHighlight(sentence);
  //     }
  //   }
  // }

  // Add this function if needed
  // private handleSentenceHighlight(sentence: any): void {
  //   let j = this.plagiarismLabels.length;

  //   if (sentence.page < j) {
  //     j = sentence.page;
  //   }

  //   let sentenceCopy = { ...sentence };

  //   if (this.shouldHighlightSentence(sentenceCopy)) {
  //     this.doHighlight(sentenceCopy.text, sentenceCopy, this.notFound, source);
  //   }
  // }

  // Add this function if needed
  // private shouldHighlightSentence(sentence: any): boolean {
  //   if (this.highlighQuotes === true) {
  //     return this.loadedPagesId.includes(sentence.page);
  //   } else {
  //     return this.loadedPagesId.includes(sentence.page) && sentence.isCitation === null;
  //   }
  // }

  zoomIn() {
    this.zoom += 0.1;
  }

  zoomOut() {
    this.zoom -= 0.1;
  }


  ngOnDestroy(): void {
    this.currentPageSubscription.forEach((sub) => {
      sub.unsubscribe()
    })
  }
}
