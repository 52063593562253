<div class="modalContainer" [attr.aria-label]="'aria-labels.settings' | translate" role="dialog">

    <div class="modalHeader" tabindex="0" [attr.aria-label]="'aria-labels.permissions' | translate">
        <h1>{{ 'app.privacy_settings' | translate }}</h1>
        <i class="far fa-edit"></i>
    </div>
    <div class="btnContainer" *ngIf="((data.permissions.deleteInArchive && !(data.isDocumentInArchive == undefined)) || data.  permissions.deleteSources || data.permissions.deleteEverything)">
        <div *ngIf="data.permissions.deleteInArchive && !(data.isDocumentInArchive == undefined)">
            <span tabindex="0" [attr.aria-label]="'aria-labels.delete_document' | translate">{{ 'aria-labels.delete_document' | translate }}</span>
            <button class="deleteBtn"  (click)="deleteDocument(1)" tabindex="0">{{ 'app.delete' | translate }}</button></div>
        <!-- <div *ngIf="data.permissions.deleteSources">
            <span tabindex="0" [attr.aria-label]="'aria-labels.delete_similarity_sources' | translate">{{ 'report.delete_similarity_sources' | translate }}</span>
            <button class="deleteBtn"  (click)="deleteDocument(0)" tabindex="0">{{ 'app.delete' | translate }}</button></div> -->
        <div *ngIf="data.permissions.deleteEverything">
           <span tabindex="0" [attr.aria-label]="'aria-labels.delete_everything' | translate">{{ 'delete.delete_everything' | translate }}</span>
           <button class="deleteBtn"  (click)="deleteDocument(2)" tabindex="0">{{ 'app.delete' | translate }}</button></div>
        </div>

        <p *ngIf="!((data.permissions.deleteInArchive && !(data.isDocumentInArchive == undefined)) || data.permissions.deleteSources || data.permissions.deleteEverything)" tabindex="0" [attr.aria-label]="'aria-labels.no_permissions' | translate">{{ 'aria-labels.no_permissions' | translate }}</p>

    <div class="cancel">
        <button class="cancel-btn" tabindex="0" (click)="close()">{{ 'general.cancel' | translate }}</button>
    </div>
</div>

