<div class="report" [ngClass]="{
      report_expandedPdf: this.expandedPdf == true
   }">
  <div>
    <header class="report-header flex">
      <!-- <img src="../../../assets/images/icona-image.png" alt="" /> -->
      <img src="../../../assets/images/inspera-logo.svg" [attr.alt]="'aria-labels.inspera_originality_logo' | translate" tabindex="0"
        [attr.aria-label]="'aria-labels.inspera_originality_logo' | translate" (click)="goHome()" />
      <div>
        <div class="flex border shadow white-bg total-plag similarity-mobile">
          <h2 *ngIf="!displayAiText">{{ 'report.similarity' | translate }}</h2>
          <ng-container *ngIf="MLPlagIndex && !displayAiText">
            <ng-container *ngIf="this.highlightQuotes">
              <span [ngClass]="{
                'greenBackground': (currentSubmissionDetails$ | async)?.translatedPercentage <= 5 && (currentSubmissionDetails$ | async)?.translatedPercentage >= 0,
                'yellowBackground': (currentSubmissionDetails$ | async)?.translatedPercentage <= 20 && (currentSubmissionDetails$ | async)?.translatedPercentage > 5,
                'redBackground': (currentSubmissionDetails$ | async)?.translatedPercentage > 20 && (currentSubmissionDetails$ | async)?.translatedPercentage <= 100
                       }">{{
                (currentSubmissionDetails$ | async)?.translatedPercentage.toFixed(2)
                }}%</span>
            </ng-container>
            <ng-container *ngIf="!this.highlightQuotes">
              <span [ngClass]="{
                'greenBackground': (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes <= 5 && (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes >= 0,
                'yellowBackground': (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes <= 20 && (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes > 5,
                'redBackground': (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes > 20 && (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes <= 100
                       }">{{
                (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes.toFixed(2)
                }}%</span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="crossPlagIndex && !displayAiText">
            <ng-container *ngIf="this.highlightQuotes">
              <span [ngClass]="{
                'greenBackground': (currentSubmissionDetails$ | async)?.originalPercentage <= 5 && (currentSubmissionDetails$ | async)?.originalPercentage >= 0,
                'yellowBackground': (currentSubmissionDetails$ | async)?.originalPercentage <= 20 && (currentSubmissionDetails$ | async)?.originalPercentage > 5,
                'redBackground': (currentSubmissionDetails$ | async)?.originalPercentage > 20 && (currentSubmissionDetails$ | async)?.originalPercentage <= 100
                       }">{{
                (currentSubmissionDetails$ | async)?.originalPercentage.toFixed(2)
                }}%</span>
            </ng-container>
            <ng-container *ngIf="!this.highlightQuotes">
              <span [ngClass]="{
                'greenBackground': (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes <= 5 && (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes >= 0,
                'yellowBackground': (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes <= 20 && (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes > 5,
                'redBackground': (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes > 20 && (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes <= 100
                       }">{{
                (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes.toFixed(2)
                }}%</span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="displayAiText">
            <span *ngIf="ai_model !== 'base'" [ngClass]="{
              'greenBackground': currentSubmissionDetails?.aiPercentage < 10 && currentSubmissionDetails?.aiPercentage >= 0,
              'redBackground': currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage <= 100
                     }">{{
              currentSubmissionDetails?.aiPercentage?.toFixed(2)
              }}%</span>
            <h2>{{ 'report.ai_sentence' | translate }}s</h2>
            <span *ngIf="ai_model == 'base'" [ngClass]="{
              'greenBackground': currentSubmissionDetails?.aiPercentage < 10 && currentSubmissionDetails?.aiPercentage >= 0,
              'redBackground': currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage <= 100
                     }">{{
                  currentSubmissionDetails?.aiPercentage?.toFixed(2)
              }}%</span>
          </ng-container>
        </div>
        <h1 class="report-nav-desktop" tabindex="0" [attr.aria-label]="'aria-labels.report_navigation' | translate">
          {{ 'report.report_nav' | translate }}
        </h1>
        <nav class="white-bg navigation report-nav-desktop" *ngIf="selectedSources !== true && !this.displayAiText && !this.displayManipulations"
          [ngClass]="{ onePage: this.plagiarismLabels.length == 1 }" tabindex="0" role="tablist">
          <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
            <span class="nav_arrow prev_nav to_the_beginning" (click)="scrollToBeginning()">&laquo;</span>
            <span class="nav_arrow prev_nav" (click)="scrollToPreviousPages()">&lsaquo;</span>
          </div>
          <span [ngClass]="{
                     page_with_plag: this.pages.numberOfSentences !== 0,
                     active_page_with_plag:
                        this.page == pages.page,
                     scale: 50 > this.plagiarismLabels.length
                  }" *ngFor="let pages of (plagiarismLabels.length > 10 ? visiblePages : plagiarismLabels); let i = index" (click)="scrollPage(pages.page)" tabindex="0"
            role="tab">{{ pages.page }}</span>
            <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
              <span class="nav_arrow next_nav" (click)="scrollToNextPages()">&rsaquo;</span>
              <span class="nav_arrow next_nav to_the_end" (click)="scrollToEnd()">&raquo;</span>
            </div>
        </nav>


        <nav  class="white-bg navigation report-nav-desktop" *ngIf="selectedSources !== true && this.displayAiText && !this.displayManipulations"
          [ngClass]="{ onePage: this.aiNavigation.length == 1 }" tabindex="0" role="tablist">
          <div class="nav_arrows" *ngIf="this.aiNavigation.length > 10">
            <span class="nav_arrow prev_nav to_the_beginning" (click)="scrollToBeginning()">&laquo;</span>
            <span class="nav_arrow prev_nav" (click)="scrollToPreviousPages()">&lsaquo;</span>
          </div>
          <span [ngClass]="{
                     page_with_plag: this.pages.numberOfSentences !== 0,
                     active_page_with_plag:
                        this.page == pages.page,
                     scale: 50 > this.aiNavigation.length
                  }" *ngFor="let pages of (aiNavigation.length > 10 ? visiblePages : aiNavigation); let i = index" (click)="scrollPage(pages.page)" tabindex="0"
            role="tab">{{ pages.page }}</span>
            <div class="nav_arrows" *ngIf="this.aiNavigation.length > 10">
              <span class="nav_arrow next_nav" (click)="scrollToNextPages()">&rsaquo;</span>
              <span class="nav_arrow next_nav to_the_end" (click)="scrollToEnd()">&raquo;</span>
            </div>
        </nav>

        <nav  class="white-bg navigation report-nav-desktop" *ngIf="selectedSources !== true && !this.displayAiText && this.displayManipulations"
        [ngClass]="{ onePage: this.manipulationNavigation.length == 1 }" tabindex="0" role="tablist">
        <div class="nav_arrows" *ngIf="manipulationNavigation.length > 10">
          <span class="nav_arrow prev_nav to_the_beginning" (click)="scrollToBeginning()">&laquo;</span>
          <span class="nav_arrow prev_nav" (click)="scrollToPreviousPages()">&lsaquo;</span>
        </div>
        <span [ngClass]="{
                   page_with_plag: this.pages.numberOfSentences !== 0,
                   active_page_with_plag:
                      this.page == pages.page,
                   scale: 50 > this.manipulationNavigation.length
                }" *ngFor="let pages of (manipulationNavigation.length > 10 ? visiblePages : manipulationNavigation); let i = index" (click)="scrollPage(pages.page)" tabindex="0"
          role="tab">{{ pages.page }}</span>
          <div class="nav_arrows" *ngIf="manipulationNavigation.length > 10">
            <span class="nav_arrow next_nav" (click)="scrollToNextPages()">&rsaquo;</span>
            <span class="nav_arrow next_nav to_the_end" (click)="scrollToEnd()">&raquo;</span>
          </div>
      </nav>

        <nav class="white-bg navigation report-nav-desktop selected_pages" *ngIf="selectedSources == true"
          [ngClass]="{ onePage: this.plagiarismLabels.length == 1 }" tabindex="0" role="tablist">
          <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
            <span class="nav_arrow prev_nav to_the_beginning" (click)="scrollToBeginning()">&laquo;</span>
            <span class="nav_arrow prev_nav" (click)="scrollToPreviousPages()">&lsaquo;</span>
          </div>
          <span *ngFor="let page1 of (plagiarismLabels.length > 10 ? visiblePages : plagiarismLabels); let i = index" (click)="scrollPage(page1.page)"
            [ngClass]="{ 'parent-with-page_with_plag': pages.includes(page1.page) }" role="tab" tabindex="0">
            <span
              [ngClass]="{page_with_plag: pages.includes(page1.page), active_page_with_plag: this.page == page1.page, scale: 50 > this.plagiarismLabels.length }">{{
              page1.page }}</span>
          </span>
          <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
            <span class="nav_arrow next_nav" (click)="scrollToNextPages()">&rsaquo;</span>
            <span class="nav_arrow next_nav to_the_end" (click)="scrollToEnd()">&raquo;</span>
          </div>
        </nav>
        <div class="flex border shadow white-bg documents-data">
          <h2 style="max-width: 25%; overflow:hidden; text-overflow:ellipsis; white-space:nowrap" tabindex="0"
            [attr.aria-label]="'Name: ' + this.author">
            {{ 'general.name' | translate }}:
            <span>{{this.author}}</span>
          </h2>
          <h2 class="title" title="{{ (currentSubmissionDetails$ | async)?.title }}"
            style="max-width: 50%; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; text-transform: capitalize;"
            tabindex="0" [attr.aria-label]="('aria-labels.document_title' | translate) + ': ' + ((currentSubmissionDetails$ | async)?.title)">
            {{ 'report.title' | translate }}:
            <span class="testIt"><span class="nameSpan scrollTextAni">{{
                (currentSubmissionDetails$ | async)?.title
                }}</span></span>
          </h2>
          <h2 style="max-width: 25%; overflow:hidden; text-overflow:ellipsis; white-space:nowrap" tabindex="0"
            [attr.aria-label]="('aria-labels.date' | translate) + ': ' + ((currentSubmissionDetails$ | async)?.createdAt.split('T')[0])">
            {{ 'general.date' | translate }}:
            <span>{{
              (currentSubmissionDetails$ | async)?.createdAt.split(
              'T'
              )[0]
              }}</span>
          </h2>
        </div>
      </div>
    </header>
    <div class="report-nav-mobile">
      <div class="flex mobile-translation">
        <h1 tabindex="0" [attr.aria-label]="'aria-labels.report_navigation' | translate">{{ 'report.report_nav' | translate }}</h1>
        <app-translate></app-translate>
      </div>
      <div [ngClass]="{ onePage: this.plagiarismLabels.length == 1 }" class="white-bg navigation"
        *ngIf="selectedSources !== true" role="tablist" tabindex="0">
        <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
          <span class="nav_arrow prev_nav to_the_beginning" (click)="scrollToBeginning()">&laquo;</span>
          <span class="nav_arrow prev_nav" (click)="scrollToPreviousPages()">&lsaquo;</span>
        </div>
        <span [ngClass]="{
                  page_with_plag: this.pages.sentences !== 0,
                  active_page_with_plag: this.page == pages.page,
                  scale: 50 > this.plagiarismLabels.length
               }" *ngFor="let pages of (plagiarismLabels.length > 10 ? visiblePages : plagiarismLabels); let i = index" (click)="scrollPage(pages.page)" tabindex="0"
          role="tab">{{ pages.page }}</span>
          <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
            <span class="nav_arrow next_nav" (click)="scrollToNextPages()">&rsaquo;</span>
            <span class="nav_arrow next_nav to_the_end" (click)="scrollToEnd()">&raquo;</span>
          </div>
      </div>
      <div class="white-bg navigation selected_pages" *ngIf="selectedSources == true" role="tablist" tabindex="0">
        <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
          <span class="nav_arrow prev_nav to_the_beginning" (click)="scrollToBeginning()">&laquo;</span>
          <span class="nav_arrow prev_nav" (click)="scrollToPreviousPages()">&lsaquo;</span>
        </div>
        <span *ngFor="let page1 of (plagiarismLabels.length > 10 ? visiblePages : plagiarismLabels); let i = index" (click)="scrollPage(page1.page)" role="tab"><span
            *ngFor="let page_plag of pages">
            <span [ngClass]="{
                        page_with_plag: page_plag == page1.page,
                        active_page_with_plag:
                           this.page == page1.page,
                        scale: 50 > this.plagiarismLabels.length
                     }" *ngIf="page_plag == page1.page" tabindex="0">
              {{ page1.page }}
            </span>
          </span>
        </span>
        <div class="nav_arrows" *ngIf="plagiarismLabels.length > 10">
          <span class="nav_arrow next_nav" (click)="scrollToNextPages()">&rsaquo;</span>
          <span class="nav_arrow next_nav to_the_end" (click)="scrollToEnd()">&raquo;</span>
        </div>
      </div>
    </div>
    <div class="flex report-content pdf" [ngClass]="{
            expandedPdf: this.expandedPdf == true
         }">
      <aside class="flex column border shadow white-bg">
        <!-- <span>
               <span class="file"><i class="far fa-file-alt"></i> </span>
            </span> -->
            <span class="expand-button" *ngIf="!this.isExpanded">
              <button tabindex="0" [attr.aria-label]="'aria-labels.expand_report' | translate" aria-haspopup="true" role="button" (click)="openCurrentUrlInNewWindow()"
                [attr.alt]="'aria-labels.expand_report' | translate"><i class="fas fa-external-link-alt"></i>
              </button>
              <p>{{ 'report.expanded_view' | translate }}</p>
            </span>

        <span>
          <button [attr.data-lang]="'general.download' | translate" #Download [ngClass]="{
                     download: downloadClicked,
                     active: downloadClicked
                  }" (click)="download()" tabindex="0" [attr.aria-label]="'aria-labels.download_report' | translate" aria-haspopup="true" role="button"
            [attr.alt]="'aria-labels.download_report' | translate"><i class="fas fa-download"></i>
            <div *ngIf="downloadClicked" class="shadow border">
              <button style="
                           padding: 10px 15px;
                           border-bottom: 1px solid #d9d9d9;
                        " (click)="downloadPdfReport('mlplag')" role="button" tabindex="0" class="downloadBtn_docLang"
                [attr.aria-label]="('aria-labels.download' | translate) +  documentLanguage + ' ' + ('aria-labels.report' | translate)">{{ documentLanguage }} {{ 'report.originality_report' | translate }}</button>
              <span style="
                           padding: 10px 5px;
                           border-bottom: 1px solid #d9d9d9;
                        " (click)="downloadPdfReport('crossplag')" *ngIf="translatedLanguage" role="button"
                tabindex="0" [attr.aria-label]="('aria-labels.download' | translate) +  translatedLanguage + ' ' + ('aria-labels.report' | translate)">{{ translatedLanguage }} {{ 'report.originality_report' | translate }}</span>
                  <span style="padding: 10px 15px; border-bottom: 1px solid #d9d9d9;"
                    *ngIf="(currentSubmissionDetails$ | async)?.aiDetection" (click)="downloadAiReport()">
                    {{ 'report.ai_authorship_report' | translate }}
                  </span>
              <!-- <span
                        *ngIf="this.canEdit"
                        style="
                           padding: 10px 15px;
                           border-bottom: 1px solid #d9d9d9;
                        "
                        (click)="downloadCertificate()"
                     >
                        Originality Certificate
                     </span> -->
              <span style="padding: 10px 15px; padding-bottom: 0" (click)="downloadOriginalDocument()" tabindex="0"
                role="button" [attr.aria-label]="'aria-labels.download_original_document' | translate">{{ 'general.document_file' | translate }}</span>
            </div>
            <div *ngIf="downloadClicked">
              <span (click)="downloadPdfReport('mlplag')" tabindex="0" role="button"
                [attr.aria-label]="documentLanguage + ' ' + ('aria-labels.originality_report' | translate)">{{ documentLanguage }} {{ 'aria-labels.originality_report' | translate }}</span>
              <span (click)="downloadPdfReport('crossplag')" tabindex="0" *ngIf="translatedLanguage" role="button"
                [attr.aria-label]="translatedLanguage + ' ' + ('aria-labels.originality_report' | translate)">{{ translatedLanguage }} {{ 'aria-labels.originality_report' | translate }}</span>
              <!-- <span *ngIf="this.canEdit" (click)="downloadCertificate()" tabindex="0" role="button"
                [attr.aria-label]="'aria-labels.generate_e_certificate' | translate">Generate E-certificate</span> -->
                <span *ngIf="(currentSubmissionDetails$ | async)?.aiDetection"  (click)="downloadAiReport()">
                  {{ 'report.ai_authorship_report' | translate }}
                </span>
              <span *ngIf="this.canEdit" (click)="downloadOriginalDocument()" tabindex="0" role="button"
                [attr.aria-label]="'aria-labels.generate_original_document' | translate">{{ 'general.document_file' | translate }}</span>
            </div>
          </button>
          <p>{{ 'general.download' | translate }}</p>
        </span>
        <span>
          <span class="flex column" tabindex="0"
            [attr.aria-label]="('aria-labels.current_page' | translate) + ': ' + page + ' ' + ('aria-labels.out_of' | translate) + plagiarismLabels.length + ' ' + ('aria-labels.in_total' | translate)"
            role="button">{{ 'report.page' | translate }}:<span>{{ page }}/{{ plagiarismLabels.length
              }}</span></span></span>
        <span>
          <span class="flex column" tabindex="0"
            [attr.aria-label]="('aria-labels.there_are') + ': ' + (currentSubmissionDetails$ | async)?.wordCount + ' ' + ('aria-labels.words_in_document' | translate)"
            role="button">{{ 'general.words' | translate }}:<span>{{
              (currentSubmissionDetails$ | async)?.wordCount
              }}</span></span></span>
        <span>
          <span class="flex column" tabindex="0"
            [attr.aria-label]="('aria-labels.document_language' | translate) + ': ' + (crossPlagIndex ? documentLanguage : translatedLanguage)"
            role="button">{{ 'report.language' | translate }}:
            <span *ngIf="crossPlagIndex || displayManipulations"> {{ documentLanguage }}</span>
            <span *ngIf="MLPlagIndex">{{ translatedLanguage }}</span>
          </span>
        </span>
        <span (click)="openDeleteModal()" *ngIf="deleteModal">
          <button class="flex column file settingBtn" aria-haspopup="dialog" tabindex="0" role="button"
            [attr.aria-label]="'aria-labels.open_delete_modal' | translate">
            <i class="far fa-edit"></i>
            <span class="">{{ 'report.privacy_settings' | translate }}</span>
          </button>
        </span>
        <span (click)="openPermissionsModal()" *ngIf="!deleteModal">
          <button class="flex column file settingBtn" aria-haspopup="dialog" tabindex="0" role="button"
            [attr.aria-label]="'aria-labels.open_permissions_modal' | translate">
            <i class="far fa-edit"></i>
            <span class="">{{ 'report.privacy_settings' | translate }}</span>
          </button>
        </span>
      </aside>
      <main #mainDocument (keypress)="handleGlobalKeyPress($event)"
        style="display: flex; justify-content: center; overflow: hidden" tabindex="0">
        <button *ngIf="this.expandedPdf == true">
          <i [attr.alt]="'aria-labels.downzise_pdf' | translate" (click)="expandPdf()" class="fas fa-times-circle"></i>
        </button>
        <div class="expandPdf" (click)="expandPdf()">
          <button *ngIf="this.expandedPdf == true" [attr.aria-label]="'aria-labels.downsize_pdf' | translate">
            <i [attr.alt]="'aria-labels.downzise_pdf' | translate" class="
                       fas
                       fa-compress-arrows-alt
                       animate__tada animate__animated
                    "></i>
          </button>
          <button *ngIf="this.expandedPdf == false" [attr.aria-label]="'aria-labels.expand_pdf' | translate">
            <i [attr.alt]="'aria-labels.expand_pdf' | translate" class="
                       fas
                       fa-expand-arrows-alt
                       animate__tada animate__animated
                    "></i>
          </button>
        </div>
        <section [attr.aria-label]="'aria-labels.document' | translate" class="content_wrapper">
          <pdf-viewer id="viewer" (pagechanging)="pageChanging($event)" [src]="pdfSrc" [render-text]="true"
            [fit-to-page]="true" [page]="selectedPage" (after-load-complete)="callBackFn($event)"
            (page-rendered)="pageRendered($event)" style="
                     display: block;
                     overflow: auto;
                     height: 100%;
                     scroll-behavior: smooth;
                     border-radius: 10px;
                  "></pdf-viewer>
        </section>
      </main>
    </div>
  </div>
  <div class="flex column">
    <div class="flex translation_section">
      <app-translate></app-translate>
    </div>
    <main role="main">
      <div class="changeView">
        <h1 role="button" #changeView_1 (click)="changeView('similarity')" [ngClass]="{ active: !displayAiText && !displayManipulations }"
          tabindex="0" [attr.aria-label]="'aria-labels.switch_similarity_view' | translate">
          {{ 'report.similarity' | translate }}</h1>
        <h1 role="button" tabindex="0" [attr.aria-label]="'aria-labels.switch_ai_index_view' | translate"
          (click)="changeView('aiIndex')" [ngClass]="{
               active: displayAiText && !displayManipulations,
               disabled: !(currentSubmissionDetails$ | async)?.aiDetection
            }">{{ 'general.ai_authorship' | translate }}</h1>
        <h1 role="button" tabindex="0" class="inBeta" [attr.aria-label]=""
          (click)="changeView('manipulations')" [ngClass]="{
               active: displayManipulations
            }">{{ 'new_entries.manipulation' | translate }}</h1>

      </div>
      <div *ngIf="!displayAiText && !displayManipulations" class="flex border shadow white-bg total-plag similarity-desktop" tabindex="0">
        <h2>{{ 'report.similarity' | translate }}</h2>
        <ng-container *ngIf="MLPlagIndex">
          <ng-container *ngIf="this.highlightQuotes">
            <span [ngClass]="{
              'greenBackground': (currentSubmissionDetails$ | async)?.translatedPercentage <= 5 && (currentSubmissionDetails$ | async)?.translatedPercentage >= 0,
              'yellowBackground': (currentSubmissionDetails$ | async)?.translatedPercentage <= 20 && (currentSubmissionDetails$ | async)?.translatedPercentage > 5,
              'redBackground': (currentSubmissionDetails$ | async)?.translatedPercentage > 20 && (currentSubmissionDetails$ | async)?.translatedPercentage <= 100
                     }">{{
              (currentSubmissionDetails$ | async)?.translatedPercentage.toFixed(2)
              }}%</span>
          </ng-container>
          <ng-container *ngIf="!this.highlightQuotes">
            <span [ngClass]="{
              'greenBackground': (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes <= 5 && (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes >= 0,
              'yellowBackground': (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes <= 20 && (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes > 5,
              'redBackground': (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes > 20 && (currentSubmissionDetails$ | async)?.translatedPercentageWithoutQuotes <= 100
                     }">{{
              (
              currentSubmissionDetails$ | async
              )?.translatedPercentageWithoutQuotes.toFixed(2)
              }}%</span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="crossPlagIndex">
          <ng-container *ngIf="this.highlightQuotes">
            <span [ngClass]="{
              'greenBackground': (currentSubmissionDetails$ | async)?.originalPercentage <= 5 && (currentSubmissionDetails$ | async)?.originalPercentage >= 0,
              'yellowBackground': (currentSubmissionDetails$ | async)?.originalPercentage <= 20 && (currentSubmissionDetails$ | async)?.originalPercentage > 5,
              'redBackground': (currentSubmissionDetails$ | async)?.originalPercentage > 20 && (currentSubmissionDetails$ | async)?.originalPercentage <= 100
                     }">{{
              (
              currentSubmissionDetails$ | async
              )?.originalPercentage.toFixed(2)
              }}%</span>
          </ng-container>
          <ng-container *ngIf="!this.highlightQuotes">
            <span [ngClass]="{
              'greenBackground': (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes <= 5 && (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes >= 0,
              'yellowBackground': (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes <= 20 && (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes > 5,
              'redBackground': (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes > 20 && (currentSubmissionDetails$ | async)?.originalPercentageWithoutQuotes <= 100
                     }">{{
              (
              currentSubmissionDetails$ | async
              )?.originalPercentageWithoutQuotes.toFixed(2)
              }}%</span>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="displayAiText && !displayManipulations" class="flex border shadow white-bg total-plag similarity-desktop" tabindex="0">
        <div class="ai-models-index"
          *ngIf="ai_model == 'base' && displayAiText && currentSubmissionId == 'clnvm3cjk0009eod5m7iq0w1p'">
          <div><img src="../../../assets/images/icons/Group 33841.png" alt=""><b>{{ 'report.base_mode' | translate }}</b></div>
          <p>{{ 'app.ai.ai_content_index' | translate }}</p>
        </div>
        <div class="ai-models-index"
          *ngIf="ai_model !== 'base' && displayAiText && currentSubmissionId == 'clnvm3cjk0009eod5m7iq0w1p'">
          <div><img src="../../../assets/images/icons/Group.png" alt=""><b>{{ 'report.performance_mode' | translate }}</b></div>
          <p>{{ 'app.ai.ai_content_index' | translate }}</p>
        </div>
        <h2 *ngIf="currentSubmissionId !== 'clnvm3cjk0009eod5m7iq0w1p'">
          {{ 'report.ai_generated_sentences' | translate }}
        </h2>
        <span *ngIf="ai_model == 'base'" [ngClass]="{
          'greenBackground': currentSubmissionDetails?.aiPercentage < 10 && currentSubmissionDetails?.aiPercentage >= 0,
          'redBackground': currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage <= 100
            }">{{ currentSubmissionDetails?.aiPercentage?.toFixed(2) }}%</span>
        <span *ngIf="ai_model !== 'base'" [ngClass]="{
          'greenBackground': currentSubmissionDetails?.aiPercentage < 10 && currentSubmissionDetails?.aiPercentage >= 0,
          'redBackground': currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage <= 100
            }">{{ currentSubmissionDetails?.aiPercentagePerformance?.toFixed(2) }}%</span>
      </div>
      <div *ngIf="!displayAiText && displayManipulations" class="flex border shadow white-bg manipulations-preview" [ngClass]="(totalCharReplacements + totalWhiteCharacters + totalManipulatedImages) > 0 ? 'hasManipulations' : 'noManipulations'">
        <h2>{{ 'new_entries.flags' | translate }}</h2>
        <span>{{ totalCharReplacements + totalWhiteCharacters + totalManipulatedImages }}</span>
      </div>
      <div *ngIf="!displayAiText && displayManipulations" class="flex border shadow white-bg manipulations-preview-long" [ngClass]="(totalCharReplacements + totalWhiteCharacters + totalManipulatedImages) > 0 ? 'hasManipulations' : 'noManipulations'">
        <span>We found <span class="bold">{{ totalCharReplacements + totalWhiteCharacters + totalManipulatedImages }} instances</span> of manipulations in this text.</span>
      </div>
    </main>
    <section class="scrollable_panel">
      <section [attr.aria-label]="'aria-labels.sources_panel' | translate" class="sources_panel" tabindex="0">
        <div *ngIf="!displayAiText && !displayManipulations" class="border shadow white-bg plag-type">
          <div class="flex">
            <h3 tabindex="0" [attr.aria-label]="documentLanguage + ', ' + ('aria-labels.open_sources' | translate)"
              [ngClass]="{ active: crossPlagIndex == true }" #crossplag class="border" (click)="getCrossPlag()">{{
              documentLanguage }}</h3>
            <h3 tabindex="0"
              [attr.aria-label]="translatedLanguage ? translatedLanguage  + ', ' + ('aria-labels.open_sources' | translate) : ('aria-labels.no_translated_language' | translate)"
              [ngClass]="{ active: MLPlagIndex == true, disabled: !translatedLanguage }" (click)="getMLPlag()" class="border">
              {{ translatedLanguage ? translatedLanguage : '-' }}</h3>
          </div>
          <div *ngIf="!sourcesAreDeleted" class="flex">
            <button role="button" tabindex="0" [attr.aria-label]="'aria-labels.view_all_sources' | translate" [ngClass]="{
                    active_sources: filterSources == false && topSources == false
                 }" (click)="getFilteredSources('all')" (keydown.enter)="getFilteredSources('all')">
              {{ 'report.all_sources' | translate }}
            </button>
            <button role="button" tabindex="0" [attr.aria-label]="'aria-labels.view_top_3_sources' | translate" [ngClass]="{
                    active_sources: topSources == true
                 }" (click)="getFilteredSources('top3')">
              {{ 'report.top_three' | translate }}
            </button>
            <button role="button" tabindex="0" [attr.aria-label]="'aria-labels.view_internet_sources' | translate" [ngClass]="{
                    active_sources: internetSources == true
                 }" (click)="getFilteredSources(2)">
              {{ 'report.internet_sources' | translate }}
            </button>
            <button role="button" tabindex="0" [attr.aria-label]="'aria-labels.view_document_sources' | translate" [ngClass]="{
                    active_sources: documentSources == true
                 }" (click)="getFilteredSources(1)">
              {{ 'report.document_sources' | translate }}
            </button>
          </div>
          <p class="view_more" *ngIf="!sourcesAreDeleted" #targetSpan>
            <i class="fas fa-eye"></i> {{ 'report.view' | translate }} :
            <span tabindex="0" [attr.aria-label]="'aria-labels.view_current_all_sources' | translate" *ngIf="
                    filterSources == false &&
                    topSources == false &&
                    !selectedSources
                 ">
              {{ 'report.all_sources' | translate }}</span>
            <span tabindex="0" [attr.aria-label]="'aria-labels.view_current_internet_sources' | translate"
              *ngIf="internetSources == true && !selectedSources">
              {{ 'report.internet_sources' | translate }}</span>
            <span tabindex="0" [attr.aria-label]="'aria-labels.view_current_top_3_sources' | translate"
              *ngIf="topSources == true && !selectedSources">
              {{ 'report.top_three' | translate }}</span>
            <span tabindex="0" [attr.aria-label]="'aria-labels.view_current_document_sources' | translate"
              *ngIf="documentSources == true && !selectedSources">
              {{ 'report.document_sources' | translate }}</span>
            <span tabindex="0" [attr.aria-label]="'aria-labels.view_current_selected_sources' | translate"
              *ngIf="selectedSources">
              {{ 'report.selected_sources' | translate }}</span>
            <button (click)="resetFilter()" tabindex="0" role="button" [attr.aria-label]="'aria-labels.reset_filter' | translate">
              {{ 'report.reset_filter' | translate }}<i class="fas fa-sync"></i></button>
          </p>
        </div>
        <div *ngIf="!sourcesAreDeleted && !displayAiText && !displayManipulations" class="relative sources-parent">
          <!-- {{ 'report.all_sources' | translate }} -->
          <div class="sources" *ngIf="filterSources == false && topSources == false">
            <div *ngFor="let source of activePlagSources; let i = index">
              <ng-container *ngIf="
                       source.percentage >= 1 &&
                       source.isExcluded == false &&
                       source.isSentenceExcluded === false &&
                       (!source.hide || source.hide == false)
                    ">
                <label [for]="source.sourceId">
                  <div class="flex border shadow white-bg source" tabindex="0"
                    [ariaLabel]="('aria-labels.source' | translate) + ' ' + (i + 1) + ' ,' + ('aria-labels.with' | translate) + source.percentage.toFixed(2) + '% ' + ('aria-labels.select_source_similarity' | translate)">
                    <div class="checkbox_wrapper">
                      <div class="Checkbox">
                        <input [id]="source.sourceId" type="checkbox" #checkboxes [checked]="source.isChecked"
                          (change)="selectSource(source.sourceId)" tabindex="-1" />
                        <div [ngClass]="{
                                      'checkbox-visible-def-selected':
                                         defaultSourceSelect
                                   }" class="Checkbox-visible"></div>
                      </div>
                    </div>
                    <div class="flex column">
                      <h4>
                        {{ 'report.source' | translate }}
                        {{ i + 1 }}
                      </h4>
                      <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank"
                        [attr.aria-label]="('aria-labels.source' | translate) + ' ' + (i + 1) + ' ' + ('aria-labels.open_source_link' | translate)">{{ source.sourceUrl
                        }}
                        <p>{{ source.sourceUrl }}</p>
                      </a>
                      <ng-container *ngIf="!source.sourceUrl">
                        <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                          <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                          {{ 'general.submissions' | translate }}
                        </p>
                        <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                          <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                          {{ 'general.submissions' | translate }}
                        </p>

                      </ng-container>
                      <h5 (click)="expandRow(i)">
                        {{ 'report.highlight_matches' | translate }}
                      </h5>
                    </div>
                    <span>{{ source.percentage.toFixed(2) }}%</span>
                  </div>
                </label>
                <button [matTooltip]="('aria-labels.remove_source' | translate) + ' ' + (i + 1)" matTooltipClass="tooltipCustomCss"
                  tabindex="0" role="button" [attr.aria-label]="('aria-labels.remove_source' | translate) + ' ' + (i + 1)"
                  *ngIf="this.canEdit" (click)="excludeSource(source)"><i class="fas fa-unlink"></i></button>
              </ng-container>

              <div class="expanded border shadow white-bg" *ngIf="i == expandedIndex">
                <div>
                  <i class="fas fa-times-circle" (click)="close()"></i>
                  <h4>{{ 'report.source' | translate }} 77 {{ i + 1 }}</h4>
                  <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{ source.sourceUrl }}
                  </a>
                </div>
                <p>
                  {{ source.description }}
                </p>
              </div>
            </div>

            <div *ngIf="lowPercentage(activePlagSources)" class="source-box ribbon-sources">
              <h4 class="source-box" [ngClass]="{ other_sources: this.revealDiv }">
                <button tabindex="0" [attr.aria-label]="'aria-labels.sources_less_than_1' | translate"
                  [attr.aria-expanded]="true ? this.revealDiv : false" (click)="toggleFilteredSource()">{{
                  'report.sources_with_low_plag' | translate }}</button>
              </h4>
              <div *ngFor="let source of activePlagSources; let i = index">
                <ng-container *ngIf="this.revealDiv">
                  <ng-container *ngIf="!source.hide && !source.isSentenceExcluded">
                    <ng-container *ngIf="1 > source.percentage">
                      <label [for]="source.sourceId">
                        <div class="flex border shadow white-bg source" tabindex="0"
                          [ariaLabel]="('aria-labels.source' | translate) + ' ' + (i + 1) + ' , ' + ('aria-labels.with' | translate) + source.percentage.toFixed(2) + '% ' + ('aria-labels.select_source_similarity' | translate)">
                          <div class="checkbox_wrapper">
                            <div class="Checkbox">
                              <input tabindex="-1" [id]="source.sourceId" type="checkbox" #checkboxes
                                [checked]="source.isChecked" (change)="
                                               selectSource(source.sourceId)
                                            " />

                              <div [ngClass]="{
                                               'checkbox-visible-def-selected':
                                                  defaultSourceSelect
                                            }" class="Checkbox-visible"></div>
                            </div>
                          </div>
                          <div class="flex column">
                            <h4>
                              {{ 'report.source' | translate }}
                              {{ i + 1 }}
                            </h4>
                            <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank"
                              [attr.aria-label]="('aria-labels.source' | translate) + ' ' + (i + 1) + ' ' + ('aria-labels.open_source_link' | translate)">{{
                              source.sourceUrl }}</a>
                              <ng-container *ngIf="!source.sourceUrl">
                                <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                                  <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                  {{ 'general.submissions' | translate }}
                                </p>
                                <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                                  <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                  {{ 'general.submissions' | translate }}
                                </p>
                              </ng-container>
                            <h5 (click)="expandRow(i)">
                              {{
                              'report.highlight_matches' | translate
                              }}
                            </h5>
                          </div>
                          <span>{{ source.percentage.toFixed(2) }}%</span>
                        </div>
                      </label>
                      <button [matTooltip]="('aria-labels.remove_source' | translate) + ' ' + (i + 1)" matTooltipClass="tooltipCustomCss"
                        tabindex="0" role="button" [attr.aria-label]="('aria-labels.remove_source' | translate) + ' ' + (i + 1)"
                        *ngIf="this.canEdit" (click)="excludeSource(source)"><i class="fas fa-unlink"></i></button>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <!-- Excluded Sources -->
            <div class="source-box ribbon-sources">
              <h4 class="source-box" [ngClass]="{ other_sources: excludedSourcesShow }">
                <button tabindex="0" [attr.aria-label]="'aria-labels.excluded_sources' | translate"
                  [attr.aria-expanded]="true ? excludedSourcesShow : false" (click)="toggleExcludedSource()">{{ 'aria-labels.excluded_sources' | translate }}</button>
              </h4>
              <div *ngFor="let source of excludeSources; let i = index">
                <ng-container *ngIf="excludedSourcesShow">
                  <label [for]="source.sourceId">
                    <div class="flex border shadow white-bg source" tabindex="0"
                      [ariaLabel]="('aria-labels.source' | translate) + ' ' + source.no + ' , ' + ('aria-labels.with' | translate) + source.percentage.toFixed(2) + '% ' + ('aria-labels.select_source_similarity' | translate)">
                      <div class="checkbox_wrapper">
                        <div class="Checkbox">
                          <input tabindex="-1" [id]="source.sourceId" type="checkbox" #checkboxes
                            [checked]="source.isChecked" (change)="
                                         highlightExcludedSource(source.sourceId)
                                      " />

                          <div [ngClass]="{
                                         'checkbox-visible-def-selected':
                                            defaultSourceSelect
                                      }" class="Checkbox-visible"></div>
                        </div>
                      </div>
                      <div class="flex column">
                        <h4>{{ 'report.source' | translate }} {{ source.no }}</h4>
                        <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{ source.sourceUrl
                          }}</a>
                          <ng-container *ngIf="!source.sourceUrl">
                            <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                              <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                              {{ 'general.submissions' | translate }}
                            </p>
                            <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                              <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                              {{ 'general.submissions' | translate }}
                            </p>
                          </ng-container>
                      </div>
                      <span>{{ source.percentage.toFixed(2) }}%</span>
                    </div>
                  </label>
                  <button style="background: #0059B2;" [matTooltip]="('aria-labels.add_source' | translate) + ' ' + source.no"
                    matTooltipClass="tooltipCustomCss" tabindex="0" role="button"
                    [attr.aria-label]="('aria-labels.add_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                    (click)="includeSource(source)"><i class="fas fa-link" style="color: #fff"></i></button>
                </ng-container>
              </div>
            </div>

            <!-- Excluded Sources -->
          </div>
          <!-- {{ 'report.all_sources' | translate }} -->

          <!-- Filtered Sources (internet or documents) -->
          <div class="sources" *ngIf="filterSources == true">
            <div *ngFor="let source of filteredSources; let i = index">
              <ng-container *ngIf="source.percentage >= 1 && source.isExcluded == false">
                <label [for]="source.sourceId">
                  <div class="flex border shadow white-bg source">
                    <div class="checkbox_wrapper">
                      <div class="Checkbox">
                        <input [id]="source.sourceId" type="checkbox" (change)="selectSource(source.sourceId)"
                          [checked]="source.isChecked" #checkboxes />

                        <div [ngClass]="{
                                      'checkbox-visible-def-selected':
                                         defaultSourceSelect
                                   }" class="Checkbox-visible"></div>
                      </div>
                    </div>
                    <div class="flex column">
                      <h4>
                        {{ 'report.source' | translate }}
                        {{ i + 1 }}
                      </h4>
                      <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{ source.sourceUrl }}
                        <p>{{ source.sourceUrl }}</p>
                      </a>
                      <ng-container *ngIf="!source.sourceUrl">
                        <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                          <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                          {{ 'general.submissions' | translate }}
                        </p>
                        <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                          <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                          {{ 'general.submissions' | translate }}
                        </p>
                      </ng-container>
                      <h5 (click)="expandRow(i)">
                        {{ 'report.highlight_matches' | translate }}
                      </h5>
                    </div>
                    <span>{{ source.percentage.toFixed(2) }}%</span>
                  </div>
                </label>
                <button [matTooltip]="('aria-labels.remove_source' | translate) + ' ' + source.no" matTooltipClass="tooltipCustomCss"
                  tabindex="0" [attr.aria-label]="('aria-labels.remove_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                  (click)="excludeSource(source)"><i class="fas fa-unlink"></i></button>
                <div class="expanded border shadow white-bg" *ngIf="i == expandedIndex">
                  <i class="fas fa-times-circle" (click)="close()"></i>
                  {{ source.description }}
                </div>
              </ng-container>
            </div>
            <!-- {{ 'report.sources_with_low_plag' | translate }} -->
            <div *ngIf="lowPercentage(filteredSources)" class="source-box ribbon-sources">
              <h4 class="source-box" [ngClass]="{ other_sources: this.revealDiv }">
                <button tabindex="0" [attr.aria-label]="'aria-labels.sources_less_than_1' | translate" [attr.aria-expanded]="true ? this.revealDiv : false" (click)="toggleFilteredSource()">{{ 'report.sources_with_low_plag' | translate }}</button>
              </h4>
              <div *ngFor="let source of filteredSources; let i = index">
                <ng-container *ngIf="this.revealDiv">
                  <ng-container *ngIf="
                             1 > source.percentage && source.isExcluded == false
                          ">
                    <label [for]="source.sourceId">
                      <div class="flex border shadow white-bg source">
                        <div class="checkbox_wrapper">
                          <div class="Checkbox">
                            <input [id]="source.sourceId" type="checkbox" #checkboxes [checked]="source.isChecked"
                              (change)="selectSource(source.sourceId)" />

                            <div [ngClass]="{
                                            'checkbox-visible-def-selected':
                                               defaultSourceSelect
                                         }" class="Checkbox-visible"></div>
                          </div>
                        </div>
                        <div class="flex column">
                          <h4>{{ 'report.source' | translate }} {{ source.no }}</h4>
                          <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{
                            source.sourceUrl
                            }}</a>
                            <ng-container *ngIf="!source.sourceUrl">
                              <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                              <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                            </ng-container>
                          <!-- <h5 (click)="expandRow(i)">
                                   {{ 'report.highlight_matches' | translate }}
                                </h5> -->
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                      </div>
                    </label>
                    <button [matTooltip]="('aria-labels.remove_source' | translate) + ' ' + source.no" matTooltipClass="tooltipCustomCss"
                    tabindex="0" [attr.aria-label]="('aria-labels.remove_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                    (click)="excludeSource(source)"><i class="fas fa-unlink"></i></button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!-- {{ 'report.sources_with_low_plag' | translate }} -->
            <!-- Excluded Sources -->
            <div class="source-box ribbon-sources">
              <h4 class="source-box" [ngClass]="{ other_sources: excludedSourcesShow }">
                <button tabindex="0" [attr.aria-label]="'aria-labels.excluded_sources' | translate"
                  [attr.aria-expanded]="true ? excludedSourcesShow : false" (click)="toggleExcludedSource()">{{ 'aria-labels.excluded_sources' | translate }}</button>
              </h4>
              <div *ngFor="let source of excludeSources; let i = index">
                <ng-container *ngIf="excludedSourcesShow && internetSources">
                  <ng-container *ngIf="source.isExcluded == true && source.sourceUrl">
                    <label [for]="source.sourceId">
                      <div class="flex border shadow white-bg source">
                        <div class="checkbox_wrapper">
                          <div class="Checkbox">
                            <input [id]="source.sourceId" type="checkbox" #checkboxes [checked]="source.isChecked"
                              (change)="selectSource(source.sourceId)" />

                            <div [ngClass]="{
                                            'checkbox-visible-def-selected':
                                               defaultSourceSelect
                                         }" class="Checkbox-visible"></div>
                          </div>
                        </div>
                        <div class="flex column">
                          <h4>
                            {{ 'report.source' | translate }}
                            {{ i + 1 }}
                          </h4>
                          <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{
                            source.sourceUrl
                            }}</a>
                          <h5 (click)="expandRow(i)">
                            {{ 'report.highlight_matches' | translate }}
                          </h5>
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                      </div>
                    </label>
                    <button style="background: #0059B2;" [matTooltip]="('aria-labels.add_source' | translate) + ' ' + source.no"
                      matTooltipClass="tooltipCustomCss" tabindex="0"
                      [attr.aria-label]="('aria-labels.add_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                      (click)="includeSource(source)"><i class="fas fa-link" style="color: #fff"></i></button>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="excludedSourcesShow && documentSources">
                  <ng-container *ngIf="source.isExcluded == true && !source.sourceUrl">
                    <label [for]="source.sourceId">
                      <div class="flex border shadow white-bg source">
                        <div class="checkbox_wrapper">
                          <div class="Checkbox">
                            <input [id]="source.sourceId" type="checkbox" #checkboxes [checked]="source.isChecked"
                              (change)="selectSource(source.sourceId)" />

                            <div [ngClass]="{
                                            'checkbox-visible-def-selected':
                                               defaultSourceSelect
                                         }" class="Checkbox-visible"></div>
                          </div>
                        </div>
                        <div class="flex column">
                          <h4>
                            {{ 'report.source' | translate }}
                            {{ i + 1 }}
                          </h4>
                            <ng-container *ngIf="!source.sourceUrl">
                              <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                              <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                            </ng-container>
                          <h5 (click)="expandRow(i)">
                            {{ 'report.highlight_matches' | translate }}
                          </h5>
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                      </div>
                    </label>
                    <button style="background: #0059B2;" [matTooltip]="('aria-labels.add_source' | translate) + ' ' + source.no"
                      matTooltipClass="tooltipCustomCss" tabindex="0"
                      [attr.aria-label]="('aria-labels.add_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                      (click)="includeSource(source)"><i class="fas fa-link" style="color: #fff"></i></button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!-- Excluded Sources -->
          </div>
          <!-- Filtered Sources (internet or documents) -->

          <!-- Top Sources -->
          <div class="sources" *ngIf="topSources == true">
            <div *ngFor="let source of topThreeSources; let i = index">
              <ng-container *ngIf="source.percentage >= 1 && source.isExcluded == false">
                <label [for]="source.sourceId">
                  <div class="flex border shadow white-bg source">
                    <div class="checkbox_wrapper">
                      <div class="Checkbox">
                        <input [id]="source.sourceId" type="checkbox" (change)="selectSource(source.sourceId)"
                          [checked]="source.isChecked" #checkboxes />

                        <div [ngClass]="{
                                      'checkbox-visible-def-selected':
                                         defaultSourceSelect
                                   }" class="Checkbox-visible"></div>
                      </div>
                    </div>
                    <div class="flex column">
                      <h4>
                        {{ 'report.source' | translate }}
                        {{ i + 1 }}
                      </h4>
                      <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{ source.sourceUrl }}
                        <p>{{ source.sourceUrl }}</p>
                      </a>
                      <ng-container *ngIf="!source.sourceUrl">
                        <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                          <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                          {{ 'general.submissions' | translate }}
                        </p>
                        <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                          <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                          {{ 'general.submissions' | translate }}
                        </p>
                      </ng-container>
                      <h5 (click)="expandRow(i)">
                        {{ 'report.highlight_matches' | translate }}
                      </h5>
                    </div>
                    <span>{{ source.percentage.toFixed(2) }}%</span>
                  </div>
                </label>
                <button [matTooltip]="('aria-labels.remove_source' | translate) + ' ' + source.no" matTooltipClass="tooltipCustomCss"
                  tabindex="0" [attr.aria-label]="('aria-labels.remove_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                  (click)="excludeSource(source)"><i class="fas fa-unlink"></i></button>
                <div class="expanded border shadow white-bg" *ngIf="i == expandedIndex">
                  <i class="fas fa-times-circle" (click)="close()"></i>
                  {{ source.description }}
                </div>
              </ng-container>
            </div>
            <!-- Sources Less than 1 % -->
            <div *ngIf="lowPercentage(topThreeSources) && topThreeSources" class="source-box ribbon-sources">
              <h4 class="source-box" [ngClass]="{ other_sources: this.revealDiv }">
                <!-- {{ 'report.sources_with_low_plag' | translate }} -->
                <button tabindex="0" [attr.aria-label]="'aria-labels.sources_less_than_1' | translate" [attr.aria-expanded]="true ? this.revealDiv : false" (click)="toggleFilteredSource()">{{ 'report.sources_with_low_plag' | translate }}</button>
              </h4>
              <div *ngFor="let source of topThreeSources; let i = index">
                <ng-container *ngIf="this.revealDiv">
                  <ng-container *ngIf="1 > source.percentage">
                    <label>
                      <div class="flex border shadow white-bg source">
                        <div class="checkbox_wrapper">
                          <div class="Checkbox">
                            <input [id]="source.sourceId" type="checkbox" #checkboxes [checked]="source.isChecked"
                              (change)="selectSource(source.sourceId)" />

                            <div [ngClass]="{
                                            'checkbox-visible-def-selected':
                                               defaultSourceSelect
                                         }" class="Checkbox-visible"></div>
                          </div>
                        </div>
                        <div class="flex column">
                          <h4>
                            {{ 'report.source' | translate }}
                            {{ i + 1 }}
                          </h4>
                          <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{
                            source.sourceUrl
                            }}</a>
                            <ng-container *ngIf="!source.sourceUrl">
                              <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                              <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                            </ng-container>
                          <h5 (click)="expandRow(i)">
                            {{ 'report.highlight_matches' | translate }}
                          </h5>
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                      </div>
                    </label>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!-- Sources Less than 1 % -->

            <!-- Excluded Sources -->
            <div class="source-box ribbon-sources">
              <!-- <h4 class="source-box" [ngClass]="{ other_sources: excludedSourcesShow }">
                <button tabindex="0" [attr.aria-label]="'aria-labels.excluded_sources' | translate"
                  [attr.aria-expanded]="true ? excludedSourcesShow : false" (click)="toggleExcludedSource()">{{ 'aria-labels.excluded_sources' | translate }}</button>
              </h4> -->
              <div *ngFor="let source of topThreeSources; let i = index">
                <ng-container *ngIf="excludedSourcesShow">
                  <ng-container *ngIf="source.isExcluded == true">
                    <label [for]="source.sourceId">
                      <div class="flex border shadow white-bg source">
                        <div class="checkbox_wrapper">
                          <div class="Checkbox">
                            <input [id]="source.sourceId" type="checkbox" #checkboxes [checked]="source.isChecked"
                              (change)="selectSource(source.sourceId)" />

                            <div [ngClass]="{
                                            'checkbox-visible-def-selected':
                                               defaultSourceSelect
                                         }" class="Checkbox-visible"></div>
                          </div>
                        </div>
                        <div class="flex column">
                          <h4>{{ 'report.source' | translate }} {{ source.no }}</h4>
                          <a [title]="source.sourceUrl" href="{{ source.sourceUrl }}" target="_blank">{{
                            source.sourceUrl
                            }}</a>
                            <ng-container *ngIf="!source.sourceUrl">
                              <p *ngIf="!canEdit" style="display: flex; z-index: 99; color: #434343;">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                              <p *ngIf="canEdit" style="display: flex; z-index: 99" (click)="startDocumentPreview(source.sourceId)">
                                <i class="fas fa-file-pdf"></i> {{ 'general.student' | translate }}
                                {{ 'general.submissions' | translate }}
                              </p>
                            </ng-container>
                          <!-- <h5 (click)="expandRow(i)">
                                      {{ 'report.highlight_matches' | translate }}
                                   </h5> -->
                        </div>
                        <span>{{ source.percentage.toFixed(2) }}%</span>
                      </div>
                    </label>
                    <button style="background: #0059B2;" [matTooltip]="('aria-labels.add_source' | translate) + ' ' + source.no"
                      matTooltipClass="tooltipCustomCss" tabindex="0"
                      [attr.aria-label]="('aria-labels.add_source' | translate) + ' ' + source.no" *ngIf="this.canEdit"
                      (click)="includeSource(source)"><i class="fas fa-link" style="color: #fff"></i></button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!-- Excluded Sources -->
          </div>
          <!-- Top Sources -->
        </div>
        <div class="relative white-bg deletedSourcesContainer" *ngIf="sourcesAreDeleted && !displayManipulations && !displayAiText">
          <span *ngIf="
                 !canEdit ||
                 (currentUserDetails?.roleId == 2 &&
                    currentUserDetails?.institutionId == null)
              ">{{ 'report.you_have_deleted_sources' | translate }} <br /></span>
          <span *ngIf="
                 canEdit &&
                 currentUserDetails?.roleId != 2 &&
                 currentUserDetails?.institutionId != null
              ">{{ 'report.student_has_deleted_sources' | translate }} <br /></span>
        </div>
        <div tabindex="0" class="flex border shadow white-bg aidetector-classification"
          *ngIf="displayAiText && ai_model != 'base' && !displayManipulations" [ngClass]="{
            'greenColor': currentSubmissionDetails?.aiPercentage < 10 && currentSubmissionDetails?.aiPercentage >= 0,
            'redColor': currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage <= 100
           }">
           {{
            currentSubmissionDetails?.aiPercentage == 0 ? ('report.this_text_has_a_high_probability_of_being_written_by_a_human' | translate) :
            (currentSubmissionDetails?.aiPercentage > 0 && currentSubmissionDetails?.aiPercentage < 10) ? ('report.this_text_has_a_moderate_probability_of_being_written_by_a_human' | translate) :
            (currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage < 60) ? ('report.this_text_likely_contains_snippets_of_text_generated_by_ai' | translate) :
            (currentSubmissionDetails?.aiPercentage >= 60 && currentSubmissionDetails?.aiPercentage < 90) ? ('report.this_text_has_a_moderate_probability_of_being_ai_generated' | translate) :
            (currentSubmissionDetails?.aiPercentage >= 90 && currentSubmissionDetails?.aiPercentage <= 100) ? ('report.this_text_has_a_high_probability_of_being_ai_generated' | translate) : ''
          }}
          <!-- {{
          currentSubmissionDetails?.classificationPerformance !=
          'This text likely contains snippets of text generated by AI'
          ? currentSubmissionDetails?.classificationPerformance
          : 'This text contains text written by AI'
          }} -->
        </div>
        <div tabindex="0" class="flex border shadow white-bg aidetector-classification"
          *ngIf="displayAiText && ai_model == 'base' && !displayManipulations" [ngClass]="{
            'greenColor': currentSubmissionDetails?.aiPercentage < 10 && currentSubmissionDetails?.aiPercentage >= 0,
            'redColor': currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage <= 100
        }">
        {{
          currentSubmissionDetails?.aiPercentage == 0 ? ('report.this_text_has_a_high_probability_of_being_written_by_a_human' | translate) :
          (currentSubmissionDetails?.aiPercentage > 0 && currentSubmissionDetails?.aiPercentage < 10) ? ('report.this_text_has_a_moderate_probability_of_being_written_by_a_human' | translate) :
          (currentSubmissionDetails?.aiPercentage >= 10 && currentSubmissionDetails?.aiPercentage < 60) ? ('report.this_text_likely_contains_snippets_of_text_generated_by_ai' | translate) :
          (currentSubmissionDetails?.aiPercentage >= 60 && currentSubmissionDetails?.aiPercentage < 90) ? ('report.this_text_has_a_moderate_probability_of_being_ai_generated' | translate) :
          (currentSubmissionDetails?.aiPercentage >= 90 && currentSubmissionDetails?.aiPercentage <= 100) ? ('report.this_text_has_a_high_probability_of_being_ai_generated' | translate) : ''
        }}
          <!-- {{
          currentSubmissionDetails?.classification !=
          'This text likely contains snippets of text generated by AI'
          ? currentSubmissionDetails?.classification
          : 'This text contains text written by AI'
          }} -->
        </div>
        <div *ngIf="displayAiText && !displayManipulations  && currentSubmissionId == 'clnvm3cjk0009eod5m7iq0w1p'"
          class="ai-models white-bg border">
          <div *ngIf="ai_model == 'performance' && displayAiText">
            <img src="../../../assets/images/icons/Group.png" alt="">
            <span>{{ 'report.performance_mode' | translate }}</span>
            <i class="fas fa-info-circle"></i>
          </div>
          <div *ngIf="ai_model == 'base' && displayAiText">
            <img src="../../../assets/images/icons/Group 33841.png" alt="">
            <span>{{ 'report.base_mode' | translate }}</span>
            <i class="fas fa-info-circle"></i>
          </div>
          <label class="switch" [ngClass]="{
              disable:currentSubmissionDetails?.aiPercentagePerformance?.toFixed(2) == -1
           }">
            <input name="AImodel" id="AImodel" type="checkbox" (click)="changeModel()"
              [disabled]=" currentSubmissionDetails?.aiPercentagePerformance?.toFixed(2) == -1" />

            <span class="slider round"></span>
          </label>
        </div>
        <div tabindex="0" [attr.aria-label]="('aria-labels.there_are') + ': ' + aiSentences.length + ' total sentences!'"
          class="flex border shadow white-bg aidetector-details" *ngIf="displayAiText && !displayManipulations">
          <span>{{ 'report.total_sentences' | translate }}:</span><span>{{ aiSentences.length }}</span>
        </div>
        <div tabindex="0" [attr.aria-label]="('aria-labels.there_are') + ': ' + totalSentencesWithAI + ' ' + ('aria-labels.ai_written_sentences' | translate)"
          class="flex border shadow white-bg aidetector-details" *ngIf="displayAiText && !displayManipulations">
          <span>{{ 'report.ai_sentences' | translate }}:</span><span>{{ totalSentencesWithAI }}</span>
        </div>
        <div tabindex="0"
          [attr.aria-label]="('aria-labels.there_are') + ': ' + (aiSentences.length - totalSentencesWithAI) + ' ' + ('aria-labels.human_written_sentences' | translate)"
          class="flex border shadow white-bg aidetector-details" *ngIf="displayAiText  && !displayManipulations">
          <span>{{ 'report.human_sentences' | translate }}:</span><span>{{ aiSentences.length - totalSentencesWithAI }}</span>
        </div>
      </section>
      <div *ngIf="!displayAiText && displayManipulations" class="flex border shadow manipulations">

        <section class="flag charReplacement" [ngClass]="{'active': isCharReplacement}">
          <section (click)="openFlag('charReplacement')" class="flag-title">
            <section class="flag-count"><span class="counter">{{ totalCharReplacements }}</span></section>
            <section class="flag-body">
              <h5><img src="../../../assets/images/flag.svg" alt=""> {{ 'new_entries.character_replacement' | translate }}</h5>
              <p>{{ 'new_entries.character_replacement_description' | translate }}</p>
            </section>
            <button class="flag-toggle" [ngClass]="isCharReplacement ? 'rotate' : ''" *ngIf="includedCharactersSentences.length > 0 && excludedCharactersSentences.length > 0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 5V19" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 12L12 19L5 12" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </section>
          <section class="found-flags" [ngClass]="!isCharReplacement ? 'closed' : ''" *ngIf="includedCharactersSentences.length > 0 || excludedCharactersSentences.length > 0">
            <section class="flagged-content">
              <h5 *ngIf="includedCharactersSentences.length > 0">{{ 'new_entries.content_flagged' | translate }}</h5>
              <ng-container *ngFor="let sentence of includedCharactersSentences">
                <section class="content" (click)="selectSentence($event); scrollPage(sentence.page)" *ngIf="sentence.replacedCharacters || sentence.replacedCharacters.length > 0">
                  <img src="../../../assets/images/flag.svg" alt="">
                  <section class="content-body">
                    <h6>{{ 'new_entries.flag' | translate }}</h6>
                    <p>{{ sentence.text }}</p>
                  </section>
                </section>
              </ng-container>
              <h5 class="excluded-content-title" *ngIf="excludedCharactersSentences.length > 0">Excluded Content</h5>
              <ng-container *ngFor="let sentence of excludedCharactersSentences">
                <section class="content excluded" (click)="selectSentence($event); scrollPage(sentence.page)" *ngIf="sentence.replacedCharacters && sentence.replacedCharacters.length > 0">
                  <img src="../../../assets/images/flag.svg" alt="">
                  <section class="content-body">
                    <h6>{{ 'new_entries.flag' | translate }}</h6>
                    <p>{{ sentence.text }}</p>
                  </section>
                </section>
              </ng-container>
            </section>
            <section class="actionBtns">
              <button class="includeCharBtn" [ngClass]="allManipulations.excludedReplacedCharacters.length == 0 ? 'disabled': ''" (click)="openCharReplacementModal('include')">Include Characters</button>
              <button class="excludeCharBtn" [ngClass]="allManipulations.replacedCharacters.length == 0 ? 'disabled': ''" (click)="openCharReplacementModal('exclude')">Exclude Characters</button>
            </section>
          </section>
        </section>

        <section class="flag hiddenText" [ngClass]="{'active': isHiddenText}">
          <section (click)="openFlag('hiddenText')" class="flag-title">
            <section class="flag-count"><span class="counter">{{ totalWhiteCharacters }}</span></section>
            <section class="flag-body">
              <h5><img src="../../../assets/images/flag.svg" alt="">{{ 'new_entries.hidden_text' | translate }}</h5>
              <p>{{ 'new_entries.hidden_text_description' | translate }}</p>
            </section>
            <button class="flag-toggle" [ngClass]="isHiddenText ? 'rotate' : ''" *ngIf="totalWhiteCharacters > 0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 5V19" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 12L12 19L5 12" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </section>
          <section class="found-flags" [ngClass]="!isHiddenText ? 'closed' : ''" *ngIf="includedHiddenTextSentences.length > 0 || excludedHiddenTextSentences.length > 0">
            <!-- <h5>{{ 'new_entries.content_flagged' | translate }}</h5> -->
            <section class="flagged-content">
              <h5 *ngIf="includedHiddenTextSentences.length > 0">{{ 'new_entries.content_flagged' | translate }}</h5>
              <ng-container *ngFor="let sentence  of includedHiddenTextSentences">
                <section class="content" (click)="selectSentence($event); scrollPage(sentence.page)" *ngIf="sentence.characters && sentence.characters.length > 0">
                  <img src="../../../assets/images/flag.svg" alt="">
                  <section class="content-body">
                    <h6>{{ 'new_entries.flag' | translate }}</h6>
                    <p>{{ sentence.text }}</p>
                  </section>
                </section>
              </ng-container>
              <h5 class="excluded-content-title" *ngIf="excludedHiddenTextSentences.length > 0">Excluded Content</h5>
              <ng-container *ngFor="let sentence of excludedHiddenTextSentences">
                <section class="content excluded" (click)="selectSentence($event); scrollPage(sentence.page)" *ngIf="sentence.characters && sentence.characters.length > 0">
                  <img src="../../../assets/images/flag.svg" alt="">
                  <section class="content-body">
                    <h6>{{ 'new_entries.flag' | translate }}</h6>
                    <p>{{ sentence.text }}</p>
                  </section>
                </section>
              </ng-container>
            </section>
          </section>
        </section>

        <section class="flag isImage" [ngClass]="{'active': isImage}">
          <section (click)="openFlag('isImage')" class="flag-title">
            <section class="flag-count"><span class="counter">{{ totalManipulatedImages }}</span></section>
            <section class="flag-body">
              <h5><img src="../../../assets/images/flag.svg" alt="">Image in place  of  text</h5>
              <p>{{ 'new_entries.image_in_place_of_text_description' | translate }}</p>
            </section>
            <button class="flag-toggle" [ngClass]="isImage ? 'rotate' : ''">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 5V19" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 12L12 19L5 12" stroke="#0059B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </section>
          <section class="found-flags" [ngClass]="!isImage ? 'closed' : ''">
            <section class="flagged-content">
              <h5 *ngIf="redImages.length > 0">Red Flags</h5>
              <ng-container *ngIf="redImages.length > 0">
                <div class="content red" *ngFor="let image of redImages" (click)="selectSentence($event); scrollPage(image.page + 1)">
                  <img src="../../../assets/images/flag.svg" alt="">
                  <section class="content-body">
                    <h6>{{ 'new_entries.flag' | translate }}</h6>
                    <p>Image {{ image.index }}</p>
                  </section>
                  <button><img src="../../../assets/images/plus-circle.svg" alt="Plus Button" (click)="includeManipulatedImage(image)"></button>
                </div>
              </ng-container>
              <h5 class="excluded-content-title" *ngIf="greenImages.length > 0">Green Flags</h5>
              <ng-container *ngIf="greenImages.length > 0">
                <div class="content green" *ngFor="let image of greenImages" (click)="selectSentence($event); scrollPage(image.page + 1)">
                  <img src="../../../assets/images/flag.svg" alt="">
                  <section class="content-body">
                    <h6>{{ 'new_entries.flag' | translate }}</h6>
                    <p>Image {{ image.index }}</p>
                  </section>
                  <button><img src="../../../assets/images/x-circle.svg" alt="X Button" (click)="excludeManipulatedImage(image)"></button>
                </div>
              </ng-container>
            </section>
            <section class="actionBtns">
              <button *ngIf="isImage" (click)="refreshPdf()" class="refresh_btn"><img src="../../../assets/images/reload.svg" alt="Reload"> Reload PDF <span class="tooltip" [ngClass]="tooltipVisible ? 'visible' : ''">Click here to refresh the PDF!</span></button>
            </section>
          </section>
        </section>
      </div>

      <section [attr.aria-label]="'aria-labels.document_highlighting_legend' | translate" class="legend-parent" tabindex="0">
        <div class="border shadow white-bg legend desktop-legend" *ngIf="!displayAiText  && !displayManipulations">
          <h1>{{ 'report.legend_highlight' | translate }}</h1>
          <div>
            <div tabindex="0" [attr.aria-label]="'aria-labels.exact_match' | translate" class="parent_exact">
              <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
                matTooltipClass="tooltipCustomCss"
                [matTooltip]="'aria-labels.high_similarity_degree' | translate"
                [attr.aria-label]="'aria-labels.high_similarity_degree' | translate" />
              <!-- <button><img src="../../../assets/images/info-empty.svg" alt=""></button> -->
              <p class="exact_match">{{ 'report.exact_match' | translate }}</p>
            </div>
            <div tabindex="0" [attr.aria-label]="'aria-labels.possibly_altered_text' | translate" class="parent_possibly">
              <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
                matTooltipClass="tooltipCustomCss"
                [matTooltip]="'aria-labels.moderate_similarity' | translate"
                [attr.aria-label]="'aria-labels.moderate_similarity' | translate" />
              <p class="possibly_altered">{{ 'report.possibly_altered_text' | translate }}</p>
            </div>
            <div tabindex="0" [attr.aria-label]="'aria-labels.marked_quotation' | translate" class="parent_quotation" role="group">
              <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
                matTooltipClass="tooltipCustomCss"
                [matTooltip]="'aria-labels.text_resembles_sources' | translate"
                [attr.aria-label]="'aria-labels.text_resembles_sources' | translate" />
              <label class="switch" *ngIf="this.canEdit" tabindex="0" role="option"
                [attr.aria-label]="'aria-labels.switch_show_hide_quotations' | translate">
                <input [checked]="highlightQuotes" type="checkbox" (change)="toggleQuotes()" tabindex="0" />

                <span class="slider round"></span>
              </label>
              <p class="quotation" [ngClass]="{'no_switch': !this.canEdit}">{{ 'report.qoutation' | translate }}</p>
            </div>
            <div tabindex="0" [attr.aria-label]="'aria-labels.removed_highlight_0' | translate" class="parent_removed">
              <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
                matTooltipClass="tooltipCustomCss"
                [matTooltip]="'aria-labels.removed_highlight' | translate"
                [attr.aria-label]="'aria-labels.removed_highlight' | translate" />
              <p class="removed-report">{{ 'report.removed' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="border shadow white-bg legend desktop-legend aidetector-legend" *ngIf="displayAiText  && !displayManipulations">
          <h1>{{ 'report.legend_highlight' | translate }}</h1>
          <!-- <div tabindex="0" [attr.aria-label]="'aria-labels.exact_match' | translate">
            <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
              matTooltipClass="tooltipCustomCss"
              [matTooltip]="'aria-labels.high_similarity_degree' | translate"
              [attr.aria-label]="'aria-labels.high_similarity_degree' | translate" />
            <li class="exact_match">{{ 'report.exact_match' | translate }}</li>
          </div> -->
          <div *ngIf="currentSubmissionId == 'clnvm3cjk0009eod5m7iq0w1p'">
            <div>
              <img src="../../../assets/images/icons/Group 33836.png" alt="" />
              <h3>{{ 'report.base_mode' | translate }} - {{ 'report.ai_text' | translate }}</h3>
            </div>
            <div>
              <img src="../../../assets/images/icons/Group 33838.png" alt="" />
              <h3>{{ 'report.performance_mode_ai' | translate }}</h3>
            </div>

          </div>

          <div *ngIf="currentSubmissionId !== 'clnvm3cjk0009eod5m7iq0w1p'">
            <div class="ai_txt parent_ai">
              <img src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
                matTooltipClass="tooltipCustomCss"
                [matTooltip]="'aria-labels.ai_authorship_likelihood' | translate"
                [attr.aria-label]="'aria-labels.ai_authorship_likelihood' | translate" />
              <li>{{ 'report.ai_text' | translate }}</li>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
  <div [ngClass]="{ 'show-scrollTop': windowScrolled }" class="to-top">
    <button (click)="scrollPageToTop()" [attr.aria-label]="'aria-labels.go_to_top_icon' | translate">
      <i class="fas fa-arrow-circle-up" [attr.alt]="'aria-labels.go_to_top_icon' | translate"></i>
    </button>
  </div>
  <section class="legend-parent">
    <div class="border shadow white-bg legend mobile-legend" *ngIf="!displayAiText && !displayManipulations">
      <h1>{{ 'report.legend_highlight' | translate }}</h1>
      <div>
        <div tabindex="0" class="parent_exact" [attr.aria-label]="'aria-labels.exact_match' | translate">
          <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
            matTooltipClass="tooltipCustomCss"
            [matTooltip]="'aria-labels.high_similarity_degree' | translate"
            [attr.aria-label]="'aria-labels.high_similarity_degree' | translate" />
          <!-- <button><img src="../../../assets/images/info-empty.svg" alt=""></button> -->
          <p class="exact_match">{{ 'report.exact_match' | translate }}</p>
        </div>
        <div tabindex="0" class="parent_possibly" [attr.aria-label]="'aria-labels.possibly_altered_text' | translate">
          <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
            matTooltipClass="tooltipCustomCss"
            [matTooltip]="'aria-labels.moderate_similarity' | translate"
            [attr.aria-label]="'aria-labels.moderate_similarity' | translate" />
          <p class="possibly_altered">{{ 'report.possibly_altered_text' | translate }}</p>
        </div>
        <div tabindex="0" class="parent_quotation" [attr.aria-label]="'aria-labels.marked_quotation' | translate" role="group">
          <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
            matTooltipClass="tooltipCustomCss"
            [matTooltip]="'aria-labels.text_resembles_sources' | translate"
            [attr.aria-label]="'aria-labels.text_resembles_sources' | translate" />
          <label class="switch" *ngIf="this.canEdit" tabindex="0" role="option"
            [attr.aria-label]="'aria-labels.switch_show_hide_quotations' | translate">
            <input [checked]="highlightQuotes" type="checkbox" (change)="toggleQuotes()" tabindex="0" />

            <span class="slider round"></span>
          </label>
          <p class="quotation" [ngClass]="{'no_switch': !this.canEdit}">{{ 'report.qoutation' | translate }}</p>
        </div>
        <div tabindex="0" class="parent_removed" [attr.aria-label]="'aria-labels.removed_highlight_0' | translate">
          <img tabindex="0" src="../../../assets/images/info-empty.svg" alt="" matTooltipPosition="after"
            matTooltipClass="tooltipCustomCss"
            [matTooltip]="'aria-labels.removed_highlight' | translate"
            [attr.aria-label]="'aria-labels.removed_highlight' | translate" />
          <p class="removed-report">{{ 'report.removed' | translate }}</p>
        </div>
      </div>
    </div>
  </section>
</div>

<h1 class="report-info-text" *ngIf="showText" role="status" aria-live="assertive">{{ 'report.generating_offline_report' | translate }}</h1>
