import { SubmissionsService } from './../../services/submissions.service';
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LanguageService } from 'src/app/services/language.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})

export class TranslateComponent  implements OnInit {

  constructor(private submissionsService: SubmissionsService, private languageService: LanguageService, private store: Store, public translate: TranslateService, private cdr: ChangeDetectorRef) { }
  currentUser$;
  ngOnInit(): void {
      const storedLanguage = localStorage.getItem('websiteLanguage');
      const storedUser = JSON.parse(localStorage.getItem('user')).id;
      if (storedLanguage) {
        this.selectedLanguage = storedLanguage;
      }
      if (storedUser) {
        this.currentUser = storedUser;
      }

      this.languageService.selectedLanguage$.subscribe((language: string) => {
        this.selectedLanguage = language;
        this.translate.use(language);
      });

      this.getLanguages('website')
  }

  currentUser: string;
  selectedLanguage: string = '0';
  supportedLanguages: any[] = [];
  languages = [
    {
        "code": "bg",
        "title": "Bulgarian",
    },
    {
        "code": "bs",
        "title": "Bosnian",
    },
    {
        "code": "cs",
        "title": "Czech",
    },
    {
        "code": "de",
        "title": "German",
    },
    {
        "code": "el",
        "title": "Greek",
    },
    {
        "code": "en",
        "title": "English",
    },
    {
        "code": "es",
        "title": "Spanish",
    },
    {
        "code": "fi",
        "title": "Finnish",
    },
    {
        "code": "fr",
        "title": "French",
    },
    {
        "code": "hr",
        "title": "Croatian",
    },
    {
        "code": "hu",
        "title": "Hungarian",
    },
    {
        "code": "it",
        "title": "Italian",
    },
    {
        "code": "lt",
        "title": "Lithuanian",
    },
    {
        "code": "lv",
        "title": "Latvian",
    },
    {
        "code": "mk",
        "title": "Macedonian",
    },
    {
        "code": "nl",
        "title": "Dutch",
    },
    {
        "code": "no",
        "title": "Norwegian",
    },
    {
      "code": "no_bokmal",
      "title": "Norwegian Bokmål",
    },
    {
      "code": "no_nynorsk",
      "title": "Norwegian Nynorsk",
    },
    {
        "code": "pl",
        "title": "Polish",
    },
    {
        "code": "pt",
        "title": "Portuguese",
    },
    {
        "code": "ro",
        "title": "Romanian",
    },
    {
        "code": "ru",
        "title": "Russian",
    },
    {
        "code": "sk",
        "title": "Slovak",
    },
    {
        "code": "sl",
        "title": "Slovenian",
    },
    {
        "code": "sq",
        "title": "Albanian",
    },
    {
        "code": "sr",
        "title": "Serbian",
    },
    {
        "code": "sv",
        "title": "Swedish",
    },
    {
        "code": "tr",
        "title": "Turkish",
    }
]

  selectLanguage(language: string) {
    this.selectedLanguage = language;
    localStorage.setItem('websiteLanguage', language);
    this.submissionsService.setLanguage(language).subscribe((data) => {
    });
    this.languageService.setSelectedLanguage(language);
  }

  getLanguages(type: string) {
    this.submissionsService.getLanguages(type).subscribe((data) => {
      this.currentUser = data.userId;
      this.languageService.setUserId(data.userId);
      if (type == 'website') {
        this.supportedLanguages = data.webLanguages;
        this.cdr.detectChanges();
      }
    });
  }
}
