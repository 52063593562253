<ng-container *ngIf="this.currentUserDetails?.institutionId === null">
   <div *ngIf="this.userRole == '1'" class="flex register-monitoruesi">
      <h4 class="bold">{{ 'general.add_credit' | translate }}</h4>
      <a routerLink="/admin/register" class="plus active upload-mobile"
         ><svg
            is="plus"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="66"
            viewBox="0 0 33 66"
         >
            <text
               id="_"
               data-name="+"
               transform="translate(0 52)"
               fill="#fff"
               font-size="54"
               font-weight="700"
            >
               <tspan x="0" y="0">+</tspan>
            </text>
         </svg>
         <h5>{{ 'general.register' | translate }}</h5>
      </a>
   </div>
   <section class="add-credit">
      <h4 class="bold w-87">{{ 'general.add_credits' | translate }}</h4>
      <main
         *ngIf="hasRoute('add-credits') && this.route.children.length == 0"
         class="border white-bg"
      >
         <div class="content">
            <h4 class="bold">{{ 'add_credits.pricing_plans' | translate }}</h4>
            <div class="flex">
               <div class="pricing-plans">
                  <h5>{{ 'add_credits.pay_as_you_go' | translate }}</h5>
                  <div class="price">
                     <h1>
                        9.95€
                        <div></div>
                     </h1>
                  </div>
                  <div class="flex">
                     <h6>
                        5,000 {{ 'general.words' | translate }} /
                        <b>50 {{ 'general.credits' | translate }}</b>
                     </h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.crosslingual' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.single_language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.no_daily_limits' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.support' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.document_check' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <a (click)="order()"> {{ 'general.checkout' | translate }} </a>
               </div>
               <div class="pricing-plans">
                  <h5>{{ 'add_credits.bundle' | translate }}</h5>
                  <div class="price">
                     <h1>
                        149.95€
                        <div></div>
                     </h1>
                  </div>
                  <div class="flex">
                     <h6>
                        100,000 {{ 'general.words' | translate }} /
                        <b>1000 credits</b>
                     </h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.crosslingual' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.single_language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.no_daily_limits' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.priority_support' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'add_credits.faster_document_check' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <a (click)="orderBundle()"> {{ 'general.checkout' | translate }} </a>
               </div>
               <div class="pricing-plans">
                  <div>
                     <h5>{{ 'app.institution' | translate }}</h5>
                     <div class="price"></div>
                  </div>
                  <div>{{ 'add_credits.institutional_text' | translate }}!</div>

                  <a routerLink="/individual/add-credits/contact-us"
                     >{{ 'help_center.contact_us.contact_us' | translate }}!</a
                  >
               </div>
            </div>
         </div>
      </main>
   </section>
   <ng-container *ngIf="hasRoute('contact-us')">
      <app-contact-us-bank></app-contact-us-bank>
   </ng-container>
</ng-container>

<ng-container *ngIf="this.currentUserDetails?.institutionId !== null">
   <div *ngIf="this.userRole == '1'" class="flex register-monitoruesi">
      <h4 class="bold">{{ 'general.add_credit' | translate }}</h4>
      <a routerLink="/admin/register" class="plus active upload-mobile"
         ><svg
            is="plus"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="66"
            viewBox="0 0 33 66"
         >
            <text
               id="_"
               data-name="+"
               transform="translate(0 52)"
               fill="#fff"
               font-size="54"
               font-weight="700"
            >
               <tspan x="0" y="0">+</tspan>
            </text>
         </svg>
         <h5>{{ 'general.register' | translate }}</h5>
      </a>
   </div>
   <section class="add-credit">
      <h4 class="bold w-87">{{ 'add_credits.pricing_plans_1' | translate }}</h4>
      <main
         *ngIf="hasRoute('add-credits') && this.route.children.length == 0"
         class="border white-bg"
      >
         <div
            style="
               display: flex;
               justify-content: space-evenly;
               align-items: center;
               background: #49bbfc;
               border-radius: 10px;
            "
         >
            <div>
               <h4 style="color: white; padding: 10px">
                  To make purchasing process easier for you we made a guide,
                  give it a look by
                  <a
                     href="https://crossplag-asset-migration.s3.eu-central-1.amazonaws.com/Guides/Kandidati_Payments_English.pdf"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     clicking here.</a
                  >
               </h4>
            </div>
            <div></div>
         </div>
         <div class="content">
            <h4 class="bold">{{ 'app.offer.institutional_purchasing_offers' | translate }}</h4>
            <div class="flex">
               <div class="pricing-plans">
                  <h5>{{ 'add_credits.custom' | translate }}</h5>
                  <div class="price">
                     <h1>
                        8.95€
                        <div></div>
                     </h1>
                  </div>
                  <div class="flex">
                     <h6>
                        5,000 {{ 'general.words' | translate }} /
                        <b>50 {{ 'general.credits' | translate }}</b>
                     </h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.translated_language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.no_daily_limits' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.live_chat_support' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.institutional_discount' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <a
                     *ngIf="this.reSubmissions === 0"
                     (click)="orderInstitutional()"
                  >
                     {{ 'general.checkout' | translate }}
                  </a>
                  <a
                     *ngIf="this.reSubmissions !== 0"
                     style="opacity: 0.6; cursor: not-allowed"
                  >
                     {{ 'general.checkout' | translate }}
                  </a>
               </div>
               <div class="pricing-plans">
                  <h5>{{ 'app.phd_thesis' | translate }}</h5>
                  <div class="price">
                     <h1>
                        99.99€
                        <div></div>
                     </h1>
                  </div>
                  <div class="flex">
                     <h6>
                        {{ 'general.unlimited' | translate }} {{ 'general.words' | translate }} /
                        <b>1 Document</b>
                     </h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.translated_language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.no_daily_limits' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.live_chat_support' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div class="flex">
                     <h6>{{ 'general.institutional_discount' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <a
                     *ngIf="this.reSubmissions === 0"
                     (click)="orderThesisInstitutional()"
                  >
                     {{ 'general.checkout' | translate }}
                  </a>
                  <a
                     *ngIf="this.reSubmissions !== 0"
                     style="opacity: 0.6; cursor: not-allowed"
                  >
                     {{ 'general.checkout' | translate }}
                  </a>
               </div>
               <div class="pricing-plans">
                  <h5>DRAFT</h5>
                  <div class="price">
                     <h1>
                        49.99€
                        <div></div>
                     </h1>
                  </div>
                  <div
                     class="flex"
                     style="padding-left: 40px; padding-right: 40px"
                  >
                     <h6>
                        {{ 'general.unlimited' | translate }} {{ 'general.words' | translate }} /
                        <b>1 {{ 'general.table.draft' | translate }}</b>
                     </h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div
                     class="flex"
                     style="padding-left: 40px; padding-right: 40px"
                  >
                     <h6>{{ 'general.translated_language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div
                     class="flex"
                     style="padding-left: 40px; padding-right: 40px"
                  >
                     <h6>{{ 'general.language' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div
                     class="flex"
                     style="padding-left: 40px; padding-right: 40px"
                  >
                     <h6>{{ 'general.no_daily_limits' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div
                     class="flex"
                     style="padding-left: 40px; padding-right: 40px"
                  >
                     <h6>{{ 'general.live_chat_support' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <div
                     class="flex"
                     style="padding-left: 40px; padding-right: 40px"
                  >
                     <h6>{{ 'general.institutional_discount' | translate }}</h6>
                     <img src="../../assets/images/tick2.png" alt="" />
                  </div>
                  <a
                     *ngIf="this.reSubmissions !== 0"
                     (click)="orderReSubmission()"
                  >
                     {{ 'general.checkout' | translate }}
                  </a>
                  <a
                     *ngIf="this.reSubmissions === 0"
                     style="opacity: 0.6; cursor: not-allowed"
                  >
                     {{ 'general.checkout' | translate }}
                  </a>
               </div>
            </div>
         </div>
      </main>
   </section>
   <ng-container *ngIf="hasRoute('contact-us')">
      <app-contact-us-bank></app-contact-us-bank>
   </ng-container>
</ng-container>
