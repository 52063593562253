<div class="documents-2 shadow white-bg">
   <div
      *ngIf="
         !(
            this.totalDepartments == 0 &&
            departmentName == undefined &&
            faculty == '-1'
         )
      "
      class="filtersContainer"
   >
      <h4>{{ 'general.overview' | translate }}</h4>
      <div class="selectors">
         <!-- <div>
            <select [(ngModel)]="faculty" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_institution' | translate }}
               </option>
               <option
                  *ngFor="let faculty of this.faculties"
                  value="{{ faculty.id }}"
               >
                  {{ faculty.name }}
               </option>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div> -->
         <div>
            <input
               [placeholder]="'placeholders.search_by_name' | translate"
               [(ngModel)]="departmentName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </div>

   <div
      *ngIf="this.totalDepartments != 0"
      class="tableWithExpandedInfoDepartments tablewithRatios"
   >
      <ng-container
         *ngFor="
            let department of this.departments
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalDepartments
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">{{ department.name }}</h4>
               <div class="container_data">
                  <div>
                     <span>{{ 'general.educators' | translate }}</span>
                     <span>{{ 'general.students' | translate }}</span>
                     <span>{{ 'general.subjects' | translate }}</span>
                     <span>{{ 'general.assignments' | translate }}</span>
                     <span>{{ 'general.submissions' | translate }}</span>
                     <!-- <span>{{ 'app.educators_submissions' | translate }}</span> -->
                     <!-- <span>{{ 'general.thesis' | translate }}</span> -->
                     <span>{{ 'general.document_language_similarity' | translate }}</span>
                     <span>{{ 'general.translation_language_similarity' | translate }}</span>
                  </div>
                  <div>
                     <span
                        (click)="
                           professorFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.professors }}</span
                     >
                     <span
                        (click)="
                           studentsFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.students }}</span
                     >
                     <span
                        (click)="
                           subjectsFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.subjects }}</span
                     >
                     <span
                        (click)="
                           assignmentFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.assignments }}</span
                     >
                     <span
                        (click)="
                           assignmentsSubmissionsFilter(
                              department.id,
                              department.facultyId
                           )
                        "
                        class="bold clickable"
                        >{{ department.assignmentsSubmissions }}</span
                     >
                     <!-- <span
                        (click)="
                           professorsSubmissionsFilter(
                              department.id,
                              department.facultyId
                           )
                        "
                        class="bold clickable"
                        >{{ department.professorsSubmissions }}</span
                     > -->
                     <!-- <span
                        (click)="
                           thesisFilter(department.id, department.facultyId)
                        "
                        class="bold clickable"
                        >{{ department.thesis }}</span
                     > -->
                     <span class="bold">{{
                        department.submissions._avg.originalPercentage === null
                           ? 0
                           : department.submissions._avg.originalPercentage.toFixed(
                                2
                             )
                     }}%</span>
                     <span class="bold">{{
                        department.submissions._avg.translatedPercentage ===
                        null
                           ? 0
                           : department.submissions._avg.translatedPercentage.toFixed(
                                2
                             )
                     }}%</span>
                  </div>
               </div>
               <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >{{ 'general.expand' | translate }}</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >{{ 'general.collapse' | translate }}</a
               >
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }"
               class="moreInfo"
               style="grid-template: none !important"
            >
<div class="details">
   <span>
      {{ 'app.most_used_language' | translate }}:
      <b>{{ setLanguageName(department.language?department.language[0]:'-') }}</b>
      <!-- <b>{{
         department.language === undefined
            ? '-'
            : department.language[0]
      }}</b></span -->
      </span>
   >
   <span>
      Bachelor theses: <b>{{ department.bachelor }}</b></span
   >
   <span>
      Master theses: <b>{{ department.master }}</b></span
   >
   <span>
    {{ 'app.phd_thesis' | translate }}: <b>{{ department.phd }}</b></span
   >
   <span>
      {{ 'report.high_similarity_submissions' | translate }}:
      <b>{{ department.highSimilaritySubmissions }}</b></span
   >
   <span>
      {{ 'report.medium_similarity_submissions' | translate }}:
      <b>{{
         department.mediumSimilaritySubmissions
      }}</b></span
   >
   <span>
      {{ 'report.low_similarity_submissions' | translate }}:
      <b>{{ department.lowSimilaritySubmissions }}</b></span
   >
</div>
<div class="ratios">
{{ 'app.ratios' | translate }}
</div>
<div class="details">
   <span>
      {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.subjects' | translate }}:
      <b
         >{{ department.professors }} : {{
            department.subjects
         }}</b
      ></span
   >
   <span>
      {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.student' | translate }}:
      <b
         >{{ department.professors }} : {{
            department.students
         }}</b
      >
   </span>
   <span>
      {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' | translate }}:<b
         > {{ department.professors }} : {{
            department.submissions._count
         }}</b
      >
   </span>
   <span>
      {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.subjects' | translate }}:
      <b
         >{{ department.students }} : {{
            department.subjects
         }}</b
      >
   </span>
   <span>
      {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' | translate }}:
      <b
         >{{ department.students }} : {{
            department.submissions._count
         }}</b
      ></span
   >
</div>
               </div>
            </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.totalDepartments == 0 &&
         (departmentName != undefined || faculty != '-1')
      "
   >
      No departments found
   </div>
   <pagination-controls
      *ngIf="this.totalDepartments != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.totalDepartments == 0 &&
         departmentName == undefined &&
         faculty == '-1'
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">{{ 'app.there_are_no_departments_registered_in_your_instutition' | translate }}.
         <a
           routerLink ="/administrator/register-module/department-register"
           routerLinkAction="active">{{ 'general.click_here' | translate }}</a>
          {{ 'app.to_begin_registering_your_first_department' | translate }}.</h5>
   </div>
</div>
