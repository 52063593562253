import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogContent
} from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import swal from 'sweetalert2';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { Store } from '@ngrx/store';
import * as reportActions from '../../state/actions/report.actions';
import { tap } from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-char-replacement-actions',
  templateUrl: './char-replacement.component.html',
  styleUrls: ['./char-replacement.component.scss'],
})
export class CharReplacementModal {
  result: any;
  selectionState: boolean[]; // Array to store the state of each checkbox
  modalType: string;

   /**
    * Constructor Component
    * @param store
    * @param dialogRef
    * @param data
    */
   constructor(
    public dialogRef: MatDialogRef<CharReplacementModal>,
    public dialog: MatDialog,
    private languageService: LanguageService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private submissionService: SubmissionsService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
 ) {
    this.result = data.manipulations;
    this.selectionState = new Array(this.result.length).fill(false);
    this.modalType = data.type;
  }

  // ngOnInit() {
  // }

  toggleSelectAll(event) {
    const checked = event.target.checked;
    this.selectionState = this.selectionState.fill(checked);
  }

  onSubmit(type) {
    const toggledCharacters = this.result.filter((char, index) => this.selectionState[index]);
    if (type == 'exclude') {
      swal.fire({
        title: 'Are you sure you want to exclude the selected characters?',
        showDenyButton: true,
        confirmButtonText: this.translate.instant('general.yes'),
        denyButtonText: this.translate.instant('general.no'),
      }).then((result) => {
        if (result.isConfirmed) {
            this.spinner.show();
            this.submissionService
            .toggleReplacedCharacters(this.data.submissionId, toggledCharacters, 1)
            .pipe()
            .subscribe((data: any) => {
              this.store.dispatch(
                reportActions.getSubmissionPlag({
                  presignedUrl: data?.presignedUrlJson,
                })
              )
              this.spinner.hide();
              this.dialogRef.close();
              swal.fire('Characters excluded successfully!', '', 'success');
            })
        } else if (result.isDenied) {
          swal.fire('Characters are  not excluded', '', 'info');
        }
      });
    } else if (type == 'include') {
      swal.fire({
        title: 'Are you sure you want to include the selected characters?',
        showDenyButton: true,
        confirmButtonText: this.translate.instant('general.yes'),
        denyButtonText: this.translate.instant('general.no'),
      }).then((result) => {
        if (result.isConfirmed) {
            this.spinner.show();
            this.submissionService
            .toggleReplacedCharacters(this.data.submissionId, toggledCharacters, 2)
            .pipe()
            .subscribe((data: any) => {
              this.store.dispatch(
                reportActions.getSubmissionPlag({
                  presignedUrl: data?.presignedUrlJson,
                })
              )
              this.spinner.hide();
              this.dialogRef.close();
              swal.fire('Characters included successfully!', '', 'success');
            })
        } else if (result.isDenied) {
          swal.fire('Characters are  not included', '', 'info');
        }
      });

    }


  }


  cancel() {
    this.dialog.closeAll();
  }
}
