<div class="billing-information">
   <main>
      <div>
         <h5>{{ 'profile.current_plan' | translate }}</h5>
         <h5 class="bold">{{ 'general.basic' | translate }}</h5>
         <a href="">{{ 'profile.change_plan' | translate }}</a>
      </div>
      <div>
         <h5>{{ 'profile.payment_method' | translate }}</h5>
         <h5 class="bold">{{ 'other.stripe' | translate }}</h5>
         <a href="">{{ 'profile.change_method' | translate }}</a>
      </div>
   </main>
</div>
