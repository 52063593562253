import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as submissionsActions from '../../individual-components/state/actions/submissions.actions';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
// import { TawkService } from '../../services/tawk.service';
import * as individualAdministratorActions from '../state/action/individualAdministrator.actions';
import { getIndividualAdministratorStateDetails } from '../state/selector/individualAdministrator.selectors';
import { DraftMessageRequestComponent } from '../modals/draft-message-request/draft-message-request.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentSubmissionsStateDetails } from '../state/selector/individualAdministrator.selectors';
import { TranslateService } from '@ngx-translate/core';
/**
 * Individual Dashboard Component
 */

@Component({
   selector: 'app-individual-administrator-dashboard',
   templateUrl: './individual-administrator-dashboard.component.html',
   styleUrls: ['./individual-administrator-dashboard.component.scss'],
})
export class IndividualAdministratorDashboardComponent
   implements OnInit, OnDestroy {
   /**
    * Variable used to store state store
    */
   submissionsDetails;

   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 2;

   /**
    * Representing tile model
    */
   title: string;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Observable used to store state slice from state store.
    */
   submissionsDetails$;
   /**
    * Used to store user data from the store
    */
   user;
   /**
    * Subscriber to subscribe to user state in the store.
    */
   user$;
   /**
    * Used to show zero state and search result state if filter are active
    */
   isFilterActive: boolean = false;
   /**
    * Filter for the submissions percentage
    */
   filter: number = -1;
   reSubmission;
   expandInfo: any;
   submissionPage = 1;
   documentSubmissionsDetails: any;
   subId: any;
   /**
    * Component constructor.
    * @param store
    * @param router
    * @param toastr
    */

   constructor(
      private store: Store,
      private toastr: ToastrService,
      // private tawkService: TawkService,
      public dialog: MatDialog,
      public translate: TranslateService
   ) { }
   ngOnDestroy(): void {
      this.user$.unsubscribe();
      this.submissionsDetails$.unsubscribe();
   }
   /**
    * A lifecycle hook that is called after Angular has fully initialized a component's view.
    */
   ngAfterViewInit(): void {
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });
   }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      // this.tawkService.SetChatVisibility(true);

      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
            // this.tawkService.UpdateTawkUser(this.user);
         }
      });

      this.store.dispatch(
         individualAdministratorActions.administratorIndividualDashboardDetails(
            { page: 1 }
         )
      );

      this.submissionsDetails$ = this.store
         .select(getIndividualAdministratorStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.submissionsDetails = data;
            }
         });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page and title as params.
    * @param page
    */
   changePage(page): void {
      this.page = page;
      this.store.dispatch(
         individualAdministratorActions.administratorIndividualDashboardDetails(
            { page, title: this.title }
         )
      );
   }

   /**
    * Method is used to toggle a submission access that was submitted by a student.
    * @param id
    */
   toggleAccess(id) {
      this.store.dispatch(
         individualAdministratorActions.toggleSubmissionAccess({
            id,
            page: this.page,
            title: this.title,
         })
      );
   }

   /**
    * Method is used to toggle a submission access that was submitted by a student.
    * @param id
    */
   toggleDraft(id) {
      this.dialog.open(DraftMessageRequestComponent, {
         data: {
            submissionId: id,
            page: this.page,
            title: this.title,
         },
         width: '50%',
      });
   }

   /**
    * Method used to open report in a new tab.
    * @param url
    */
   goToLink(id: string): void {
      let url = `${environment.currentUrl}/vleresues/submission/${id}/report/v2`;
      window.open(url, '_blank');
   }
   /**
    * Method used filter submissions by title
    */
   titleFilter(): void {
      this.filter = undefined;
      let time;
      time = 800;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            individualAdministratorActions.administratorIndividualDashboardDetails(
               { page: this.page, filter: this.filter, title: this.title }
            )
         );
      }, time);
   }
   /**
    * Method used to show warning toaster message
    * @param message
    */
   showWarning(message): void {
      this.toastr.warning(message);
   }
   /**

  * Method used to alert user before deleting a submission.
  * @param id submission Id.
  */
   async deleteSubmissionAlert(id, showSubmission): Promise<void> {
      const result = await swal.fire({
         title: 'Delete this document?',
         text: 'You will not be able to recover it',
         icon: 'warning',
         confirmButtonText: 'Yes, delete it',
         confirmButtonColor: '#f34135',
         showCancelButton: true,
         cancelButtonText: this.translate.instant('report.cancel'),
         focusCancel: true,
      });
      if (result.isConfirmed) {
         this.deleteSubmission(id, showSubmission);
      }
   }
   /**
    * Method used to delete a submission
    * @param id submission id
    */
   deleteSubmission(id, showSubmission) {
      this.store.dispatch(
         submissionsActions.deleteSubmission({
            id: id,
            showSubmission: !showSubmission,
         })
      );

      swal.fire('Deleted!', 'Document deleted successfully', 'success');
   }

   expand(submissionId) {
      this.subId = submissionId;
      if (this.expandInfo !== submissionId) {
         this.expandInfo = submissionId;
         this.store.dispatch(
            individualAdministratorActions.getDocumentSubmissions({
               submissionId: this.subId,
               page: this.submissionPage,
            })
         );

         this.submissionsDetails$ = this.store
            .select(DocumentSubmissionsStateDetails)
            .subscribe((data) => {
               if (data !== null) {
                  this.documentSubmissionsDetails = data.allSubmissions;
               }
            });
      } else {
         this.expandInfo = -1;
      }
   }
}
