import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
   selector: 'app-upload',
   templateUrl: './upload.component.html',
   styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
   userRole;
   assignmentId
   currentLanguage: string = localStorage.getItem('websiteLanguage');
   currentUserRole = JSON.parse( localStorage.getItem('role'))
   constructor(private router: Router, public route: ActivatedRoute, private authService: AuthService, private languageService: LanguageService, public translate: TranslateService) {}

   ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });

      this.userRole = this.authService.isLoggedInRole();
      this.assignmentId = JSON.parse(localStorage.getItem('aId'));
   }
}
