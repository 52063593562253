import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-overview-disclaimer',
  templateUrl: './overview-disclaimer.component.html',
  styleUrls: ['./overview-disclaimer.component.scss']
})
export class OverviewDisclaimerComponent {
  @Input() title: string = '';
  @Input() disclaimerText: string = '';
  @Input() disclaimerLink: string | null = null;
  @Input() linkText: string | null = '';
}
