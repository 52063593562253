import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class StudentStatisticsService {
   constructor(private http: HttpClient) {}

   studentOverview() {
      return this.http.get<any>(`${environment.apiUrl}/student/overview`).pipe(
         map((data) => {
            return data;
         })
      );
   }

   studentGraph(year) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/submissions/graph/?year=${year}`
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   studentSubjects(page, title) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/subjects/statistics/?page=${page}` +
               (title ? `&title=${title}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   studentAssignments(page, title, subjectId, assignmentId = null, submissionId = null) {
      let url = `${environment.apiUrl}/student/assignments/statistics/?page=${page}`;

      if (title) {
         url += `&title=${title}`;
      }

      if (subjectId) {
         url += `&subjectId=${subjectId}`;
      }

      if (assignmentId) {
         url += `&assignmentId=${assignmentId}`;
      }
      if (submissionId) {
         url += `&submissionId=${submissionId}`;
      }

      return this.http
         .get<any>(url)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   studentAssignmentsSubmissions(page, title, subjectId, assignmentId) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/assignments/submissions/statistics/?page=${page}` +
               (title ? `&title=${title}` : '') +
               (subjectId ? `&subjectId=${subjectId}` : '') +
               (assignmentId ? `&assignmentId=${assignmentId}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   studentThesisSubmissions(page, title) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/thesis/statistics/?page=${page}` +
               (title ? `&title=${title}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   countSubjectsAndAssignments() {
      return this.http
         .get<any>(`${environment.apiUrl}/student/subjects/assignments/counts`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   countSubmissionsAndThesis() {
      return this.http
         .get<any>(`${environment.apiUrl}/student/submissions/thesis/counts`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   documentSubmissions(submissionId) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/submission/${submissionId}/student/document`
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
}
