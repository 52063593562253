<article class="matching_sources" [ngClass]="isOnAnalysis ? 'isOnAnalysis' : ''">
   <ng-container *ngIf="!isOnAnalysis; else onAnalysis">
    <header>
      <section class="header-section">
        <section class="header-label" tabindex="0" aria-label="Similarity Analyses">
          <img src="../../../../../../assets/report_v2/similarity_analyses.svg" alt="Original language logo/image">
          <section>
            <h3>{{ 'new_entries.similarity_analyses' | translate }}</h3>
            <span tabindex="0" aria-label="Percentage shows similarity to existing sources">{{ 'new_entries.similarity_percentage' | translate }}</span>
          </section>
        </section>
        <button (click)="toggleIsOnAnalysis()" tabindex="0">
          <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow right. Click to go to the language analysis.">
        </button>
      </section>
    </header>
   </ng-container>
   <ng-template #onAnalysis>
    <header>
      <section class="header-section">
        <section class="header-label" tabindex="0" aria-label="Similarity Analyses">
          <img src="../../../../../../assets/report_v2/similarity_analyses.svg" alt="Original language logo/image">
          <section>
            <h3>{{ 'new_entries.similarity_analyses' | translate }}</h3>
            <span tabindex="0" aria-label="Percentage shows similarity to existing sources">{{ 'new_entries.similarity_percentage' | translate }}</span>
          </section>
        </section>
        <button (click)="toggleIsOnAnalysis()" tabindex="0">
          <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow left. Click to go to the Summary.">
        </button>
      </section>
    </header>
     <!-- <header>
       <section class="header-section isOnAnalysis">
         <button (click)="toggleIsOnAnalysis()" tabindex="0">
           <img src="../../../../../../assets/report_v2/arrow-right.svg" alt="Arrow right. Click to go to the language analysis">
         </button>
         <section class="header-label" tabindex="0" aria-label="Similarity Analyses">
           <img src="../../../../../../assets/report_v2/doc-search.svg" alt="Language Analysis Logo/Image">
           <h3>Similarity analyses</h3>
         </section>
       </section>
       <span tabindex="0" aria-label="Percentage shows similarity to existing sources">Percentage shows similarity to existing sources</span>
     </header> -->
   </ng-template>
  <app-language-bar [canEdit]="canEdit" [isOnAnalysis]="isOnAnalysis" [similarityAnalysis]="similarityAnalysis"></app-language-bar>
</article>
