import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';

import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import validator from 'validator';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { AssignmentService } from '../services/assignment.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-assignment-configuration',
  templateUrl: './assignment-configuration.component.html',
  styleUrls: ['./assignment-configuration.component.scss']
})

export class AssignmentConfigurationComponent implements OnInit, AfterViewInit {
   assignmentForm;
   separatorKeysCodes: number[] = [ENTER, COMMA];
   includeUrlsArray: string[] = [];
   excludeUrlsArray: string[] = [];
   currentUser: any;
   subjects;
   isNew: boolean = true;
   languages = [
      {
         text: 'English',
         value: 'en',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Albanian',
         value: 'sq',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'German',
         value: 'de',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Italian',
         value: 'it',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'French',
         value: 'fr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Spanish',
         value: 'es',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Greek',
         value: 'el',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Czech',
         value: 'cs',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Turkish',
         value: 'tr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Slovak',
         value: 'sk',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Lithuanian',
         value: 'lt',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Latvian',
         value: 'lv',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Polish',
         value: 'pl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Serbian',
         value: 'sr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Macedonian',
         value: 'mk',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Portuguese',
         value: 'pt',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Dutch',
         value: 'nl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Russian',
         value: 'ru',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Bulgarian',
         value: 'bg',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Hungarian',
         value: 'hu',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Romanian',
         value: 'ro',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Slovenian',
         value: 'sl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Swedish',
         value: 'sv',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Finnish',
         value: 'fi',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Croatian',
         value: 'hr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Bosnian',
         value: 'bs',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Norwegian',
         value: 'no',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Danish',
         value: 'da',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Estonian',
         value: 'et',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      }
   ];
   getCurrentUser$: any;
   getSubjectsStateDetails$: any;
   getAssignmentsStateDetails$: any;
   submitClicked = false;
   translatedLanguageEnabled = new FormControl(false);
   selectedTranslatedLanguages = new FormControl([], [Validators.maxLength(3)]);
   additionalSettings = false;
  userId: any;
  assignmentId: any;
  assignmentData:any;
  dataLoaded: boolean = false
   constructor(
      private fb: FormBuilder,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      @Optional() public dialogRef: MatDialogRef<any>,
      private toastrService: ToastrService,
      private route: ActivatedRoute,
      private userService: UserService,
      private assignmentService:AssignmentService,
      private languageService: LanguageService
   ) { }

   ngAfterViewInit(): void {
     setTimeout(() => {
        this.assignmentForm.valueChanges.subscribe((formValues) => {
           this.onFormChange(formValues);
        });
     }, 4000);
   }

   ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.assignmentId = this.route.snapshot.paramMap.get('assignmentId');

      this.assignmentForm = this.fb.group({
         type: [1],
         translatedLanguage: this.selectedTranslatedLanguages,
         aiDetection: [false],
         archive: [false],
         anonymous_submissions: [false],
         exclude_citations: [false],
         submission_word_limit: [0],
         source_percentage_threshold: [0],
         sources: this.fb.group({
            includeSources: this.fb.array([]),
            excludeSources: this.fb.array([]) 
         }),
         contextualSimilarity: [false],
         contextualSimilaritiesThreshold: [80, [Validators.max(100), Validators.min(50)]]
      });

    this.route.params.subscribe(params => {
      let userData;
      let token;
      userData = JSON.parse(params['userData']) 
      token = JSON.parse(params['userData']).token
      localStorage.setItem('ACCESS_TOKEN', JSON.stringify(token));
      localStorage.setItem('role', JSON.stringify(userData.roleId));
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('websiteLanguage', userData.language);

      this.languageService.setSelectedLanguage(userData.language);


      this.userService.userInfo(this.userId).subscribe(response => {
         console.log('response', response.user);
         this.currentUser = response.user
         console.log('currentUser', this.currentUser)
         this.assignmentService.getAssignment(this.assignmentId).subscribe((data) => {
            console.log('Assignment data', data);
            this.assignmentData = data;
  
            // Start patching the form (during initialization)
            this.assignmentForm.controls['type'].setValue(data.type);
            this.assignmentForm.controls['translatedLanguage'].setValue(JSON.parse(data.translatedLanguage));
            this.assignmentForm.controls['aiDetection'].setValue(data.aiDetection || false);
            this.assignmentForm.controls['archive'].setValue(data.archive || false);
            this.assignmentForm.controls['anonymous_submissions'].setValue(data.anonymous_submissions || false);
            this.assignmentForm.controls['exclude_citations'].setValue(data.exclude_citations || false);
            this.assignmentForm.controls['source_percentage_threshold'].setValue(data.source_percentage_threshold || 0);
            this.assignmentForm.controls['contextualSimilarity'].setValue(data.context_similarity);
            this.assignmentForm.controls['contextualSimilaritiesThreshold'].setValue(data.sentence_thresholds?.contextualSimilaritiesThreshold || 80);

            // Handle sources
            this.includeUrlsArray = data.sources?.includeSources || [];
            this.excludeUrlsArray = data.sources?.excludeSources || [];
            this.assignmentForm.controls['sources'].get('includeSources').setValue(this.includeUrlsArray);
            this.assignmentForm.controls['sources'].get('excludeSources').setValue(this.excludeUrlsArray);

            // Set the translated language toggle
            this.translatedLanguageEnabled.setValue(JSON.parse(data.translatedLanguage)?.length > 0);

            this.dataLoaded = true;
         });
      });

    });
    console.log('this.userId', this.userId)
    console.log('this.assignmentId', this.assignmentId)
    
    this.languages.sort((a, b) => a.text.localeCompare(b.text));

    if (this.data !== undefined && this.data !== null) {
       this.isNew = this.data.isNew;
    }

    this.translatedLanguageEnabled.valueChanges.subscribe((enabled) => {
       if (!enabled) {
          this.selectedTranslatedLanguages.setValue([]);
       }
    });
 }

   isSelected(language: string): boolean {
    return this.selectedTranslatedLanguages.value.includes(language);
 }

 selectTranslatedLanguage(event) {
  this.selectedTranslatedLanguages = event.value;
  this.form.translatedLanguage.setValue(this.selectedTranslatedLanguages);
}


   addUrl(event: MatChipInputEvent, type: string): void {
      const input = event.input;
      const value = event.value;

      if (value?.trim()) {
         if (validator.isURL(value, { require_protocol: false })) {
            if (type === 'include') {
               this.includeUrlsArray.push(value.trim());
               this.assignmentForm.controls.sources.get('includeSources').setValue(this.includeUrlsArray);
            } else if (type === 'exclude') {
               this.excludeUrlsArray.push(value.trim());
               this.assignmentForm.controls.sources.get('excludeSources').setValue(this.excludeUrlsArray);
            }
         } else {
            this.toastrService.error(`Invalid URL: ${value}`);
         }
      }

      if (input) {
         input.value = '';
      }
   }

   removeUrl(index: number, type: string): void {
      if (type === 'include') {
         this.includeUrlsArray.splice(index, 1);
         this.assignmentForm.controls.sources.get('includeSources').setValue(this.includeUrlsArray);
      } else if (type === 'exclude') {
         this.excludeUrlsArray.splice(index, 1);
         this.assignmentForm.controls.sources.get('excludeSources').setValue(this.excludeUrlsArray);
      }
   }
   /**
    * Method used to get controls from formGroup
    */
   get form() {
      return this.assignmentForm.controls;
   }

   async createAssignment() {
      this.submitClicked = true;

      if (this.assignmentForm.invalid) {
         return
      }

      const result = await Swal.fire({
         title: 'Confirm create of assignment',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
      });
      if (result.isConfirmed) {
         this.submitClicked = false;
         if (this.isNew) {
         } else {
        
            this.includeUrlsArray = [];
            this.excludeUrlsArray = [];
            this.assignmentForm.controls.sources.get('includeSources').setValue([]);
            this.assignmentForm.controls.sources.get('excludeSources').setValue([]);
            this.dialogRef.close();
         }
      }
   }

   languageSelected(type) {
      if (type === 1) {
         for (let language of this.languages) {
            language.selectedDocLanguage = false;

            if (language.value === this.form.originalLanguage.value) {
               language.selectedDocLanguage = true;
            }
         }
         if (!this.form.translatedLanguage.value) {
            this.form.translatedLanguage.setValue('0');
         }
      } else {
         for (let language of this.languages) {
            language.selectedTranslateLanguage = false;
            if (language.value === this.form.translatedLanguage.value) {
               language.selectedTranslateLanguage = true;
            }
         }
      }
   }

   resetDate() {
      this.assignmentForm.controls.lateSubmissionsDate.reset();
   }

   resetSubmissionsNr() {
      this.assignmentForm.controls.nrReSubmissions.reset();
   }


   isValid(field) {
      return (field.invalid && field.touched) || (field.invalid && this.submitClicked);
   }

   openLink(url: string) {
    window.open(url, '_blank');
   }

   toggleAdditionalSettings() {
      this.additionalSettings = !this.additionalSettings;
   }

   onFormChange(formValues: any) {
    console.log('Form changed:', formValues);
    formValues.startDate = this.assignmentData.startDate;
    formValues.endDate = this.assignmentData.endDate;
    formValues.description = this.assignmentData.description;
    formValues.name = this.assignmentData.name;
    formValues.numberOfReSubmisions = this.assignmentData.numberOfReSubmisions;
    this.assignmentService.editAssignment(formValues, this.assignmentId).subscribe((data) => {
       console.log(data);
    });
 }
}
