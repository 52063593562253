import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as authActions from '../state/actions/authentication.actions';
import { AssignmentService } from 'src/app/services/assignment.service';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-lti-login',
  templateUrl: './lti-login.component.html',
  styleUrls: ['./lti-login.component.scss']
})
export class LtiLoginComponent implements OnInit {
  data: string;
  loginForm: any;
  constructor(private route: ActivatedRoute, private router: Router, private store: Store, private assignmentService: AssignmentService, private languageService: LanguageService) { }

  ngOnInit(): void {
    localStorage.clear();
    this.route.params.subscribe(params => {
      let userData;
      let token;
      this.data = JSON.parse(params['token']);

      userData = this.data['userDetails']['user']
      console.log(this.data['userDetails'])
      token = this.data['userDetails']['token']

      localStorage.setItem('ACCESS_TOKEN', JSON.stringify(token));
      localStorage.setItem('role', JSON.stringify(userData.roleId));
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('aId', JSON.stringify(this.data['assignmentId']))
      localStorage.setItem('websiteLanguage', userData.language);

      this.languageService.setSelectedLanguage(userData.language);

      this.store.dispatch(
        authActions.ltiSuccess({
          user: userData
        })
      );

      if (userData.roleId === 10) {
        this.router.navigateByUrl(
          `professor/administration/assignments/${this.data['assignmentId']}`
        );

      } else if (userData.roleId === 9) {
        this.assignmentService.getAssignment(this.data['assignmentId']).subscribe((data) => {
          if (data['Submissions'].length === 0) {
            this.router.navigateByUrl(
              `student/upload/upload-file?assignmentId=${this.data['assignmentId']}`
            );
          }else{
            this.router.navigateByUrl('/student/statistics/submissions-statistics/assignments');
          }
        })

      } else {
        this.router.navigateByUrl('administrator');
      }

    });
  }

}
