<div class="transaction">
   <ng-container
      *ngIf="
         this.currentUser.institutionId !== null &&
         this.currentUser.roleId === 2
      "
   >
      <span> {{ 'app.filter_by_type' | translate }} </span>
      <select class="border" (change)="filterType($event.target.value)">
         <option value="1" selected>{{ 'general.credits' | translate }}</option>
         <option value="2">{{ 'general.thesis' | translate }}</option>
         <option value="3">{{ 'app.upload.drafts' | translate }}</option>
      </select>
   </ng-container>
   <main>
      <div *ngIf="currentUser?.wordsToUse < 10" class="flex border">
         <div>
            <i class="fas fa-clock"></i>
            <h6>{{ 'add_credits.you_only_have' | translate }} {{ currentUser?.wordsToUse }} {{ 'add_credits.credits_left' | translate }}</h6>
         </div>
         <a routerLink="/individual/add-credits"
            ><h5 class="bold">{{ 'profile.buy' | translate }}</h5></a
         >
      </div>
      <div class="scroll">
         <i
            class="animate__shakeX animate__animated fas fa-long-arrow-alt-right"
         ></i>
         <div class="grid border">
            <h5 class="bold">#</h5>
            <h5 class="bold">{{ 'general.number' | translate }}</h5>
            <h5 class="bold">{{ 'general.date' | translate }}</h5>

            <ng-container *ngIf="this.type == 0 || this.type == 1">
               <h5 class="bold">{{ 'general.credits' | translate }}</h5>
               <h5 class="bold">{{ 'general.words' | translate }}</h5>
            </ng-container>

            <ng-container *ngIf="this.type != 0 && this.type != 1">
               <h5 class="bold">{{ 'other.quantity' | translate }}</h5>
               <h5 class="bold">{{ 'app.price' | translate }}</h5>
            </ng-container>

            <h5 class="bold">{{ 'general.total' | translate }}</h5>
            <h5 class="bold">
               {{ 'general.download' | translate }}
            </h5>
         </div>
         <ng-container
            *ngFor="
               let transaction of transactionsPagination?.transactions
                  | paginate
                     : {
                          id: 'transactionsPagination',
                          itemsPerPage: pageSize,
                          currentPage: pageTransaction,
                          totalItems:
                             transactionsPagination?.userTotalTransactions
                       };
               let i = index
            "
         >
            <div class="grid">
               <h5>{{ i + 1 }}</h5>
               <h5>{{ transaction.refNo }}</h5>
               <h5>{{ transaction.createdAt.split('T')[0] }}</h5>
               <ng-container *ngIf="this.type == 0 || this.type == 1">
                  <h5>{{ transaction.words / 100 }}</h5>
                  <h5>{{ transaction.words }}</h5>
               </ng-container>
               <ng-container *ngIf="this.type != 0 && this.type != 1">
                  <h5>{{ transaction.qty }}</h5>
                  <h5>
                     {{
                        transaction.price | currency: transaction.currency:true
                     }}
                  </h5>
               </ng-container>
               <h5>
                  {{
                     transaction.price * transaction.qty
                        | currency: transaction.currency:true
                  }}
               </h5>
               <div>
                  <button
                     class="border"
                     (click)="downloadPdfInvoice(transaction.id)"
                  >
                     <i class="fas fa-download"></i>
                  </button>
               </div>
            </div>
         </ng-container>
         <pagination-controls
            id="transactionsPagination"
            class="num"
            (pageChange)="changeTransactionPage($event)"
            autoHide="true"
            responsive="true"
            previousLabel=""
            nextLabel=""
         ></pagination-controls>
      </div>
   </main>
</div>
