// Shared Service
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentData {
  setIsNavigationClicked(arg0: boolean) {
    throw new Error('Method not implemented.');
  }
  // private totalPagesSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private currentPageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  private currentScrollingPageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public totalPagesSubject = new BehaviorSubject<number>(0)
  public totalPagesObject = this.totalPagesSubject as Observable<number>

  // private totalPages: number = 0;

  setTotalPages(pages: number): void {
    this.totalPagesSubject.next(pages);
  }
  getTotalPages(): Observable<number> {
    return this.totalPagesSubject.asObservable();
  }

  setCurrentPage(page: number): void {
    this.currentPageSubject.next(page)
  }
  getCurrentPage(): Observable<number> {
    return this.currentPageSubject.asObservable();
  }
  setCurrentScrollingPage(page: number): void {
    this.currentScrollingPageSubject.next(page);
  }
  getCurrentScrollingPage(): Observable<number> {
    return this.currentScrollingPageSubject.asObservable();
  }
}
