<div class="personal-information">
   <main>
      <form action="" [formGroup]="passwordForm">
         <h5>{{ 'general.current_password' | translate }}</h5>
         <div class="password">
            <input
               [type]="showPassword ? 'text' : 'password'"
               formControlName="currentPassword"
            />
            <span class="input-group-text" (click)="password()">
               <i
                  class="fa"
                  [ngClass]="{
                     'fa-eye-slash': !showPassword,
                     'fa-eye': showPassword
                  }"
               ></i>
            </span>
         </div>
         <div
            *ngIf="form.currentPassword.invalid && form.currentPassword.touched"
            class="invalid"
         >
            <div *ngIf="form.currentPassword.errors.required">
               {{ 'validators.password_required' | translate }}
            </div>
         </div>
         <h5>{{ 'general.new_password' | translate }}</h5>
         <div class="password">
            <input
               [type]="showNewPassword ? 'text' : 'password'"
               name=""
               id=""
               formControlName="newPassword"
            />
            <span class="input-group-text" (click)="newPassword()">
               <i
                  class="fa"
                  [ngClass]="{
                     'fa-eye-slash': !showNewPassword,
                     'fa-eye': showNewPassword
                  }"
               ></i>
            </span>
         </div>
         <div
            *ngIf="form.newPassword.invalid && form.newPassword.touched"
            class="invalid"
         >
            <div *ngIf="form.newPassword.errors.required">
               {{ 'validators.newpassword_required' | translate }}
            </div>
            <div
               *ngIf="
                  form.newPassword.errors.pattern &&
                  form.newPassword.value !== form.currentPassword.value
               "
            >
               {{ 'validators.password_pattern' | translate }}
            </div>
         </div>
         <div
            class="invalid"
            *ngIf="
               form.newPassword.value == form.currentPassword.value &&
               form.newPassword.touched &&
               form.newPassword.value !== ''
            "
         >
            {{ 'validators.different_newpassowrd' | translate }}
         </div>
         <h5>{{ 'general.confirm_new_password' | translate }}</h5>
         <div class="password">
            <input
               [type]="showConfirmPassword ? 'text' : 'password'"
               name=""
               id=""
               formControlName="confirmNewPassword"
            />
            <span class="input-group-text" (click)="confirmPassword()">
               <i
                  class="fa"
                  [ngClass]="{
                     'fa-eye-slash': !showConfirmPassword,
                     'fa-eye': showConfirmPassword
                  }"
               ></i>
            </span>
         </div>
         <div
            class="invalid"
            *ngIf="
               form.newPassword.value !== form.confirmNewPassword.value &&
               form.newPassword.touched &&
               form.newPassword.value !== ''
            "
         >
            {{ 'validators.different_newpassowrd' | translate }}
         </div>
         <div
            *ngIf="
               form.confirmNewPassword.invalid &&
               form.confirmNewPassword.touched
            "
            class="invalid"
         >
            <div
               class="invalid"
               *ngIf="form.confirmNewPassword.errors.required"
            >
               {{ 'validators.confirmpassword_required' | translate }}
            </div>
         </div>
         <div
            class="invalid"
            *ngIf="
               form.confirmNewPassword.value !== form.newPassword.value &&
               form.confirmNewPassword.touched &&
               form.confirmNewPassword.value !== ''
            "
         >
            {{ "validators.passwords_don't_match" | translate }}
         </div>

         <div>
            <!-- <div class="center">
               <button class="submit" (click)="activateAccount()">
                  {{ 'login.activate_account' | translate }}
               </button>
            </div> -->

            <div class="center">
               <button [disabled]="
                              passwordForm.invalid ||
                              form.newPassword.value !== form.confirmNewPassword.value ||
                              form.confirmNewPassword.value == '' ||
                              form.newPassword.value == form.currentPassword.value
                           " [ngClass]="{
                              disable:
                                 passwordForm.invalid ||
                                 form.newPassword.value !== form.confirmNewPassword.value ||
                                 form.confirmNewPassword.value == '' ||
                                 form.newPassword.value == form.currentPassword.value
                           }" class="submit" type="submit" (click)="updatePassword()">
                  {{ 'general.save' | translate }}
               </button>
            </div>
         </div>
      </form>
   </main>
</div>
