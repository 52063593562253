import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { getCurrentUser } from '../../../authModule/state/authentication.selectors';
// import { TawkService } from '../../../services/tawk.service';
import { UserService } from '../../../services/user.service';

@Component({
   selector: 'app-contact-us',
   templateUrl: './contact-us.component.html',
   styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit, OnDestroy {
   /**
    * Form group to contact us form values
    */
   contactUsForm: FormGroup;
   /**
    * User subscriber
    */
   user$;
   /**
    * Used to store user data from the store.
    */
   user;
   chatVisible = false;

   /**
    * Component constructor.
    * @param fb
    * @param userService
    * @param store
    * @param spinner
    * @param toastrService
    * @param tawkService
    */
   constructor(
      private fb: FormBuilder,
      private userService: UserService,
      private store: Store,
      private spinner: NgxSpinnerService,
      private toastrService: ToastrService
      // private tawkService: TawkService
   ) { }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });
      this.contactUsForm = this.fb.group({
         fullName: [this.user.name, [Validators.required]],
         email: [this.user.email, [Validators.email, Validators.required]],
         phoneNumber: ['', [Validators.required]],
         message: ['', Validators.required],
         request: ['', Validators.required],
      });
   }
   /**
    * Method use to open user default email client if he want us to email us directly
    */
   emailUs() {
      window.location.href =
         'mailto:support@crossplag.com?subject=Subject&body=message%20goes%20here';
   }
   /**
    * Method used to open crossplag facebook page.
    */
   openFacebook() {
      window.open('https://www.facebook.com/crossplagofficial/', '_blank');
   }
   /**
    * Method is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed. In our case we will use to unsubscribe.
    */
   ngOnDestroy() {
      this.user$.unsubscribe();
   }
   /**
    * Method used to send email from contact us bank form.
    */
   sendMessage() {
      this.spinner.show();
      this.userService
         .sendMessage(
            this.formControls.fullName.value,
            this.formControls.email.value,
            this.formControls.phoneNumber.value,
            this.formControls.message.value,
            this.user.id,
            this.formControls.request.value
         )
         .pipe(first())
         .subscribe((data) => {
            this.spinner.hide();
            this.contactUsForm.controls['phoneNumber'].reset();
            this.contactUsForm.controls['message'].reset();
            this.contactUsForm.controls['request'].reset();
            // this.contactUsForm.reset();
            this.toastrService.success(data.message);
         }),
         (error) => {
            console.log('error', error);
         };
   }
   /**
    * Method used to show tawk chat.
    */
   startChat() {
      this.chatVisible = !this.chatVisible;
      // this.tawkService.SetChatVisibility(this.chatVisible);
      // this.tawkService.UpdateTawkUser(this.user);
   }
   /**
    * Method used to get from controls from the contactUsForm.
    */
   get formControls() {
      return this.contactUsForm.controls;
   }
   /**
    * Method used to show a toaster with a warning message to the user.
    * @param message
    */
   showToasterWarning(message) {
      this.toastrService.warning(message);
   }
}
