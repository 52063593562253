import {NgZone, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges, OnChanges, HostListener, ViewEncapsulation} from '@angular/core';
import {DocumentData} from '../../../services/document-data.service';
import {Subscription} from 'rxjs';
import {ReportService} from "../../../services/report.service";

@Component({
  selector: 'app-document-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DocumentNavigation implements OnInit, OnDestroy, OnChanges {
  title = 'Document Navigation';
  totalPages!: number;
  pagesArray: number[] = [];
  activeSectionIndex!: number;
  @Input() navigationDetails: any
  visiblePages: any[] = [];
  numVisiblePages: number = 10;

  isNavClicked = false;
  isOnAnalysis:string = 'none'
  private totalPagesSubscription!: Subscription;
  private currentPageSubscription!: Subscription;
  private currentScrollingPageSubscription!: Subscription;
  private highlightedPagesCache = new Map<number, boolean>();

  constructor(public documentDataService: DocumentData, private cdr: ChangeDetectorRef, private reportService:ReportService, private zone: NgZone) {
    this.updateWindowSize(window.innerWidth);
  }

  ngOnInit(): void {
    this.reportService.selectAnalysisTypeObject.subscribe((data:string)=>{
      this.isOnAnalysis = data
    })
    this.totalPagesSubscription = this.documentDataService.getTotalPages().subscribe(pages => {
      this.totalPages = pages;
      this.pagesArray = Array.from({length: pages}, (_, index) => index + 1);
      this.updateVisiblePages(this.navigationDetails)
    });


    this.currentPageSubscription = this.documentDataService.getCurrentPage().subscribe(page => {
      if (!this.isNavClicked) {
        this.currentScrollingPageSubscription = this.documentDataService.getCurrentScrollingPage().subscribe(page => {
          this.activeSectionIndex = page;

          if (this.visiblePages.some(item => item.ellipsis && item.value === page)) {
            this.updateVisiblePages(this.navigationDetails);
          }
          this.updateVisiblePages(this.navigationDetails);

          this.isNavClicked = true;

          this.zone.run(() => {
            this.cdr.detectChanges();
          })

        })
      } else {
        this.activeSectionIndex = page;
      }

    });

    this.currentPageSubscription = this.documentDataService.getCurrentPage().subscribe(page => {
      this.activeSectionIndex = page;
      this.updateVisiblePages(this.navigationDetails);
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const navigationDetailsChange = changes['navigationDetails'];
    if (navigationDetailsChange && !navigationDetailsChange.isFirstChange()) {
      this.navigationDetails = navigationDetailsChange.currentValue;

      this.visiblePages = [];
      this.highlightedPagesCache.clear();
      this.cdr.detectChanges();
    }
    if (this.navigationDetails && this.navigationDetails!.length > 22) {
      this.updateVisiblePages(this.navigationDetails);
    } else if (this.navigationDetails){
      this.navigationDetails.forEach((tab: any) => {
        this.visiblePages.push(tab.page);
      })
    }
    this.updateVisiblePages(this.navigationDetails);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateWindowSize(event.target.innerWidth);
  }

  updateWindowSize(width: number) {
    if (width <= 450) {
      this.numVisiblePages = 2;
    } else if (width <= 600) {
      this.numVisiblePages = 4
    } else if (width <= 700) {
      this.numVisiblePages = 5;
    } else if (width <= 900) {
      this.numVisiblePages = 7;
    } else if (width <= 930) {
      this.numVisiblePages = 2;
    } else if (width <= 990) {
      this.numVisiblePages = 3;
    } else if (width <= 1150) {
      this.numVisiblePages = 4;
    } else if (width <= 1200) {
      this.numVisiblePages = 6;
    } else if (width <= 1400) {
      this.numVisiblePages = 7;
    } else {
      this.numVisiblePages = 10;
    }

    this.updateVisiblePages(this.navigationDetails);
  }

  updateVisiblePages(navigation: any) {
    if (navigation && navigation!.length <= (this.numVisiblePages * 2) + 2) {
      this.visiblePages = this.pagesArray;
      return;
    }

    // const numVisiblePages = 10;
    const startPage = Math.max(1, this.activeSectionIndex - this.numVisiblePages);
    const endPage = Math.min(navigation?.length, this.activeSectionIndex + this.numVisiblePages);

    this.visiblePages = [];

    if (startPage > 1) {
      this.visiblePages.push(1);
      if (startPage > 2) {
        const ellipsisVal = startPage - 1;
        this.visiblePages.push({ ellipsis: true, value: ellipsisVal });
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      if (!this.visiblePages.some(item => typeof item === 'number' && item === i)) {
        this.visiblePages.push(i);
      }
    }

    if (endPage < navigation?.length) {
      if (endPage < navigation.length - 1) {
        const ellipsisVal = endPage + 1;

        if (!this.visiblePages.some(item => typeof item === 'number' && item === ellipsisVal)) {
          this.visiblePages.push({ ellipsis: true, value: ellipsisVal });
        }
      }
      if (!this.visiblePages.some(item => typeof item === 'number' && item === navigation[endPage].page)) {
        this.visiblePages.push(navigation[navigation.length - 1].page);
      }
    }

  }

  setActiveSection(page: any) {
    this.isNavClicked = false;
    if (typeof page === 'number') {
      this.activeSectionIndex = page;
    } else if (page.ellipsis) {
      this.activeSectionIndex = page.value;
    }
    this.documentDataService.setCurrentPage(this.activeSectionIndex);
    this.cdr.detectChanges()
  }

  hasHighlightedSentences(page: number): boolean {
    if (this.highlightedPagesCache.has(page)) {
      return this.highlightedPagesCache.get(page)!;
    }
    const navigationArray = this.navigationDetails;

    let hasHighlightedOriginal;
    if(navigationArray){
       hasHighlightedOriginal = navigationArray.some((item: any) => (item).page === page && (item).numberOfSentences > 0);
    }
    this.highlightedPagesCache.set(page, hasHighlightedOriginal);
    return hasHighlightedOriginal;
  }

  ngOnDestroy(): void {
    this.totalPagesSubscription.unsubscribe();
    this.currentPageSubscription.unsubscribe();
    this.currentScrollingPageSubscription.unsubscribe();
  }
}
