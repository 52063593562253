<div class="emailConfirmationSend">
    <img src="../../../../assets/images/Vector (3).png" alt="">
    <div>
        <img class="logo" src="../../../../assets/images/Crossplag_Logo (3).png" alt="">
        <img class="emailLogo" src="../../../../assets/images/Group 33897.png" alt="">
        <h3>{{ 'app.check_your_email' | translate }}</h3>
        <p>You successfully registred on Inspera AS.<br/>
            We sent a link to activate your account to</p>
        <h4>{{email}}</h4>
    <hr>
        <span class="notReceived">{{ 'login.didnt_receive_email' | translate }}?</span>
        <button (click)="resendEmail()">{{ 'app.resend_email' | translate }}</button>
        <a routerLink="/login"> <i class="fas fa-arrow-left"></i>
            Back to log in</a>
            <span class="warning">*Please allow up to 5 minutes for the email to arrive.</span>
    </div>

</div>
