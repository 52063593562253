<div class="editimi-departamentit-modal">
   <div class="edit-department-title">
      <h4>{{ 'app.edit.edit_department' | translate }}</h4>
   </div>
   <form [formGroup]="departmentEdit">
      <div class="editimi-departamentit-inputs">
         <div class="fieldContainer">
            <label for="Emri">{{ 'general.name' | translate }}</label>
            <input id="Emri" type="text" formControlName="departmentName" />
            <div
               *ngIf="
                  departmentEdit.controls.departmentName.touched &&
                  departmentEdit.controls.departmentName.invalid
               "
               class="invalid"
            >
               {{ 'app.info.department_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <div
               *ngIf="departmentEdit.controls.departmentName.errors?.minlength"
               class="invalid"
            >
               {{ 'app.info.department_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
            </div>
         </div>
         <div class="fieldContainer">
            <label for="Emri">{{ 'app.institution' | translate }}</label>
            <select formControlName="departmentFaculty">
               <ng-container *ngFor="let faculty of this.faculties">
                  <option
                     value="{{ faculty.id }}"
                     [selected]="
                        faculty.id ===
                        departmentEdit.controls.departmentFaculty.value
                     "
                  >
                     {{ faculty.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  departmentEdit.controls.departmentFaculty.touched &&
                  departmentEdit.controls.departmentFaculty.invalid
               "
               class="invalid"
            >
               {{ 'general.department' | translate }} {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
         </div>
         <div class="fieldContainer">
            <label for="Emri">{{ 'app.duration_in_years' | translate }}</label>
            <input id="Emri" type="text" formControlName="departmentYear" />
            <div
               *ngIf="
                  departmentEdit.controls.departmentYear.touched &&
                  departmentEdit.controls.departmentYear.invalid
               "
               class="invalid"
            >
               {{ 'app.department_academic_years_are_required' | translate }}*
            </div>
         </div>
         <div class="fieldContainer">
            <label for="Emri">{{ 'app.level_of_studies' | translate }}</label>
            <!-- <input id="Emri" type="text" formControlName="departmentLevel" /> -->
            <select formControlName="departmentLevel">
               <!-- <option value="-1" selected disabled>
                  Choose level
               </option> -->
               <option value="1">{{ 'general.bachelor' | translate }}</option>
               <option value="2">{{ 'general.master' | translate }}</option>
               <option value="3">{{ 'general.phd' | translate }}.</option>
            </select>
            <div
               *ngIf="
                  departmentEdit.controls.departmentLevel.touched &&
                  departmentEdit.controls.departmentLevel.invalid
               "
               class="invalid"
            >
               {{ 'general.department' | translate }} {{ 'general.level' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
         </div>
         <div class="fieldContainer">
            <label for="Emri">{{ 'general.description' | translate }}</label>
            <textarea
               formControlName="departmentDescription"
               name=""
               id=""
               cols="30"
               rows="2"
            ></textarea>
            <div
               *ngIf="
                  departmentEdit.controls.departmentDescription.touched &&
                  departmentEdit.controls.departmentDescription.invalid
               "
               class="invalid"
            >
               {{ 'general.department' | translate }} {{ 'general.description' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <div
               *ngIf="
                  departmentEdit.controls.departmentDescription.errors
                     ?.minlength
               "
               class="invalid"
            >
               {{ 'general.department' | translate }} {{ 'general.description' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
            </div>
         </div>
      </div>
      <div class="buttons">
         <a class="fshij-link" (click)="delete()">{{ 'app.delete' | translate }}</a>
         <button
            class="perfundo-dep-editim"
            [disabled]="departmentEdit.invalid"
            [ngClass]="{
               disable: departmentEdit.invalid
            }"
            type="submit"
            (click)="update()"
         >
            SAVE
         </button>
      </div>
   </form>
</div>
<div id="overlay"></div>
